import apiClient from "./apiClient";

export const getUserIntegrations = async () => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get("/user-integration", {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getIntegrationCategory = async () => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get("/integration-category", {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getIntegrationById = async (integration_id) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get(`/integration/${integration_id}`, {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateIntegration = async (integration_id, integrationData) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.put(
      `/user-integration/${integration_id}`,
      integrationData,
      {
        headers: {
          website_id: websiteId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
