// services/authService.ts
import { signOut } from "aws-amplify/auth";
import { persistor } from "../store"; // Import Redux Persistor if used

export const handleLogout = async (navigate) => {
  try {
    await signOut();
    sessionStorage.clear();
    localStorage.clear();
    persistor.purge();
    navigate("/login"); // Redirect user
    window.location.reload();
  } catch (err) {
    throw err;
  }
};
