import apiClient from "./apiClient";

export const getDashboardStats = async (days) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get(`/dashboard_stats/?days=${days}`, {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDashboardVisitors = async (days) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get(`/dashboard_visitors/?days=${days}`, {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDashboardConsents = async (days) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get(`/dashboard_consents/?days=${days}`, {
      headers: {
        website_id: websiteId,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDashboardCountires = async (days) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get(`/dashboard_countries/?days=${days}`, {
      headers: {
        website_id: websiteId,
      },
    });
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDashboardDevices = async (days) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get(`/dashboard_devices/?days=${days}`, {
      headers: {
        website_id: websiteId,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
