import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Grid,
  Divider,
  CircularProgress,
  FormHelperText,
  Stack,
  IconButton,
  Drawer
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TranslateIcon from "@mui/icons-material/Translate";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { getAllLanguages } from "../api/websiteService";
import { showSuccessToast, showErrorToast, showInfoToast } from "../toastUtils";
import ButtonCommon from "./ButtonCommon";
import { CancelOutlined } from "@mui/icons-material";
import { deleteServicesTranslations, getServicesTranslations, saveServicesTranslations, updateServicesTranslations } from "../api/chooseService";
import DeletePopup from "./DeletePopup";

const TranslationModal = ({ isOpen, onClose, service }) => {
  const [allLanguages, setAllLanguages] = useState([]);
  const [translations, setTranslations] = useState({});
  const [isBulkTranslating, setIsBulkTranslating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [availableLanguagesToAdd, setAvailableLanguagesToAdd] = useState([]);
  const [activeLanguageCards, setActiveLanguageCards] = useState([])
  const [supportedLanguages, setSupportedLanguages] = useState(["en"]);
  const [defaultLanguage] = useState("en");
  const [savedTranslations, setSavedTranslations] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errors, setErrors] = useState({
    service_name: false,
    description: false,
  })
  const [isEdited, setIsEdited] = useState(false);



  // console.log('service', service);

  const validateForm = (langCode) => {
    const newErrors = {
      service_name: !translations[langCode]?.service_name,
      description: !translations[langCode]?.description,
    };

    // setErrors(newErrors);
    setErrors((prev) => ({
      ...prev,
      [langCode]: newErrors
    }));

    if (newErrors.service_name) {
      showErrorToast("Please enter a service name");
    } else if (newErrors.description) {
      showErrorToast("Please enter a service description");
    }

    return !Object.values(newErrors).some(error => error);
  };

  // Original service content in default language
  const defaultContent = {
    service_name: service?.service_name || "",
    description: service?.description || "",
  };

  // Fetch available languages
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        setLoading(true);
        const response = await getAllLanguages();
        if (response?.status_code === 200 && Array.isArray(response.data)) {
          setAllLanguages(response.data);
        }

        // Check if service has existing translations and set them
        if (service?.translations) {
          setTranslations(service.translations);
          const translationLanguages = Object.keys(service.translations);
          if (translationLanguages.length > 0) {
            setSupportedLanguages([...new Set(["en", ...translationLanguages])]);
          }
        }
      } catch (error) {
        console.error("Error fetching languages:", error);
        showErrorToast("Failed to load languages");
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchLanguages();
    }
  }, [isOpen, service]);


  // Fetch service translations
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        setLoading(true);
        const response = await getServicesTranslations(service.service_id); // Wait for the API call
        // console.log('getCategoryTranslations', response);

        if (response.success && response.data) {
          // Convert array of translations into an object { langCode: { ...translationData } }
          const formattedTranslations = response.data.reduce((acc, item) => {
            acc[item.language_code] = item; // Store each translation under its language code
            return acc;
          }, {});

          setTranslations(formattedTranslations);
          setActiveLanguageCards(Object.keys(formattedTranslations))

          // Mark saved translations
          const savedTranslationsState = Object.keys(formattedTranslations).reduce((acc, key) => {
            acc[key] = true;
            return acc;
          }, {});
          setSavedTranslations(savedTranslationsState);
        }
      } catch (error) {
        console.error("Error fetching category translations:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTranslations();
    //eslint-disable-next-line
  }, []); // Empty dependency array means it runs only on mount


  useEffect(() => {
    // Early return if required data is not available
    if (!Array.isArray(allLanguages) || !allLanguages.length) {
      setAvailableLanguagesToAdd([]);
      return;
    }

    try {
      const availableLanguages = allLanguages.filter(lang => {
        // Add null checks for all properties
        if (!lang || typeof lang !== 'object') return false;

        const isDefaultLang = lang?.language_description !== defaultLanguage;
        const isSupported = Array.isArray(supportedLanguages) &&
          supportedLanguages.includes(lang?.language_code);
        const isNotTranslated = !Object.keys(translations).includes(lang?.language_code);

        return (isDefaultLang || isSupported) && isNotTranslated;
      })
        .map(lang => {
          // Ensure we have valid data before mapping
          if (!lang?.language_code || !lang?.language_description) {
            return null;
          }

          return {
            code: lang.language_code,
            name: lang.language_description
          };
        })
        .filter(Boolean); // Remove any null values from mapping

      setAvailableLanguagesToAdd(availableLanguages);
    } catch (error) {
      console.error('Error processing languages:', error);
      setAvailableLanguagesToAdd([]);
    }
    // eslint-disable-next-line
  }, [allLanguages, service]);

  // Handler to update translations for a specific language and field
  const handleTranslationChange = (langCode, field, value) => {
    // setIsEdited(true)
    setIsEdited((prev) => ({
      ...prev,
      [langCode]: true
    }))

    setTranslations(prev => ({
      ...prev,
      [langCode]: {
        ...(prev[langCode] || {}),
        [field]: value
      }
    }));

    if (errors?.[langCode]?.[field]) { // Ensure errors[langCode] and errors[langCode][field] exist
      setErrors(prev => ({
        ...prev,
        [langCode]: {
          ...(prev?.[langCode] || {}), // Ensure previous state exists
          [field]: false // Update only the specific field
        }
      }));
    }

  };

  // Function to translate text using an AI service (currently mocked)
  const translateWithAI = async (sourceText, targetLang) => {
    if (!sourceText || sourceText.trim() === '') return '';

    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 500));

    // Mock translation (in real implementation, this would call an AI translation API)
    const mockTranslations = {
      es: {
        "Google Analytics": "Google Analytics",
        "We use Google Analytics": "Usamos Google Analytics",
        "This cookie is used for analytics": "Esta cookie se utiliza para análisis"
      },
      fr: {
        "Google Analytics": "Google Analytics",
        "We use Google Analytics": "Nous utilisons Google Analytics",
        "This cookie is used for analytics": "Ce cookie est utilisé pour l'analyse"
      },
      de: {
        "Google Analytics": "Google Analytics",
        "We use Google Analytics": "Wir verwenden Google Analytics",
        "This cookie is used for analytics": "Dieser Cookie wird für die Analyse verwendet"
      }
    };

    // Return a mock translation or the original text with language prefix
    return mockTranslations[targetLang]?.[sourceText] || `[${targetLang.toUpperCase()}] ${sourceText}`;
  };

  // Handler for translating a single field
  const handleTranslateField = async (langCode, field) => {
    try {
      const sourceText = defaultContent[field];
      if (!sourceText) return;

      const translatedText = await translateWithAI(sourceText, langCode);
      handleTranslationChange(langCode, field, translatedText);
    } catch (error) {
      console.error("Translation error:", error);
      showErrorToast(`Failed to translate ${field}`);
    }
  };

  // Handler for translating all fields for a language
  const handleTranslateAllForLanguage = async (langCode) => {
    try {
      const langObj = allLanguages.find(l => l.language_code === langCode);
      const langName = langObj ? langObj.language_description : langCode;

      showInfoToast(`Translating content to ${langName}...`);

      // Translate all fields in parallel
      const fields = Object.keys(defaultContent);
      await Promise.all(fields.map(field => handleTranslateField(langCode, field)));

      showSuccessToast(`Content translated to ${langName}`);
    } catch (error) {
      console.error("Translation error for language:", error);
      showErrorToast(`Failed to translate to ${langCode}`);
    }
  };

  // Handler for bulk translating all languages and all fields
  const handleBulkTranslate = async () => {
    setIsBulkTranslating(true);
    try {
      // Get languages to translate (all supported except default)
      const langsToTranslate = supportedLanguages.filter(lang => lang !== defaultLanguage);

      if (langsToTranslate.length === 0) {
        showInfoToast("No languages selected for translation");
        return;
      }

      showInfoToast(`Starting bulk translation for ${langsToTranslate.length} languages...`);

      // For each language, translate all fields
      for (const langCode of langsToTranslate) {
        await handleTranslateAllForLanguage(langCode);
      }

      showSuccessToast("All translations completed");
    } catch (error) {
      console.error("Bulk translation error:", error);
      showErrorToast("Failed to complete all translations");
    } finally {
      setIsBulkTranslating(false);
    }
  };

  // Handler to save translations
  const handleSaveTranslation = async (langCode) => {
    if (!validateForm(langCode)) {
      return;
    }

    const isExistingTranslation = savedTranslations?.[langCode];

    try {
      // setIsSaving(true);
      setIsSaving((prev) => ({
        ...prev,
        [langCode]: true
      }));

      let response = null;

      // Check for translation ID early if updating
      if (isExistingTranslation) {
        const translationId = translations?.[langCode]?.service_translate_id;
        if (!translationId) {
          showErrorToast('Error: Translation ID missing for update.');
          return;
        }

        const payload = {
          service_name: translations[langCode].service_name,
          description: translations[langCode].description
        };

        response = await updateServicesTranslations(translationId, payload);
      } else {
        const payload = {
          parent_service_id: service.service_id,
          service_name: translations[langCode].service_name,
          description: translations[langCode].description,
          language_code: langCode
        };

        response = await saveServicesTranslations(payload);
        // console.log('save', response);

        if (response?.success) {
          // After saving transalation we are fetching translation id
          setTranslations(prev => ({
            ...prev,
            [langCode]: {
              ...prev[langCode],
              service_translate_id: response?.data?.service_translate_id
            }
          }))

          // After saving save button should show update text
          setSavedTranslations(prev => ({
            ...prev,
            [langCode]: true
          }))
        }
      }

      // Early return if response is not what we expect
      if (!response.success) {
        throw new Error('No response received from server');
      }

      // Success case
      if (response.success) {
        showSuccessToast(
          isExistingTranslation
            ? 'Translation updated successfully'
            : 'Translation saved successfully'
        );
      } else {
        // If response indicates failure
        throw new Error(response.message || 'Operation failed');
      }

      // setIsEdited(false)
      setIsEdited((prev) => ({
        ...prev,
        [langCode]: false
      }))
    } catch (error) {
      console.error('Save translation error:', error);
      showErrorToast(
        isExistingTranslation
          ? 'Failed to update translation'
          : 'Failed to save translation'
      );
    } finally {
      // setIsSaving(false);
      setIsSaving((prev) => ({
        ...prev,
        [langCode]: false
      }));
    }
  };

  // Handler to add a new language for translation
  const handleAddLanguage = (langCode) => {
    // console.log('availableLanguagesToAdd', availableLanguagesToAdd);

    // Check if language is already selected
    if (!langCode || activeLanguageCards?.includes(langCode)) {
      return;
    }

    // Add language to active cards
    setActiveLanguageCards([...activeLanguageCards, langCode]);

    // Remove selected language from available languages
    setAvailableLanguagesToAdd(prevAvailable =>
      prevAvailable.filter(lang => lang.code !== langCode)
    );
  };


  // Handler to remove a language from translations
  const handleRemoveLanguage = async (langCode) => {
    if (langCode === defaultLanguage) {
      showErrorToast("Cannot remove default language");
      return;
    }

    try {
      // Handle saved translation deletion from backend
      if (savedTranslations[langCode]) {
        await deleteServicesTranslations(translations[langCode]?.service_translate_id);
      }

      // Update translations state - remove the specific language
      setTranslations(prevTranslations => {
        const updatedTranslations = { ...prevTranslations };
        delete updatedTranslations[langCode];
        return updatedTranslations;
      });

      // Update activeLanguageCards - remove the specific language
      setActiveLanguageCards(prevCards =>
        prevCards.filter(card => card !== langCode)
      );

      // Update supportedLanguages - remove the specific language
      setSupportedLanguages(prevSupported =>
        prevSupported.filter(lang => lang !== langCode)
      );

      // Find the language object from allLanguages
      const languageToAdd = allLanguages.find(lang => lang.language_code === langCode);
      if (languageToAdd) {
        // Create the properly formatted language object
        const formattedLanguage = {
          code: languageToAdd.language_code,
          name: languageToAdd.language_description
        };

        // Add it back to available languages
        setAvailableLanguagesToAdd(prevAvailable => [...prevAvailable, formattedLanguage]);
      }

      // Update savedTranslations - remove the specific language
      setSavedTranslations(prevSaved => {
        const updatedSaved = { ...prevSaved };
        delete updatedSaved[langCode];
        return updatedSaved;
      });

      showSuccessToast("Language translation removed successfully");
    } catch (error) {
      console.error("Error deleting translation:", error);
      showErrorToast("Failed to remove language translation");
    }
  };

  // Get languages that need translation (supported languages excluding default)
  const languagesToTranslate = supportedLanguages.filter(
    lang => lang !== defaultLanguage
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: {
            xs: "100%",
            // sm: `${drawerWidth}px`,
            sm: '600px',
          },
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          // borderTopLeftRadius: borderRadius,
          // borderBottomLeftRadius: borderRadius,
          boxSizing: "border-box",
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        component="form"
      // onSubmit={handleSubmit} // Use the local handleSubmit
      >
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: "20px",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                component="h2"
                fontWeight="bold"
                color="#525455"
              >
                Translations for {service?.service_name}
              </Typography>
              <Typography variant="body2" color="#989898" sx={{ mt: 1 }}>
                Add translations for your service in multiple languages.
              </Typography>
            </Box>
            <IconButton onClick={onClose} size="small" sx={{ mt: -0.5 }}>
              <CancelOutlined />
            </IconButton>
          </Box>
        </Box>

        <Divider />

        <Box sx={{ p: 2 }}>
          {/* Header with Translate All Button */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3
          }}>
            <Typography variant="h6">
              Service Translations
            </Typography>

            <Button
              variant="contained"
              onClick={handleBulkTranslate}
              disabled={isBulkTranslating || languagesToTranslate.length === 0}
              startIcon={<TranslateIcon />}
              size="small"
            >
              {isBulkTranslating ? "Translating..." : "Translate All with AI"}
            </Button>
          </Box>

          {/* Information alert */}
          <Alert severity="info" sx={{ mb: 3 }}>
            Translate your service content to multiple languages. The default language ({defaultLanguage})
            is used as the source for translations.
          </Alert>

          {/* Add language selector */}
          <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
            <FormControl sx={{ minWidth: 200 }}>
              <Select
                value=''
                onChange={(event) => {
                  if (event?.target?.value) {
                    handleAddLanguage(event.target.value);
                  }
                }}
                disabled={!Array.isArray(availableLanguagesToAdd) || availableLanguagesToAdd.length === 0}
                displayEmpty
                renderValue={() => "Add Languages"}
              >
                {Array.isArray(availableLanguagesToAdd) && availableLanguagesToAdd.map(lang => (
                  lang && lang.code ? (
                    <MenuItem
                      key={lang.code}
                      value={lang.code}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <span>{lang.name} ({lang.code.toUpperCase()})</span>
                      {translations && translations[lang.code] && (
                        <Box component="span" sx={{ color: 'success.main', ml: 1 }}>
                          ✓
                        </Box>
                      )}
                    </MenuItem>
                  ) : null
                ))}
              </Select>
            </FormControl>
            <Typography variant="body2" color="text.secondary">
              {availableLanguagesToAdd.length === 0
                ? "All available languages are already added"
                : `${availableLanguagesToAdd.length} languages available to add`}
            </Typography>
          </Box>

          {/* No languages message */}
          {/* {activeLanguageCards.length === 0 && (
            <Typography sx={{ mb: 3 }}>
              No languages selected for translation. Add languages using the dropdown above.
            </Typography>
          )} */}

          {activeLanguageCards?.length === 0 && (
            <Alert severity="warning" sx={{ mb: 3 }}>
              There is no language translation added
            </Alert>
          )}

          {/* Language accordion sections */}
          {activeLanguageCards && activeLanguageCards.length > 0 && activeLanguageCards.map((langCode) => {
            const langObj = allLanguages.find(l => l.language_code === langCode);
            const langName = langObj ? langObj.language_description : langCode;

            return (
              <Accordion key={langCode} sx={{ mb: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">
                      {langName} ({langCode.toUpperCase()})
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {/* <Button
                        size="small"
                        startIcon={<TranslateIcon />}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTranslateAllForLanguage(langCode);
                        }}
                      >
                        Translate
                      </Button> */}
                      <Button
                        size="small"
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenModal((prev) => {
                            return { ...prev, [langCode]: true }
                          })
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <Typography variant="h6" sx={{ mb: 2 }}>Service Content</Typography>

                  <Box ml={2}>
                    <Grid container spacing={2} sx={{ display: 'flex', gap: 2 }}>

                      {/* Service Name */}
                      <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Service Name"
                            value={defaultContent?.service_name || ''}
                            // onChange={(e) => handleTranslationChange(langCode, 'service_name', e.target.value)}
                            disabled
                            helperText={
                              <Typography
                                variant="caption"
                                component="span"
                                sx={{ display: 'flex', flexDirection: 'column' }}
                              >
                                {/* <strong>Original ({defaultLanguage})</strong> */}
                                <strong>Original (English)</strong>
                                {/* {defaultContent.name} */}
                              </Typography>
                            }
                          // placeholder={defaultContent.name}
                          />
                        </Grid>
                        <Box mb={2}><ArrowRightAltIcon /></Box>
                        <Grid item xs={12}>
                          <FormControl fullWidth error={errors?.[langCode]?.service_name} required>
                            <TextField
                              fullWidth
                              label="Service Name"
                              value={translations[langCode]?.service_name || ''}
                              onChange={(e) => handleTranslationChange(langCode, 'service_name', e.target.value)}
                              helperText={
                                <Typography
                                  variant="caption"
                                  component="span"
                                  sx={{ display: 'flex', flexDirection: 'column' }}
                                >
                                  <strong>Translate ({langName})</strong>
                                  {/* {defaultContent.name} */}
                                </Typography>
                              }
                            // placeholder={defaultContent.name}
                            />
                            {errors?.[langCode]?.service_name && <FormHelperText>Service Name field is required</FormHelperText>}
                          </FormControl>
                        </Grid>
                      </Stack>

                      {/* Service Description */}
                      <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Service Description"
                            value={defaultContent?.description || ''}
                            multiline
                            rows={4}
                            // onChange={(e) => handleTranslationChange(langCode, 'description', e.target.value)}
                            disabled
                            helperText={
                              <Typography
                                variant="caption"
                                component="span"
                                sx={{ display: 'flex', flexDirection: 'column' }}
                              >
                                {/* <strong>Original ({defaultLanguage})</strong> */}
                                <strong>Original (English)</strong>
                                {/* {defaultContent.description?.substring(0, 100)} */}
                                {/* {defaultContent.description?.length > 100 ? '...' : ''} */}
                              </Typography>
                            }
                          // placeholder={defaultContent.description}
                          />
                        </Grid>
                        <Box mb={2}><ArrowRightAltIcon /></Box>
                        <Grid item xs={12}>
                          <FormControl fullWidth error={errors?.[langCode]?.description} required>
                            <TextField
                              fullWidth
                              label="Service Description"
                              value={translations[langCode]?.description || ''}
                              onChange={(e) => handleTranslationChange(langCode, 'description', e.target.value)}
                              multiline
                              rows={4}
                              helperText={
                                <Typography
                                  variant="caption"
                                  component="span"
                                  sx={{ display: 'flex', flexDirection: 'column' }}
                                >
                                  <strong>Translate ({langName})</strong>
                                  {/* {defaultContent.description?.substring(0, 100)} */}
                                  {/* {defaultContent.description?.length > 100 ? '...' : ''} */}
                                </Typography>
                              }
                            // placeholder={defaultContent.description}
                            />
                            {errors?.[langCode]?.description && <FormHelperText>Service Description field is required</FormHelperText>}
                          </FormControl>
                        </Grid>
                      </Stack>

                    </Grid>

                    {/* Save button for individual language (when banner already exists) */}
                    {service && (
                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                        <ButtonCommon
                          onClick={() => handleSaveTranslation(langCode)}
                          disabled={!isEdited?.[langCode]}
                        >
                          {isSaving?.[langCode]
                            ? (savedTranslations && savedTranslations[langCode]
                              ? "Updating Translation"
                              : "Saving Translation"
                            )
                            : (savedTranslations && savedTranslations[langCode]
                              ? "Update Translation"
                              : "Save Translation"
                            )
                          }
                        </ButtonCommon>
                      </Box>
                    )}
                  </Box>


                  {/* Storage Items section - can be added if needed */}
                  {/* {service?.storage_item && service.storage_item.length > 0 && (
                  <>
                    <Divider sx={{ my: 3 }} />
                    <Typography variant="h6" sx={{ mb: 2 }}>Storage Items</Typography>
                    <Alert severity="info" sx={{ mb: 2 }}>
                      Translation for storage items will be available in future updates.
                    </Alert>
                  </>
                )} */}

                </AccordionDetails>

                {openModal[langCode] && <DeletePopup openModal={openModal} setOpenModal={setOpenModal} handleRemoveLanguage={handleRemoveLanguage} langCode={langCode} />}
              </Accordion>
            );
          })}
        </Box>
      </Box>
    </Drawer>
  );
};

export default TranslationModal;
