import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Chip,
  Divider,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Switch,
  CircularProgress,
  FormHelperText,
  IconButton,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Add,
  AddCircleOutline,
  CancelOutlined,
  LaunchOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import CommonSubHeader from "../../CommonComponent/CommonSubHeader";
import ButtonCommon from "../../CommonComponent/ButtonCommon";
import { BriefcaseBlackIcon, StorageItemIcon } from "../../assets/svg";
import CommonDrawer from "../../CommonComponent/CommonDrawer";
import CustomBreadcrumb from "../../CommonComponent/CommonBreadcrumb";
import { useNavigate } from "react-router-dom";
import {
  createCategory,
  deleteCategory,
  getAllCategory,
  getCategoryById,
  updateCategory,
} from "../../api/category";
import { showErrorToast, showSuccessToast } from "../../toastUtils";
import { createService, updateService } from "../../api/servies";
import ConfirmationModal from "../../CommonComponent/CommonConfirmationModal";
import Loader from "../../CommonComponent/Loader";
import CategoryTranslationModal from "../../CommonComponent/CategoryTranslationModal";

// Custom Font Style
const DM_SANS = styled("span")({
  fontFamily: "DM Sans",
});

const CategoryCards = () => {
  const [open, setOpen] = useState(false);
  const [categoryType, setCategoryType] = useState();
  const [language, setLanguage] = useState("English");
  const [showDetails, setShowDetails] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTranslationModalOpen, setIsTranslationModalOpen] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  // Error state
  const [errors, setErrors] = useState({
    name: "",
    description: "",
    categoryType: "",
  });

  const validateForm = () => {
    const newErrors = {
      name: "",
      description: "",
      categoryType: "",
    };

    let isValid = true;

    // Validate name
    if (!formData.name.trim()) {
      newErrors.name = "Category name is required";
      isValid = false;
    }

    // Validate description
    if (!formData.description.trim()) {
      newErrors.description = "Description is required";
      isValid = false;
    }

    // Validate category type
    if (!categoryType) {
      newErrors.categoryType = "Please select a category type";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();

    if (validateForm()) {
      const payload = {
        category_name: formData.name,
        description: formData.description,
        category_type: categoryType,
      };

      try {
        setLoading(true);
        if (selectedCategory?.category_id) {
          const res = await updateCategory(
            selectedCategory?.category_id,
            payload
          );
          if (res?.status_code === 200) {
            setTimeout(async () => {
              const response = await getCategoryById(
                selectedCategory?.category_id
              );
              setSelectedCategory(response?.data);

              setCategoryData((prevCategories) => {
                // Find and replace the updated category
                return prevCategories.map((category) =>
                  category.category_id === selectedCategory?.category_id
                    ? { ...category, ...res?.data } // Replace the old category with the updated data
                    : category
                );
              });
              showSuccessToast("Category updated successfully");
              setFormData({});
            }, 200);
            setLoading(false);
          } else if (res?.status_code === 400) {
            showErrorToast(res?.message);
            setLoading(true);
          }
        } else {
          setLoading(true);
          const res = await createCategory(payload);
          if (res?.status_code === 201) {
            setTimeout(async () => {
              const res = await getAllCategory();
              setCategoryData(res?.data);
              showSuccessToast("Category created successfully");
              setFormData({});
            }, 500);
            setLoading(false);
          } else if (res?.status_code === 400) {
            showErrorToast(res?.message);
            setLoading(true);
          }
        }
      } catch (error) {
        setLoading(false);
        showErrorToast("Error processing category:", error);
      } finally {
        setFormData({
          name: "",
          description: "",
        });
        setSelectedCategory(null);
        setLoading(false);
        setOpen(false);
      }
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [activeService, setActiveService] = useState(true);
  const [openCreateService, setOpenCrrateService] = useState(false);
  const [storageItems, setStorageItems] = useState([
    {
      name: "",
      description: "",
      duration: "",
      durationValue: "",
    },
  ]);

  // Handle input changes for storage items
  const handleStorageItemChange = (index, field, value) => {
    const updatedItems = [...storageItems];
    updatedItems[index][field] = value;
    setStorageItems(updatedItems);
  };

  const [durationOptions, setDurationOptions] = useState([]);

  // Handle removing a storage item
  const handleRemoveStorageItem = (index) => {
    const updatedStorageItems = storageItems.filter((_, i) => i !== index);
    setStorageItems(updatedStorageItems);
  };

  const [activeStorageItems, setActiveStorageItems] = useState(true);

  const handleDurationSelect = (e, index) => {
    const selectedDuration = e.target.value;
    const options = getDurationOptions(selectedDuration);
    setDurationOptions(options);
    handleStorageItemChange(index, "duration", selectedDuration);
  };

  const handleAddStorageItem = () => {
    setStorageItems([
      ...storageItems,
      {
        name: "",
        description: "",
        duration: "Years",
        durationValue: "",
      },
    ]);
  };

  const handleStorageSwitchChange = (e) => {
    setActiveStorageItems(e.target.checked);
    if (!e.target.checked) {
      setStorageItems([]); // Clear all storage items when turned off
    }
  };

  const handleServiceSwitchChange = (e) => {
    setActiveService(e.target.checked);
    if (!e.target.checked) {
      setActiveStorageItems(false); // Disable storage items if the service is turned off
      setStorageItems([]); // Clear storage items when service is inactive
    }
  };

  const getDurationOptions = (type) => {
    switch (type) {
      case "year":
        return Array.from({ length: 10 }, (_, i) => i + 1); // Years 1-10
      case "month":
        return Array.from({ length: 12 }, (_, i) => i + 1); // Months 1-12
      case "day":
        return Array.from({ length: 31 }, (_, i) => i + 1); // Days 1-31
      case "hour":
        return Array.from({ length: 24 }, (_, i) => i + 1); // Hours 1-24
      default:
        return [];
    }
  };

  const handleDeleteClick = () => {
    setOpenModal(true); // Open the confirmation modal
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const res = await deleteCategory(selectedCategory.category_id);
      if (res?.status_code === 200) {
        setShowDetails(false);
        await getAllCategory();
        const updatedCategoryData = categoryData?.filter(
          (item) => item?.category_id !== selectedCategory?.category_id
        );
        setCategoryData(updatedCategoryData);
        setOpen(false);
        setLoading(false);
        setOpenModal(false);
        showSuccessToast("Category deleted successfully");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
    if (errors[id]) {
      setErrors((prev) => ({
        ...prev,
        [id]: "",
      }));
    }
  };

  const fetchAllCategories = async () => {
    try {
      setLoading(true);
      const res = await getAllCategory();
      if (res?.status_code === 200 || res?.status_code === 404) {
        setCategoryData(res?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const categories = categoryData || [];

  const activeCategories = (
    Array.isArray(categories) ? categories : []
  )?.filter((cat) => cat?.services && cat?.services.length > 0);

  const inactiveCategories = (
    Array.isArray(categories) ? categories : []
  )?.filter(
    (cat) => !cat?.services || Object?.keys(cat.services || {}).length === 0
  );

  const breadcrumbItems = [
    {
      text: "Vendor Management",
      href: "/vendor-management",
      onClick: () => navigate("/vendor-management"),
    },
    {
      text: `${selectedCategory?.services?.length >= 1
        ? "Active Category"
        : "Inactive Category"
        }`,
    },
    {
      text: selectedCategory?.category_name || "",
    },
  ];

  const handleBackClick = async (e) => {
    e.preventDefault();
    setShowDetails(false);
    setSelectedCategory(null);
    const res = await getAllCategory();
    setCategoryData(res?.data);
  };

  const handleCloseTranslationModal = () => {
    setIsTranslationModalOpen(false);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setFormData({
      name: category.category_name || "",
      description: category.description || "",
    });
    setCategoryType(category.category_type);
    setShowDetails(true);
  };

  const handleChange = async (service) => {
    // Create the payload with the necessary fields
    const payload = {
      service_name: service.service_name, // Use the service's name
      description: service.description, // Use the service's description
      is_active: !service.is_active, // Toggle the active status
    };

    try {
      setLoading(true);
      const res = await updateService(service.service_id, payload);
      if (res?.status_code === 200) {
        showSuccessToast("Updated Successfully");
        setSelectedCategory((prevState) => {
          const updatedServices = prevState.services.map((s) =>
            s.service_id === service.service_id
              ? { ...s, is_active: res?.data?.is_active }
              : s
          );
          return {
            ...prevState,
            services: updatedServices,
          };
        });
        await getAllCategory();
        setLoading(false);
      } else {
        showErrorToast("Failed to update service");
        setLoading(false);
      }
    } catch (err) {
      showErrorToast(err.message || "An error occurred");
      setLoading(false);
    }
  };

  const renderCategoryCard = (category) => (
    <Box
      key={category.category_id}
      sx={{
        background: "#FFFFFF",
        borderRadius: "12px",
        mb: "1rem",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "0.5rem",
        }}
      >
        <Box display="flex" alignItems="center" gap="0.5rem">
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600, cursor: "pointer" }}
            color="#525455"
            onClick={() => handleCategoryClick(category)}
          >
            {category.category_name}
          </Typography>
          <Chip
            label={category.category_type}
            sx={{
              backgroundColor:
                category.category_type === "Required" ? "#F1A5E7" : "#FAB1A0",
              color: "#000400",
              fontSize: "12px",
              height: "20px",
            }}
            icon={
              category.category_type === "Required" ? (
                <AddCircleOutline sx={{ fontSize: "14px" }} />
              ) : (
                <RefreshOutlined sx={{ fontSize: "14px" }} />
              )
            }
          />
          <Chip
            label={category.category_name}
            size="small"
            sx={{
              bgcolor: "#E6E7EB",
              color: "#000400",
              fontWeight: 500,
              borderRadius: 1,
            }}
          />
        </Box>
        <LaunchOutlined
          onClick={() => handleCategoryClick(category)}
          sx={{ cursor: "pointer" }}
        />
      </Box>

      <Box display="flex" flexDirection="row" gap="5px">
        <BriefcaseBlackIcon />
        <Typography
          variant="body2"
          color="#6b7280"
          sx={{ mb: "0.5rem", fontSize: "14px" }}
        >
          {category.services?.length > 0 ? category.services.length : 0}{" "}
          {category.services?.length === 1 ? "Service" : "Services"}
        </Typography>
      </Box>
      <Divider />
      <Typography
        variant="body2"
        color="#6b7280"
        sx={{ fontSize: "14px", mt: 2 }}
      >
        {category.description}
      </Typography>
    </Box>
  );

  const formatStorageItemsData = () => {
    return storageItems.map((item) => ({
      name: item.name,
      description: item.description,
      duration: `${item.durationNumber} ${item.duration}`,
    }));
  };

  const validateCreateServiceForm = () => {
    let isValid = true;
    let formErrors = {
      serviceName: "",
      selectedCategory: "",
      serviceDescription: "",
      storageItems: "",
    };

    // Validate Service Name
    if (!serviceName.trim()) {
      formErrors.serviceName = "Service Name is required.";
      isValid = false;
    }

    // Validate Selected Category
    if (!selectedCategory) {
      formErrors.selectedCategory = "Category selection is required.";
      isValid = false;
    }

    // Validate Service Description
    if (!serviceDescription.trim()) {
      formErrors.serviceDescription = "Service Description is required.";
      isValid = false;
    }

    // Validate Storage Items only if the switch is on
    if (activeStorageItems) {
      storageItems.forEach((item, index) => {
        // Validate Storage Item Name
        if (!item.name.trim()) {
          formErrors[`storageItemName${index}`] =
            "Storage Item name is required.";
          isValid = false;
        } else if (item.name.includes(" ")) {
          formErrors[`storageItemName${index}`] =
            "Storage Item name cannot contain spaces.";
          isValid = false;
        }

        // Validate Storage Item Description
        if (!item.description.trim()) {
          formErrors[`storageItemDescription${index}`] =
            "Storage Item description is required.";
          isValid = false;
        }

        // Validate Duration and Duration Number
        if (!item.duration) {
          formErrors[`storageItemDuration${index}`] =
            "Storage Item Duration is Required";
          isValid = false;
        }

        if (!item.durationNumber) {
          formErrors[`storageItemDurationNumber${index}`] =
            "Storage Item Duration Number is Required.";
          isValid = false;
        } else if (item.durationNumber <= 0) {
          formErrors[`storageItemDurationNumber${index}`] =
            "Duration number must be greater than zero.";
          isValid = false;
        }
      });
    }
    setErrors(formErrors);
    return isValid;
  };

  const handleCreateServiceSubmit = async (e) => {
    if (!validateCreateServiceForm()) {
      return;
    }

    e.preventDefault();

    // Prepare form data
    const formData = {
      service_name: serviceName,
      description: serviceDescription,
      category_id: selectedCategory?.category_id,
      storage_item: formatStorageItemsData(),
      active: activeService,
      // translations: translations,
    };

    try {
      // Call your API (replace URL with your actual API endpoint)
      setLoading(true);
      const response = await createService(formData);
      if (response.status_code === 200) {
        setTimeout(async () => {
          const response = await getCategoryById(selectedCategory?.category_id);
          setSelectedCategory(response?.data);
          showSuccessToast("Service created successfully!");
          setOpenCrrateService(false);
          setLoading(false);
        }, 200);
      } else {
        showErrorToast("Error in creating service");
        setOpenCrrateService(false);
      }
    } catch (error) {
      showErrorToast("There was an error submitting the form.");
      setOpenCrrateService(false);
      setLoading(false);
    } finally {
      setServiceName("");
      setServiceDescription("");
      setStorageItems([]);
    }
  };

  const renderCreateServiceDrawer = () => (
    <CommonDrawer
      open={openCreateService}
      onClose={() => setOpenCrrateService(false)}
      title="Create Service"
      subTitle="Sort cookies with specific jobs, helping users personalize their cookie choices."
      primaryButton={{
        label: "Create",
        onClick: handleCreateServiceSubmit,
        sx: { px: 2 },
      }}
      cancelButtonLabel="Cancel"
      drawerWidth={400}
    >
      <Box component="form" onSubmit={handleSubmit}>
        {/* Default Settings Section */}
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 600, color: "#525455", mb: 2 }}
        >
          Default Settings
        </Typography>

        {/* Service Name Field */}
        <Box sx={{ mb: 3 }}>
          <InputLabel
            required
            sx={{ color: "#525455", mb: 1, fontSize: "0.875rem" }}
          >
            Name
          </InputLabel>
          <TextField
            fullWidth
            size="small"
            placeholder="Service name"
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
            sx={{ mb: 0.5 }}
            error={!!errors.serviceName}
            helperText={errors.serviceName}
          />
          <Typography variant="caption" color="text.secondary">
            You can add * to pattern match & capture all items that follow this
            pattern.
          </Typography>
        </Box>

        {/* Category Selection */}
        <Box sx={{ mb: 3 }}>
          <InputLabel
            required
            sx={{ color: "#525455", mb: 1, fontSize: "0.875rem" }}
          >
            Select Category
          </InputLabel>
          <TextField
            value={selectedCategory?.category_name || ""}
            fullWidth
            size="small"
            error={!!errors.selectedCategory}
            disabled
            InputProps={{
              readOnly: true,
            }}
          />
          <FormHelperText error>{errors.selectedCategory}</FormHelperText>
        </Box>

        {/* Service Description */}
        <Box sx={{ mb: 4 }}>
          <InputLabel
            required
            sx={{ color: "#525455", mb: 1, fontSize: "0.875rem" }}
          >
            Description of Service
          </InputLabel>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={serviceDescription}
            onChange={(e) => setServiceDescription(e.target.value)}
            placeholder="Description"
            error={!!errors.serviceDescription}
            helperText={errors.serviceDescription}
          />
        </Box>

        {/* Storage Items Section */}
        <Box sx={{ mb: 4, bgcolor: "#F8F8F8", p: 2, borderRadius: "12px" }}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Box display="flex" flexDirection="column">
              <Typography color="#5F5F5F" fontWeight="600">
                Storage Items
              </Typography>
              <Typography variant="body2" color="#5F5F5F">
                Enable the service by default.
              </Typography>
            </Box>
            <Switch
              color="primary"
              checked={activeStorageItems}
              onChange={handleStorageSwitchChange}
              disabled={!activeService} // Disable the storage switch if the service is inactive
            />
          </Box>

          {/* Only show and enable storage items if service is active */}
          {activeStorageItems && activeService ? (
            <>
              {storageItems.map((item, index) => (
                <Box
                  key={index}
                  sx={{ mb: 2, p: 2, bgcolor: "#FFF", borderRadius: 2 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ color: "#525455" }}>
                      Storage Item Name
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveStorageItem(index)}
                    >
                      <CancelOutlined size={12} />
                    </IconButton>
                  </Box>

                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Storage Item"
                    value={item.name}
                    onChange={(e) =>
                      handleStorageItemChange(index, "name", e.target.value)
                    }
                    sx={{ mb: 2 }}
                    error={!!errors[`storageItemName${index}`]} // Error condition
                    helperText={errors[`storageItemName${index}`]} // Error message
                  />
                  {/* Description */}
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={item.description}
                    onChange={(e) =>
                      handleStorageItemChange(
                        index,
                        "description",
                        e.target.value
                      )
                    }
                    placeholder="Description here"
                    sx={{ mb: 2 }}
                    error={!!errors[`storageItemDescription${index}`]} // Error condition
                    helperText={errors[`storageItemDescription${index}`]} // Error message
                  />

                  {/* Duration */}
                  <Box
                    sx={{ display: "flex", gap: 2, flexDirection: "column" }}
                  >
                    <Select
                      size="small"
                      value={item.duration}
                      onChange={(e) => handleDurationSelect(e, index)}
                      sx={{ flex: 1 }}
                      error={!!errors[`storageItemDuration${index}`]} // Error condition
                    >
                      <MenuItem value="year">Year</MenuItem>
                      <MenuItem value="month">Month</MenuItem>
                      <MenuItem value="day">Day</MenuItem>
                      <MenuItem value="hour">Hour</MenuItem>
                    </Select>
                    {errors[`storageItemDuration${index}`] && (
                      <FormHelperText error>
                        {errors[`storageItemDuration${index}`]}
                      </FormHelperText>
                    )}
                    <Select
                      size="small"
                      value={item.durationNumber}
                      onChange={(e) =>
                        handleStorageItemChange(
                          index,
                          "durationNumber",
                          e.target.value
                        )
                      }
                      sx={{ flex: 1 }}
                      error={!!errors[`storageItemDurationNumber${index}`]} // Error condition
                    >
                      {durationOptions.length > 0 ? (
                        durationOptions.map((num) => (
                          <MenuItem key={num} value={num}>
                            {num}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">Select Duration Number</MenuItem>
                      )}
                    </Select>
                    {errors[`storageItemDurationNumber${index}`] && (
                      <FormHelperText error>
                        {errors[`storageItemDurationNumber${index}`]}
                      </FormHelperText>
                    )}
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              Storage items are disabled because the service is not active.
            </Typography>
          )}

          {activeStorageItems && (
            <Button
              startIcon={<Add size={16} />}
              onClick={handleAddStorageItem}
              sx={{ color: "#525455", textTransform: "none", fontWeight: 700 }}
            >
              Add More
            </Button>
          )}
          <Divider sx={{ mt: 2, mb: 2 }} />

          {/* Service Active */}
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 600, color: "#525455" }}
              >
                Service Active
              </Typography>
              <Switch
                color="primary"
                checked={activeService}
                onChange={handleServiceSwitchChange}
              />
            </Box>
            <Typography variant="body2" color="text.secondary">
              If disabled, this service will not be shown to any visitors.
            </Typography>
          </Box>
        </Box>
      </Box>
    </CommonDrawer>
  );

  const renderCategoryDetails = () => (
    <Box>
      <Box sx={{ mb: 4 }}>
        <CustomBreadcrumb
          items={breadcrumbItems}
          onBackClick={handleBackClick}
        />
      </Box>

      <Box
        sx={{
          mb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="h6" color="#525455" fontWeight="700">
            {selectedCategory?.category_name}
          </Typography>
          <Chip
            label={selectedCategory?.category_type}
            size="small"
            sx={{ backgroundColor: "#F1A5E7" }}
            icon={<AddCircleOutline />}
          />
          <Chip
            label={selectedCategory?.category_name}
            variant="filled"
            size="small"
            sx={{ backgroundColor: "#E6E7EB", borderRadius: "4px" }}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <ButtonCommon onClick={() => setOpen(true)}>Edit</ButtonCommon>
          <ButtonCommon onClick={() => setIsTranslationModalOpen(true)}>Translations</ButtonCommon>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" gap="5px">
        <BriefcaseBlackIcon />
        <Typography variant="body2" color="#525455" sx={{ mb: 2 }}>
          {selectedCategory?.services?.length || 0} Service
          {selectedCategory?.services?.length !== 1 ? "s" : ""}
        </Typography>
      </Box>

      <Typography variant="body2" color="#989898" sx={{ mb: 3 }}>
        {selectedCategory?.description}
      </Typography>

      <Divider sx={{ mb: 3 }} />

      <Box sx={{ mb: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h6" color="#525455" fontWeight="700">
            Services
          </Typography>
          <ButtonCommon
            icon={<Add />}
            onClick={() => setOpenCrrateService(true)}
          >
            Add a Service
          </ButtonCommon>
        </Box>

        <Typography
          variant="subtitle1"
          sx={{ mb: 1 }}
          color="#525455"
          fontWeight="700"
        >
          Active Services
        </Typography>
        <Typography variant="body2" color="#989898" sx={{ mb: 3 }}>
          Data Processing Services (DPS) are services that handle, manage, and
          process data on behalf of an organisation.
        </Typography>

        {selectedCategory?.services && selectedCategory.services.length > 0 ? (
          (Array.isArray(selectedCategory.services)
            ? selectedCategory.services
            : []
          ).map((service) => (
            <Paper
              key={service.service_id}
              sx={{
                p: 3,
                background: "#F9FAFB",
                border: "1px solid #D9D9D9",
                borderRadius: "12px",
                mb: 2,
              }}
              variant="outlined"
            >
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}
              >
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mr: 1,
                  }}
                >
                  <img
                    src={
                      service?.service_icon !== null
                        ? `/images/${service?.service_icon}`
                        : "/images/default.svg"
                    }
                    alt={`${service?.service_name} Icon`}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Typography variant="subtitle1">
                  {service.service_name}
                </Typography>
                <Chip
                  label={selectedCategory.category_type}
                  size="small"
                  sx={{ backgroundColor: "#F1A5E7" }}
                  icon={<AddCircleOutline />}
                />
                <Chip
                  label={selectedCategory.category_name}
                  variant="filled"
                  size="small"
                  sx={{ backgroundColor: "#E6E7EB", borderRadius: "4px" }}
                />
              </Box>

              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <StorageItemIcon />
                  <Typography variant="body2" color="#525455">
                    {service.storage_item?.length || 0} Storage Items
                  </Typography>
                </Box>
                <Typography variant="body2" color="#525455">
                  {service.is_active ? "Active" : "Inactive"}
                </Typography>
                <Switch
                  checked={service.is_active} // Dynamically bind to the service's active status
                  onChange={() => handleChange(service)} // Trigger the API call when toggled
                />{" "}
              </Box>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body2" color="text.secondary">
                {service.description}
              </Typography>
            </Paper>
          ))
        ) : (
          <Typography textAlign="center" mt={2} fontWeight="bold">
            No services have been added yet.
          </Typography>
        )}
      </Box>
      {renderCreateServiceDrawer()}
    </Box>
  );

  const renderDrawer = () => (
    <>
      <CommonDrawer
        open={open}
        onClose={() => setOpen(false)}
        title={selectedCategory ? "Edit Category" : "New Category"}
        subTitle="Sort cookies with specific jobs, helping users personalize their cookie choices."
        primaryButton={{
          label: selectedCategory ? "Update" : "Create",
          sx: { px: 4 },
          onClick: handleSubmit,
        }}
        cancelButtonLabel={selectedCategory ? "Delete" : "Cancel"}
        drawerWidth={400}
        onDelete={handleDeleteClick}
        onSubmit={handleSubmit} // Pass handleSubmit as the onSubmit prop
      >
        <Box component="form">
          <Typography variant="body" fontWeight="600" color="#525455">
            Default Settings
          </Typography>

          {/* Category Name Field */}
          <InputLabel shrink htmlFor="name" required sx={{ mt: 2 }}>
            Category Name
          </InputLabel>
          <TextField
            fullWidth
            id="name"
            placeholder="Name"
            variant="outlined"
            size="small"
            value={formData.name}
            onChange={handleInputChange}
            error={!!errors.name}
            helperText={errors.name}
            sx={{ mb: 2 }}
          />

          {/* Category Description Field */}
          <InputLabel shrink htmlFor="description" required>
            Description
          </InputLabel>
          <TextField
            fullWidth
            id="description"
            placeholder="Description"
            multiline
            rows={3}
            variant="outlined"
            size="small"
            value={formData.description}
            onChange={handleInputChange}
            error={!!errors.description}
            helperText={errors.description}
            sx={{ mb: 2 }}
          />

          {/* Category Type Radio Buttons */}
          <InputLabel shrink htmlFor="category-type" required>
            Category Type
          </InputLabel>
          <RadioGroup
            value={categoryType}
            onChange={(e) => {
              setCategoryType(e.target.value);
              setErrors((prev) => ({ ...prev, categoryType: "" }));
            }}
          >
            <Box
              sx={{
                border: "1px solid",
                borderColor:
                  categoryType === "Required" ? "primary.main" : "grey.300",
                borderRadius: 1,
                p: 1.5,
                mb: 1,
              }}
            >
              <FormControlLabel
                value="Required"
                control={<Radio />}
                label={
                  <Box>
                    <Typography fontWeight="bold">Required</Typography>
                    <Typography variant="caption" color="textSecondary">
                      Users must accept cookies in this category.
                    </Typography>
                  </Box>
                }
              />
            </Box>
            <Box
              sx={{
                border: "1px solid",
                borderColor:
                  categoryType === "Optional" ? "primary.main" : "grey.300",
                borderRadius: 1,
                p: 1.5,
              }}
            >
              <FormControlLabel
                value="Optional"
                control={<Radio />}
                label={
                  <Box>
                    <Typography fontWeight="bold">Optional</Typography>
                    <Typography variant="caption" color="textSecondary">
                      Users can choose to reject cookies in this category.
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </RadioGroup>
          {errors.categoryType && (
            <Typography color="error" className="mb-2">
              {errors.categoryType}
            </Typography>
          )}

          {/* Translations Section */}
          <Typography
            variant="subtitle1"
            mt={2}
            sx={{ mb: 2 }}
            fontWeight="600"
            color="#525455"
          >
            Translations
          </Typography>
          <Typography variant="caption" sx={{ mb: 1 }}>
            Select Language
          </Typography>
          <FormControl fullWidth variant="outlined" size="small">
            <Select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Spanish">Spanish</MenuItem>
              <MenuItem value="French">French</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </CommonDrawer>
      <ConfirmationModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="Delete Category"
        description={"Are you sure you want to delete this category?"}
        onConfirm={handleDelete} // Confirm delete
        loading={loading} // Show loading spinner while deleting
        confirmLabel="Delete"
        cancelLabel="Cancel"
      />
    </>
  );

  if (loading) {
    <Loader />;
  }

  return (
    <Box>
      {!showDetails ? (
        loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 0 }}>
            {/* Active Categories Section */}
            <Grid
              container
              sx={{
                background: "#F9FAFB",
                borderRadius: "12px",
                p: 0,
                mt: "4px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <CommonSubHeader
                    mediumTitle="Active Categories"
                    subTitle="Categories with active services"
                  />
                  <Box>
                    <ButtonCommon
                      size="small"
                      icon={<Add />}
                      onClick={() => setOpen(true)}
                    >
                      New Category
                    </ButtonCommon>
                  </Box>
                </Box>
                <Box sx={{ p: "1.2rem" }}>
                  {(activeCategories || []).map(renderCategoryCard)}
                  {activeCategories?.length === 0 && (
                    <Box sx={{ textAlign: "center", py: 2 }}>
                      <Typography sx={{ color: "#989898" }}>
                        <DM_SANS>No Active Categories</DM_SANS>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>

            {/* Inactive Categories Section */}
            <Grid
              container
              sx={{ background: "#F9FAFB", borderRadius: "12px", p: 0 }}
            >
              <Box sx={{ width: "100%", p: 2 }}>
                <CommonSubHeader
                  mediumTitle="Inactive Categories"
                  subTitle="Categories without any services"
                />
                <Box>
                  {(inactiveCategories || [])?.map(renderCategoryCard)}
                  {inactiveCategories.length === 0 && (
                    <Box sx={{ textAlign: "center", py: 2 }}>
                      <Typography sx={{ color: "#989898" }}>
                        <DM_SANS>No Inactive Categories</DM_SANS>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Box>
        )
      ) : (
        renderCategoryDetails()
      )}
      {renderDrawer()}

      {/* Category Translation Modal */}
      {isTranslationModalOpen && selectedCategory && (
        <CategoryTranslationModal
          isOpen={isTranslationModalOpen}
          onClose={handleCloseTranslationModal}
          category={selectedCategory}
        />
      )}
    </Box>
  );
};

export default CategoryCards;
