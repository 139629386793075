import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  TextField,
  Button,
  Modal,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  CircularProgress,
  Grid,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/system";
import { CloseStatus, Eye, ReAssign } from "../assets/svg";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CommonHeader from "../CommonComponent/CommonHeader";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CloseOutlined, UploadFile, Search } from "@mui/icons-material";
import ButtonCommon from "../CommonComponent/ButtonCommon";
import {
  assignUser,
  generatePresignedUrl,
  getAllDataRequest,
  updateStatusChange,
} from "../api/dataRequest";
import { showErrorToast, showSuccessToast } from "../toastUtils";
import { getUserData } from "../api/user";
import Loader from "../CommonComponent/Loader";
import { fetchUserAttributes } from "@aws-amplify/auth";
import dayjs from "dayjs";
import { getFormattedTimestamp } from "../utils/dateUtils";
import { getWebsiteById } from "../api/websiteService";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",
    color: "#000000",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const SearchTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "8px",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "#F9FAFB",
  borderRadius: "16px",
  boxShadow: 24,
  p: 3,
};

const DataRequests = () => {
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [reAssignModalOpen, setReAssignModalOpen] = useState(false);
  const [closedDate, setClosedDate] = useState(null);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [closedBy, setClosedBy] = useState("");
  const [assignee, setAssignee] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [closedDateError, setClosedDateError] = useState("");
  const [closedByError, setClosedByError] = useState("");
  const [timezone, setTimezone] = useState(null);
  const [userData, setUserData] = useState([]);

  // Single search state and filters
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [dateFilter, setDateFilter] = useState(null);

  const itemsPerPage = 10;
  const navigate = useNavigate();

  // Fetch data from API
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getAllDataRequest();
      setData(response.data || []);
    } catch (err) {
      showErrorToast("Failed to fetch data requests.");
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const res = await getUserData();
      if (res?.status_code === 200) {
        setUserData(res?.data);
      }
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrentUser = async () => {
    try {
      setLoading(true);
      const data = await fetchUserAttributes();
      setCurrentUser(data);
    } finally {
      setLoading(false);
    }
  };

  const getWebsiteDetails = async () => {
    try {
      setLoading(true);
      const websiteId = localStorage.getItem("website_id");
      const websiteData = await getWebsiteById(websiteId);
      const timezoneFromApi = websiteData?.data?.time_zone;
      setTimezone(timezoneFromApi);
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Filter and search logic
  const filteredAndSearchedData = useMemo(() => {
    return data
      .filter((row) => {
        // Search across multiple fields
        const searchLower = searchQuery.toLowerCase();
        const nameMatch = row.name.toLowerCase().includes(searchLower);
        const emailMatch = row.email.toLowerCase().includes(searchLower);
        const assigneeMatch = (
          currentUser?.zoneinfo === "Billing_Admin"
            ? userData.find((user) => user.user_id === row.assignee)
                ?.full_name || ""
            : row.assignee_name || ""
        )
          .toLowerCase()
          .includes(searchLower);
        const requestTypeMatch = row.request_type
          .toLowerCase()
          .includes(searchLower);

        // Status filter
        const statusText = row.status.closed
          ? "closed"
          : Object.keys(row.status)[0];
        const statusMatch =
          statusFilter === "all" || statusText === statusFilter;

        // Date filter
        const dateMatch =
          !dateFilter ||
          dayjs(row.status.new.created_on).format("YYYY-MM-DD") ===
            dayjs(dateFilter).format("YYYY-MM-DD");

        return (
          (nameMatch || emailMatch || assigneeMatch || requestTypeMatch) &&
          statusMatch &&
          dateMatch
        );
      })
      .sort(
        (a, b) =>
          dayjs(b.status.new.created_on).valueOf() -
          dayjs(a.status.new.created_on).valueOf()
      ); // Sorting by date in descending order (latest first)
  }, [data, searchQuery, statusFilter, dateFilter, userData, currentUser]);

  // Pagination logic
  const paginatedData = useMemo(() => {
    const startIndex = (page - 1) * itemsPerPage;
    return filteredAndSearchedData.slice(startIndex, startIndex + itemsPerPage);
  }, [page, filteredAndSearchedData]);

  const totalPages = Math.ceil(filteredAndSearchedData.length / itemsPerPage);

  useEffect(() => {
    fetchData();
    fetchUserData();
    fetchCurrentUser();
    getWebsiteDetails();
  }, []);

  useEffect(() => {
    setPage(1);
  }, [searchQuery, statusFilter, dateFilter]);

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleRowClick = (id) => {
    navigate(`/data-request-details/${id}`);
  };

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    const matchingUser = userData.find((user) => user.user_id === row.assignee);
    setClosedBy(
      currentUser.zoneinfo === "Billing_Admin"
        ? matchingUser
          ? matchingUser.user_id
          : ""
        : row.assignee
    );
    setModalOpen(true);
  };

  const handleReAssignModalOpen = (row) => {
    setSelectedRow(row);
    const matchingUser = userData.find((user) => user.user_id === row.assignee);
    setAssignee(matchingUser ? matchingUser.user_id : "");
    setReAssignModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRow(null);
    setFile(null);
    setClosedDate(null);
    setMessage("");
    setClosedBy("");
    setFileError("");
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      const allowedFormats = ["application/pdf", "image/jpeg", "image/jpg"];
      if (/\s/.test(selectedFile.name)) {
        setFileError(
          "File name with spaces is not allowed. Please rename the file."
        );
        return;
      }
      if (!allowedFormats.includes(selectedFile.type)) {
        setFileError(
          "Invalid file type! Please upload a .jpg, .jpeg, or .pdf file."
        );
        return;
      }
      if (selectedFile.size > maxSize) {
        setFileError("File size exceeds 10MB. Please upload a smaller file.");
        return;
      }
      setFile(selectedFile);
      setFileError("");
      setClosedDateError("");
      setClosedByError("");
    }
  };

  const handleSubmit = async () => {
    // Reset previous errors
    setClosedDateError("");
    setFileError("");
    setClosedByError("");

    // Validate fields
    let isValid = true;

    if (!closedDate) {
      setClosedDateError("Closed date is required!");
      isValid = false;
    }

    if (!file) {
      setFileError("Attachment is required!");
      isValid = false;
    }

    if (!closedBy) {
      setClosedByError("Closed by is required!");
      isValid = false;
    }

    if (!isValid) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      // Step 1: Get the presigned URL
      const { presigned_url, file_name } = await generatePresignedUrl(
        file.name
      );

      if (!presigned_url) {
        throw new Error("Failed to get presigned URL");
      }

      // Step 2: Upload file to the presigned URL
      const uploadResponse = await fetch(presigned_url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      if (!uploadResponse.ok) {
        throw new Error("File upload failed!");
      }

      // Step 3: Call the update status API with the uploaded file's URL
      const payload = {
        status: {
          closed_on: closedDate,
          attachment: file_name, // Use presigned URL
          close_by:
            currentUser.zoneinfo === "Billing_Admin"
              ? closedBy
              : selectedRow?.assignee,
          comments: message,
        },
      };
      const response = await updateStatusChange(
        selectedRow?.request_id,
        payload
      );
      if (response.status_code === 200) {
        await fetchData();
        showSuccessToast(response.message);
        handleCloseModal();
      } else {
        showErrorToast(response.message);
      }
    } catch (error) {
      showErrorToast(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleAssigneeSubmit = async () => {
    setLoading(true);
    if (!assignee) {
      return;
    }
    const payload = {
      user_id: assignee,
    };
    try {
      const response = await assignUser(selectedRow?.request_id, payload);
      if (response.status_code === 200) {
        await fetchData();
        showSuccessToast(response.message);
        setReAssignModalOpen(false);
      } else {
        showErrorToast(response.message);
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const renderSearchAndFilters = () => (
    <Box sx={{ mb: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <SearchTextField
            fullWidth
            size="small"
            label="Search by Name, Email, Assignee or Request Type"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: <Search sx={{ color: "gray", mr: 1 }} />,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth size="small">
            <InputLabel>Status</InputLabel>
            <Select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              label="Status"
            >
              <MenuItem value="all">All Status</MenuItem>
              <MenuItem value="new">New</MenuItem>
              <MenuItem value="closed">Closed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Filter by Date"
              value={dateFilter}
              onChange={setDateFilter}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                },
              }}
              maxDate={dayjs()}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  );

  const minDate = dayjs(selectedRow?.status?.new?.created_on).startOf("day");
  const maxDate = dayjs().endOf("day"); // Allow full today

  const handleDateChange = (date) => {
    if (
      date &&
      (date.isBefore(minDate, "day") || date.isAfter(maxDate, "day"))
    ) {
      setClosedDateError("Date must be within the allowed range.");
      setClosedDate(null);
    } else {
      setClosedDate(date);
      setClosedDateError(""); // Clear error when valid date is selected
    }
  };

  const isSaveDisabled = loading || assignee === selectedRow?.assignee;
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <CommonHeader
          title="Data Requests"
          subTitle="You can review, audit and change the status of data request on this page."
        />
      </Box>

      {loading ? (
        <Loader />
      ) : (
        <>
          {renderSearchAndFilters()}
          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ borderRadius: "12px" }}
          >
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  {/* <StyledTableCell>Request ID</StyledTableCell> */}
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Request Type</StyledTableCell>
                  <StyledTableCell>Created On</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Assignee</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No data requests found.
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedData.map((row) => {
                    const statusText = row.status.closed
                      ? "closed"
                      : Object.keys(row.status)[0];
                    const assigneeName =
                      currentUser?.zoneinfo === "Billing_Admin"
                        ? userData.find((user) => user.user_id === row.assignee)
                            ?.full_name || "Unknown User"
                        : row.assignee_name;
                    return (
                      <TableRow key={row.request_id} hover>
                        <StyledTableCell>
                          <Tooltip title={row.name} arrow>
                            <span
                              style={{
                                display: "inline-block",
                                maxWidth: "10ch", // Limit to 6 characters
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {row.name}
                            </span>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Tooltip title={row.email} arrow>
                            <span
                              style={{
                                display: "inline-block",
                                maxWidth: "200px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textAlign: "center",
                                wordBreak: "break-word",
                              }}
                            >
                              {row.email}
                            </span>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell>{row.request_type}</StyledTableCell>
                        <StyledTableCell>
                          {getFormattedTimestamp(
                            row?.status?.new?.created_on,
                            timezone
                          )}
                        </StyledTableCell>
                        <StyledTableCell sx={{ textTransform: "capitalize" }}>
                          {statusText}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Tooltip title={assigneeName} arrow>
                            <span
                              style={{
                                display: "inline-block",
                                maxWidth: "80px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textAlign: "center",
                                wordBreak: "break-word",
                              }}
                            >
                              {assigneeName}
                            </span>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Tooltip title="Change Status" arrow>
                            <IconButton
                              size="small"
                              disabled={statusText === "closed"}
                              onClick={() => handleOpenModal(row)}
                              sx={{
                                color: (theme) =>
                                  statusText === "closed"
                                    ? theme.palette.grey[400]
                                    : "inherit", // Change color when disabled
                              }}
                            >
                              <CloseStatus />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Reassign" arrow>
                            <IconButton
                              size="small"
                              onClick={() => handleReAssignModalOpen(row)}
                              disabled={
                                statusText === "closed" ||
                                currentUser?.zoneinfo === "Org_user"
                              }
                              sx={{
                                color: (theme) =>
                                  statusText === "closed"
                                    ? theme.palette.grey[400]
                                    : "inherit", // Change color when disabled
                              }}
                            >
                              <ReAssign />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="View Details" arrow>
                            <IconButton
                              size="small"
                              onClick={() => handleRowClick(row.request_id)}
                            >
                              <Eye />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal open={modalOpen}>
            <Box sx={style}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" color="primary" fontWeight="bold">
                  Close Data Request
                </Typography>
                <IconButton onClick={handleCloseModal}>
                  <CloseOutlined />
                </IconButton>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography variant="body1">Request ID</Typography>
                <TextField
                  value={selectedRow?.request_id || ""}
                  disabled
                  fullWidth
                  sx={{ mt: 1, mb: 1 }}
                  size="small"
                />

                {/* Closed Date */}
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Closed Date <span style={{ color: "red" }}>*</span>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={closedDate}
                    onChange={handleDateChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={!!closedDateError}
                        helperText={closedDateError}
                      />
                    )}
                  />
                </LocalizationProvider>
                {closedDateError && (
                  <Typography color="error" sx={{ mt: 1 }}>
                    {closedDateError}
                  </Typography>
                )}

                {/* File Upload */}
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Attachment <span style={{ color: "red" }}>*</span>
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<UploadFile />}
                  fullWidth
                  sx={{ mt: 1 }}
                >
                  {file ? file.name : "Choose File"}
                  <input
                    type="file"
                    accept=".pdf,.jpeg,.jpg"
                    onChange={handleFileChange}
                    hidden
                  />
                </Button>
                <Typography
                  variant="caption"
                  sx={{ mt: 1, color: "gray", display: "block" }}
                >
                  * Accepted file formats: <strong>.jpg, .jpeg, .pdf</strong>
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: "gray", display: "block" }}
                >
                  * Maximum file size: <strong>10MB</strong>
                </Typography>
                {fileError && (
                  <Typography color="error" sx={{ mt: 1 }}>
                    {fileError}
                  </Typography>
                )}

                {/* Closed By Dropdown */}
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Closed By <span style={{ color: "red" }}>*</span>
                </Typography>
                {currentUser?.zoneinfo === "Billing_Admin" ? (
                  <Select
                    value={closedBy}
                    onChange={(event) => {
                      setClosedBy(event.target.value);
                      setClosedByError(""); // Clear error when a value is selected
                    }}
                    fullWidth
                    error={!!closedByError}
                    sx={{ mt: 1 }}
                  >
                    {(() => {
                      const billingAdmin = (
                        Array.isArray(userData) ? userData : []
                      ).find(
                        (user) =>
                          user.email?.toLowerCase() ===
                          currentUser?.email?.toLowerCase()
                      );

                      const assignee = (
                        Array.isArray(userData) ? userData : []
                      ).find((user) => user.user_id === selectedRow?.assignee);

                      const uniqueUsers = [billingAdmin, assignee].filter(
                        (user, index, self) =>
                          user &&
                          self.findIndex(
                            (u) => u?.user_id === user?.user_id
                          ) === index
                      );

                      return (
                        Array.isArray(uniqueUsers) ? uniqueUsers : []
                      ).map((user) => (
                        <MenuItem key={user.user_id} value={user.user_id}>
                          {user.full_name}
                        </MenuItem>
                      ));
                    })()}
                  </Select>
                ) : (
                  <TextField
                    value={selectedRow?.assignee_name}
                    fullWidth
                    sx={{ mt: 1 }}
                    disabled
                  />
                )}
                {closedByError && (
                  <Typography color="error" sx={{ mt: 1 }}>
                    {closedByError}
                  </Typography>
                )}

                {/* Comment */}
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Comment
                </Typography>
                <TextField
                  label="Comment"
                  multiline
                  rows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  fullWidth
                  sx={{ mt: 1 }}
                />

                {/* Action Buttons */}
                <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                  <ButtonCommon onClick={handleSubmit} disabled={loading}>
                    {loading ? <CircularProgress size={20} /> : "Save"}
                  </ButtonCommon>
                  <ButtonCommon onClick={() => setModalOpen(false)}>
                    Cancel
                  </ButtonCommon>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Modal open={reAssignModalOpen}>
            <Box sx={style}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" color="primary" fontWeight="bold">
                  Choose Assignee
                </Typography>
                <IconButton onClick={() => setReAssignModalOpen(false)}>
                  <CloseOutlined />
                </IconButton>
              </Box>

              <Box
                sx={{
                  borderRadius: "8px",
                  mt: 2,
                }}
              >
                <InputLabel
                  name="request_id"
                  sx={{ display: "block", marginTop: 1 }}
                >
                  Request ID
                </InputLabel>
                <TextField
                  value={selectedRow?.request_id || ""}
                  disabled
                  fullWidth
                  sx={{ marginTop: 1, mb: 1 }}
                  size="small"
                />

                {/* Closed By Dropdown */}
                <InputLabel
                  name="Assignee"
                  sx={{ display: "block", marginTop: 2 }}
                  required
                >
                  Assignee
                </InputLabel>
                <Select
                  value={assignee || ""} // Ensuring controlled value
                  onChange={(event) => setAssignee(event.target.value)}
                  fullWidth
                  sx={{ marginTop: 1 }}
                  size="small"
                >
                  {userData.map((user) => (
                    <MenuItem key={user.user_id} value={user.user_id}>
                      {user.full_name}
                    </MenuItem>
                  ))}
                </Select>

                {/* Action Buttons */}
                <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
                  <ButtonCommon
                    onClick={handleAssigneeSubmit}
                    disabled={isSaveDisabled}
                  >
                    {loading ? <CircularProgress size={20} /> : "Save"}
                  </ButtonCommon>
                  <ButtonCommon onClick={() => setReAssignModalOpen(false)}>
                    Cancel
                  </ButtonCommon>
                </Box>
              </Box>
            </Box>
          </Modal>
          <Box display="flex" justifyContent="center" mt={3}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              disabled={totalPages <= 1}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default DataRequests;
