import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../services/authService";

const AUTO_LOGOUT_TIME = 15 * 60 * 1000; // 15 minutes in milliseconds

const AutoLogout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let logoutTimer;

    const resetTimer = () => {
      if (logoutTimer) clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => handleLogout(navigate), AUTO_LOGOUT_TIME);
    };

    // Reset timer on user activity
    const activityEvents = ["mousemove", "keydown", "click", "scroll"];
    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimer)
    );

    // Set initial timer
    resetTimer();

    return () => {
      if (logoutTimer) clearTimeout(logoutTimer);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimer)
      );
    };
  }, [navigate]);

  return null;
};

export default AutoLogout;
