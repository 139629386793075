import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // LocalStorage
import { combineReducers } from "redux";
import scanReducer from "./scanSlice";
import licenseReducer from "./licenseSlice"; // Import licenseSlice

// Combine reducers if you have multiple slices
const rootReducer = combineReducers({
  scan: scanReducer,
  license: licenseReducer, // Add license reducer
});

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Needed for redux-persist
    }),
});

export const persistor = persistStore(store);
