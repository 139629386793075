import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ size, icon }) => ({
  border: `2px solid #00B894`,
  borderRadius: "8px",
  padding: size === "small" ? "8px 12px" : "12px 20px",
  textTransform: "capitalize",
  fontFamily: "DM Sans",
  fontSize: "15px",
  "&:hover": {
    backgroundColor: "#00B894",
    color: "#fff",
  },
  color: "#525455",
  fontWeight: "bold",
  "& > *:first-child": {
    marginRight: "8px",
  },
  height: "48px",
}));

const ButtonCommon = ({
  children,
  size = "medium",
  icon,
  onClick,
  disabled,
  to,
  sx = {}, // Accept sx prop for styling
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick(); // Call any provided onClick handler first
    }
    if (to) {
      navigate(to); // Navigate to the specified route
    }
  };

  return (
    <StyledButton
      size={size}
      disabled={disabled}
      onClick={handleClick}
      variant="outlined"
      sx={{ ...sx }} // Apply fullWidth via sx
    >
      {icon && icon}
      {children}
    </StyledButton>
  );
};

export default ButtonCommon;
