import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

const ConfirmationModal = ({
  open,
  onClose,
  title,
  description,
  onConfirm,
  loading,
  confirmLabel = "Delete",
  cancelLabel = "Cancel",
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" color="#525455" fontWeight="bold">
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Typography color="#989898">{description}</Typography>
        <Divider sx={{ my: 2 }} />
        <Box gap={2} display="flex">
          <Button
            variant="outlined"
            sx={{
              border: "2px solid #00B894",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
            color="#525455"
            onClick={onConfirm}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : confirmLabel}
          </Button>
          <Button
            variant="outlined"
            sx={{
              border: "2px solid #CECECE",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
            color="#525455"
            onClick={onClose}
          >
            {cancelLabel}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#FFFFFF",
  borderRadius: "16px",
  boxShadow: 24,
  p: 3,
};

export default ConfirmationModal;
