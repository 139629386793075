import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const licenseSlice = createSlice({
  name: "license",
  initialState,
  reducers: {
    setLicenseData: (state, action) => {
      state.data = action.payload;
    },
    updateLicenseUsage: (state, action) => {
      if (state.data) {
        // Ensure license_usage_details exists
        if (!state.data.license_usage_details) {
          state.data.license_usage_details = {};
        }

        // Merge the new usage details
        state.data.license_usage_details = {
          ...state.data.license_usage_details,
          ...action.payload,
        };
      }
    },
    clearLicenseData: (state) => {
      state.data = null;
    },
  },
});

export const { setLicenseData, updateLicenseUsage, clearLicenseData } =
  licenseSlice.actions;
export default licenseSlice.reducer;
