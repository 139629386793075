import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  styled,
  Tooltip,
  IconButton,
  Pagination,
  Modal,
  Typography,
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CommonHeader from "../CommonComponent/CommonHeader";
import { CloseStatus, Eye, ReAssign } from "../assets/svg";
import { Add, CloseOutlined, Search, UploadFile } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ButtonCommon from "../CommonComponent/ButtonCommon";
import { getUserData } from "../api/user";
import { useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../toastUtils";
import {
  assignBreachUser,
  createBreach,
  generateBreachPresignedUrl,
  getDataBreaches,
  updateBreachStatusChange,
} from "../api/dataBreach";
import dayjs from "dayjs";
import { getFormattedTimestamp } from "../utils/dateUtils";
import { getWebsiteById } from "../api/websiteService";
import { fetchUserAttributes } from "aws-amplify/auth";

const DataBreach = () => {
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [dateFilter, setDateFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [selectedBreach, setSelectedBreach] = useState(null);
  const [updatedStatus, setUpdatedStatus] = useState(
    selectedBreach?.status || ""
  );
  const [closedDate, setClosedDate] = useState(null);
  const [closedBy, setClosedBy] = useState("");
  const [file, setFile] = useState(null);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [reAssignModalOpen, setReAssignModalOpen] = useState(false);
  const [assignee, setAssignee] = useState("");
  const [userData, setUserData] = useState([]);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [dataBreaches, setDataBreaches] = useState([]);
  const [timezone, setTimezone] = useState(null);
  const [websiteDetails, setWebsiteDetails] = useState(null);
  const [missingDpoOpen, setMissingDpoOpen] = useState(false);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const breaches = await getDataBreaches();

      const formattedBreaches = breaches?.data
        .map((breach) => {
          let statusKey = "Unknown";
          if (breach.status && Object.keys(breach.status).length > 0) {
            const statusKeys = Object.keys(breach.status);
            statusKey = statusKeys[statusKeys.length - 1];
          }
          return { ...breach, displayed_status: statusKey };
        })
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)); // 🔥 Sorting latest first
      setDataBreaches(formattedBreaches);
    } catch (error) {
      showErrorToast("Failed to load data. Please try again.");
    }
  };

  // ✅ Make sure to return the Promise
  const getWebsiteDetails = async () => {
    try {
      const websiteId = localStorage.getItem("website_id");
      const websiteData = await getWebsiteById(websiteId);
      setWebsiteDetails(websiteData);
      const timezoneFromApi = websiteData?.data?.time_zone;
      setTimezone(timezoneFromApi);
    } catch (err) {
      showErrorToast("Failed to load website details.");
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await Promise.all([fetchData(), getWebsiteDetails()]);
      setLoading(false);
      setPage(1);
    };

    loadData();
  }, []);

  const filteredData = dataBreaches
    .map((row) => {
      // Convert breach date to user's timezone
      const breachDateInTimeZone = dayjs
        .utc(row.date_and_time_of_breach)
        .tz(timezone)
        .startOf("day"); // Remove time

      let daysLapsed = row.days_lapsed; // Default from backend

      if (
        row.displayed_status.toLowerCase() === "closed" &&
        row.status?.closed?.closed_on
      ) {
        const closedDateInTimeZone = dayjs
          .utc(row.status?.closed?.closed_on)
          .tz(timezone)
          .startOf("day"); // Remove time
        daysLapsed = closedDateInTimeZone.diff(breachDateInTimeZone, "days");
      } else if (row.displayed_status.toLowerCase() !== "closed") {
        const currentDate = dayjs().tz(timezone).startOf("day"); // Remove time
        daysLapsed = currentDate.diff(breachDateInTimeZone, "days");
      }

      return {
        ...row,
        days_lapsed: daysLapsed, // Override with the calculated value
      };
    })
    .filter((row) => {
      const searchMatch =
        searchText === "" ||
        row.summary.toLowerCase().includes(searchText.toLowerCase()) ||
        row.assignee_name.toLowerCase().includes(searchText.toLowerCase()) ||
        row.breach_id.toLowerCase().includes(searchText.toLowerCase());

      const statusMatch =
        statusFilter === "all" || row.displayed_status === statusFilter;

      const rowDateInTimeZone = dayjs
        .utc(row.date_and_time_of_breach)
        .tz(timezone)
        .format("YYYY-MM-DD");

      const filterDateInTimeZone = dateFilter
        ? dayjs(dateFilter).format("YYYY-MM-DD")
        : null;

      const dateMatch =
        !dateFilter || rowDateInTimeZone === filterDateInTimeZone;

      return searchMatch && statusMatch && dateMatch;
    });

  const itemsPerPage = 10; // Number of items per page
  const totalPages = Math.ceil(filteredData.length / itemsPerPage); // Correct total pages

  // Paginate the filtered data
  const paginatedData = filteredData.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  // Ensure that pagination is displayed when needed
  useEffect(() => {
    if (page > totalPages && totalPages > 0) {
      setPage(totalPages); // Reset to the last page if the current page exceeds total pages
    }
  }, [filteredData, page, totalPages]);

  const validateForm = () => {
    let newErrors = {};

    if (!formData.summary.trim()) {
      newErrors.summary = "Summary is required.";
    }

    if (!formData.detailed_description.trim()) {
      newErrors.detailed_description = "Detailed description is required.";
    }

    if (
      !formData.date_and_time_of_breach ||
      !dayjs(formData.date_and_time_of_breach).isValid()
    ) {
      newErrors.date_and_time_of_breach =
        "Please select a valid date and time.";
    }

    if (!formData.location_of_breach.trim()) {
      newErrors.location_of_breach = "Location is required.";
    } else if (formData.location_of_breach.length < 5) {
      newErrors.location_of_breach = "Location must be at least 5 characters.";
    }

    if (!formData.assignee) {
      newErrors.assignee = "Please select an assignee.";
    }

    return newErrors;
  };

  const handleOpenModal = (breach) => {
    setSelectedBreach(breach);
    setUpdatedStatus(breach.displayed_status); // Set initial status
    setOpenModal(true);
    setClosedBy(breach?.assignee);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedBreach(null);
  };

  // Handle Page Change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const [formData, setFormData] = useState({
    summary: "",
    detailed_description: "",
    date_and_time_of_breach: null,
    location_of_breach: "",
    assignee: "",
  });

  const [initialFile, setInitialFile] = useState(null);
  const [finalFile, setFinalFile] = useState(null);

  const [fileError, setFileError] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    if (!date || date.isAfter(maxDate, "day")) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        date_and_time_of_breach: "Date must be within the allowed range.",
      }));
      setFormData((prevData) => ({
        ...prevData,
        date_and_time_of_breach: null,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        date_and_time_of_breach: "", // Clear error when valid
      }));
      setFormData((prevData) => ({
        ...prevData,
        date_and_time_of_breach: date.toISOString(),
      }));
    }
  };

  const handleCreateFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    const fileSizeLimit = 10 * 1024 * 1024; // 10MB in bytes
    if (selectedFile.name.includes(" ")) {
      setFileError("File name should not contain spaces.");
      return;
    }
    if (selectedFile.size > fileSizeLimit) {
      setFileError("File size should not exceed 10MB.");
      return;
    }
    setFileError(""); // Clear error if valid
    setFile(selectedFile);
  };

  const handleCreateBreachSubmit = async () => {
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      setLoading(true);
      let uploadedFileName = null;
      if (file) {
        const { presigned_url, file_name } = await generateBreachPresignedUrl(
          file.name
        );
        if (!presigned_url) {
          throw new Error("Failed to get presigned URL");
        }

        const uploadResponse = await fetch(presigned_url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });
        if (!uploadResponse.ok) {
          throw new Error("File upload failed!");
        }
        uploadedFileName = file_name;
      }
      const formattedData = {
        summary: formData.summary,
        detailed_description: formData.detailed_description,
        date_and_time_of_breach: formData.date_and_time_of_breach
          ? new Date(formData.date_and_time_of_breach).toISOString()
          : null,
        location_of_breach: formData.location_of_breach,
        assignee: formData.assignee,
        attachment: uploadedFileName,
      };
      const response = await createBreach(formattedData);
      if (response.status_code === 201) {
        showSuccessToast(response?.message);
        await fetchData();
        setOpen(false);
      } else {
        showErrorToast(response?.message);
      }
    } catch (error) {
      showErrorToast(
        error.message || "Failed to report data breach. Please try again."
      );
    } finally {
      setFormData({
        summary: "",
        detailed_description: "",
        date_and_time_of_breach: null,
        location_of_breach: "",
        assignee: "",
      });
      setFile(null);
      setFileError(""); // Clear error if valid
      setLoading(false);
      setErrors({});
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    "&.MuiTableCell-head": {
      backgroundColor: "#F9FAFB",
      color: "#000000",
      fontWeight: 600,
    },
    "&.MuiTableCell-body": {
      fontSize: 14,
    },
  }));

  const handleFileChange = (e, type) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    const fileSizeLimit = 10 * 1024 * 1024; // 10MB in bytes
    const validTypes = ["application/pdf", "image/jpeg", "image/jpg"];
    if (selectedFile.name.includes(" ")) {
      setFileError("File name should not contain spaces.");
      return;
    }
    if (selectedFile.size > fileSizeLimit) {
      setFileError("File size should not exceed 10MB.");
      return;
    }
    if (!validTypes.includes(selectedFile.type)) {
      setFileError(
        "Invalid file type! Only .pdf, .jpg, and .jpeg are allowed."
      );
      return;
    }
    setFileError(""); // Clear error if valid
    if (type === "initial") {
      setInitialFile(selectedFile);
    } else if (type === "final") {
      setFinalFile(selectedFile);
    }
  };

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setUpdatedStatus(newStatus);

    // Reset dependent fields if status is not "closed"
    if (newStatus !== "closed") {
      setClosedDate(null);
      setClosedBy("");
      setFile(null);
    }
  };

  const validateFields = () => {
    let tempErrors = {};

    if (!selectedBreach?.breach_id)
      tempErrors.breach_id = "Breach ID is required.";
    if (!updatedStatus) tempErrors.status = "Status is required.";
    if (updatedStatus === "closed" && !closedDate)
      tempErrors.closedDate = "Closed Date is required.";
    if (updatedStatus === "closed" && !closedBy)
      tempErrors.closedBy = "Closed By is required.";
    if (updatedStatus === "closed" && !initialFile && !finalFile)
      tempErrors.file = "Attachment is required.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;
    setLoading(true);

    try {
      let initialFileName = null;
      let finalFileName = null;
      const presignedUrls = await Promise.all([
        initialFile ? generateBreachPresignedUrl(initialFile.name) : null,
        finalFile ? generateBreachPresignedUrl(finalFile.name) : null,
      ]);
      const [initialResponse, finalResponse] = presignedUrls;
      if (initialResponse) initialFileName = initialResponse.file_name;
      if (finalResponse) finalFileName = finalResponse.file_name;
      await Promise.all([
        initialResponse?.presigned_url
          ? fetch(initialResponse.presigned_url, {
              method: "PUT",
              body: initialFile,
              headers: { "Content-Type": initialFile.type },
            })
          : null,
        finalResponse?.presigned_url
          ? fetch(finalResponse.presigned_url, {
              method: "PUT",
              body: finalFile,
              headers: { "Content-Type": finalFile.type },
            })
          : null,
      ]);
      const statusPayload = {
        [updatedStatus]: {
          closed_on: closedDate,
          closed_by: closedBy,
          initial_response_to_db: initialFileName,
          final_response_to_db: finalFileName,
          comment: comment || "",
        },
      };

      const payload = { status: statusPayload };

      const response = await updateBreachStatusChange(
        selectedBreach?.breach_id,
        payload
      );

      if (response?.status_code === 200) {
        await fetchData(); // Refresh the breach list
        setOpenModal(false); // Close modal on success
        showSuccessToast(response?.message);
      }
    } catch (err) {
      showErrorToast("Failed to update breach status.");
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#F9FAFB",
    borderRadius: "16px",
    boxShadow: 24,
    p: 3,
    height: updatedStatus === "closed" ? "750px" : "auto",
    overflow: "auto",
  };

  const handleReAssignModalOpen = (row) => {
    setSelectedBreach(row);
    setClosedBy(row?.assignee);
    const matchingUser = userData.find((user) => user.user_id === row.assignee);
    setAssignee(matchingUser ? matchingUser.user_id : "");
    setReAssignModalOpen(true);
  };

  const handleAssigneeSubmit = async () => {
    setLoading(true);
    if (!assignee) {
      return;
    }
    const payload = {
      assignee: assignee,
    };
    try {
      const response = await assignBreachUser(
        selectedBreach?.breach_id,
        payload
      );
      if (response.status_code === 200) {
        await fetchData();
        showSuccessToast(response.message);
        setReAssignModalOpen(false);
      } else {
        showErrorToast(response.message);
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const res = await getUserData();
      if (res?.status_code === 200) {
        setUserData(res?.data);
      }
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const [currentUser, setCurrentUser] = useState(null);

  const fetchCurrentUser = async () => {
    try {
      setLoading(true);
      const data = await fetchUserAttributes();
      setCurrentUser(data);
      if (data?.zoneinfo === "Billing_Admin") {
        fetchUserData();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
    //eslint-disable-next-line
  }, []);

  const minDate = dayjs(selectedBreach?.status?.new?.created_on).startOf("day");
  const maxDate = dayjs().endOf("day");

  const checkDpoEmail = async () => {
    setLoading(true);
    try {
      const dpoEmail = websiteDetails?.data?.dpo_email;

      if (dpoEmail) {
        setOpen(true);
      } else {
        setMissingDpoOpen(true);
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setPage(1); // Reset to page 1 when filter changes
  };

  const handleDateFilterChange = (newDate) => {
    setDateFilter(newDate ? newDate : null); // Strip time
    setPage(1);
  };

  const isSaveDisabled = loading || assignee === selectedBreach?.assignee;
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <CommonHeader
          title="Data Breach"
          subTitle="You can review, audit, and change the status of data breaches on this page."
        />
        {currentUser?.zoneinfo === "Billing_Admin" && (
          <ButtonCommon icon={<Add />} onClick={checkDpoEmail}>
            Create Data Breach
          </ButtonCommon>
        )}
      </Box>

      {/* Filters */}
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              fullWidth
              size="small"
              label="Search by summary, assignee & breach ID"
              value={searchText} // Bind value to the state
              onChange={(e) => setSearchText(e.target.value)} // Update the state on input change
              InputProps={{
                startAdornment: <Search sx={{ color: "gray", mr: 1 }} />,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Status</InputLabel>
              <Select
                value={statusFilter}
                onChange={handleStatusFilterChange}
                label="Status"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="new">New</MenuItem>
                {/* <MenuItem value="dp_informed">DP Informed</MenuItem>
                <MenuItem value="dpb_informed">DPB Informed</MenuItem>
                <MenuItem value="details_sent_to_dpb">
                  Details Sent to DPB
                </MenuItem> */}
                <MenuItem value="closed">Closed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Filter by Date"
                value={dateFilter}
                onChange={handleDateFilterChange}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>

      {/* Table */}
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderRadius: 2 }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Breach ID</StyledTableCell>
              <StyledTableCell>Summary</StyledTableCell>
              <StyledTableCell>Date & Time of Breach</StyledTableCell>
              <StyledTableCell>Days Lapsed</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Assignee</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              // ✅ Show loader inside the table
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress size={40} />
                </TableCell>
              </TableRow>
            ) : paginatedData.length > 0 ? (
              paginatedData.map((row) => (
                <TableRow key={row.breach_id}>
                  <StyledTableCell>{row.breach_id.slice(-6)}</StyledTableCell>
                  <Tooltip title={row.summary} arrow>
                    <StyledTableCell>
                      {row.summary.length > 30
                        ? `${row.summary.slice(0, 30)}...`
                        : row.summary}
                    </StyledTableCell>
                  </Tooltip>
                  <StyledTableCell>
                    {getFormattedTimestamp(
                      row.date_and_time_of_breach,
                      timezone
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{row.days_lapsed}</StyledTableCell>
                  <StyledTableCell>
                    {row.displayed_status
                      .split("_")
                      .map((word) => {
                        if (
                          word.toLowerCase() === "dp" ||
                          word.toLowerCase() === "dpb"
                        ) {
                          return word.toUpperCase(); // Ensure "DP" and "DPB" remain capitalized
                        }
                        return word.charAt(0).toUpperCase() + word.slice(1); // Title case for other words
                      })
                      .join(" ")}
                  </StyledTableCell>

                  <Tooltip title={row.assignee_name} arrow>
                    <StyledTableCell>
                      {row.assignee_name.length > 15
                        ? `${row.assignee_name.slice(0, 15)}...`
                        : row.assignee_name}
                    </StyledTableCell>
                  </Tooltip>
                  <StyledTableCell align="center">
                    <Tooltip title="Change Status">
                      <IconButton
                        size="small"
                        onClick={() => handleOpenModal(row)}
                        disabled={row.displayed_status === "closed"}
                        sx={{
                          color: (theme) =>
                            row.displayed_status === "closed"
                              ? theme.palette.grey[400]
                              : "inherit",
                        }}
                      >
                        <CloseStatus />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Reassign">
                      <IconButton
                        size="small"
                        onClick={() => handleReAssignModalOpen(row)}
                        disabled={
                          row.displayed_status !== "new" ||
                          currentUser?.zoneinfo === "Org_user"
                        }
                        sx={{
                          color: (theme) =>
                            row.displayed_status !== "new"
                              ? theme.palette.grey[400]
                              : "inherit",
                        }}
                      >
                        <ReAssign />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="View & Edit Details">
                      <IconButton
                        size="small"
                        onClick={() =>
                          navigate(`/data-breach-details/${row.breach_id}`)
                        }
                      >
                        <Eye />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              // ✅ Only show "No records found" if `loading` is false & data is empty
              !loading && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No records found
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      {filteredData.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            size="large"
          />
        </Box>
      )}

      {/* Missing DPO Email Modal */}
      <Modal open={missingDpoOpen} onClose={() => setMissingDpoOpen(false)}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" color="primary" fontWeight="bold">
              Missing DPO Email
            </Typography>
            <IconButton onClick={() => setMissingDpoOpen(false)}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Typography sx={{ marginTop: 2 }}>
            Please setup a DPO email in Settings to create your first data
            breach case.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              marginTop: 3,
            }}
          >
            <ButtonCommon onClick={() => navigate("/settings")}>
              Continue
            </ButtonCommon>
          </Box>
        </Box>
      </Modal>

      {/* Modal for create breach */}
      <Modal open={open}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" color="primary" fontWeight="bold">
              Create Data Breach Ticket
            </Typography>
            <IconButton
              onClick={() => {
                setOpen(false);
                setErrors({});
              }}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
          <InputLabel
            name="summary"
            sx={{ display: "block", marginTop: 2 }}
            required
          >
            Summary
          </InputLabel>
          <TextField
            name="summary"
            fullWidth
            size="small"
            value={formData.summary}
            onChange={handleChange}
            error={!!errors.summary}
            helperText={errors.summary}
          />
          <InputLabel
            name="detailed_description"
            sx={{ display: "block", marginTop: 2 }}
            required
          >
            Detailed Description
          </InputLabel>
          <TextField
            name="detailed_description"
            fullWidth
            size="small"
            multiline
            rows={4}
            value={formData.detailed_description}
            onChange={handleChange}
            error={!!errors.detailed_description}
            helperText={errors.detailed_description}
          />
          <InputLabel
            name="date_and_time_of_breach"
            sx={{ display: "block", marginTop: 2 }}
            required
          >
            Date & Time of Breach
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              name="date_and_time_of_breach"
              value={
                formData.date_and_time_of_breach
                  ? dayjs(formData.date_and_time_of_breach)
                  : null
              }
              ampm={false} // Ensures 24-hour format
              format="YYYY-MM-DD HH:mm"
              maxDate={dayjs()}
              sx={{ width: "100%" }}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  readOnly: true, // Prevents manual input
                },
              }}
            />
          </LocalizationProvider>
          {errors.date_and_time_of_breach && (
            <Typography color="error" sx={{ mt: 1 }} variant="caption">
              {errors.date_and_time_of_breach}
            </Typography>
          )}
          <InputLabel
            name="location_of_breach"
            sx={{ display: "block", marginTop: 2 }}
            required
          >
            Location
          </InputLabel>
          <TextField
            size="small"
            name="location_of_breach"
            fullWidth
            value={formData.location_of_breach}
            onChange={handleChange}
            error={!!errors.location_of_breach}
            helperText={errors.location_of_breach}
          />
          <InputLabel
            name="assignee"
            sx={{ display: "block", marginTop: 2 }}
            required
          >
            Assignee
          </InputLabel>
          <TextField
            select
            name="assignee"
            fullWidth
            size="small"
            value={formData.assignee}
            onChange={handleChange}
            error={!!errors.assignee}
            helperText={errors.assignee}
          >
            {userData.map((user) => (
              <MenuItem key={user.user_id} value={user.user_id}>
                {user.full_name}
              </MenuItem>
            ))}
          </TextField>
          {/* File Upload */}
          <InputLabel name="attachment" sx={{ display: "block", marginTop: 2 }}>
            Attachment
          </InputLabel>
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadFile />}
            fullWidth
            sx={{ mt: 1 }}
          >
            {file ? file.name : "Choose File"}
            <input
              type="file"
              accept=".pdf,.jpeg,.jpg"
              onChange={handleCreateFileChange}
              hidden
            />
          </Button>
          <Typography
            variant="caption"
            sx={{ mt: 1, color: "gray", display: "block" }}
          >
            * Accepted file formats: <strong>.jpg, .jpeg, .pdf</strong>
          </Typography>
          {errors.file && (
            <Typography color="error" variant="caption">
              {errors.file}
            </Typography>
          )}
          {fileError && (
            <Typography color="error" variant="caption">
              {fileError}
            </Typography>
          )}
          <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
            <ButtonCommon onClick={handleCreateBreachSubmit} disabled={loading}>
              {loading ? <CircularProgress size={20} /> : "Save"}
            </ButtonCommon>
            <ButtonCommon
              onClick={() => {
                setOpen(false);
                setErrors({});
              }}
            >
              Cancel
            </ButtonCommon>
          </Box>
        </Box>
      </Modal>

      {/* Status Change Modal */}
      <Modal open={openModal}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" color="primary" fontWeight="bold">
              Update Breach Status
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseOutlined />
            </IconButton>
          </Box>

          {/* Breach ID */}
          <Typography variant="body1" sx={{ mt: 2 }}>
            Breach ID
          </Typography>
          <TextField
            value={selectedBreach?.breach_id || ""}
            disabled
            fullWidth
            sx={{ mt: 1 }}
            size="small"
            error={!!errors.breach_id}
            helperText={errors.breach_id}
          />

          {/* Status Dropdown */}
          <Typography variant="body1" sx={{ mt: 2 }}>
            Status
          </Typography>
          <Select
            value={updatedStatus}
            onChange={handleStatusChange}
            fullWidth
            size="small"
            error={!!errors.status}
          >
            {[
              { value: "new", label: "New" },
              // { value: "dp_informed", label: "DP Informed" },
              // { value: "dpb_informed", label: "DPB Informed" },
              // { value: "details_sent_to_dpb", label: "Details Sent to DPB" },
              { value: "closed", label: "Closed" },
            ].map((option, index, array) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={
                  option.value === updatedStatus || // 🔥 Disable currently selected
                  array.findIndex((opt) => opt.value === updatedStatus) > index // 🔥 Disable previous options
                }
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>

          {errors.status && (
            <Typography color="error" variant="caption">
              {errors.status}
            </Typography>
          )}

          {updatedStatus === "closed" && (
            <>
              {/* Closed Date */}
              <Typography variant="body1" sx={{ mt: 2 }}>
                Closed Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  size="small"
                  value={closedDate}
                  onChange={setClosedDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth sx={{ mt: 1 }} />
                  )}
                  slotProps={{
                    textField: {
                      readOnly: true, // Prevents manual input
                    },
                  }}
                />
              </LocalizationProvider>
              {errors.closedDate && (
                <Typography color="error" variant="caption">
                  {errors.closedDate}
                </Typography>
              )}

              {/* Closed By Dropdown */}
              <Typography variant="body1" sx={{ mt: 2 }}>
                Closed By
              </Typography>
              {currentUser?.zoneinfo === "Billing_Admin" ? (
                <Select
                  value={closedBy}
                  onChange={(event) => {
                    setClosedBy(event.target.value);
                  }}
                  fullWidth
                  error={!!errors.closedBy}
                  sx={{ mt: 1 }}
                  size="small"
                >
                  {(() => {
                    const billingAdmin = (
                      Array.isArray(userData) ? userData : []
                    ).find(
                      (user) =>
                        user.email?.toLowerCase() ===
                        currentUser?.email?.toLowerCase()
                    );

                    const assignee = (
                      Array.isArray(userData) ? userData : []
                    ).find((user) => user.user_id === selectedBreach?.assignee);

                    const uniqueUsers = [billingAdmin, assignee].filter(
                      (user, index, self) =>
                        user &&
                        self.findIndex((u) => u?.user_id === user?.user_id) ===
                          index
                    );

                    return (Array.isArray(uniqueUsers) ? uniqueUsers : []).map(
                      (user) => (
                        <MenuItem key={user.user_id} value={user.user_id}>
                          {user.full_name}
                        </MenuItem>
                      )
                    );
                  })()}
                </Select>
              ) : (
                <TextField
                  value={selectedBreach?.assignee_name}
                  fullWidth
                  sx={{ mt: 1 }}
                  disabled
                />
              )}

              {errors.closedBy && (
                <Typography color="error" sx={{ mt: 1 }}>
                  {errors.closedBy}
                </Typography>
              )}

              {/* File Upload */}
              <Typography variant="body1" sx={{ mt: 2 }}>
                Initial Response to DPB
              </Typography>
              <Button
                variant="contained"
                component="label"
                startIcon={<UploadFile />}
                fullWidth
                sx={{ mt: 1 }}
              >
                {initialFile ? initialFile.name : "Choose File"}
                <input
                  type="file"
                  accept=".pdf,.jpeg,.jpg"
                  onChange={(e) => handleFileChange(e, "initial")}
                  hidden
                />
              </Button>
              <Typography
                variant="caption"
                sx={{ mt: 1, color: "gray", display: "block" }}
              >
                * Accepted file formats: <strong>.jpg, .jpeg, .pdf</strong>
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: "gray", display: "block" }}
              >
                * Response to DPB immediately after breach.
              </Typography>
              {errors.file && (
                <Typography color="error" variant="caption">
                  {errors.file}
                </Typography>
              )}
              {fileError && (
                <Typography color="error" variant="caption">
                  {fileError}
                </Typography>
              )}
              {/* File Upload */}
              <Typography variant="body1" sx={{ mt: 2 }}>
                Final Response to DPB
              </Typography>
              <Button
                variant="contained"
                component="label"
                startIcon={<UploadFile />}
                fullWidth
                sx={{ mt: 1 }}
              >
                {finalFile ? finalFile.name : "Choose File"}
                <input
                  type="file"
                  accept=".pdf,.jpeg,.jpg"
                  onChange={(e) => handleFileChange(e, "final")}
                  hidden
                />
              </Button>
              <Typography
                variant="caption"
                sx={{ mt: 1, color: "gray", display: "block" }}
              >
                * Accepted file formats: <strong>.jpg, .jpeg, .pdf</strong>
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: "gray", display: "block" }}
              >
                * Detailed response sent to DPB within 72-hours of breach.
              </Typography>
              {errors.file && (
                <Typography color="error" variant="caption">
                  {errors.file}
                </Typography>
              )}
              {fileError && (
                <Typography color="error" variant="caption">
                  {fileError}
                </Typography>
              )}
            </>
          )}

          {/* Comment */}
          <Typography variant="body1" sx={{ mt: 2 }}>
            Comment
          </Typography>
          <TextField
            label="Comment"
            multiline
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            fullWidth
            sx={{ mt: 1 }}
          />

          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <ButtonCommon
              onClick={handleSubmit}
              disabled={
                loading || updatedStatus === selectedBreach?.displayed_status
              }
            >
              {loading ? <CircularProgress size={20} /> : "Save"}
            </ButtonCommon>
            <ButtonCommon onClick={handleCloseModal}>Cancel</ButtonCommon>
          </Box>
        </Box>
      </Modal>

      <Modal open={reAssignModalOpen}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" color="primary" fontWeight="bold">
              Choose Assignee
            </Typography>
            <IconButton onClick={() => setReAssignModalOpen(false)}>
              <CloseOutlined />
            </IconButton>
          </Box>

          <Box
            sx={{
              borderRadius: "8px",
              mt: 2,
            }}
          >
            <InputLabel
              name="breach_id"
              sx={{ display: "block", marginTop: 1 }}
            >
              Breach ID
            </InputLabel>
            <TextField
              value={selectedBreach?.breach_id || ""}
              disabled
              fullWidth
              sx={{ marginTop: 1, mb: 1 }}
              size="small"
            />

            {/* Closed By Dropdown */}
            <InputLabel
              name="Assignee"
              sx={{ display: "block", marginTop: 2 }}
              required
            >
              Assignee
            </InputLabel>
            <Select
              value={assignee || ""} // Ensuring controlled value
              onChange={(event) => setAssignee(event.target.value)}
              fullWidth
              sx={{ marginTop: 1 }}
              size="small"
            >
              {userData.map((user) => (
                <MenuItem key={user.user_id} value={user.user_id}>
                  {user.full_name}
                </MenuItem>
              ))}
            </Select>

            {/* Action Buttons */}
            <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
              <ButtonCommon
                onClick={handleAssigneeSubmit}
                disabled={isSaveDisabled}
              >
                {loading ? <CircularProgress size={20} /> : "Save"}
              </ButtonCommon>
              <ButtonCommon onClick={() => setReAssignModalOpen(false)}>
                Cancel
              </ButtonCommon>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DataBreach;
