import {
  Box,
  Typography,
  styled,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  InputLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import CommonHeader from "../CommonComponent/CommonHeader";
import ButtonCommon from "../CommonComponent/ButtonCommon";
import { ChevronRight, Visibility, VisibilityOff } from "@mui/icons-material";
import CommonDrawer from "../CommonComponent/CommonDrawer";
import { fetchUserAttributes, updatePassword } from "@aws-amplify/auth";
import apiClient from "../api/apiClient";
import Loader from "../CommonComponent/Loader";
import { showErrorToast, showSuccessToast } from "../toastUtils";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: "#F9FAFB",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid #D9D9D9",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#fff",
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: "#e0e0e0",
    },
  },
}));

const ChangePasswordButton = styled(Button)(({ theme }) => ({
  width: "100%",
  justifyContent: "space-between",
  padding: theme.spacing(2),
  fontWeight: 600,
  textTransform: "none",
  backgroundColor: "transparent",
  border: "2px solid #00B894",
  borderRadius: theme.shape.borderRadius,
  color: "#525455",
  fontSize: "14px",
  size: "medium",
}));

const Label = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: "rgb(75, 85, 99)",
  fontWeight: 500,
}));

const Profile = () => {
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showPasswords, setShowPasswords] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const [profileInfo, setProfileInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // New state for password errors
  const [passwordErrors, setPasswordErrors] = useState({
    current: "",
    new: "",
    confirm: "",
  });

  const handleToggleDrawer = (isOpen) => () => {
    setOpen(isOpen);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const fetchCurrentUser = async () => {
    const currentUser = await fetchUserAttributes();
    setFormData({
      fullName: currentUser.name,
      email: currentUser.email,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setProfileInfo(currentUser);
  };

  useEffect(() => {
    fetchCurrentUser();
    // eslint-disable-next-line
  }, []);

  const updateUser = async (userId, updatedData) => {
    try {
      const response = await apiClient.put(`/user/${userId}`, updatedData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const validatePasswordFields = () => {
    let isValid = true;
    const newPasswordError = !formData.newPassword
      ? "New password is required"
      : formData.newPassword.length < 8
      ? "Password must be at least 8 characters long"
      : !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/.test(
          formData.newPassword
        )
      ? "Password must include lowercase, uppercase, number, and special character"
      : "";

    const confirmPasswordError = !formData.confirmPassword
      ? "Confirm password is required"
      : formData.confirmPassword !== formData.newPassword
      ? "Passwords do not match"
      : "";

    if (newPasswordError) {
      setPasswordErrors((prev) => ({ ...prev, new: newPasswordError }));
      isValid = false;
    }

    if (confirmPasswordError) {
      setPasswordErrors((prev) => ({ ...prev, confirm: confirmPasswordError }));
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validatePasswordFields();
    if (!isValid) {
      return;
    }

    const { currentPassword, newPassword } = formData;

    try {
      await updatePassword({ oldPassword: currentPassword, newPassword });
      showSuccessToast("Password changed successfully");
      setOpen(false);
      setFormData({
        fullName: "",
        email: "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setPasswordErrors({
        current: "",
        new: "",
        confirm: "",
      });
      await fetchCurrentUser();
    } catch (err) {
      showErrorToast("An error occurred while changing the password");
      setOpen(false);
    }
  };

  const handleUpdate = async () => {
    if (!formData.fullName || formData.fullName.trim() === "") {
      setError("Full name is required.");
      return;
    }
    setError("");
    try {
      setLoading(true);
      const updateResponse = await updateUser(profileInfo.sub, {
        full_name: formData.fullName,
      });
      window.location.reload();
      if (updateResponse?.status_code === 200) {
        setIsEditing(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
      <Box>
        <CommonHeader
          title="Your Profile"
          subTitle="Update your profile and change your password."
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography fontWeight="bold" color="#525455">
            Profile
          </Typography>
          <Typography color="#989898" mt={1}>
            Change your user profile details.
          </Typography>
        </Box>
        <Box sx={{ p: "1.2rem" }}>
          {!isEditing && (
            <ButtonCommon size="small" onClick={handleEditToggle}>
              Edit Profile
            </ButtonCommon>
          )}
        </Box>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <StyledBox sx={{ mt: isEditing ? 2 : 0 }}>
          <Box>
            <Label
              variant="body1"
              sx={{ fontWeight: "bold", color: "#525455" }}
            >
              Full name
            </Label>
            <StyledTextField
              fullWidth
              variant="outlined"
              placeholder="Your Name"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              size="small"
              InputProps={{
                readOnly: !isEditing,
              }}
              error={!!error}
              helperText={error}
            />
          </Box>

          <Box>
            <Label
              variant="body1"
              sx={{ fontWeight: "bold", color: "#525455" }}
            >
              Email
            </Label>
            <StyledTextField
              fullWidth
              variant="outlined"
              placeholder="Your Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              size="small"
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Box>

          {!isEditing && (
            <ChangePasswordButton
              fullWidth
              variant="outlined"
              endIcon={<ChevronRight />}
              onClick={handleToggleDrawer(true)}
            >
              Change Password
            </ChangePasswordButton>
          )}
        </StyledBox>
      )}
      {isEditing && (
        <Box mt={2} display="flex" gap={2}>
          <ButtonCommon onClick={handleUpdate} disabled={loading}>
            Update
          </ButtonCommon>
          <Button
            variant="outlined"
            sx={{
              border: "2px solid #CECECE",
              textTransform: "capitalize",
              color: "#525455",
              fontWeight: "bold",
              borderRadius: "8px",
            }}
            onClick={() => setIsEditing(false)}
          >
            Cancel
          </Button>
        </Box>
      )}
      <CommonDrawer
        open={open}
        onClose={() => setOpen(false)}
        title="Change Password"
        subTitle="Secure your account by updating your password to a new, strong one."
        primaryButton={{
          label: "Update",
          onClick: handleSubmit,
          sx: { px: 4 },
        }}
        drawerWidth={400}
      >
        <InputLabel shrink htmlFor="oldPassword" required>
          Current Password
        </InputLabel>
        <TextField
          fullWidth
          size="small"
          required
          margin="none"
          name="currentPassword"
          type={showPasswords.current ? "text" : "password"}
          value={formData.currentPassword}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => togglePasswordVisibility("current")}
                  edge="end"
                >
                  {showPasswords.current ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ mb: 3 }}
          error={!!passwordErrors.current}
          helperText={passwordErrors.current}
        />
        <InputLabel shrink htmlFor="newPassword" required>
          New Password
        </InputLabel>
        <TextField
          fullWidth
          required
          margin="none"
          size="small"
          name="newPassword"
          type={showPasswords.new ? "text" : "password"}
          value={formData.newPassword}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => togglePasswordVisibility("new")}
                  edge="end"
                >
                  {showPasswords.new ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ mb: 3 }}
          error={!!passwordErrors.new}
          helperText={passwordErrors.new}
        />
        <InputLabel shrink htmlFor="confirmPassword" required>
          Confirm New Password
        </InputLabel>
        <TextField
          fullWidth
          required
          margin="none"
          size="small"
          name="confirmPassword"
          type={showPasswords.confirm ? "text" : "password"}
          value={formData.confirmPassword}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => togglePasswordVisibility("confirm")}
                  edge="end"
                >
                  {showPasswords.confirm ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!passwordErrors.confirm}
          helperText={passwordErrors.confirm}
        />
      </CommonDrawer>
    </Box>
  );
};

export default Profile;
