import apiClient from "./apiClient";
import apiMasterClient from "./apiMasterClient";

export const getAllMasterServices = async () => {
  try {
    const response = await apiMasterClient.get("/services?page=1&per_page=100");
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getServicesTranslations = async (parentServiceId) => {
  const websiteId = localStorage.getItem("website_id");
  const organizationId = localStorage.getItem("organization_id_str");

  try {
    const response = await apiClient.get(`/service/get-all-translations/${parentServiceId}`, {
      headers: {
        website_id: websiteId,
        organization_id: organizationId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveServicesTranslations = async (payload) => {
  const websiteId = localStorage.getItem("website_id");
  const organizationId = localStorage.getItem("organization_id_str");

  try {
    const response = await apiClient.post(`/service/save_translation`, payload, {
      headers: {
        website_id: websiteId,
        organization_id: organizationId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateServicesTranslations = async (serviceTranslateId, payload) => {
  const websiteId = localStorage.getItem("website_id");
  const organizationId = localStorage.getItem("organization_id_str");

  try {
    const response = await apiClient.put(`/service/edit-translation/${serviceTranslateId}`, payload, {
      headers: {
        website_id: websiteId,
        organization_id: organizationId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteServicesTranslations = async (serviceTranslateId) => {
  const websiteId = localStorage.getItem("website_id");
  const organizationId = localStorage.getItem("organization_id_str");

  try {
    const response = await apiClient.put(`/service/delete-translation/${serviceTranslateId}`, {}, {
      headers: {
        website_id: websiteId,
        organization_id: organizationId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};