import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Replace with your actual API URL

export const signupUser = async (userData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/signup`, userData);
    return response.data; // Return response data
  } catch (error) {
    throw error;
  }
};

export const sendSignupEmail = async (payload) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/send-signup-email`,
      payload
    );
    return response.data; // Returns the response data
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
