import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Alert,
  FormControl,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Grid,
  Divider,
  TextField,
  Stack,
  FormHelperText,
  Button
} from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { showSuccessToast, showErrorToast, showInfoToast } from "../../../toastUtils";
// import { getAllLanguages } from "../../../api/websiteService";
import ButtonCommon from "../../../CommonComponent/ButtonCommon";
import { deleteBannerTranslation, editBannerTranslation, saveBannerTranslation } from "../../../api/bannerService";
import { ArrowRightAlt } from "@mui/icons-material";
import DeletePopup from "../../../CommonComponent/DeletePopup";

const LanguageSettings = ({ data = {}, onUpdate, bannerId, allLanguage, bannerSettings, preferenceSettings, savedTranslations, setSavedTranslations, isMandatoryToTrans, frameworkName, loading }) => {
  // const LanguageSettings = ({ data = {}, onUpdate, bannerId, allLanguage,activeLanguageCards, setActiveLanguageCards }) => {
  // const loading = false;
  const [isSaving, setIsSaving] = useState(false);
  // const [languages, setLanguages] = useState([]);
  const [translations, setTranslations] = useState(data.translations || {});
  const [isBulkTranslating, setIsBulkTranslating] = useState(false);
  const availableTranslations = [];
  const [activeLanguageCards, setActiveLanguageCards] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState(data.defaultLanguage || "en");
  const [supportedLanguages, setSupportedLanguages] = useState(data.supportedLanguages || []);
  const [openModal, setOpenModal] = useState(false);
  const [availableLanguagesToAdd, setAvailableLanguagesToAdd] = useState(null);
  const [errors, setErrors] = useState({
    consent_title: false,
    consent_description: false,
    banner_accept_btn_text: false,
    banner_accept_necessary_btn_text: false,
    banner_preferences_btn_text: false,
    settings_title: false,
    settings_description: false,
    settings_more_info_title: false,
    settings_more_info: false,
    preferences_accept_btn_text: false,
    preferences_accept_necessary_btn_text: false,
    save_preferences_btn_text: false
  })
  const [isEdited, setIsEdited] = useState(false);

  const validateForm = (langCode) => {
    const newErrors = {
      consent_title: !translations[langCode]?.banner_translate_text?.consent_title,
      consent_description: !translations[langCode]?.banner_translate_text?.consent_description,
      banner_accept_btn_text: !translations[langCode]?.banner_translate_text?.banner_accept_btn_text,
      banner_accept_necessary_btn_text: !translations[langCode]?.banner_translate_text?.banner_accept_necessary_btn_text,
      banner_preferences_btn_text: !translations[langCode]?.banner_translate_text?.banner_preferences_btn_text,
      settings_title: !translations[langCode]?.banner_translate_text?.settings_title,
      settings_description: !translations[langCode]?.banner_translate_text?.settings_description,
      settings_more_info_title: !translations[langCode]?.banner_translate_text?.settings_more_info_title,
      settings_more_info: !translations[langCode]?.banner_translate_text?.settings_more_info,
      preferences_accept_btn_text: !translations[langCode]?.banner_translate_text?.preferences_accept_btn_text,
      preferences_accept_necessary_btn_text: !translations[langCode]?.banner_translate_text?.preferences_accept_necessary_btn_text,
      save_preferences_btn_text: !translations[langCode]?.banner_translate_text?.save_preferences_btn_text
    };

    // setErrors(newErrors);
    setErrors((prev) => ({
      ...prev,
      [langCode]: newErrors
    }));

    if (newErrors.consent_title) {
      showErrorToast("Please enter a banner title");
    } else if (newErrors.consent_description) {
      showErrorToast("Please enter a banner description");
    } else if (newErrors.banner_accept_btn_text) {
      showErrorToast("Please enter a banner accept button text");
    } else if (newErrors.banner_accept_necessary_btn_text) {
      showErrorToast("Please enter a banner accept necessary button text");
    } else if (newErrors.banner_preferences_btn_text) {
      showErrorToast("Please enter a banner preferences button text");
    } else if (newErrors.settings_title) {
      showErrorToast("Please enter a settings title");
    } else if (newErrors.settings_description) {
      showErrorToast("Please enter a settings description");
    } else if (newErrors.settings_more_info_title) {
      showErrorToast("Please enter a settings more info title");
    } else if (newErrors.settings_more_info) {
      showErrorToast("Please enter a settings more info content");
    } else if (newErrors.preferences_accept_btn_text) {
      showErrorToast("Please enter a preferences accept button text");
    } else if (newErrors.preferences_accept_necessary_btn_text) {
      showErrorToast("Please enter a preferences accept necessary button text");
    } else if (newErrors.save_preferences_btn_text) {
      showErrorToast("Please enter a save preferences button text");
    }

    return !Object.values(newErrors).some(error => error);
  };


  // Original banner content in default language
  const defaultContent = {
    title: bannerSettings?.title || "We use cookies",
    description: bannerSettings?.description || "",
    acceptAllText: bannerSettings?.acceptAllText || "Accept All",
    acceptNecessaryText: bannerSettings?.acceptNecessaryText || "Reject All",
    managePreferencesText: bannerSettings?.managePreferencesText || "Manage Preferences",
    preferencesTitle: preferenceSettings?.title || "Cookie Usage",
    preferencesDescription: preferenceSettings?.description || "",
    moreInfoTitle: preferenceSettings?.moreInfoTitle || "More Information",
    moreInfoContent: preferenceSettings?.moreInfoContent || "",
    savePreferencesText: preferenceSettings?.savePreferencesText || "Save Preferences",
    preferencesAcceptText: preferenceSettings?.acceptAllText || "Accept All",
    preferencesAcceptNecessaryText: preferenceSettings?.acceptNecessaryText || "Reject All"
  };
  // console.log('transla', data);

  // This effect runs when the component receives updated props
  useEffect(() => {
    if (data.translations) {
      setTranslations(data.translations);
      setActiveLanguageCards(Object.keys(data.translations));
    }
    if (data.defaultLanguage) {
      setDefaultLanguage(data.defaultLanguage);
    }
    if (data.supportedLanguages) {
      setSupportedLanguages(data.supportedLanguages);
    }
  }, [data]);

  // Handle adding a new language
  const handleAddLanguage = (langCode) => {
    if (!langCode || activeLanguageCards.includes(langCode)) {
      return;
    }
    setActiveLanguageCards([...activeLanguageCards, langCode]);

    // Initialize empty translations for the new language if not exists
    if (!translations[langCode]) {
      const newTranslations = { ...translations };
      newTranslations[langCode] = {};
      setTranslations(newTranslations);

      const newData = {
        defaultLanguage: defaultLanguage,
        supportedLanguages: supportedLanguages,
        translations: newTranslations
      }
      onUpdate(newData)
    }
  };

  const handleRemoveLanguage = async (langCode) => {
    if (langCode === defaultLanguage) {
      showErrorToast("Cannot remove default language");
      return;
    }

    try {
      // Await the API response to ensure it's completed before updating the UI
      if (savedTranslations[langCode]) {
        await deleteBannerTranslation(translations[langCode]?.banner_trans_id);
        // const response = await deleteBannerTranslation(translations[langCode]?.banner_trans_id);
        // console.log("Deleted language response:", response);
      }

      // Remove from active language cards
      setActiveLanguageCards(prevCards => prevCards.filter(lang => lang !== langCode));

      // Remove translations for this language
      const updatedTranslations = { ...translations };
      delete updatedTranslations[langCode];
      setTranslations(updatedTranslations);

      onUpdate({
        translations: updatedTranslations,
        defaultLanguage,
        supportedLanguages
      });

      showSuccessToast("Language removed successfully!");
    } catch (error) {
      console.error("Error removing language:", error);
      showErrorToast("Failed to remove language. Please try again.");
    }
  };

  // Function to translate text using an AI service (currently mocked)
  const translateWithAI = async (sourceText, targetLang) => {
    if (!sourceText || sourceText.trim() === '') return '';

    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 500));

    // Mock translation (in real implementation, this would call an AI translation API)
    const mockTranslations = {
      es: {
        "Accept All": "Aceptar Todo",
        "Reject All": "Rechazar Todo",
        "Manage Preferences": "Administrar Preferencias",
        "Cookie Usage": "Uso de Cookies",
        "More Information": "Más Información",
        "Save Preferences": "Guardar Preferencias",
        "We use cookies": "Usamos cookies",
        "We respect your privacy": "Respetamos su privacidad"
      },
      fr: {
        "Accept All": "Tout Accepter",
        "Reject All": "Tout Rejeter",
        "Manage Preferences": "Gérer les Préférences",
        "Cookie Usage": "Utilisation des Cookies",
        "More Information": "Plus d'Informations",
        "Save Preferences": "Enregistrer les Préférences",
        "We use cookies": "Nous utilisons des cookies",
        "We respect your privacy": "Nous respectons votre vie privée"
      },
      de: {
        "Accept All": "Alle Akzeptieren",
        "Reject All": "Alle Ablehnen",
        "Manage Preferences": "Einstellungen Verwalten",
        "Cookie Usage": "Cookie-Nutzung",
        "More Information": "Weitere Informationen",
        "Save Preferences": "Einstellungen Speichern",
        "We use cookies": "Wir verwenden Cookies",
        "We respect your privacy": "Wir respektieren Ihre Privatsphäre"
      }
    };

    // Return a mock translation or the original text with language prefix
    return mockTranslations[targetLang]?.[sourceText] ||
      `[${targetLang.toUpperCase()}] ${sourceText}`;
  };

  // Handler for translating a single field
  const handleTranslateField = async (langCode, field) => {
    try {
      const sourceText = defaultContent[field];
      if (!sourceText) return;

      const translatedText = await translateWithAI(sourceText, langCode);
      handleTranslationChange(langCode, field, translatedText);
    } catch (error) {
      console.error("Translation error:", error);
      showErrorToast(`Failed to translate ${field}`);
    }
  };

  // Handler for translating all fields for a language
  const handleTranslateAllForLanguage = async (langCode) => {
    try {
      // const langObj = languages.find(l => l.language_code === langCode);
      const langObj = allLanguage.find(l => l.language_code === langCode);
      const langName = langObj ? langObj.language_description : langCode;

      showInfoToast(`Translating content to ${langName}...`);

      // Translate all fields in parallel
      const fields = Object.keys(defaultContent);
      await Promise.all(fields.map(field => handleTranslateField(langCode, field)));

      showSuccessToast(`Content translated to ${langName}`);
    } catch (error) {
      console.error("Translation error for language:", error);
      showErrorToast(`Failed to translate to ${langCode}`);
    }
  };

  // Handler for bulk translating all languages and all fields
  const handleBulkTranslate = async () => {
    setIsBulkTranslating(true);
    try {
      // Get languages to translate (all supported except default)
      const langsToTranslate = supportedLanguages.filter(lang => lang !== defaultLanguage);

      if (langsToTranslate.length === 0) {
        showInfoToast("No languages selected for translation");
        return;
      }

      showInfoToast(`Starting bulk translation for ${langsToTranslate.length} languages...`);

      // For each language, translate all fields
      for (const langCode of langsToTranslate) {
        await handleTranslateAllForLanguage(langCode);
      }

      showSuccessToast("All translations completed");
    } catch (error) {
      console.error("Bulk translation error:", error);
      showErrorToast("Failed to complete all translations");
    } finally {
      setIsBulkTranslating(false);
    }
  };

  const handleTranslationChange = (langCode, field, value) => {
    // setIsEdited(true)
    setIsEdited((prev) => ({
      ...prev,
      [langCode]: true
    }))

    const newTranslations = {
      ...translations,
      [langCode]: {
        ...translations[langCode],
        // [field]: value
        banner_translate_text: { ...translations[langCode]?.banner_translate_text, [field]: value }
      }
    };
    setTranslations(newTranslations);

    if (errors?.[langCode]?.[field]) { // Ensure errors[langCode] and errors[langCode][field] exist
      setErrors(prev => ({
        ...prev,
        [langCode]: {
          ...(prev?.[langCode] || {}), // Ensure previous state exists
          [field]: false // Update only the specific field
        }
      }));
    }

    onUpdate({
      translations: newTranslations,
      defaultLanguage,
      supportedLanguages: [...new Set([...supportedLanguages, langCode])]
    });
  };

  const handleSaveTranslation = async (langCode, isExistingTranslation) => {
    if (!validateForm(langCode)) {
      return; // Exit if validation fails
    }

    try {
      // setIsSaving(true);
      setIsSaving((prev) => ({
        ...prev,
        [langCode]: true
      }));

      const payload = {
        parent_banner_id: bannerId,
        banner_translate_text: translations[langCode].banner_translate_text,
        language_code: langCode
      };

      let response;
      // if (isExistingTranslation) {
      if (savedTranslations[langCode]) {
        // Update existing translation
        response = await editBannerTranslation(
          translations[langCode]?.banner_trans_id,
          { banner_translate_text: payload.banner_translate_text }
        );

        if (response.success) {
          showSuccessToast('Translation updated successfully');
        } else {
          showErrorToast('Failed to update translation');
          return; // Exit on error
        }
      } else {
        // Create new translation
        response = await saveBannerTranslation(payload);
        // console.log('save', response, translations);

        if (response.success) {
          // After saving transalation we are fetching translation id
          const newTranslations = {
            ...translations,
            [langCode]: {
              ...translations[langCode],
              banner_trans_id: response?.data?.banner_trans_id
            }
          };
          setTranslations(newTranslations)

          // After saving save button should show update text
          setSavedTranslations(prev => ({
            ...prev,
            [langCode]: true
          }))

          onUpdate({
            translations: newTranslations,
          });
          showSuccessToast('Translation saved successfully');
        } else {
          showErrorToast('Failed to save translation');
          return; // Exit on error
        }
      }

      // setIsEdited(false)
      setIsEdited((prev) => ({
        ...prev,
        [langCode]: false
      }))
    } catch (error) {
      console.error('Save translation error:', error);
      showErrorToast(
        savedTranslations[langCode]
          ? 'Failed to update translation'
          : 'Failed to save translation'
      );
    } finally {
      // setIsSaving(false);
      setIsSaving((prev) => ({
        ...prev,
        [langCode]: false
      }));
    }
  };

  useEffect(() => {
    // Destructure with default values
    const { defaultLanguage = '', supportedLanguages = [], translations = {} } = data || {};

    // Early return if required data is not available
    if (!Array.isArray(allLanguage) || !allLanguage.length) {
      setAvailableLanguagesToAdd([]);
      return;
    }

    try {
      const availableLanguages = allLanguage
        .filter(lang => {
          // Add null checks for all properties
          if (!lang || typeof lang !== 'object') return false;

          const isDefaultLang = lang?.language_description !== defaultLanguage;
          const isSupported = Array.isArray(supportedLanguages) &&
            supportedLanguages.includes(lang?.language_code);
          const isNotTranslated = !Object.keys(translations).includes(lang?.language_code);

          return (isDefaultLang && isSupported && isNotTranslated);
        })
        .map(lang => {
          // Ensure we have valid data before mapping
          if (!lang?.language_code || !lang?.language_description) {
            return null;
          }

          return {
            code: lang.language_code,
            name: lang.language_description
          };
        })
        .filter(Boolean); // Remove any null values from mapping

      setAvailableLanguagesToAdd(availableLanguages);
    } catch (error) {
      console.error('Error processing languages:', error);
      setAvailableLanguagesToAdd([]);
    }

  }, [allLanguage, data]);


  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };


  // if (!data || !allLanguage || !translations || loading) {
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      {/* Header with Translate All Button */}
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 3
      }}>
        <Typography variant="h5">
          Banner Translations
        </Typography>

        <ButtonCommon
          onClick={handleBulkTranslate}
          // disabled={isBulkTranslating || languagesToTranslate.length === 0}
          disabled={isBulkTranslating || availableTranslations?.length === 0}
          icon={<TranslateIcon />}
        >
          {isBulkTranslating ? "Translating..." : "Translate All with AI"}
        </ButtonCommon>
      </Box>

      {/* Information alert */}
      <Alert severity="info" sx={{ mb: 3 }}>
        Translate your banner content to multiple languages. The default language ({defaultLanguage})
        is used as the source for translations. Translations will be saved when you update the banner.
      </Alert>

      {(isMandatoryToTrans && availableLanguagesToAdd?.length > 0) && (
        <Alert severity="warning" sx={{ mb: 3 }}>
          As per {frameworkName}, banner must be available in all required languages. Some translations are missing. Please complete translations for all the required languages. The banner will be displayed in English for missing translations.
        </Alert>
      )}


      {/* Add language selector */}
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <Select
            value="" // Add this to reset the select after each selection
            onChange={(event) => {
              if (event?.target?.value) {
                handleAddLanguage(event.target.value);
              }
            }}
            disabled={!Array.isArray(availableLanguagesToAdd) || availableLanguagesToAdd.length === 0}
            displayEmpty
            renderValue={() => "Add Languages"}
          >
            {Array.isArray(availableLanguagesToAdd) && availableLanguagesToAdd.map(lang => (
              lang && lang.code ? (
                <MenuItem
                  key={lang.code}
                  value={lang.code}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <span>{lang.name} ({lang.code.toUpperCase()})</span>
                  {translations && translations[lang.code] && (
                    <Box component="span" sx={{ color: 'success.main', ml: 1 }}>
                      ✓
                    </Box>
                  )}
                </MenuItem>
              ) : null
            ))}
          </Select>
        </FormControl>
        <Typography variant="body2" color="text.secondary">
          {availableLanguagesToAdd?.length === 0
            ? "All supported languages are already added"
            : `${availableLanguagesToAdd?.length} supported languages available to add`}
        </Typography>
      </Box>

      {/* No languages message */}
      {/* {languagesToTranslate.length === 0 && ( */}
      {/* {activeLanguageCards?.length === 0 && (
        <Alert severity="warning" sx={{ mb: 3 }}>
          No languages selected for translation. Add supported languages in Settings → General Settings
          or use the dropdown above if languages are already configured.
        </Alert>
      )} */}

      {activeLanguageCards?.length === 0 && (
        <Alert severity="warning" sx={{ mb: 3 }}>
          There is no language translation added
        </Alert>
      )}

      {/* Language accordion sections */}
      {activeLanguageCards.map((langCode) => {
        const langObj = allLanguage?.find(l => l.language_code === langCode);
        const langName = langObj ? langObj.language_description : langCode;
        const isExistingTranslation = availableTranslations.some(t => t.language_code === langCode);

        return (
          <Accordion key={langCode} sx={{ mb: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                <Typography variant="subtitle1">
                  {langName} ({langCode.toUpperCase()})
                  {isExistingTranslation && (
                    <Typography component="span" color="primary.main" sx={{ ml: 1, fontSize: '0.8rem' }}>
                      (Saved)
                    </Typography>
                  )}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {/* <Button
                    size="small"
                    startIcon={<TranslateIcon />}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTranslateAllForLanguage(langCode);
                    }}
                  >
                    Translate with AI
                  </Button> */}
                  {langCode !== defaultLanguage && (
                    <Button
                      size="small"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenModal((prev) => {
                          return { ...prev, [langCode]: true }
                        })
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </Box>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              <Typography variant="h6" sx={{ mb: 2 }}>Banner Content</Typography>
              <Box ml={2}>
                <Grid container spacing={2} sx={{ display: 'flex', gap: 2 }}>

                  {/* Banner Title */}
                  <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Banner Title"
                        // value={translations[langCode]?.title || ''}
                        value={defaultContent.title || ''}
                        disabled
                        // onChange={(e) => handleTranslationChange(langCode, 'title', e.target.value)}
                        helperText={
                          <Typography
                            variant="caption"
                            component="span"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <strong>Original ({defaultLanguage})</strong>
                            {/* {defaultContent.title} */}
                          </Typography>
                        }
                      // placeholder={defaultContent.title}
                      />
                    </Grid>
                    <Box mb={2}><ArrowRightAlt /></Box>
                    <Grid item xs={12}>
                      <FormControl fullWidth error={errors?.[langCode]?.consent_title} required>
                        <TextField
                          fullWidth
                          label="Banner Title"
                          value={translations[langCode]?.banner_translate_text?.consent_title || ''}
                          onChange={(e) => handleTranslationChange(langCode, 'consent_title', e.target.value)}
                          helperText={
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <strong>Translate ({langName})</strong>
                              {/* {defaultContent.title} */}
                            </Typography>
                          }
                        // placeholder={defaultContent.title}
                        />
                        {errors?.[langCode]?.consent_title && <FormHelperText>Banner Title field is required</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Stack>

                  {/* Banner Description */}
                  <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Banner Description"
                        value={stripHtmlTags(defaultContent?.description || '')} // Strip HTML tags
                        disabled
                        // onChange={(e) => handleTranslationChange(langCode, 'description', e.target.value)}
                        multiline
                        rows={4}
                        helperText={
                          <Typography
                            variant="caption"
                            component="span"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <strong>Original ({defaultLanguage})</strong>
                            {/* {defaultContent.description?.substring(0, 100)}
                        {defaultContent.description?.length > 100 ? '...' : ''} */}
                          </Typography>
                        }
                      // placeholder={defaultContent.description}
                      />
                    </Grid>
                    <Box mb={2}><ArrowRightAlt /></Box>
                    <Grid item xs={12}>
                      <FormControl fullWidth error={errors?.[langCode]?.consent_description} required>
                        <TextField
                          fullWidth
                          label="Banner Description"
                          value={translations[langCode]?.banner_translate_text?.consent_description || ''}
                          onChange={(e) => handleTranslationChange(langCode, 'consent_description', e.target.value)}
                          multiline
                          rows={4}
                          helperText={
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <strong>Translate ({langName})</strong>
                              {/* {defaultContent.description?.substring(0, 100)}
                        {defaultContent.description?.length > 100 ? '...' : ''} */}
                            </Typography>
                          }
                        // placeholder={defaultContent.description}
                        />
                        {errors?.[langCode]?.consent_description && <FormHelperText>Banner Description field is required</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Stack>

                  {/* Banner Buttons */}
                  <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Accept All Button"
                        // value={translations[langCode]?.acceptAllText || ''}
                        value={defaultContent?.acceptAllText || ''}
                        disabled
                        // onChange={(e) => handleTranslationChange(langCode, 'acceptAllText', e.target.value)}
                        helperText={
                          <Typography
                            variant="caption"
                            component="span"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <strong>Original ({defaultLanguage})</strong>
                            {/* {defaultContent.acceptAllText} */}
                          </Typography>
                        }
                      // placeholder={defaultContent.acceptAllText}
                      />
                    </Grid>
                    <Box mb={2}><ArrowRightAlt /></Box>
                    <Grid item xs={12}>
                      <FormControl fullWidth error={errors?.[langCode]?.banner_accept_btn_text} required>
                        <TextField
                          fullWidth
                          label="Accept All Button"
                          value={translations[langCode]?.banner_translate_text?.banner_accept_btn_text || ''}
                          onChange={(e) => handleTranslationChange(langCode, 'banner_accept_btn_text', e.target.value)}
                          helperText={
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <strong>Translate ({langName})</strong>
                              {/* {defaultContent.acceptAllText} */}
                            </Typography>
                          }
                        // placeholder={defaultContent.acceptAllText}
                        />
                        {errors?.[langCode]?.banner_accept_btn_text && <FormHelperText>Accept All Button field is required</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Stack>

                  <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Reject All Button"
                        // value={translations[langCode]?.acceptNecessaryText || ''}
                        value={defaultContent?.acceptNecessaryText || ''}
                        disabled
                        // onChange={(e) => handleTranslationChange(langCode, 'acceptNecessaryText', e.target.value)}
                        helperText={
                          <Typography
                            variant="caption"
                            component="span"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <strong>Original ({defaultLanguage})</strong>
                            {/* {defaultContent.acceptNecessaryText} */}
                          </Typography>
                        }
                      // placeholder={defaultContent.acceptNecessaryText}
                      />
                    </Grid>
                    <Box mb={2}><ArrowRightAlt /></Box>
                    <Grid item xs={12} >
                      <FormControl fullWidth error={errors?.[langCode]?.banner_accept_necessary_btn_text} required>
                        <TextField
                          fullWidth
                          label="Reject All Button"
                          value={translations[langCode]?.banner_translate_text?.banner_accept_necessary_btn_text || ''}
                          onChange={(e) => handleTranslationChange(langCode, 'banner_accept_necessary_btn_text', e.target.value)}
                          helperText={
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <strong>Translate ({langName})</strong>
                              {/* {defaultContent.acceptNecessaryText} */}
                            </Typography>
                          }
                        // placeholder={defaultContent.acceptNecessaryText}
                        />
                        {errors?.[langCode]?.banner_accept_necessary_btn_text && <FormHelperText>Reject All Button field is required</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Stack>

                  <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Manage Preferences Button"
                        // value={translations[langCode]?.managePreferencesText || ''}
                        value={defaultContent?.managePreferencesText || ''}
                        disabled
                        // onChange={(e) => handleTranslationChange(langCode, 'managePreferencesText', e.target.value)}
                        helperText={
                          <Typography
                            variant="caption"
                            component="span"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <strong>Original ({defaultLanguage})</strong>
                            {/* {defaultContent.managePreferencesText} */}
                          </Typography>
                        }
                      // placeholder={defaultContent.managePreferencesText}
                      />
                    </Grid>
                    <Box mb={2}><ArrowRightAlt /></Box>
                    <Grid item xs={12}>
                      <FormControl fullWidth error={errors?.[langCode]?.banner_preferences_btn_text} required>
                        <TextField
                          fullWidth
                          label="Manage Preferences Button"
                          value={translations[langCode]?.banner_translate_text?.banner_preferences_btn_text || ''}
                          onChange={(e) => handleTranslationChange(langCode, 'banner_preferences_btn_text', e.target.value)}
                          helperText={
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <strong>Translate ({langName})</strong>
                              {/* {defaultContent.managePreferencesText} */}
                            </Typography>
                          }
                        // placeholder={defaultContent.managePreferencesText}
                        />
                        {errors?.[langCode]?.banner_preferences_btn_text && <FormHelperText>Manage Preferences Button field is required</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Stack>
                </Grid>

                <Divider sx={{ my: 3 }} />

                <Typography variant="h6" sx={{ mb: 2 }}>Preference Modal Content</Typography>

                <Grid container spacing={2} sx={{ display: 'flex', gap: 2 }}>
                  {/* Preferences Title */}
                  <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Preferences Modal Title"
                        // value={translations[langCode]?.preferencesTitle || ''}
                        value={defaultContent?.preferencesTitle || ''}
                        disabled
                        // onChange={(e) => handleTranslationChange(langCode, 'preferencesTitle', e.target.value)}
                        helperText={
                          <Typography
                            variant="caption"
                            component="span"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <strong>Original ({defaultLanguage})</strong>
                            {/* {defaultContent.preferencesTitle} */}
                          </Typography>
                        }
                      // placeholder={defaultContent.preferencesTitle}
                      />
                    </Grid>
                    <Box mb={2}><ArrowRightAlt /></Box>
                    <Grid item xs={12}>
                      <FormControl fullWidth error={errors?.[langCode]?.settings_title} required>
                        <TextField
                          fullWidth
                          label="Preferences Modal Title"
                          value={translations[langCode]?.banner_translate_text?.settings_title || ''}
                          onChange={(e) => handleTranslationChange(langCode, 'settings_title', e.target.value)}
                          helperText={
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <strong>Translate ({langName})</strong>
                              {/* {defaultContent.preferencesTitle} */}
                            </Typography>
                          }
                        // placeholder={defaultContent.preferencesTitle}
                        />
                        {errors?.[langCode]?.settings_title && <FormHelperText>Preferences Modal Title field is required</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Stack>

                  {/* More Info Title */}
                  <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="More Information Title"
                        // value={translations[langCode]?.moreInfoTitle || ''}
                        value={defaultContent?.moreInfoTitle || ''}
                        disabled
                        // onChange={(e) => handleTranslationChange(langCode, 'moreInfoTitle', e.target.value)}
                        helperText={
                          <Typography
                            variant="caption"
                            component="span"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <strong>Original ({defaultLanguage})</strong>
                            {/* {defaultContent.moreInfoTitle} */}
                          </Typography>
                        }
                      // placeholder={defaultContent.moreInfoTitle}
                      />
                    </Grid>
                    <Box mb={2}><ArrowRightAlt /></Box>
                    <Grid item xs={12}>
                      <FormControl fullWidth error={errors?.[langCode]?.settings_more_info_title} required>
                        <TextField
                          fullWidth
                          label="More Information Title"
                          value={translations[langCode]?.banner_translate_text?.settings_more_info_title || ''}
                          onChange={(e) => handleTranslationChange(langCode, 'settings_more_info_title', e.target.value)}
                          helperText={
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <strong>Translate ({langName})</strong>
                              {/* {defaultContent.moreInfoTitle} */}
                            </Typography>
                          }
                        // placeholder={defaultContent.moreInfoTitle}
                        />
                        {errors?.[langCode]?.settings_more_info_title && <FormHelperText>More Information Title field is required</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Stack>

                  {/* Preferences Description */}
                  <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Preferences Description"
                        // value={translations[langCode]?.preferencesDescription || ''}
                        value={defaultContent?.preferencesDescription || ''}
                        disabled
                        // onChange={(e) => handleTranslationChange(langCode, 'preferencesDescription', e.target.value)}
                        multiline
                        rows={4}
                        helperText={
                          <Typography
                            variant="caption"
                            component="span"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <strong>Original ({defaultLanguage})</strong>
                            {/* {defaultContent.preferencesDescription?.substring(0, 100)}
                        {defaultContent.preferencesDescription?.length > 100 ? '...' : ''} */}
                          </Typography>
                        }
                      // placeholder={defaultContent.preferencesDescription}
                      />
                    </Grid>
                    <Box mb={2}><ArrowRightAlt /></Box>
                    <Grid item xs={12}>
                      <FormControl fullWidth error={errors?.[langCode]?.settings_description} required>
                        <TextField
                          fullWidth
                          label="Preferences Description"
                          value={translations[langCode]?.banner_translate_text?.settings_description || ''}
                          onChange={(e) => handleTranslationChange(langCode, 'settings_description', e.target.value)}
                          multiline
                          rows={4}
                          helperText={
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <strong>Translate ({langName})</strong>
                              {/* {defaultContent.preferencesDescription?.substring(0, 100)}
                        {defaultContent.preferencesDescription?.length > 100 ? '...' : ''} */}
                            </Typography>
                          }
                        // placeholder={defaultContent.preferencesDescription}
                        />
                        {errors?.[langCode]?.settings_description && <FormHelperText>Preferences Description field is required</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Stack>

                  {/* More Info Content */}
                  <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="More Information Content"
                        // value={translations[langCode]?.moreInfoContent || ''}
                        value={defaultContent?.moreInfoContent || ''}
                        disabled
                        // onChange={(e) => handleTranslationChange(langCode, 'moreInfoContent', e.target.value)}
                        multiline
                        rows={4}
                        helperText={
                          <Typography
                            variant="caption"
                            component="span"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <strong>Original ({defaultLanguage})</strong>
                            {/* {defaultContent.moreInfoContent?.substring(0, 100)}
                        {defaultContent.moreInfoContent?.length > 100 ? '...' : ''} */}
                          </Typography>
                        }
                      // placeholder={defaultContent.moreInfoContent}
                      />
                    </Grid>
                    <Box mb={2}><ArrowRightAlt /></Box>
                    <Grid item xs={12}>
                      <FormControl fullWidth error={errors?.[langCode]?.settings_more_info} required>
                        <TextField
                          fullWidth
                          label="More Information Content"
                          value={translations[langCode]?.banner_translate_text?.settings_more_info || ''}
                          onChange={(e) => handleTranslationChange(langCode, 'settings_more_info', e.target.value)}
                          multiline
                          rows={4}
                          helperText={
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <strong>Translate ({langName})</strong>
                              {/* {defaultContent.moreInfoContent?.substring(0, 100)}
                        {defaultContent.moreInfoContent?.length > 100 ? '...' : ''} */}
                            </Typography>
                          }
                        // placeholder={defaultContent.moreInfoContent}
                        />
                        {errors?.[langCode]?.settings_more_info && <FormHelperText>More Information Content field is required</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Stack>

                  {/* Save Preferences Button */}
                  <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Save Preferences Button"
                        // value={translations[langCode]?.savePreferencesText || ''}
                        value={defaultContent?.savePreferencesText || ''}
                        disabled
                        // onChange={(e) => handleTranslationChange(langCode, 'savePreferencesText', e.target.value)}
                        helperText={
                          <Typography
                            variant="caption"
                            component="span"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <strong>Original ({defaultLanguage})</strong>
                            {/* {defaultContent.savePreferencesText} */}
                          </Typography>
                        }
                      // placeholder={defaultContent.savePreferencesText}
                      />
                    </Grid>
                    <Box mb={2}><ArrowRightAlt /></Box>
                    <Grid item xs={12}>
                      <FormControl fullWidth error={errors?.[langCode]?.save_preferences_btn_text} required>
                        <TextField
                          fullWidth
                          label="Save Preferences Button"
                          value={translations[langCode]?.banner_translate_text?.save_preferences_btn_text || ''}
                          onChange={(e) => handleTranslationChange(langCode, 'save_preferences_btn_text', e.target.value)}
                          helperText={
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <strong>Translate ({langName})</strong>
                              {/* {defaultContent.savePreferencesText} */}
                            </Typography>
                          }
                        // placeholder={defaultContent.savePreferencesText}
                        />
                        {errors?.[langCode]?.save_preferences_btn_text && <FormHelperText>Save Preferences Button field is required</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Stack>

                  {/* Preferences Accept Button */}
                  <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Preferences Accept Button"
                        // value={translations[langCode]?.preferencesAcceptText || ''}
                        value={defaultContent?.preferencesAcceptText || ''}
                        disabled
                        // onChange={(e) => handleTranslationChange(langCode, 'preferencesAcceptText', e.target.value)}
                        helperText={
                          <Typography
                            variant="caption"
                            component="span"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <strong>Original ({defaultLanguage})</strong>
                            {/* {defaultContent.preferencesAcceptText} */}
                          </Typography>
                        }
                      // placeholder={defaultContent.preferencesAcceptText}
                      />
                    </Grid>
                    <Box mb={2}><ArrowRightAlt /></Box>
                    <Grid item xs={12}>
                      <FormControl fullWidth error={errors?.[langCode]?.preferences_accept_btn_text} required>
                        <TextField
                          fullWidth
                          label="Preferences Accept Button"
                          value={translations[langCode]?.banner_translate_text?.preferences_accept_btn_text || ''}
                          onChange={(e) => handleTranslationChange(langCode, 'preferences_accept_btn_text', e.target.value)}
                          helperText={
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <strong>Translate ({langName})</strong>
                              {/* {defaultContent.preferencesAcceptText} */}
                            </Typography>
                          }
                        // placeholder={defaultContent.preferencesAcceptText}
                        />
                        {errors?.[langCode]?.preferences_accept_btn_text && <FormHelperText>Preferences Accept Button field is required</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Stack>

                  {/* Preferences Accept Necessary Button */}
                  <Stack sx={{ flexDirection: 'row', gap: 2, width: '100%', alignItems: 'center' }}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Preferences Accept Necessary Button"
                        // value={translations[langCode]?.preferencesAcceptNecessaryText || ''}
                        value={defaultContent?.preferencesAcceptNecessaryText || ''}
                        disabled
                        // onChange={(e) => handleTranslationChange(langCode, 'preferencesAcceptNecessaryText', e.target.value)}
                        helperText={
                          <Typography
                            variant="caption"
                            component="span"
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <strong>Original ({defaultLanguage})</strong>
                            {/* {defaultContent.preferencesAcceptNecessaryText} */}
                          </Typography>
                        }
                      // placeholder={defaultContent.preferencesAcceptNecessaryText}
                      />
                    </Grid>
                    <Box mb={2}><ArrowRightAlt /></Box>
                    <Grid item xs={12}>
                      <FormControl fullWidth error={errors?.[langCode]?.preferences_accept_necessary_btn_text} required>
                        <TextField
                          fullWidth
                          label="Preferences Accept Necessary Button"
                          value={translations[langCode]?.banner_translate_text?.preferences_accept_necessary_btn_text || ''}
                          onChange={(e) => handleTranslationChange(langCode, 'preferences_accept_necessary_btn_text', e.target.value)}
                          helperText={
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <strong>Translate ({langName})</strong>
                              {/* {defaultContent.preferencesAcceptNecessaryText} */}
                            </Typography>
                          }
                        // placeholder={defaultContent.preferencesAcceptNecessaryText}
                        />
                        {errors?.[langCode]?.preferences_accept_necessary_btn_text && <FormHelperText>Preferences Accept Necessary Button field is required</FormHelperText>}
                      </FormControl>
                    </Grid>
                  </Stack>
                </Grid>

                {/* Save button for individual language (when banner already exists) */}
                {bannerId && (
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <ButtonCommon
                      onClick={() => handleSaveTranslation(langCode, isExistingTranslation)}
                      disabled={!isEdited?.[langCode]}
                    >
                      {/* {isSaving ? isExistingTranslation ? "Updating Translation" : "Saving Translation" : isExistingTranslation ? "Update Translation" : "Save Translation"} */}
                      {isSaving?.[langCode] ? savedTranslations[langCode] ? "Updating Translation" : "Saving Translation" : savedTranslations[langCode] ? "Update Translation" : "Save Translation"}
                    </ButtonCommon>
                  </Box>
                )}
              </Box>
            </AccordionDetails>

            {openModal[langCode] && <DeletePopup openModal={openModal} setOpenModal={setOpenModal} handleRemoveLanguage={handleRemoveLanguage} langCode={langCode} />}
            {/* {openModal[langCode] && <DeletePopup openModal={openModal} setOpenModal={setOpenModal} handleRemoveLanguage={handleRemoveLanguage} langCode={langCode} translations={translations} isExistingTranslation={isExistingTranslation} />} */}
          </Accordion>
        )
      })}
    </Box >
  );
};

export default LanguageSettings;
