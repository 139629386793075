import React, { useMemo, useState } from "react";
import {
  TextField,
  Box,
  Typography,
  InputLabel,
  Grid,
  CircularProgress,
  Button,
  styled,
  Link,
  Card,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { signupUser } from "../api/signupService";
import { showErrorToast, showSuccessToast } from "../toastUtils";
import myIcon from "../assets/svg/logo.svg";
import illustrationSvg from "../assets/svg/signup.svg";
import { useNavigate } from "react-router-dom";
import chart from "../assets/svg/chart.svg";
import dpcIcon from "../assets/svg/dpc-logo.svg";

const SignupPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company_name: "",
    company_size: null,
    website: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [pageViewsData, setPageViewsData] = useState([]);
  const [recommendedPlan, setRecommendedPlan] = useState(null);
  const navigate = useNavigate();

  // New styled component for the scrollable illustration side
  const IllustrationContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100vh",
    overflow: "hidden", // Hide overflowing content
    padding: theme.spacing(2),
    position: "sticky",
    top: 0,
    [theme.breakpoints.down("md")]: {
      display: "none", // Hide on smaller screens
    },
  }));

  const StatsCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.spacing(1),
    height: "100%",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  }));

  const PlanCard = styled(Card)(({ theme, isRecommended }) => ({
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    height: "100%",
    position: "relative",
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
    border: isRecommended
      ? `2px solid ${theme.palette.primary.main}`
      : "1px solid #e0e0e0",
    "&:hover": {
      transform: "translateY(-4px)",
      boxShadow: isRecommended
        ? `0 8px 16px ${theme.palette.primary.main}`
        : "0 8px 16px rgba(0,0,0,0.1)",
    },
  }));

  const RecommendedBadge = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "4px 12px",
    borderBottomLeftRadius: "8px",
    fontWeight: "bold",
    fontSize: "0.75rem",
  }));

  // Plan configurations
  const plans = [
    {
      id: "startup",
      name: "Startup",
      description: "Essential privacy tools for small websites",
    },
    {
      id: "business",
      name: "Business",
      description: "Complete solution for growing businesses",
    },
    {
      id: "enterprise",
      name: "Enterprise",
      description: "Customized privacy solutions for large organizations",
    },
  ];

  const formatNumber = (num) => {
    if (num === null || num === undefined) return "0";
    const number = Number(num);
    if (number >= 1_000_000_000) {
      return (number / 1_000_000_000).toFixed(1) + "B";
    } else if (number >= 1_000_000) {
      return (number / 1_000_000).toFixed(1) + "M";
    } else if (number >= 1_000) {
      return (number / 1_000).toFixed(1) + "K";
    } else {
      return number.toLocaleString();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let newErrors = {};

    // Required Fields (Prevent Spaces)
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email.trim()))
      newErrors.email = "Invalid email";

    if (!formData.website.trim()) {
      newErrors.website = "Website domain is required";
    } else if (
      !/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/.test(
        formData.website.trim()
      )
    ) {
      newErrors.website = "Invalid domain format Use Format: example.com";
    }

    // Terms & Conditions Validation
    if (!formData.terms) {
      newErrors.terms = "You must accept the consent agreement";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    setLoading(true);
    try {
      const payload = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone || "",
        website: formData.website,
      };

      const response = await signupUser(payload);

      if (response?.status_code === 201) {
        setPageViewsData(response?.data?.page_views);
        setRecommendedPlan(response?.data?.recommended_plan);
        showSuccessToast("Signup Successfully!");
        setSubmitted(true);
      } else {
        showErrorToast(response?.message);
      }
    } catch (error) {
      showErrorToast(
        "Submission failed: " + (error.message || "Unknown error")
      );
    } finally {
      setLoading(false);
    }
  };

  // Render the scrollable illustration side
  const renderIllustration = () => {
    return (
      <>
        <IllustrationContainer>
          <div className="scroll-container">
            <img
              src={illustrationSvg}
              alt="Privacy Protection Illustration"
              className="scrolling-image"
            />
            <img
              src={illustrationSvg}
              alt="Privacy Protection Illustration Clone"
              className="scrolling-image"
              style={{ paddingTop: "10px" }}
            />
          </div>
        </IllustrationContainer>

        <style>
          {`
          .scroll-container {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
          }
          .scrolling-image {
            max-width: 100%;
            height: auto;
            animation: scrollVertical 120s linear infinite;
          }
          @keyframes scrollVertical {
            0% { transform: translateY(0%); }
            100% { transform: translateY(-100%); } /* Moves up infinitely */
          }
        `}
        </style>
      </>
    );
  };

  const illustration = useMemo(() => renderIllustration(), []);

  // Render the signup form
  const renderSignupForm = () => {
    if (submitted) {
      return (
        <Box
          sx={{
            width: "100%",
            maxWidth: 700,
            borderRadius: 2,
            textAlign: "center",
            mx: "auto",
          }}
        >
          <img src={dpcIcon} alt="logo" width="250" height="50" />
          <Typography
            variant="h4"
            color="#5F5F5F"
            fontWeight="bold"
            mb={1}
            mt={1}
          >
            Thank You!
          </Typography>
          <Typography
            variant="h6"
            color="#5F5F5F"
            sx={{ mb: 4, fontWeight: 400 }}
          >
            for signing-up, we will get back to you shortly.
          </Typography>
          <Typography
            variant="body1"
            color="#5F5F5F"
            sx={{ mb: 8, fontWeight: 500 }}
          >
            We have recommended a plan based on your domain{" "}
            <strong style={{ color: "#00B894" }}>{formData?.website}</strong>,
            here is your last 3 months website traffic.
          </Typography>
          <Box sx={{ p: 2 }}>
            {/* Page Views Cards */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              {pageViewsData.map((data, index) => (
                <Grid item xs={12} sm={4} key={`pageviews-${index}`}>
                  <StatsCard>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body2" color="#898989">
                        Page Views
                      </Typography>
                      <Typography
                        variant="h6"
                        color="#6C6C6C"
                        fontWeight="bold"
                      >
                        {new Date(data.date).toLocaleString("en-US", {
                          month: "short",
                        })}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        variant="h4"
                        component="div"
                        fontWeight="bold"
                        sx={{ mt: 1 }}
                        color="#101C34"
                      >
                        {formatNumber(Math.round(data.pages_views))}
                      </Typography>
                      <img src={chart} alt="chart" />
                    </Box>
                  </StatsCard>
                </Grid>
              ))}
            </Grid>

            {/* Plans Cards */}
            <Grid container spacing={3}>
              {plans.map((plan) => {
                const isRecommended =
                  plan.name ===
                  recommendedPlan.charAt(0).toUpperCase() +
                    recommendedPlan.slice(1);

                return (
                  <Grid item xs={12} sm={4} key={`plan-${plan.id}`}>
                    <PlanCard isRecommended={isRecommended}>
                      {isRecommended && (
                        <RecommendedBadge>Recommended</RecommendedBadge>
                      )}
                      <Typography
                        variant="h5"
                        component="div"
                        fontWeight="bold"
                      >
                        {plan.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mt: 1 }}
                      >
                        {plan.description}
                      </Typography>
                    </PlanCard>
                  </Grid>
                );
              })}
            </Grid>

            <Typography
              alignItems="center"
              justifyContent="center"
              display="flex"
              fontWeight="bold"
              color="#5F5F5F"
              mt={6}
            >
              Already have an account?{" "}
              <Link
                ml={1}
                onClick={() => navigate("/login")}
                color="#00B894"
                sx={{ cursor: "pointer" }}
              >
                Sign In
              </Link>
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: 550,
          p: 3,
          borderRadius: 2,
          mx: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 4,
          }}
        >
          <img src={myIcon} alt="logo" width="250" height="50" />
          <Typography mt={4} variant="h6" fontWeight="bold" color="#5F5F5F">
            Sign Up to{" "}
            <span style={{ color: "#00B894" }}>Data Privacy Cloud</span>
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          {/* Required Fields */}
          <Box mb={2}>
            <InputLabel sx={{ fontWeight: "bold" }} required>
              Name
            </InputLabel>
            <TextField
              fullWidth
              name="name"
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              size="small"
              placeholder="Enter your full name"
            />
          </Box>

          <Box mb={2}>
            <InputLabel sx={{ fontWeight: "bold" }} required>
              Email
            </InputLabel>
            <TextField
              fullWidth
              name="email"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              size="small"
              placeholder="Enter your email address"
            />
          </Box>

          <Box mb={2}>
            <InputLabel sx={{ fontWeight: "bold" }}>Phone</InputLabel>
            <TextField
              fullWidth
              name="phone"
              variant="outlined"
              value={formData.phone}
              onChange={handleChange}
              size="small"
              placeholder="Enter your phone number"
              type="tel" // Ensures a numeric keypad on mobile
              inputProps={{
                inputMode: "numeric", // Improves user experience on mobile
                pattern: "[0-9]*", // Ensures only numbers are allowed
              }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
              }}
            />
          </Box>

          <Box mb={2}>
            <InputLabel sx={{ fontWeight: "bold" }} required>
              Website
            </InputLabel>
            <TextField
              fullWidth
              name="website"
              variant="outlined"
              value={formData.website}
              onChange={handleChange}
              error={!!errors.website}
              helperText={errors.website}
              size="small"
              placeholder="example.com"
            />
          </Box>

          <Box mb={2}>
            <Typography variant="body2" color="#5F5F5F">
              We'll use your data only to contact you for our products and
              services. We may share your data with our partners in case you are
              an end customer.
            </Typography>
          </Box>

          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  name="terms"
                  checked={formData.terms}
                  onChange={(e) =>
                    setFormData({ ...formData, terms: e.target.checked })
                  }
                  color="primary"
                />
              }
              label={
                <Typography variant="body2" color="#5F5F5F">
                  I consent to DataVizz to collect my data.
                </Typography>
              }
            />
            {errors.terms && (
              <Typography variant="body" color="error" display="flex">
                {errors.terms}
              </Typography>
            )}
          </Box>

          <Button
            type="submit"
            size="small"
            fullWidth
            variant="contained"
            sx={{
              mt: 2,
              mb: 2,
              borderRadius: 1.5,
              textTransform: "none",
              fontSize: "1rem",
              color: "white",
              fontWeight: 700,
            }}
            disabled={loading}
          >
            {!loading ? "Sign Up" : <CircularProgress size={20} />}
          </Button>
        </form>
        <Typography
          alignItems="center"
          justifyContent="center"
          display="flex"
          fontWeight="bold"
          color="#5F5F5F"
        >
          Already have an account?{" "}
          <Link
            ml={1}
            onClick={() => navigate("/login")}
            color="#00B894"
            sx={{ cursor: "pointer" }}
          >
            Sign In
          </Link>
        </Typography>
      </Box>
    );
  };

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
      }}
    >
      {/* Left side - Scrollable Illustration */}
      <Grid
        item
        md={6}
        lg={6}
        sx={{
          display: { xs: "none", md: "block" },
          background: "#F6F6F6",
          pl: 1,
        }}
      >
        {illustration}
      </Grid>

      {/* Right side - Form */}
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
          background: "#F6F6F6",
        }}
      >
        {renderSignupForm()}
      </Grid>
    </Grid>
  );
};

export default SignupPage;
