import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  TextField,
  // CircularProgress,
  FormControl,
  Alert,
  CircularProgress,
  Modal,
  IconButton,
  Divider,
  MenuItem,
  Select,
  Button,
  Stack,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import { updateSupportedLanguage } from "../../../api/bannerService";
import { showErrorToast, showSuccessToast } from "../../../toastUtils";
import WarningIcon from '@mui/icons-material/Warning';
// import { getAllLanguages } from "../../../api/websiteService";

const FrameworkSettings = ({ data, allLanguage, loading, onUpdate, bannerId, translations, onUpdateTranslation }) => {
  // State for displaying data
  const [frameworkName, setFrameworkName] = useState("");
  // const [allLanguage, setAllLanguage] = useState("");
  const [languageName, setLanguageName] = useState("");
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [openModal, setOpenModal] = useState(false)
  // const [selectedFramework, setSelectedFramework] = useState(null);
  // console.log('data', data);

  // Initialize state from props when component mounts or props change
  useEffect(() => {
    if (data) {
      // console.log('frameworkName', data);

      // Set framework name
      if (data.frameworkName) {
        setFrameworkName(data.frameworkName);
      }

      // Set language code
      if (data.languageName) {
        setLanguageName(data.languageName);
      }
      // Set supported languages
      // Ensure data.supportedLanguages is properly filtered
      if (data.supportedLanguages?.length && allLanguage?.length) {
        const filteredLanguages = allLanguage.filter(lang =>
          data.supportedLanguages.includes(lang.language_code) // ✅ Proper filtering
        );

        // console.log('filteredLanguages', filteredLanguages);


        setSupportedLanguages(filteredLanguages);
      }
    }
  }, [data, allLanguage]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box>
        <Typography variant="h6" mb={1} sx={{ color: "#525455" }}>
          Banner Framework
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={2}>
          Select a framework to configure your consent banner with appropriate settings based on regional regulations.
        </Typography>

        <Card variant="outlined" sx={{ bgcolor: "#F9FAFB", mb: 3 }}>
          <CardContent sx={{ p: 3 }}>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <TextField
                value={frameworkName}
                disabled
              />
            </FormControl>
          </CardContent>
        </Card>

        <Card variant="outlined" sx={{ bgcolor: "#F9FAFB" }}>
          <CardContent sx={{ p: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: "#637381", mb: 1 }}>
              Default Language
            </Typography>

            <Typography variant="body2" color="text.secondary" mb={2}>
              Select the primary language for your banner. This will be the default language shown to visitors.
            </Typography>

            <FormControl fullWidth sx={{ mb: 3 }}>
              <TextField
                value={languageName}
                disabled
              />
            </FormControl>

            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: "#637381", mb: 1, mt: 3 }}>
              Supported Languages
            </Typography>

            <Typography variant="body2" color="text.secondary" mb={2}>
              These languages are available for your consent banner translation.
            </Typography>

            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
              {/* {console.log('supportedLanguages', supportedLanguages)} */}
              {supportedLanguages && supportedLanguages?.length > 0 && supportedLanguages.map(lang => (
                <Chip
                  key={lang.language_id}
                  label={lang.language_description}
                  variant="outlined"
                  color="default"
                />
              ))}

              <Box sx={{ width: '30px', height: '30px', bgcolor: '#00B894', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100%', cursor: 'pointer' }} onClick={() => setOpenModal(true)}>
                <AddIcon />
              </Box>

              <AddNewLanguages openModal={openModal} setOpenModal={setOpenModal} allLanguage={allLanguage} supportedLanguages={supportedLanguages} setSupportedLanguages={setSupportedLanguages} onUpdate={onUpdate} bannerId={bannerId} translations={translations} onUpdateTranslation={onUpdateTranslation} />
            </Box>

            {supportedLanguages?.length === 0 && (
              <Alert severity="info" sx={{ mt: 2 }}>
                Select a framework first to see available languages
              </Alert>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default FrameworkSettings;

const AddNewLanguages = ({ openModal, setOpenModal, allLanguage, supportedLanguages, onUpdate, bannerId, translations, onUpdateTranslation }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [customLanguage, setCustomLanguage] = useState([]);
  const [deletedLanguage, setDeletedLanguage] = useState([]);
  const [openConfirmModalPopup, setOpenConfirmModalPopup] = useState(false)

  useEffect(() => {
    if (allLanguage?.length) {
      const filteredLanguages = allLanguage.filter(
        lang => !supportedLanguages?.some(supported => supported.language_code === lang.language_code)
      );
      setLanguages(filteredLanguages);
      setCustomLanguage([...supportedLanguages])
    }
  }, [allLanguage, supportedLanguages, openModal]);

  const handleClose = () => {
    setOpenModal(false);
    setCustomLanguage([]);
    setDeletedLanguage([]);
  };

  const handleAddChange = (langCode) => {
    if (!langCode) return;

    const newLanguage = allLanguage.find(lang => lang.language_code === langCode);
    if (!newLanguage) return;
    setCustomLanguage(prev => [...prev, newLanguage]); // Append to customLanguage

    // Remove selected language from dropdown
    setLanguages(prev => prev.filter(lang => lang.language_code !== langCode));
  };

  const handleAddLanguage = async () => {
    console.log(customLanguage, deletedLanguage, deletedLanguage?.length > 0);

    if (deletedLanguage && deletedLanguage?.length === 0) {
      try {
        setIsAdding(true);

        const payload = {
          supported_languages: customLanguage.map(lang => lang.language_code),
          deleted_supported_language: deletedLanguage.map(lang => lang.language_code),
        };
        // console.log(payload, bannerId, translations);

        const response = await updateSupportedLanguage(bannerId, payload);

        if (response?.success) {
          onUpdate({
            supportedLanguages: customLanguage.map(lang => lang.language_code)
          });

          const deletedLanguageCodes = deletedLanguage.map(lang => lang.language_code);
          const filteredTranslation = translations && Object.keys(translations || {}) // Ensure translation is not null/undefined
            .filter(lang => !deletedLanguageCodes.includes(lang))
            .reduce((acc, lang) => {
              acc[lang] = translations[lang];
              return acc;
            }, {});

          onUpdateTranslation({
            translations: filteredTranslation,
          })

          handleClose()

          setCustomLanguage([]);
          setDeletedLanguage([]);

          // Show success toast
          showSuccessToast('Supported languages updated successfully');
        }
      } catch (error) {
        console.error('Error updating supported languages:', error);
        showErrorToast('Supported languages update failed. Please try again.');
      } finally {
        setIsAdding(false);
        handleClose();
      }
    } else {
      setOpenConfirmModalPopup(true)
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
      >
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "#FFFFFF",
          borderRadius: "16px",
          boxShadow: 24,
          p: 3,
        }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" color="#525455" fontWeight="bold">
              Add New Language
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Divider sx={{ my: 2 }} />

          {/* Language Selection Section */}
          <Box sx={{ mb: 3 }}>
            <FormControl fullWidth>
              <Select
                // value={selectedLanguage || ''}
                onChange={(event) => {
                  if (event?.target?.value) {
                    handleAddChange(event.target.value);
                  }
                }}
                disabled={!Array.isArray(languages) || languages.length === 0}
                displayEmpty
                renderValue={() => "Select Language"}
              >
                {languages && languages.length > 0 && languages.map(lang => (
                  <MenuItem
                    key={lang.language_code}
                    value={lang.language_code}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <span>{lang.language_description} ({lang.language_code})</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box mt={2}>
              <Typography gutterBottom>Added Languages</Typography>
              <Stack sx={{ flexDirection: 'row', gap: 1, flexWrap: 'wrap', }}>
                {customLanguage && customLanguage?.length > 0 && customLanguage.map(lang => (
                  <Chip
                    key={lang.language_code}
                    label={lang.language_description}
                    variant="outlined"
                    color="default"
                    onDelete={() => {
                      setCustomLanguage(prev => prev.filter(item => item.language_code !== lang.language_code));
                      setLanguages(prev => [...prev, lang]);
                      setDeletedLanguage((prev => [...prev, lang]))
                    }}
                  />
                ))}
              </Stack>
            </Box>
          </Box>

          <Box gap={2} display="flex">
            <Button
              variant="outlined"
              sx={{
                border: "2px solid #00B894",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
              onClick={handleAddLanguage}
              disabled={deletedLanguage?.length === 0 && isAdding}
            >
              {/* Confirm */}
              {(deletedLanguage?.length === 0 && isAdding) ? 'Confirming...' : 'Confirm'}
            </Button>
            <Button
              variant="outlined"
              sx={{
                border: "2px solid #CECECE",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Use the ConfirmPopup component */}
      <ConfirmPopup
        openConfirmModalPopup={openConfirmModalPopup}
        setOpenConfirmModalPopup={setOpenConfirmModalPopup}
        deletedLanguage={deletedLanguage}
        customLanguage={customLanguage}
        translations={translations}
        bannerId={bannerId}
        onUpdate={onUpdate}
        onUpdateTranslation={onUpdateTranslation}
        setOpenModal={setOpenModal}
        setCustomLanguage={setCustomLanguage}
        setDeletedLanguage={setDeletedLanguage}
      />
    </>
  );
};


const ConfirmPopup = ({ openConfirmModalPopup, setOpenConfirmModalPopup, deletedLanguage, customLanguage, translations, bannerId, onUpdate, onUpdateTranslation, setOpenModal ,setCustomLanguage,setDeletedLanguage}) => {
  const [isAdding, setIsAdding] = useState(false);

  // Ensure we have default values and proper handling
  const handleClose = () => {
    if (setOpenConfirmModalPopup) {
      setOpenConfirmModalPopup(false);
    }
  };

  // If openConfirmModalPopup is undefined, don't render the modal
  if (typeof openConfirmModalPopup === 'undefined') {
    return null;
  }

  const handleOk = async () => {
    try {
      setIsAdding(true);

      const payload = {
        supported_languages: customLanguage.map(lang => lang.language_code),
        deleted_supported_language: deletedLanguage.map(lang => lang.language_code),
      };
      // console.log(payload, bannerId, translations);

      const response = await updateSupportedLanguage(bannerId, payload);

      if (response?.success) {
        onUpdate({
          supportedLanguages: customLanguage.map(lang => lang.language_code)
        });

        const deletedLanguageCodes = deletedLanguage.map(lang => lang.language_code);
        const filteredTranslation = translations && Object.keys(translations || {}) // Ensure translation is not null/undefined
          .filter(lang => !deletedLanguageCodes.includes(lang))
          .reduce((acc, lang) => {
            acc[lang] = translations[lang];
            return acc;
          }, {});

        onUpdateTranslation({
          translations: filteredTranslation,
        })

        handleClose()
        setOpenModal(false)

        setCustomLanguage([]);
        setDeletedLanguage([]);

        // Show success toast
        showSuccessToast('Supported languages updated successfully');
      }
    } catch (error) {
      console.error('Error updating supported languages:', error);
      showErrorToast('Supported languages update failed. Please try again.');
    } finally {
      setIsAdding(false);
      handleClose();
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: openConfirmModalPopup ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1400,
      }}
    >
      <Box
        sx={{
          width: 600,
          bgcolor: "#FFFFFF",
          borderRadius: "16px",
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            color="warning"
            fontWeight="bold"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <WarningIcon /> <span>Warning</span>
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Typography color="#989898">
          You have deleted some languages. Are you sure you want to continue?
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box gap={2} display="flex">
          <Button
            variant="outlined"
            sx={{
              border: "2px solid #00B894",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
            disabled={isAdding}
            onClick={handleOk}
          >
            Ok
          </Button>
          <Button
            variant="outlined"
            sx={{
              border: "2px solid #CECECE",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
