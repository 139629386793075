import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  InputLabel,
  Paper,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Divider,
  Alert,
} from "@mui/material";
import myIcon from "../assets/svg/logo.svg";
import { useNavigate } from "react-router-dom";
import {
  fetchUserAttributes,
  getCurrentUser,
  updateUserAttributes,
} from "@aws-amplify/auth";
import {
  createWebsite,
  getAllLanguages,
  startScan,
} from "../api/websiteService";
import {
  createBanner,
  getAllFrameworks,
  getFrameworkLanguages,
} from "../api/bannerService";
import { useDispatch } from "react-redux";
import { setScanData } from "../store/scanSlice";
import { showErrorToast, showSuccessToast } from "../toastUtils";
// import { updateLicenseUsage } from "../store/licenseSlice";
// import { updateLicense } from "../api/licensing";

const AddWebAndScanPage = () => {
  const [url, setUrl] = useState("");
  const [currentStep, setCurrentStep] = useState(1); // Step 1: Domain, Step 2: Framework/Language, Step 3: Scan
  const [error, setError] = useState(null);
  const [addWebsiteData, setAddWebsiteData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoadingScan, setIsLoadingScan] = useState(false);
  // const [websiteCreated, setWebsiteCreated] = useState(false);

  // Framework and language state
  const [frameworks, setFrameworks] = useState([]);
  const [selectedFramework, setSelectedFramework] = useState(null);
  const [frameworkDetails, setFrameworkDetails] = useState(null);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [loadingFrameworks, setLoadingFrameworks] = useState(false);
  const [allLanguage, setAllLanguage] = useState(null);
  const [isMandatoryToTrans, setIsMandatoryToTrans] = useState(null);

  // const licenseData = useSelector((state) => state.license.data);
  // const websitesUsed =
  //   licenseData?.license_plan_details?.license_usage_details?.websites_used ||
  //   0;
  // const maxWebsites =
  //   licenseData?.license_plan_details?.license_plan?.number_of_websites
  //     ?.limit || 0;
  // const licenseId = licenseData?.license_plan_details?.license_id;

  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");

  const dispatch = useDispatch();

  const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;

  const validateUrl = (input) => {
    if (!input) {
      return "Please enter a website URL.";
    }
    if (!urlRegex.test(input)) {
      return "URL must start with http:// or https://";
    }
    return null;
  };

  const fetchCurrentUser = async () => {
    const data = await getCurrentUser();
    const currentUser = await fetchUserAttributes();

    localStorage.setItem("organization_id", parseInt(currentUser?.profile));
    localStorage.setItem("user_id", data.userId);
  };

  useEffect(() => {
    currentStep === 1 && fetchCurrentUser();
    currentStep === 2 && fetchFrameworks();
    // eslint-disable-next-line
  }, [currentStep]);

  const getTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  // Fetch available frameworks
  const fetchFrameworks = async () => {
    try {
      setLoadingFrameworks(true);
      const response = await getAllFrameworks();

      if (response.success && response.data) {
        setFrameworks(response.data.consent_data);
        // Select first framework by default if available
        // if (response.data.length > 0) {
        //   setSelectedFramework(response.data[0].framework_id);
        //   setFrameworkDetails(response.data[0]);
        //   // fetchLanguagesForFramework(response.data[0].framework_id);
        // }
      }
    } catch (error) {
      showErrorToast("Error fetching frameworks:", error);
    } finally {
      setLoadingFrameworks(false);
    }
  };
  // Handle framework selection change
  const handleFrameworkChange = (event) => {
    const frameworkId = event.target.value;
    setSelectedFramework(frameworkId);
    const selectedFrameworkData = frameworks.find(
      (f) => f.framework_id === frameworkId
    );
    setFrameworkDetails(selectedFrameworkData);
    const filteredLanguages = allLanguage?.filter((lang) =>
      selectedFrameworkData.supported_languages.includes(lang.language_code)
    );
    setSupportedLanguages(filteredLanguages);
    // fetchLanguagesForFramework(frameworkId);
  };

  useEffect(() => {
    // Fetch languages for a selected framework
    const fetchIsMandatoryToTrans = async (frameworkId) => {
      if (!frameworkId) return;

      try {
        setLoadingFrameworks(true);
        const response = await getFrameworkLanguages(frameworkId);
        if (response.success && response.data) {
          setIsMandatoryToTrans(response.data.is_mandatory_to_trans);
        }
      } catch (error) {
        showErrorToast("Error fetching framework languages:", error);
      } finally {
        setLoadingFrameworks(false);
      }
    };

    fetchIsMandatoryToTrans(selectedFramework);
  }, [selectedFramework]);

  // Handle language selection change
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  // Step 1: Handle domain submission
  const handleDomainSubmit = async () => {
    try {
      setLoading(true);
      setError("");

      // **Validate URL**
      const validationError = validateUrl(url);
      if (validationError) {
        setError(validationError);
        setLoading(false);
        setLoading(false);
        return;
      }

      const timeZone = getTimezone();

      // Create website
      // if (websitesUsed >= maxWebsites) {
      //   setLoading(false);
      //   navigate("/dashboard");
      //   showInfoToast(
      //     "You have reached the maximum number of allowed websites."
      //   );
      //   return;
      // }

      // **Step 1: Create Website**
      const websiteRes = await createWebsite(url, userId, timeZone);
      setAddWebsiteData(websiteRes?.data);

      if (websiteRes?.status_code === 201) {
        // Store the website ID and organization ID
        const newWebsiteId = websiteRes.data.website_id;
        const organizationId = websiteRes.data.organization_id;
        localStorage.setItem("website_id", newWebsiteId);
        localStorage.setItem("organization_id_str", organizationId);

        // **Step 3: Update License Usage API**
        // const updatedWebsitesUsed = websitesUsed + 1;

        // const payload = {
        //   websites_used: updatedWebsitesUsed,
        // };

        // await updateLicense(licenseId, payload);

        // dispatch(updateLicenseUsage(payload));

        // Move to next step
        // setWebsiteCreated(true);
        setCurrentStep(2);
        showSuccessToast(
          "Website added successfully. Now configure your cookie consent banner."
        );
      } else if (websiteRes?.status_code === 400) {
        setError(websiteRes.message);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Step 2: Handle framework and language submission
  const handleFrameworkSubmit = async () => {
    try {
      setLoading(true);

      // Validate framework and language selection
      if (!selectedFramework) {
        setError("Please select a compliance framework");
        setLoading(false);
        return;
      }

      if (!selectedLanguage) {
        setError("Please select a default language");
        setLoading(false);
        return;
      }

      // Prepare banner payload with selected framework and language
      const bannerPayload = {
        banner_html_data: {
          name: "Default Banner",
          enabled: true,
          acceptAll: true,
          dark_mode: "light",
          target_type: "WorldWide",
          swap_buttons: false,
          button_colors: {
            bg: "#FFFFFF",
            link_color: "#FFFFFF",
            text_color: "#717171",
            heading_color: "#333333",
            btn_primary_bg: "#717171",
            dark_mode_link: "#FFFFFF",
            dark_mode_text: "#717171",
            btn_primary_color: "#333333",
            dark_mode_heading: "#333333",
            secondary_button_bg: "#717171",
            dark_mode_background: "#FFFFFF",
            secondary_button_text: "#FFFFFF",
            dark_mode_primary_button_bg: "#FFFFFF",
            dark_mode_primary_button_text: "#333333",
            dark_mode_secondary_button_bg: "#717171",
            dark_mode_secondary_button_text: "#FFFFFF",
          },
          consent_title: "We use cookies",
          force_consent: false,
          logo_url_dark: "",
          show_services: true,
          consent_layout: "Cloud",
          forced_consent: false,
          logo_url_light: "",
          settings_title: "Cookie Usage",
          acceptNecessary: true,
          savePreferences: true,
          settings_layout: "Box",
          consent_position: "BottomCenter",
          default_language: selectedLanguage,
          btn_border_radius: "2.0",
          settings_more_info:
            "For any queries in relation to our policy on cookies and your choices, please contact us.",
          consent_description:
            "We use cookies to personalize content, ads & to analyze our traffic. By clicking Accept All, you consent to cookies. Please feel free to update your preferences anytime in future.",
          language_autodetect: "document",
          modal_border_radius: "1.5",
          settings_position_x: "Left",
          equal_weight_buttons: true,
          settings_description:
            "We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose to accept cookies or opt-out whenever you want. For more details, read the full privacy policy.",
          consent_duration_days: "182",
          show_banner_close_btn: true,
          banner_accept_btn_text: "Accept All",
          show_banner_accept_btn: true,
          consent_button_placement: "Stacked",
          settings_more_info_title: "More Information",
          preferences_control_level: "Category & Services",
          save_preferences_btn_text: "Save Preference",
          show_save_preferences_btn: true,
          banner_preferences_btn_text: "Manage Preference",
          preferences_accept_btn_text: "Accept All",
          show_banner_preferences_btn: true,
          show_preferences_accept_btn: true,
          consent_additional_description:
            "Consent Management Powered By Data Privacy Cloud",
          show_floating_preferences_icon: true,
          banner_accept_necessary_btn_text: "Reject All",
          show_banner_accept_necessary_btn: true,
          floating_preferences_icon_position: "Bottom Left",
          preferences_accept_necessary_btn_text: "Reject All",
          show_preferences_accept_necessary_btn: true,
        },
        // Added framework ID, language code, and supported languages
        framework_id: selectedFramework,
        language_code: selectedLanguage,
        supported_languages: supportedLanguages,
        language_settings: {
          default_language: selectedLanguage,
          supported_languages: supportedLanguages,
        },
      };

      // Create banner
      await createBanner(bannerPayload);
      showSuccessToast("Cookie consent banner configured successfully");

      // Move to scan step
      setCurrentStep(3);
    } catch (error) {
      showErrorToast("There was an issue setting up the cookie banner");
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleScanWebsite = async () => {
    setIsLoadingScan(true);
    if (url) {
      try {
        const res = await startScan(addWebsiteData?.website_id, url);

        dispatch(setScanData(res?.data));
        if (res?.data && res.data.website_id !== undefined) {
          try {
            const websiteId = res.data.website_id.toString();
            await updateUserAttributes({
              userAttributes: {
                website: websiteId,
              },
            });
            showSuccessToast("Website scan started successfully.");
          } catch (error) {
            showErrorToast("Error updating user attributes:", error);
          }
        }
        setIsLoadingScan(false);
        setLoading(false);
        navigate("/website-scan");
      } catch (err) {
        setError("An error occurred while scanning. Please try again.");
        setIsLoadingScan(false);
      }
    } else {
      setError("Please add a website before scanning.");
      setLoading(false);
    }
  };

  const handleUrlChange = (e) => {
    const input = e.target.value;
    setUrl(input);
    const validationError = validateUrl(input);
    setError(validationError);
  };

  useEffect(() => {
    const fetchAllLanguage = async () => {
      try {
        const response = await getAllLanguages();

        if (response.success && response.data) {
          setAllLanguage(response.data);
        }
      } catch (err) {
        showErrorToast(err);
      }
    };

    fetchAllLanguage();
  }, []);

  return (
    <Container
      component="main"
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F8F8F8",
        padding: 3,
      }}
    >
      {/* Logo at the top */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 4,
        }}
      >
        <img src={myIcon} alt="logo" width="250" height="50" />
      </Box>

      {/* Form Section */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 450,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Step indicator */}
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            {[1, 2, 3].map((step) => (
              <Box
                key={step}
                sx={{
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: currentStep >= step ? "#009B7D" : "#e0e0e0",
                  color: currentStep >= step ? "white" : "#525455",
                  fontWeight: "bold",
                  mx: 1,
                }}
              >
                {step}
              </Box>
            ))}
          </Box>

          {/* Text above the form */}
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              color: "#5F5F5F",
              fontWeight: 600,
              fontFamily: "DM Sans",
            }}
          >
            {currentStep === 1 && "Add a new website"}
            {currentStep === 2 && "Configure Cookie Consent"}
            {currentStep === 3 && "Scan website"}
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              mb: 4,
              color: "#989898",
              fontSize: "14px",
              fontFamily: "DM Sans",
              p: "0px 40px",
              textAlign: "center",
            }}
          >
            {currentStep === 1 &&
              "Let's get your website set up with us. First, enter your website domain to start the process."}
            {currentStep === 2 &&
              "Select a compliance framework and default language for your cookie consent banner."}
            {currentStep === 3 &&
              "We are ready to scan your website for cookies and other details. Click 'Scan Now' to begin."}
          </Typography>
          <Paper
            elevation={2}
            sx={{
              width: "90%",
              p: 4,
              borderRadius: "12px",
              backgroundColor: "white",
              border: "none",
            }}
            variant="outlined"
          >
            <Box component="form">
              {/* Step 1: Website Domain Input */}
              {currentStep === 1 && (
                <>
                  <InputLabel shrink htmlFor="websitedomain">
                    Website Domain{" "}
                  </InputLabel>

                  <TextField
                    margin="none"
                    size="small"
                    required
                    fullWidth
                    value={url}
                    onChange={handleUrlChange}
                    name="websitedomain"
                    type="text"
                    autoComplete="websitedomain"
                    error={!!error}
                    helperText={error}
                    sx={{
                      mb: 2,
                      mt: 0,
                    }}
                  />

                  <Button
                    type="submit"
                    size="small"
                    fullWidth
                    variant="outlined"
                    onClick={handleDomainSubmit}
                    sx={{
                      mt: 2,
                      mb: 2,
                      "&:hover": {
                        backgroundColor: "#009B7D",
                        color: "white",
                      },
                      borderRadius: 1.5,
                      border: "2px solid #009B7D",
                      textTransform: "none",
                      fontSize: "1rem",
                      color: "#525455",
                      fontWeight: 700,
                    }}
                    disabled={loading || !url}
                  >
                    {loading ? <CircularProgress size={20} /> : "Continue"}
                  </Button>
                </>
              )}

              {/* Step 2: Framework and Language Selection */}
              {currentStep === 2 && (
                <>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 1, color: "#525455" }}
                  >
                    Compliance Framework
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mb: 2, color: "#989898", fontSize: "0.85rem" }}
                  >
                    Select the appropriate privacy framework for your website's
                    compliance needs.
                  </Typography>

                  <FormControl fullWidth size="small" sx={{ mb: 3 }}>
                    <InputLabel id="framework-select-label">
                      Framework
                    </InputLabel>
                    <Select
                      labelId="framework-select-label"
                      id="framework-select"
                      value={selectedFramework || ""}
                      label="Framework"
                      onChange={handleFrameworkChange}
                    >
                      {loadingFrameworks ? (
                        <MenuItem
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress />
                        </MenuItem>
                      ) : (
                        frameworks &&
                        frameworks?.map((framework) => (
                          <MenuItem
                            key={framework.framework_id}
                            value={framework.framework_id}
                          >
                            {framework.framework_name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "5px",
                      mb: 1,
                    }}
                  >
                    {frameworkDetails && (
                      <Box>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          mb={0}
                        >
                          {frameworkDetails.framework_description}
                        </Typography>
                      </Box>
                    )}

                    {isMandatoryToTrans && frameworkDetails && (
                      <Alert severity="warning" sx={{ mt: 1, p: 0, pl: 1 }}>
                        {`As per ${frameworkDetails?.framework_name}, banner must be available in all required languages. Some translations are missing. Please complete translations for all the required languages. The banner will be displayed in English for missing translations.`}
                      </Alert>
                    )}
                  </Box>

                  <Divider sx={{ my: 2 }} />
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 1, color: "#525455" }}
                  >
                    Default Language
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mb: 2, color: "#989898", fontSize: "0.85rem" }}
                  >
                    Choose the primary language for your cookie consent banner.
                  </Typography>

                  <FormControl fullWidth size="small" sx={{ mb: 3 }}>
                    <InputLabel id="language-select-label">
                      Default Language
                    </InputLabel>
                    <Select
                      labelId="language-select-label"
                      id="language-select"
                      value={selectedLanguage || ""}
                      label="Default Language"
                      onChange={handleLanguageChange}
                      disabled={loadingFrameworks}
                      // disabled={loadingFrameworks || supportedLanguages?.length === 0}
                    >
                      {allLanguage &&
                        allLanguage.map((lang) => (
                          <MenuItem
                            key={lang.language_id}
                            value={lang.language_code}
                          >
                            {lang.language_description}
                          </MenuItem>
                        ))}
                    </Select>
                    {selectedLanguage !== "en" && (
                      <Alert severity="warning" sx={{ mt: 1, p: 0, pl: 1 }}>
                        Please translate the banner content into your default
                        language in banner setting.
                      </Alert>
                    )}
                  </FormControl>

                  {supportedLanguages.length > 0 && (
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="body2"
                        sx={{ mb: 1, color: "#637381", fontSize: "0.85rem" }}
                      >
                        Supported Languages:
                      </Typography>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {supportedLanguages.map((lang) => (
                          <Chip
                            key={lang.language_id}
                            label={lang.language_description}
                            // variant={lang === selectedLanguage ? "filled" : "outlined"}
                            // color={lang === selectedLanguage ? "primary" : "default"}
                            variant="outlined"
                            color="default"
                            size="small"
                          />
                        ))}
                      </Box>
                    </Box>
                  )}

                  <Button
                    type="submit"
                    size="small"
                    fullWidth
                    variant="outlined"
                    onClick={handleFrameworkSubmit}
                    sx={{
                      mt: 2,
                      mb: 2,
                      "&:hover": {
                        backgroundColor: "#009B7D",
                        color: "white",
                      },
                      borderRadius: 1.5,
                      border: "2px solid #009B7D",
                      textTransform: "none",
                      fontSize: "1rem",
                      color: "#525455",
                      fontWeight: 700,
                    }}
                    disabled={
                      loading || !selectedFramework || !selectedLanguage
                    }
                  >
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      "Configure Banner"
                    )}
                  </Button>
                </>
              )}

              {/* Step 3: Scan Website */}
              {currentStep === 3 && (
                <>
                  <Typography variant="body1" sx={{ mb: 3, color: "#525455" }}>
                    Your website has been added and the cookie consent banner
                    has been configured. Now we can scan your website to detect
                    cookies and tracking technologies.
                  </Typography>

                  <Button
                    type="submit"
                    size="small"
                    fullWidth
                    variant="outlined"
                    onClick={handleScanWebsite}
                    sx={{
                      mt: 2,
                      mb: 2,
                      "&:hover": {
                        backgroundColor: "#009B7D",
                        color: "white",
                      },
                      borderRadius: 1.5,
                      border: "2px solid #009B7D",
                      textTransform: "none",
                      fontSize: "1rem",
                      color: "#525455",
                      fontWeight: 700,
                    }}
                    disabled={isLoadingScan}
                  >
                    {isLoadingScan ? (
                      <CircularProgress size={20} />
                    ) : (
                      "Scan Now"
                    )}
                  </Button>
                </>
              )}

              {/* <Button
                size="small"
                fullWidth
                onClick={handleCancelEdit}
                variant="underline"
                textTransform="capitalize"
              >
                Cancel
              </Button> */}
            </Box>
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default AddWebAndScanPage;
