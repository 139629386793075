import {
  Box,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  styled,
  tableCellClasses,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Add, DeleteOutline } from "@mui/icons-material";
import ButtonCommon from "../../CommonComponent/ButtonCommon";
import CommonSubHeader from "../../CommonComponent/CommonSubHeader";
import { useEffect, useState } from "react";
import { createUser, deleteUser, getUserData } from "../../api/user";
import CommonDrawer from "../../CommonComponent/CommonDrawer";
import Loader from "../../CommonComponent/Loader";
import { showErrorToast, showSuccessToast } from "../../toastUtils";
import ConfirmationModal from "../../CommonComponent/CommonConfirmationModal";
// import { useDispatch, useSelector } from "react-redux";
// import { updateLicense } from "../../api/licensing";
// import { updateLicenseUsage } from "../../store/licenseSlice";

const Team = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
  });

  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F9FAFB",
      color: "#000000",
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const res = await getUserData();
      if (res?.status_code === 200) {
        setUserData(res?.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // const dispatch = useDispatch();
  // const currentLicense = useSelector((state) => state.license.data);
  // const usersUsed = currentLicense?.license_usage_details?.users_used || 0;
  // const maxUsers =
  //   currentLicense?.license_plan_details?.license_plan?.number_of_users
  //     ?.limit || 0;
  // const overageAllowed = currentLicense?.overage_allowed || false;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = { name: "", email: "" };

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    if (newErrors.name || newErrors.email) {
      setErrors(newErrors);
      return;
    }

    try {
      setLoading(true);

      // let updatedUsersUsed = usersUsed;
      // let updatedUsersOverage = currentLicense?.users_overage || 0;

      // if (usersUsed >= maxUsers) {
      //   if (!overageAllowed) {
      //     setLoading(false);
      //     setOpen(false);
      //     showErrorToast(
      //       "You have reached the maximum number of allowed users."
      //     );
      //     return;
      //   } else {
      //     updatedUsersOverage += 1; // If overage is allowed, increment it
      //   }
      // } else {
      //   updatedUsersUsed += 1; // Increment users_used when within limit
      // }

      const data = {
        email: formData.email,
        full_name: formData.name,
      };
      const res = await createUser(data);

      if (res?.data?.status_code === 400) {
        showErrorToast(res?.data?.message);
      } else {
        // const payload = {
        //   users_used: updatedUsersUsed,
        //   users_overage: updatedUsersOverage,
        // };

        // // **Update License in Backend**
        // const updateRes = await updateLicense(
        //   currentLicense?.license_plan_details?.license_id,
        //   payload
        // );

        // if (updateRes?.status_code === 200) {
        //   // After update
        //   await dispatch(updateLicenseUsage(payload));
        // } else {
        //   showErrorToast("Failed to update license on backend:", updateRes);
        // }

        // **Fetch Updated Data**
        setTimeout(async () => {
          await fetchUserData(); // Ensure fresh data is loaded
          setOpen(false);
          setLoading(false);
          setFormData({ name: "", email: "" });
          setErrors({ name: "", email: "" });
          setOpenModal(false);
          setUserToDelete(null);
        }, 500);
      }
    } catch (err) {
      showErrorToast("Error in handleSubmit:", err);
      setOpen(false);
      setLoading(false);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const handleOpenModal = (userId) => {
    setUserToDelete(userId); // Set the user ID to delete
    setOpenModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
    setUserToDelete(null); // Reset user to delete
  };

  // const licenseData = useSelector((state) => state.license.data);
  // const licenseId = licenseData?.license_plan_details?.license_id;

  const handleDelete = async (userId) => {
    try {
      setLoading(true);
      const res = await deleteUser(userId);      
      if (res?.status === 200) {
        // const updatedUsersUsed = Math.max(0, usersUsed - 1); // Ensure it doesn't go below 0
        // await updateLicense(licenseId, { users_used: updatedUsersUsed });
        // dispatch(updateLicenseUsage({ users_used: updatedUsersUsed }));
        showSuccessToast("User Deleted Successfully!");
        setOpenModal(false); // Close the modal
        setUserToDelete(null); // Reset user to delete
      }

      setTimeout(async () => {
        await fetchUserData();
        setLoading(false);
        setFormData({ name: "", email: "" });
        setErrors({ name: "", email: "" });
      }, 500);
    } catch (err) {
      showErrorToast("Error deleting user:", err);
      setLoading(false);
      setOpen(false);
    }
  };

  if (loading) {
    <Loader />;
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Box>
          <CommonSubHeader
            mediumTitle="Manage Members"
            subTitle="Invite your team to help manage your website. You can assign roles and set permissions to control access."
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <ButtonCommon
            size="small"
            icon={<Add />}
            onClick={() => setOpen(true)}
          >
            Invite A User
          </ButtonCommon>
        </Box>
      </Box>

      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderRadius: "12px" }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email Address</StyledTableCell>
              <StyledTableCell>Role</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableCell
                colSpan={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </TableCell>
            ) : (
              userData?.map((user) => (
                <TableRow key={user.user_id} hover sx={{ cursor: "pointer" }}>
                  <StyledTableCell>{user.full_name}</StyledTableCell>
                  <StyledTableCell>{user.email}</StyledTableCell>
                  <StyledTableCell>
                    {user.is_admin === 1 ? "Billing Admin" : "Org User"}{" "}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton size="small" disabled={user?.is_admin === 1}>
                      <DeleteOutline
                        onClick={() => handleOpenModal(user?.cognito_id)}
                      />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))
            )}
            <ConfirmationModal
              open={openModal}
              onClose={handleCloseModal} // Close modal when canceled
              title="Delete User"
              description={"Are you sure you want to delete this user?"}
              onConfirm={() => {
                handleDelete(userToDelete); // Call handleDelete with the user ID to delete
                handleCloseModal(); // Close modal after confirmation
              }}
              loading={loading} // Show loading spinner while deleting
              confirmLabel="Delete"
              cancelLabel="Cancel"
            />
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
        <Typography variant="caption">
          * Billing Admin can manage whole application, including Account
          Settings.
        </Typography>
        <Typography variant="caption">
          * Org User can manage the application but cannot manage Account
          Settings.
        </Typography>
      </Box>
      <CommonDrawer
        open={open}
        onClose={() => setOpen(false)}
        title="Invite a User"
        subTitle="Add information below to invite a user"
        primaryButton={{
          label: "Invite",
          onClick: handleSubmit,
          sx: { px: 4 },
        }}
        drawerWidth={400}
      >
        {/* Name Field */}
        <InputLabel shrink htmlFor="name" required>
          Name
        </InputLabel>
        <TextField
          fullWidth
          size="small"
          required
          margin="none"
          name="name"
          type="text"
          value={formData.name}
          onChange={handleInputChange}
          sx={{ mb: 3 }}
          error={Boolean(errors.name)} // Show error if validation fails
          helperText={errors.name} // Display the error message
        />

        {/* Email Field */}
        <InputLabel shrink htmlFor="email" required>
          Email
        </InputLabel>
        <TextField
          fullWidth
          required
          margin="none"
          size="small"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          sx={{ mb: 3 }}
          error={Boolean(errors.email)} // Show error if validation fails
          helperText={errors.email} // Display the error message
        />
      </CommonDrawer>
    </>
  );
};

export default Team;
