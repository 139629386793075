import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  LinearProgress,
  Paper,
  Grid,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OnboardingImage from "../../assets/svg/g8.png";
import { useNavigate } from "react-router-dom";
import apiClient from "../../api/apiClient";
import Loader from "../../CommonComponent/Loader";
import { useSelector } from "react-redux";

const OnboardingProgress = () => {
  const navigate = useNavigate();
  const scanData = useSelector((state) => state.scan.scanData);

  const [steps, setSteps] = useState([
    {
      title: "Add Website",
      description: "Please add your website for us to scan.",
      path: "/add-website",
      completed: false,
      apiPath: "/website/get_all_website",
    },
    {
      title: "Scan Website",
      description:
        "Please scan your website for us to automatically detect the cookies and prepare the banner.",
      path: "/website-scan",
      completed: false,
      apiPath: `/scan/${scanData?.scan_id}`,
    },
    {
      title: "Review and customize Banner",
      description:
        "We have created a default banner for you. Please review and update the banner text, positioning and color scheme. You can preview your banner here before making it live on your website.",
      path: "/banner",
      completed: false,
      apiPath: "/banner",
    },
    {
      title: "Review and customize Vendors",
      description:
        "We have detected all the cookie vendors for you. Please review and update the vendors.",
      path: "/vendor-management",
      completed: false,
      apiPath: "/services",
    },
    {
      title: "Install Plugin",
      description:
        "Please install our Data Privacy Cloud plugin for us to show the banner on your website and capture the consent.",
      path: "/install-plugin",
      completed: false,
      apiPath: "/consent/get-consent-details",
    },
  ]);

  const [loading, setLoading] = useState(false);

  const checkStepCompletion = async (stepIndex, apiPath) => {
    try {
      setLoading(true);
      const websiteId = localStorage.getItem("website_id");
      const organizationId = localStorage.getItem("organization_id_str");

      const headers =
        apiPath === "/banner"
          ? {
              "website_id": websiteId,
              "organization_id": organizationId,
            }
          : {
              website_id: websiteId,
              organization_id: organizationId,
            };

      const response = await apiClient.get(apiPath, { headers });

      if (response.status === 200 && response.data.success) {
        // Check if data exists and is either a non-empty array or a non-empty object
        const isCompleted =
          response.data.data &&
          ((Array.isArray(response.data.data) &&
            response.data.data.length > 0) ||
            (!Array.isArray(response.data.data) &&
              Object.keys(response.data.data).length > 0));

        if (isCompleted) {
          setSteps((prevSteps) =>
            prevSteps.map((step, index) =>
              index === stepIndex ? { ...step, completed: true } : step
            )
          );
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    steps.forEach((step, index) => {
      checkStepCompletion(index, step.apiPath);
    });
    // eslint-disable-next-line
  }, []);

  const calculateProgress = () => {
    const completedSteps = steps.filter((step) => step.completed).length;
    const totalSteps = steps.length;
    return (completedSteps / totalSteps) * 100;
  };

  if (loading) {
    <Loader />;
  }

  return (
    <Paper sx={{ p: 3, mb: 4 }} variant="outlined">
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box className="max-w-4xl mx-auto">
            <Typography
              variant="h6"
              sx={{ mb: 1, color: "#525455", fontWeight: "bold" }}
            >
              We're excited to get you started!
            </Typography>

            <Typography variant="body2" sx={{ mb: 2, color: "#989898" }}>
              Welcome aboard! In five easy steps, you'll be up and running. Once
              our plugin is installed, we'll start tracking your website's
              visitors and consents.
            </Typography>

            <Box className="mb-8">
              <Typography
                variant="body1"
                sx={{ mb: 1, color: "#525455", fontWeight: "500" }}
              >
                Your Progress
              </Typography>
              <LinearProgress
                variant="determinate"
                value={calculateProgress()}
                sx={{
                  backgroundColor: "#E0E0E0",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#00B894",
                  },

                  borderRadius: "35px",
                  mb: 2,
                  height: 6,
                }}
              />
            </Box>

            <List className="space-y-4">
              {steps.map((step, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-white rounded-lg hover:bg-gray-50"
                  sx={{
                    borderBottom:
                      index !== steps.length - 1 ? "1px solid #E6E6E6" : "none",
                  }}
                >
                  <ListItemButton
                    className="py-4"
                    onClick={() => navigate(step.path)}
                  >
                    <ListItemIcon>
                      {step.completed ? (
                        <CheckCircleIcon color="primary" />
                      ) : (
                        <RadioButtonUncheckedIcon className="text-gray-300" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          sx={{ fontWeight: "600" }}
                        >
                          {step.title}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="body2"
                          className="mt-1"
                          color="#989898"
                        >
                          {step.description}
                        </Typography>
                      }
                    />
                    <ChevronRightIcon className="text-gray-400" />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
          <Typography variant="caption" color="textSecondary">
            * You will see the dashboard as soon as the first consent is
            received on your website.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              bgcolor: "#F9FAFB",
            }}
            p={2}
          >
            <img
              src={OnboardingImage}
              alt="Upgrade"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OnboardingProgress;
