// index.js
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Amplify } from "aws-amplify";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { ToastContainer } from "react-toastify";

// Configure Amplify
const config = {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      region: process.env.REACT_APP_AWS_REGION,
      loginWith: {
        email: true,
        phone: false,
        username: true,
      },
      authenticationFlowType: "USER_SRP_AUTH",
    },
  },
};
Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles
            styles={{
              "*": {
                fontFamily: `'DM Sans', sans-serif`,
              },
              body: {
                margin: 0,
                fontFamily: `'DM Sans', sans-serif`,
              },
            }}
          />
           <ToastContainer />
          <App />
          {/* <PublicBetaRibbon /> */}
        </ThemeProvider>
      </PersistGate>
    </Provider>
);
