import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  styled,
  tableCellClasses,
  TableCell,
  TextField,
  Grid,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { fetchPageVisitData } from "../../api/consentLog";
import Loader from "../../CommonComponent/Loader";
import { showErrorToast } from "../../toastUtils";
import { getFormattedTimestamp } from "../../utils/dateUtils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",
    color: "#000000",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const PageVisitTable = ({ consent_hashid, timezone }) => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [flatData, setFlatData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null); // State for end date

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchPageVisitData(consent_hashid);
        setFlatData(response?.data);
        if (response?.status_code === 404) {
          showErrorToast(response?.message);
          setLoading(false);
          return;
        }
      } catch (err) {
        showErrorToast(err?.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    if (consent_hashid) {
      fetchData();
    }
  }, [consent_hashid]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortData = (array) => {
    return (Array.isArray(array) ? [...array] : []).sort((a, b) => {
      if (orderBy === "date") {
        return order === "asc"
          ? dayjs(a.date).valueOf() - dayjs(b.date).valueOf()
          : dayjs(b.date).valueOf() - dayjs(a.date).valueOf();
      } else if (orderBy === "page_url") {
        return order === "asc"
          ? a.page_url.localeCompare(b.page_url)
          : b.page_url.localeCompare(a.page_url);
      }
      return 0;
    });
  };

  // Filter data by date range, ignoring time
  const filterDataByDateRange = (data) => {
    if (!startDate || !endDate) {
      return data; // If no date range is selected, return all data
    }

    // Ensure that startDate and endDate are set to the start of the day for accurate filtering
    const startOfDay = dayjs(startDate).startOf('day');  // Start of start date
    const endOfDay = dayjs(endDate).endOf('day');        // End of end date

    return data.filter((row) => {
      const rowDate = dayjs(row.date); // Ensure row.date is properly formatted
      return rowDate.isBetween(startOfDay, endOfDay, null, '[]'); // Inclusive range
    });
  };

  const sortedData = sortData(flatData);
  const filteredData = filterDataByDateRange(sortedData);

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box mb={2}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newDate) => setStartDate(newDate)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newDate) => setEndDate(newDate)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>

          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ borderRadius: "12px" }}
          >
            <Table sx={{ minWidth: 650 }} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    sortDirection={orderBy === "date" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "date"}
                      direction={orderBy === "date" ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, "date")}
                    >
                      Visit Date
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell
                    sortDirection={orderBy === "page_url" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "page_url"}
                      direction={orderBy === "page_url" ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, "page_url")}
                    >
                      Page URL
                    </TableSortLabel>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Check if filteredData is empty and show message */}
                {filteredData?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <Typography>No data available</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row) => (
                      <TableRow hover key={uuidv4()}>
                        <StyledTableCell>
                          {getFormattedTimestamp(row.date, timezone)}
                        </StyledTableCell>
                        <StyledTableCell>{row.page_url}</StyledTableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={filteredData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

export default PageVisitTable;
