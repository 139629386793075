import apiClient from "./apiClient";

const safeGet = (obj, path, defaultValue = "") => {
  try {
    return (
      path.split(".").reduce((acc, part) => acc?.[part], obj) ?? defaultValue
    );
  } catch {
    return defaultValue;
  }
};


export const frontendToBackendMapper = (formData) => {
  if (!formData) {
    console.warn("No form data provided to mapper");
    return null;
  }

  return {
    // General Settings
    name: safeGet(formData, "generalSettings.bannerName", ""),
    enabled: safeGet(formData, "generalSettings.isActive", false),
    forced_consent: safeGet(formData, "generalSettings.forcedConsent", false),
    consent_duration_days: safeGet(formData, "generalSettings.consentDuration", 365),
    target_type: safeGet(formData, "generalSettings.targeting", ""),
    // Framework Settings
    framework_id: safeGet(formData, "frameworkSettings.frameworkId", null),
    // Language Settings
    language_settings: {
      default_language: safeGet(formData, "frameworkSettings.languageCode", "en") || safeGet(formData, "languageSettings.defaultLanguage", "en"),
      supported_languages: safeGet(formData, "languageSettings.supportedLanguages", ["en"]),
      translations: safeGet(formData, "languageSettings.translations", {})
    },
    // Banner Settings
    consent_title: safeGet(formData, "bannerSettings.title", ""),
    consent_description: safeGet(formData, "bannerSettings.description", ""),
    consent_additional_description: safeGet(formData, "bannerSettings.footerContent", ""),
    consent_layout: safeGet(formData, "bannerSettings.layout", ""),
    consent_button_placement: safeGet(formData, "bannerSettings.buttonPlacement", ""),
    consent_position: safeGet(formData, "bannerSettings.position", ""),
    equal_weight_buttons: safeGet(formData, "bannerSettings.equalWeight", false),
    swap_buttons: safeGet(formData, "bannerSettings.swapButtons", false),
    show_banner_accept_btn: safeGet(formData, "bannerSettings.acceptAll", false),
    show_banner_accept_necessary_btn: safeGet(
      formData,
      "bannerSettings.acceptNecessary",
      false
    ),
    show_banner_preferences_btn: safeGet(
      formData,
      "bannerSettings.managePreferences",
      false
    ),
    banner_accept_btn_text: safeGet(formData, "bannerSettings.acceptAllText", true),
    banner_accept_necessary_btn_text: safeGet(formData, "bannerSettings.acceptNecessaryText", true),
    banner_preferences_btn_text: safeGet(
      formData,
      "bannerSettings.managePreferencesText",
      true
    ),
    // Preference Settings
    show_floating_preferences_icon: safeGet(
      formData,
      "preferenceSettings.enablePrivacyTrigger",
      false
    ),
    floating_preferences_icon_position: safeGet(formData, "preferenceSettings.privacyTriggerPosition", ""),
    show_services: safeGet(
      formData,
      "preferenceSettings.displayCookies",
      true
    ),
    preferences_control_level: safeGet(formData, "preferenceSettings.controlLevel", ""),
    settings_title: safeGet(formData, "preferenceSettings.title", ""),
    settings_description: safeGet(
      formData,
      "preferenceSettings.description",
      ""
    ),
    settings_more_info_title: safeGet(formData, "preferenceSettings.moreInfoTitle", ""),
    settings_more_info: safeGet(
      formData,
      "preferenceSettings.moreInfoContent",
      ""
    ),
    settings_layout: safeGet(formData, "preferenceSettings.layout", ""),
    settings_position_x: safeGet(formData, "preferenceSettings.modalAlignment", ""),
    show_preferences_accept_btn: safeGet(formData, "preferenceSettings.acceptAll", false),
    show_preferences_accept_necessary_btn: safeGet(
      formData,
      "preferenceSettings.acceptNecessary",
      false
    ),
    show_save_preferences_btn: safeGet(formData, "preferenceSettings.savePreferences", false),
    preferences_accept_btn_text: safeGet(
      formData,
      "preferenceSettings.acceptAllText",
      ""
    ),
    preferences_accept_necessary_btn_text: safeGet(
      formData,
      "preferenceSettings.acceptNecessaryText",
      ""
    ),
    save_preferences_btn_text: safeGet(
      formData,
      "preferenceSettings.savePreferencesText",
      ""
    ),
    // Theme Settings
    dark_mode: safeGet(formData, "themeSettings.darkMode", "auto"),
    button_colors: {
      bg: safeGet(formData, "themeSettings.background", "#FFFFFF"),
      heading_color: safeGet(formData, "themeSettings.heading", "#333333"),
      text_color: safeGet(formData, "themeSettings.text", "#717171"),
      link_color: safeGet(formData, "themeSettings.link", "#FFFFFF"),
      btn_primary_bg: safeGet(
        formData,
        "themeSettings.primaryButtonBg",
        "#FFFFFF"
      ),
      btn_primary_color: safeGet(
        formData,
        "themeSettings.primaryButtonText",
        "#333333"
      ),
      secondary_button_bg: safeGet(
        formData,
        "themeSettings.secondaryButtonBg",
        "#717171"
      ),
      secondary_button_text: safeGet(
        formData,
        "themeSettings.secondaryButtonText",
        "#FFFFFF"
      ),
      dark_mode_background: safeGet(
        formData,
        "themeSettings.darkModeBackground",
        "#FFFFFF"
      ),
      dark_mode_heading: safeGet(
        formData,
        "themeSettings.darkModeHeading",
        "#333333"
      ),
      dark_mode_text: safeGet(formData, "themeSettings.darkModeText", "#717171"),
      dark_mode_link: safeGet(formData, "themeSettings.darkModeLink", "#FFFFFF"),
      dark_mode_primary_button_bg: safeGet(
        formData,
        "themeSettings.darkModePrimaryButtonBg",
        "#FFFFFF"
      ),
      dark_mode_primary_button_text: safeGet(
        formData,
        "themeSettings.darkModePrimaryButtonText",
        "#333333"
      ),
      dark_mode_secondary_button_bg: safeGet(
        formData,
        "themeSettings.darkModeSecondaryButtonBg",
        "#717171"
      ),
      dark_mode_secondary_button_text: safeGet(
        formData,
        "themeSettings.darkModeSecondaryButtonText",
        "#FFFFFF"
      )
    },
    modal_border_radius: safeGet(
      formData,
      "themeSettings.modalBorderRadius",
      "0.5rem"
    ),
    btn_border_radius: safeGet(
      formData,
      "themeSettings.buttonBorderRadius",
      "0.4rem"
    ),
    logo_url_light: safeGet(formData, "themeSettings.logoUrl", ""),
    logo_url_dark: safeGet(formData, "themeSettings.darkModeLogoUrl", ""),
  };
};

const backendToFrontendMapper = (backendData) => {
  if (!backendData) {
    console.warn("Received empty backend data");
    return null;
  }
  return {
    generalSettings: {
      bannerName: safeGet(backendData, "name", ""),
      isActive: safeGet(backendData, "enabled", false),
      forcedConsent: safeGet(backendData, "forced_consent", false),
      consentDuration: safeGet(backendData, "consent_duration_days", 0),
      targeting: safeGet(backendData, "target_type", ""),
    },
    frameworkSettings: {
      frameworkId: safeGet(backendData, "framework_id", 1), // Default to Generic Cookie Banner (ID 1)
      frameworkName: safeGet(backendData, "framework_name", "Generic Cookie Banner"),
      languageCode: safeGet(backendData, "language_code", "en"),
    },
    bannerSettings: {
      title: safeGet(backendData, "consent_title", ""),
      description: safeGet(backendData, "consent_description", ""),
      footerContent: safeGet(backendData, "consent_additional_description", ""),
      layout: safeGet(backendData, "consent_layout", ""),
      buttonPlacement: safeGet(backendData, "consent_button_placement", ""),
      position: safeGet(backendData, "consent_position", ""),
      equalWeight: safeGet(backendData, "equal_weight_buttons", false),
      swapButtons: safeGet(backendData, "swap_buttons", false),
      acceptAll: safeGet(backendData, "show_banner_accept_btn", false),
      acceptNecessary: safeGet(
        backendData,
        "show_banner_accept_necessary_btn",
        false
      ),
      acceptAllText: safeGet(backendData, "banner_accept_btn_text", ""),
      acceptNecessaryText: safeGet(
        backendData,
        "banner_accept_necessary_btn_text",
        ""
      ),
      managePreferencesText: safeGet(
        backendData,
        "banner_preferences_btn_text",
        ""
      ),
      managePreferences: safeGet(
        backendData,
        "show_banner_preferences_btn",
        false
      ),
    },
    preferenceSettings: {
      enablePrivacyTrigger: safeGet(
        backendData,
        "show_floating_preferences_icon",
        false
      ),
      privacyTriggerPosition: safeGet(backendData, "floating_preferences_icon_position", ""),
      displayCookies: safeGet(backendData, "show_services", false),
      controlLevel: safeGet(backendData, "preferences_control_level", ""),
      title: safeGet(backendData, "settings_title", ""),
      description: safeGet(backendData, "settings_description", ""),
      moreInfoTitle: safeGet(backendData, "settings_more_info_title", ""),
      moreInfoContent: safeGet(backendData, "settings_more_info", ""),
      layout: safeGet(backendData, "settings_layout", ""),
      modalAlignment: safeGet(backendData, "settings_position_x", ""),
      acceptAll: safeGet(backendData, "show_preferences_accept_btn", false),
      acceptNecessary: safeGet(
        backendData,
        "show_preferences_accept_necessary_btn",
        false
      ),
      savePreferences: safeGet(backendData, "show_save_preferences_btn", false),
      acceptAllText: safeGet(backendData, "preferences_accept_btn_text", ""),
      acceptNecessaryText: safeGet(
        backendData,
        "preferences_accept_necessary_btn_text",
        ""
      ),
      savePreferencesText: safeGet(backendData, "save_preferences_btn_text", ""),
    },
    themeSettings: {
      darkMode: safeGet(backendData, "dark_mode", false),
      background: safeGet(backendData, "button_colors.bg", ""),
      heading: safeGet(backendData, "button_colors.heading_color", ""),
      text: safeGet(backendData, "button_colors.text_color", ""),
      link: safeGet(backendData, "button_colors.link_color", ""),
      primaryButtonBg: safeGet(backendData, "button_colors.btn_primary_bg", ""),
      primaryButtonText: safeGet(
        backendData,
        "button_colors.btn_primary_color",
        ""
      ),
      secondaryButtonBg: safeGet(
        backendData,
        "button_colors.btn_secondary_bg",
        ""
      ),
      secondaryButtonText: safeGet(
        backendData,
        "button_colors.btn_secondary_color",
        ""
      ),
      darkModeBackground: safeGet(backendData, "button_colors.dark_bg", ""),
      darkModeHeading: safeGet(
        backendData,
        "button_colors.dark_heading_color",
        ""
      ),
      darkModeText: safeGet(backendData, "button_colors.dark_text_color", ""),
      darkModeLink: safeGet(backendData, "button_colors.dark_link_color", ""),
      darkModePrimaryButtonBg: safeGet(
        backendData,
        "button_colors.dark_btn_primary_bg",
        ""
      ),
      darkModePrimaryButtonText: safeGet(
        backendData,
        "button_colors.dark_btn_primary_color",
        ""
      ),
      darkModeSecondaryButtonBg: safeGet(
        backendData,
        "button_colors.dark_btn_secondary_bg",
        ""
      ),
      darkModeSecondaryButtonText: safeGet(
        backendData,
        "button_colors.dark_btn_secondary_color",
        ""
      ),
      modalBorderRadius: safeGet(backendData, "modal_border_radius", 0),
      buttonBorderRadius: safeGet(backendData, "btn_border_radius", 0),
      logoUrl: safeGet(backendData, "logo_url_light", ""),
      darkModeLogoUrl: safeGet(backendData, "logo_url_dark", ""),
    },
    languageSettings: {
      defaultLanguage: safeGet(backendData, "language_settings.default_language", "en"),
      supportedLanguages: safeGet(backendData, "language_settings.supported_languages", ["en"]),
      translations: safeGet(backendData, "language_settings.translations", {})
    },
  };
};

// Export the mapper and utility functions
export {
  backendToFrontendMapper,
  safeGet,
};

export const getAllBanners = async () => {
  try {
    const organizationId = localStorage.getItem("organization_id_str");
    const websiteId = localStorage.getItem("website_id");
    const response = await apiClient.get("/banner", {
      headers: {
        "Content-Type": "application/json",
        "website_id": websiteId, // Convert to string
        "organization_id": organizationId, // Convert to string
      },
    });

    return response.data.data || []; // Assuming the data is nested in a data property
  } catch (error) {
    console.error("Error fetching banners:", {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data,
      timestamp: new Date().toISOString(),
    });
    throw error;
  }
};

export const getBannerById = async (bannerId, languageCode = null) => {
  try {
    const organizationId = localStorage.getItem("organization_id_str");
    const websiteId = localStorage.getItem("website_id");

    let url = `/banner/${bannerId}`;
    if (languageCode) {
      url += `?language_code=${languageCode}`;
    }

    const response = await apiClient.get(url, {
      headers: {
        "Content-Type": "application/json",
        "website_id": websiteId,
        "organization_id": organizationId,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching banner:", {
      bannerId,
      languageCode,
      error: error.message,
      timestamp: new Date().toISOString(),
    });
    throw error;
  }
};

export const createBanner = async (payload) => {
  try {
    const organizationId = localStorage.getItem("organization_id_str");
    const websiteId = localStorage.getItem("website_id");

    // Enhanced payload with framework_id, language_code, and supported_languages if provided
    const enhancedPayload = {
      ...payload,
      framework_id: payload.framework_id || null,
      language_code: payload.language_code || 'en',
      supported_languages: payload.supported_languages || [payload.language_code || 'en']
    };

    const response = await apiClient.post("/banner/create", enhancedPayload, {
      headers: {
        "Content-Type": "application/json",
        "website_id": websiteId,
        "organization_id": organizationId,
      },
      timeout: 30000, // 30 second timeout
    });

    // console.log("Banner creation response received:", {
    //   status: response.status,
    //   timestamp: new Date().toISOString(),
    // });

    // Check for error response even with 200 status
    if (response.data.status_code === 403 || response.data.success === false) {
      const error = new Error(response.data.message);
      error.response = response;
      error.status_code = response.data.status_code;
      error.success = response.data.success;
      throw error;
    }

    // return backendToFrontendMapper(response);
    return response.data;
  } catch (error) {
    console.error("Error creating banner:", {
      message: error.message,
      status: error.response?.status,
      timestamp: new Date().toISOString(),
    });
    throw error;
  }
};

export const updateBanner = async (bannerId, payload) => {
  try {
    const organizationId = localStorage.getItem("organization_id_str");
    const websiteId = localStorage.getItem("website_id");
    const response = await apiClient.put(`/banner/${bannerId}`, payload, {
      headers: {
        "Content-Type": "application/json",
        "website_id": websiteId,
        "organization_id": organizationId,
      },
      timeout: 30000, // 30 second timeout
    });

    // console.log("Banner update response received:", {
    //   status: response.status,
    //   timestamp: new Date().toISOString(),
    // });
    // Check for error response even with 200 status
    if (response.data.status_code === 403 || response.data.success === false) {
      const error = new Error(response.data.message);
      error.response = response;
      error.status_code = response.data.status_code;
      error.success = response.data.success;
      throw error;
    }

    return backendToFrontendMapper(response);
  } catch (error) {
    console.error("Error updating banner:", {
      bannerId,
      message: error.message,
      status: error.response?.status,
      timestamp: new Date().toISOString(),
    });
    throw error;
  }
};

export const deleteBanner = async (bannerId) => {
  try {
    const organizationId = localStorage.getItem("organization_id_str");
    const websiteId = localStorage.getItem("website_id");
    const response = await apiClient.delete(`/banner/${bannerId}`, {
      headers: {
        "Content-Type": "application/json",
        "website_id": websiteId,
        "organization_id": organizationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting banner:", error);
    throw error;
  }
};

// Get all available banner frameworks
export const getAllFrameworks = async () => {
  try {
    const organizationId = localStorage.getItem("organization_id_str");
    const websiteId = localStorage.getItem("website_id");

    const response = await apiClient.get("/framework/get-all-framework", {
      headers: {
        "Content-Type": "application/json",
        "website_id": websiteId,
        "organization_id": organizationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching banner frameworks:", error);
    throw error;
  }
};

// Get a specific framework by ID
export const getFrameworkById = async (frameworkId) => {
  try {
    const organizationId = localStorage.getItem("organization_id_str");
    const websiteId = localStorage.getItem("website_id");

    const response = await apiClient.get(`/banner/framework/${frameworkId}`, {
      headers: {
        "Content-Type": "application/json",
        "website_id": websiteId,
        "organization_id": organizationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching framework details:", error);
    throw error;
  }
};

// Get supported languages for a framework
export const getFrameworkLanguages = async (frameworkId) => {
  try {
    const organizationId = localStorage.getItem("organization_id_str");
    const websiteId = localStorage.getItem("website_id");

    // const response = await apiClient.get(`/banner/framework/${frameworkId}/languages`, {
    const response = await apiClient.get(`/framework/get-framework-lang/${frameworkId}`, {
      headers: {
        "Content-Type": "application/json",
        "website_id": websiteId,
        "organization_id": organizationId,
      },
    });

    if (!response.data.success) {
      throw new Error(response.message);
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching framework languages:", error);
    throw error;
  }
};

export const getBannerTranslations = async (bannerId) => {
  try {
    const organizationId = localStorage.getItem("organization_id_str");
    const websiteId = localStorage.getItem("website_id");
    const response = await apiClient.get(`/banner/get-all-translation/${bannerId}`, {
      headers: {
        "Content-Type": "application/json",
        "website_id": websiteId,
        "organization_id": organizationId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching banner translation:", error);
    throw error;
  }
};

export const saveBannerTranslation = async (payload) => {
  try {
    const organizationId = localStorage.getItem("organization_id_str");
    const websiteId = localStorage.getItem("website_id");
    const response = await apiClient.post(`/banner/save-translation`, payload, {
      headers: {
        "Content-Type": "application/json",
        "website_id": websiteId,
        "organization_id": organizationId,
      },
    });
    // Check for error response even with 200 status
    if (response.data.success === false) {
      const error = new Error(response.data.message);
      error.response = response;
      error.status_code = response.data.status_code;
      error.success = response.data.success;
      throw error;
    }

    return response.data;
  } catch (error) {
    console.log("Error saving banner translation:", error);
    throw error;
  }
};

export const editBannerTranslation = async (bannerTranslationId, payload) => {
  try {
    const organizationId = localStorage.getItem("organization_id_str");
    const websiteId = localStorage.getItem("website_id");
    const response = await apiClient.put(`/banner/edit-translation/${bannerTranslationId}`, payload, {
      headers: {
        "Content-Type": "application/json",
        "website_id": websiteId,
        "organization_id": organizationId,
      },
    });

    // Check for error response even with 200 status
    if (response.data.success === false) {
      const error = new Error(response.data.message);
      error.response = response;
      error.status_code = response.data.status_code;
      error.success = response.data.success;
      throw error;
    }

    return response.data;
  } catch (error) {
    console.log("Error edit banner translation:", error);
    throw error;
  }
};

export const deleteBannerTranslation = async (id) => {
  try {
    const organizationId = localStorage.getItem("organization_id_str");
    const websiteId = localStorage.getItem("website_id");
    const response = await apiClient.put(`/banner/delete-translation/${id}`, {}, {
      headers: {
        "Content-Type": "application/json",
        "website_id": websiteId,
        "organization_id": organizationId,
      },
    });
    // Check for error response even with 200 status
    if (response.data.success === false) {
      const error = new Error(response.data.message);
      error.response = response;
      error.status_code = response.data.status_code;
      error.success = response.data.success;
      throw error;
    }

    return response.data;
  } catch (error) {
    console.log("Error deleting banner translation:", error);
    throw error;
  }
};

export const updateSupportedLanguage = async (bannerId, payload) => {
  try {
    const organizationId = localStorage.getItem("organization_id_str");
    const websiteId = localStorage.getItem("website_id");

    const response = await apiClient.put(`/banner/manage-supported_lang/${bannerId}`, payload, {
      headers: {
        "Content-Type": "application/json",
        "website_id": websiteId,
        "organization_id": organizationId,
      },
    });
    // Check for error response even with 200 status
    if (response.data.success === false) {
      const error = new Error(response.data.message);
      error.response = response;
      error.status_code = response.data.status_code;
      error.success = response.data.success;
      throw error;
    }

    return response.data;
  } catch (error) {
    console.log("Error deleting banner translation:", error);
    throw error;
  }
};