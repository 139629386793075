import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Typography,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Chip,
} from "@mui/material";
import { ExpandMoreOutlined, AutorenewOutlined } from "@mui/icons-material";
import {
  AlarmIcon,
  BriefcaseIcon,
  RedCross,
  StatusUp,
  UncategorizedIcon,
  UserSquare,
} from "../assets/svg";
import ButtonCommon from "../CommonComponent/ButtonCommon";
import CommonHeader from "../CommonComponent/CommonHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  getWebsiteById,
  lastScan,
  scanWebsite,
  startScan,
} from "../api/websiteService";
import { showErrorToast } from "../toastUtils";
import { setScanData } from "../store/scanSlice";
import Loader from "../CommonComponent/Loader";
import { getFormattedTimestamp } from "../utils/dateUtils";

const WebsiteScan = () => {
  const scanData = useSelector((state) => state.scan.scanData);
  const [isLoading, setIsLoading] = useState(false);

  const handleScanRefresh = async () => {
    try {
      setIsLoading(true);
      const updatedScanData = await scanWebsite(scanData.scan_id);
      dispatch(setScanData(updatedScanData?.data));
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const StatCard = ({ icon, title, value }) => (
    <Card
      variant="outlined"
      sx={{
        p: 2,
        height: "100%",
        borderRadius: 2,
        bgcolor: "white",
        pb: 0,
        pl: 0,
        pr: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          pl: 3,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0, 200, 150, 0.1)",
              mr: 1,
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: "400", color: "#989898" }}
            >
              {title}
            </Typography>
            <Typography
              variant="h6"
              sx={{ mb: 1, fontWeight: "bold", color: "#525455" }}
            >
              {value.toLocaleString()}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background: "#F9FAFB",
          pl: 2,
        }}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ fontSize: "12px" }}
        >
          {scanData && scanData.scan_start_time
            ? `Scanned on ${scanData.scan_start_time}`
            : ""}
        </Typography>
      </Box> */}
    </Card>
  );

  const [websiteDetails, setWebsiteDetails] = useState({});

  const fetchWebsiteById = async () => {
    try {
      const websiteId = localStorage.getItem("website_id");
      const data = await getWebsiteById(websiteId);
      if (data?.status_code === 200) {
        setWebsiteDetails(data?.data);
      }
    } catch (error) {
    }
  };

  const getLastScan = async () => {
    try {
      setIsLoading(true);
      const lastScanData = await lastScan();
      dispatch(setScanData(lastScanData?.data));
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWebsiteById();
    getLastScan();
    //eslint-disable-next-line
  }, []);

  const statusChips = [
    { label: "queue", color: "warning" },
    { label: "in_progress", color: "info" },
    { label: "success", color: "success" },
    { label: "failed", color: "error" },
  ];

  const categoryIcons = {
    Essential: <AlarmIcon />,
    Analytics: <StatusUp />,
    Marketing: <BriefcaseIcon />,
    Personalization: <UserSquare />,
    Default: <UncategorizedIcon />, // Fallback icon
  };

  const totalServices = (
    Array.isArray(scanData?.scan_data) ? scanData?.scan_data : []
  ).length;

  const dispatch = useDispatch();

  const handleScanWebsite = async () => {
    try {
      setIsLoading(true);
      const websiteID = localStorage.getItem("website_id");
      const res = await startScan(websiteID, websiteDetails?.domain_name);
      if (res?.status_code === 200) {
        setIsLoading(false);
        dispatch(setScanData(res?.data));
      }
    } catch (err) {
      showErrorToast(err);
      setIsLoading(false);
    }
  };

  const groupServicesByCategory = (scanData) => {
    const groupedByCategory = (Array.isArray(scanData) ? scanData : []).reduce(
      (acc, categoryData) => {
        const { category_name, service_name, storage_item, service_icon } =
          categoryData;

        if (acc[category_name]) {
          acc[category_name].services.push({
            service_name,
            storage_item,
            service_icon,
          });
        } else {
          acc[category_name] = {
            category_name,
            services: [{ service_name, storage_item, service_icon }],
          };
        }
        return acc;
      },
      {}
    );

    return groupedByCategory;
  };

  const categoryOrder = [
    "Essential",
    "Marketing",
    "Analytics",
    "Personalization",
  ];
  const groupedServices = groupServicesByCategory(scanData?.scan_data);
  const sortedServices = categoryOrder
    .map((category) => groupedServices[category])
    .filter(Boolean); // Remove undefined categories if some are missing

  // Handling uncategorised cookies
  const uncategorisedCookies = scanData?.uncategorised;

  const getStatusLabel = (status) => {
    const cleanedStatus = status?.trim(); // Remove any extra spaces
    switch (cleanedStatus) {
      case "queue":
        return "Queue";
      case "in_progress":
        return "In Progress";
      case "success":
        return "Success";
      case "failed":
        return "Failed";
      default:
        return "Queue";
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Box>
            <CommonHeader
              title="Website Scan"
              subTitle="Review the latest scan report for your website here. Also, rescan the website or add a new website."
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <ButtonCommon size="small" onClick={handleScanWebsite}>
              Scan Now
            </ButtonCommon>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body" color="#525455">
            Scan Report
          </Typography>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              flexDirection: "column",
            }}
          >
            <Typography variant="body" color="#525455">
              Scan Quota
            </Typography>
          </Box> */}
        </Box>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Typography
                  variant="h6"
                  color="#525455"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {scanData?.url_name ? scanData?.url_name : ""}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  {/* {statusChips.map((chip) => (
                <Chip
                  key={chip.label}
                  label={chip.label}
                  // color={chip.color}
                  color={scanData?.scan_status === chip.label ? chip.color : "default"}
                  size="small"
                  sx={{ borderRadius: 3 }}
                />
              ))} */}
                  {scanData && (
                    <Chip
                      key={scanData.scan_status}
                      label={getStatusLabel(scanData.scan_status)}
                      color={
                        statusChips.find(
                          (chip) => chip.label === scanData.scan_status
                        )?.color || "default"
                      }
                      size="small"
                      sx={{ borderRadius: 3 }}
                    />
                  )}
                  <IconButton
                    size="small"
                    onClick={handleScanRefresh}
                    disabled={isLoading}
                  >
                    <AutorenewOutlined />
                  </IconButton>
                </Stack>
              </Box>
              {/* <Typography variant="h6" fontWeight="bold" color="#525455">
                1/5
              </Typography> */}
            </Box>

            {/* Cookie Count Row */}
            <Box display="flex" gap={2}>
              <Typography variant="body2" color="text.secondary">
                Cookie in Total:{" "}
                <Typography component="span" fontWeight="bold">
                  {totalServices !== "null" ? totalServices : 0}
                </Typography>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Scan Started On:{" "}
                <Typography component="span" fontWeight="bold">
                  {scanData && scanData?.scan_start_time
                    ? getFormattedTimestamp(
                        scanData?.scan_start_time,
                        websiteDetails?.time_zone
                      )
                    : "-"}
                </Typography>
              </Typography>
              {(scanData?.scan_status === "success" ||
                scanData?.scan_status === "failed") && (
                <Typography variant="body2" color="text.secondary">
                  {scanData?.scan_status === "failed"
                    ? "Scan Failed On:"
                    : "Scan Completed On:"} {" "}
                  <Typography component="span" fontWeight="bold">
                    {scanData && scanData?.updated_at
                      ? getFormattedTimestamp(
                          scanData?.updated_at,
                          websiteDetails?.time_zone
                        )
                      : "-"}
                  </Typography>
                </Typography>
              )}
            </Box>
            {scanData?.scan_status === "success" &&
              scanData?.is_our_script === false && (
                <Card
                  sx={{ mt: 2, p: 2, backgroundColor: "#FDE9E7" }}
                  variant="outlined"
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <IconButton size="small">
                      {" "}
                      <RedCross />
                    </IconButton>
                    <Typography color="#DF2B03" fontWeight="400">
                      The Data Privacy Cloud plugin was not detected on your
                      website. Please follow the steps in "Install Plugin"
                      section to install it.
                    </Typography>
                  </Box>
                </Card>
              )}

            <Grid container spacing={2} sx={{ mb: 4, mt: 1 }}>
              <Grid item xs={12} md={3}>
                <StatCard
                  icon={<AlarmIcon sx={{ color: "#00B894" }} />}
                  title="Essential"
                  value={
                    (Array.isArray(scanData?.scan_data)
                      ? scanData?.scan_data
                      : []
                    ).filter((data) => data.category_name === "Essential") // Filter out only Essential category
                      .length || "-"
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <StatCard
                  icon={<BriefcaseIcon sx={{ color: "#00B894" }} />}
                  title="Marketing"
                  value={
                    (Array.isArray(scanData?.scan_data)
                      ? scanData?.scan_data
                      : []
                    ).filter((data) => data.category_name === "Marketing") // Filter out only Essential category
                      .length || "-"
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <StatCard
                  icon={<StatusUp sx={{ color: "#00B894" }} />}
                  title="Analytics"
                  value={
                    (Array.isArray(scanData?.scan_data)
                      ? scanData?.scan_data
                      : []
                    ).filter((data) => data.category_name === "Analytics") // Filter out only Essential category
                      .length || "-"
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <StatCard
                  icon={<UserSquare sx={{ color: "#00B894" }} />}
                  title="Personalization"
                  value={
                    (Array.isArray(scanData?.scan_data)
                      ? scanData?.scan_data
                      : []
                    ).filter((data) => data.category_name === "Personalization") // Filter out only Essential category
                      .length || "-"
                  }
                />
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {Object.values(sortedServices).map((group, index) => (
                <Accordion
                  key={index}
                  sx={{
                    borderRadius: "12px",
                    overflow: "hidden",
                    "&:before": { display: "none" },
                  }}
                  variant="outlined"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{
                      backgroundColor: "#F9FAFB",
                      borderBottom: (theme) =>
                        `1px solid ${
                          theme.palette.mode === "light" ? "#e0e0e0" : "#303030"
                        }`,
                      "& .MuiAccordionSummary-content": {
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      },
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "rgba(0, 200, 150, 0.1)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "14px",
                      }}
                    >
                      {categoryIcons[group?.category_name] ||
                        categoryIcons.Default}
                    </Box>
                    <Typography color="#525455" fontWeight="500">
                      {group?.category_name}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ backgroundColor: "#F9FAFB" }}>
                    <Grid container spacing={2}>
                      {(Array.isArray(group?.services)
                        ? group?.services
                        : []
                      ).map((service, serviceIndex) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          key={serviceIndex}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                              p: 2,
                              borderRadius: 2,
                              bgcolor: "white",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: 1,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  mr: 1,
                                }}
                              >
                                <img
                                  src={`/images/${service?.service_icon}`}
                                  alt={`${service?.service_name} Icon`}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    objectFit: "contain",
                                  }}
                                />
                              </Box>
                              <Typography
                                variant="h6"
                                color="#525455"
                                fontWeight="bold"
                              >
                                {service?.service_name}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1,
                                mb: 2,
                              }}
                            >
                              {(Array.isArray(service?.storage_item)
                                ? service?.storage_item.slice(0, 2)
                                : []
                              ).map((item, itemIndex) => (
                                <Chip
                                  key={itemIndex}
                                  variant="filled"
                                  label={`${item?.name}`}
                                  sx={{
                                    backgroundColor: "#F0F0F0",
                                    color: "#525455",
                                    borderRadius: "16px",
                                    "& .MuiChip-label": {
                                      padding: "8px 12px",
                                    },
                                  }}
                                />
                              ))}
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}

              {/* If there are uncategorised items, render them in a separate accordion */}
              {uncategorisedCookies?.length > 0 && (
                <Accordion
                  sx={{
                    borderRadius: "12px",
                    overflow: "hidden",
                    "&:before": { display: "none" },
                  }}
                  variant="outlined"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    sx={{
                      backgroundColor: "#F9FAFB",
                      borderBottom: (theme) =>
                        `1px solid ${
                          theme.palette.mode === "light" ? "#e0e0e0" : "#303030"
                        }`,
                      "& .MuiAccordionSummary-content": {
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      },
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "rgba(0, 200, 150, 0.1)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "14px",
                      }}
                    >
                      {categoryIcons.Default}
                    </Box>
                    <Typography color="#525455" fontWeight="500">
                      Uncategorised Items
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ backgroundColor: "#F9FAFB" }}>
                    <Grid container spacing={2}>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                          p: 2,
                        }}
                      >
                        {(Array.isArray(uncategorisedCookies)
                          ? uncategorisedCookies
                          : []
                        ).map((cookie, index) => (
                          <Chip
                            key={index}
                            variant="filled"
                            label={cookie}
                            sx={{
                              backgroundColor: "#F0F0F0",
                              color: "#525455",
                              width: "fit-content",
                            }}
                          />
                        ))}
                      </Box>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default WebsiteScan;
