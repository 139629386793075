import React, { useState, useEffect } from "react";
import CustomSwitch from "../../../CommonComponent/CustomSwitch";
import {
  Box,
  TextField,
  Typography,
  Card,
  CardContent,
  Button,
  InputAdornment,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Modal, IconButton } from "@mui/material"; // Add these to your imports
import { CloseOutlined } from "@mui/icons-material";
import { showSuccessToast, showErrorToast } from "../../../toastUtils";
import { deleteBanner } from "../../../api/bannerService"; // Adjust the import path
import { useNavigate, useParams } from "react-router-dom";


const GeneralSettings = ({ data, onUpdate, errors, isLastActiveBanner, loading }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const { bannerId } = useParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    bannerName: data?.bannerName || "",
    isActive: data?.isActive ?? true,
    forcedConsent: data?.forcedConsent ?? true,
    consentDuration: data?.consentDuration || "",
    targeting: data?.targeting || "WorldWide",
  });

  // Update local state when parent data changes (when switching tabs)
  useEffect(() => {
    if (data) {
      setFormData({
        bannerName: data.bannerName || "",
        isActive: data.isActive ?? true,
        forcedConsent: data.forcedConsent ?? false,
        consentDuration: data.consentDuration || "",
        targeting: data.targeting || "WorldWide",
      });
    }
  }, [data]);
  const targetingOptions = [
    {
      id: "WorldWide",
      label: "WorldWide",
      description:
        "Show the banner to all visitors, regardless of their location.",
    },
    // {
    //   id: "SelectedCountry",
    //   label: "Selected Country",
    //   description:
    //     "Show the banner to all visitors, regardless of their location.",
    // },
    // {
    //   id: "SelectedUSStates",
    //   label: "Selected US States",
    //   description: "Show the banner only to visitors in selected US states.",
    // },
  ];

  const handleInputChange = (field, value) => {
    // const newFormData = {
    //   ...formData,
    //   [field]: value,
    // };
    // Special validation for banner name
    if (field === 'bannerName') {
      if (!value || value.trim() === '') {
        showErrorToast('Please enter a banner name');
      }
    }

    // // Update local state
    // setFormData(newFormData);

    // // Update parent state
    // onUpdate(newFormData);
    onUpdate({
      [field]: value,
    });
    
  };
  // Add these new handlers
  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    // First confirmation

    try {
      setIsDeleting(true);

      await Promise.race([
        deleteBanner(bannerId),
        new Promise((_, reject) =>
          setTimeout(
            () => reject(new Error("Request timed out after 30 seconds")),
            30000
          )
        ),
      ]);

      // Show success message
      showSuccessToast("Banner deleted successfully");

      // Navigate back to banners list
      navigate("/banner");
    } catch (error) {
      showErrorToast(error.response?.data?.message || "Failed to delete banner");
    } finally {
      setIsDeleting(false);
      handleCloseDeleteModal();
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#FFFFFF",
    borderRadius: "16px",
    boxShadow: 24,
    p: 3,
  };
  

  const TargetButton = ({ option }) => {
    const isSelected = formData.targeting === option.id;

    return (
      <Box sx={{ flex: 1 }}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => handleInputChange("targeting", option.id)}
          sx={{
            borderColor: isSelected ? "#00B894" : "#E0E0E0",
            color: isSelected ? "#00B894" : "#525455",
            backgroundColor: "white",
            "&:hover": {
              borderColor: "#00B894",
              backgroundColor: "white",
            },
            textTransform: "none",
            fontWeight: isSelected ? 600 : 400,
            padding: "12px 16px",
            borderRadius: "8px",
            border: isSelected ? "2px solid #00B894" : "1px solid #E0E0E0",
            minHeight: "48px",
            minWidth: "200px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: isSelected ? 600 : 600,
              color: isSelected ? "#00B894" : "#525455",
            }}
          >
            {option.label}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#637381",
              fontSize: "12px",
              textAlign: "left",
              fontWeight: 400,
            }}
          >
            {option.description}
          </Typography>
        </Button>
      </Box>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
      //   width: "800px", // or whatever width you need
      //   minWidth: "800px", // ensures minimum width
      //   maxWidth: "800px", // ensures maximum width
        flexShrink: 0, // prevents shrinking
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        {/* Basic Section */}
        <Box>
          <Typography variant="h6" mb={1} sx={{ color: "#525455" }}>
            Basic
          </Typography>
          <Typography variant="body2" color="text.secondary" mb={2}>
            Organise your banner with an identifiable name, enable and disable the banner
          </Typography>
          <Card variant="outlined" sx={{ bgcolor: "#F9FAFB" }}>
            <CardContent
              sx={{
                "&:last-child": {
                  pb: 3, // Override Material-UI's default last-child padding
                },
                p: "0 !important", // Remove default padding from CardContent
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    color: "#637381", // This is a lighter gray color
                  }}
                >
                  Banner Name
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Enter banner name"
                  value={formData.bannerName}
                  // disabled
                  onChange={(e) => {
                    const newValue = e.target.value;
                    handleInputChange("bannerName", newValue);
                  }}
                  error={errors.bannerName} // Add error state
                  helperText={errors.bannerName ? "Banner name is required" : ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "40px",
                      bgcolor: "white",
                      "& fieldset": {
                        borderColor: "#E9ECEF",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#DEE2E6",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00B894",
                      },
                    },
                  }}
                />
              </Box>
              <Divider />
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 600,
                        color: "#637381", // This is a lighter gray color
                      }}
                    >
                      Banner Active
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      If disabled, this banner will not be shown to any
                      visitors.
                    </Typography>
                  </Box>
                  <CustomSwitch
                    checked={formData.isActive}
                    onChange={(e) =>
                      handleInputChange("isActive", e.target.checked)
                    }
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>

        {/* Consent Collections Section */}
        <Box>
          <Typography variant="h6" mb={2} sx={{ color: "#525455" }}>
            Consent Collections
          </Typography>
          <Card variant="outlined" sx={{ bgcolor: "#F9FAFB" }}>
            <CardContent
              sx={{
                "&:last-child": { pb: 3 },
                p: "0 !important",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column", // Stack children vertically
                  }}
                >
                  {/* Top row with title and switch */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1, // Add margin bottom for spacing
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 600,
                        color: "#637381", // This is a lighter gray color
                      }}
                    >
                      Forced Consent
                    </Typography>
                    <CustomSwitch
                      checked={formData.forcedConsent}
                      onChange={(e) =>
                        handleInputChange("forcedConsent", e.target.checked)
                      }
                    />
                  </Box>

                  {/* Description on new line */}
                  <Typography variant="body2" color="text.secondary">
                    Create a dark overlay that restricts page scrolling until
                    consent is given.
                  </Typography>
                </Box>
              </Box>

              <Divider />
              <Box sx={{ p: 2 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    color: "#637381", // This is a lighter gray color
                  }}
                >
                  Consent Duration
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  mb={2} // Added margin bottom for spacing between description and input
                >
                  How long the consent is valid for before asking the user for
                  consent again.
                </Typography>
                <TextField
                  fullWidth // Makes the input take full width
                  type="number"
                  placeholder="Enter number of days"
                  value={formData.consentDuration}
                  onChange={(e) =>
                    handleInputChange("consentDuration", e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end"></InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "40px", // Reduced height
                      bgcolor: "white",
                      "& fieldset": {
                        borderColor: "#E9ECEF",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#DEE2E6",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00B894",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: "8px 14px", // Adjusted padding for better text alignment
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>

        {/* Targeting Section */}
        <Box>
          <Typography variant="h6" mb={2} sx={{ color: "#525455" }}>
            Targeting
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            mb={2} // Added margin bottom for spacing between description and input
          >
            Configure which countries and regions your banner will be shown to.
          </Typography>
          <Card variant="outlined" sx={{ bgcolor: "#F9FAFB" }}>
            <CardContent sx={{ p: 3 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
                {/* Title */}
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    color: "#637381", // This is a lighter gray color
                  }}
                >
                  Target
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 1 }}
                >
                  Note: Selecting the same regions as other banners will
                  automatically deselect those regions from the corresponding
                  banners. If there are mulitple banners targetting "worldwide",
                  the last banner to be saved will be the one that is shown.
                </Typography>

                {/* Buttons Container */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    "& > *": {
                      flex: 1,
                    }, // Make each button container take equal space
                  }}
                >
                  {targetingOptions.map((option) => (
                    <TargetButton key={option.id} option={option} />
                  ))}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>

        {/* Danger Zone Section */}
        <Box>
          <Typography variant="h6" mb={1} sx={{ color: "#525455" }}>
            Danger Zone!
          </Typography>
          <Typography variant="body2" color="text.secondary" mb={2}>
            Delete this banner? Once you delete this banner, there is no going
            back.
          </Typography>
          {/* <Card sx={{ bgcolor: '#fff8f8' }} variant="outlined"> */}
          <Card variant="outlined" sx={{ bgcolor: "#F9FAFB" }}>
            <CardContent sx={{ p: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">
                  You are about to delete this banner! This action cannot be
                  undone. Are you sure you want to proceed?
                </Typography>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDeleteClick}
                  disabled={isDeleting || !bannerId || (isLastActiveBanner && data?.isActive)}
                >
                  {isDeleting ? "Deleting..." : "Delete"}
                </Button>
                </Box>
                <Modal 
                  open={isDeleteModalOpen} 
                  onClose={handleCloseDeleteModal}
                >
                  <Box sx={style}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6" color="#525455" fontWeight="bold">
                        Delete Banner
                      </Typography>
                      <IconButton onClick={handleCloseDeleteModal}>
                        <CloseOutlined />
                      </IconButton>
                    </Box>
                    <Typography color="#989898">
                      Are you sure you want to delete this banner? This action cannot be undone.
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Box gap={2} display="flex">
                      <Button
                        variant="outlined"
                        sx={{
                          border: "2px solid #00B894",
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                        onClick={handleDelete}
                        disabled={isDeleting}
                      >
                        {isDeleting ? "Deleting..." : "Delete"}
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          border: "2px solid #CECECE",
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                        onClick={handleCloseDeleteModal}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              
                {/* Add helper text to explain why button is disabled */}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralSettings;
