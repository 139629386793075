import apiClient from "./apiClient";

export const getAllDataRequest = async () => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get("/data_request", {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDataRequest = async (requestId) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get(`/data_request/${requestId}`, {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateStatusChange = async (requestId, formData) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.put(
      `/data_request/${requestId}`,
      formData,
      {
        headers: {
          website_id: websiteId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const assignUser = async (requestId, payload) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.put(
      `/data_request/${requestId}`,
      payload,
      {
        headers: {
          website_id: websiteId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const generatePresignedUrl = async (filename) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.post(
      "/generate_presigned_url",
      {
        file_name: filename,
      },
      {
        headers: {
          website_id: websiteId,
        },
      }
    );

    if (response.data?.status_code === 200 && response.data?.success) {
      return {
        presigned_url: response.data.data.url, // Extract URL from response
        file_name: response.data.data.file_name, // Extract file name from response
      };
    } else {
      throw new Error(response.data?.message || "Failed to generate URL");
    }
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to generate URL");
  }
};

export const getPresignedUrl = async (fileName) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get(
      `/generate_presigned_url/${fileName}`,
      {
        headers: {
          website_id: websiteId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
