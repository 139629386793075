import React, { useState } from "react";
import {
  Dialog,
  Typography,
  Box,
  Switch,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Stack,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  InputLabel,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ButtonCommon from "../../../CommonComponent/ButtonCommon";
import { BannerPrefrenceLogo } from "../../../assets/svg";
import CommonTabs from "../../../CommonComponent/CommonTabs";
import RadioGroup from "@mui/material/RadioGroup";
// Styled components remain the same

const ManagePreferencesModel = ({ isOpen, onClose, data }) => {
  // ... state management and cookieCategories remain the same ...
  const { preferenceSettings, themeSettings } = data;
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [expandedSubCategory, setExpandedSubCategory] = useState(null);
  const [preferences, setPreferences] = useState({});
  const [value, setValue] = useState("1"); // Default to "1"

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const isDarkTheme = themeSettings.darkMode === "dark";

  const CustomAccordion = styled(Accordion)(({ theme }) => ({
    "&:before": {
      display: "none",
    },
    borderRadius: "8px !important",
    border: `1px solid ${isDarkTheme ? "#374151" : theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    boxShadow: "none",
    backgroundColor: isDarkTheme ? "#1F2937" : theme.palette.background.paper,
  }));

  const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: isDarkTheme ? "#374151" : theme.palette.grey[50],
    borderRadius: "8px",
    "& .MuiAccordionSummary-content": {
      margin: "12px 0",
      color: isDarkTheme ? "#FFFFFF" : theme.palette.text.primary,
    },
  }));

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: isDarkTheme ? "#FFFFFF" : "#10B981",
      "&:hover": {
        backgroundColor: isDarkTheme
          ? "rgba(255, 255, 255, 0.08)"
          : "rgba(16, 185, 129, 0.08)",
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: isDarkTheme ? "#FFFFFF" : "#10B981",
    },
  }));

  const cookieCategories = [
    {
      id: "essential",
      title: "Essential",
      description:
        "Essential cookies are necessary for features that are essential to your use of our site or services, such as account login, authentication and site security.",
      subCategories: [
        {
          id: "recaptcha",
          title: "Google reCAPTCHA",
          description:
            "We use Google reCAPTCHA to protect our site from span and abuse",
          cookies: [
            { name: "_GRECAPTCHA", duration: "30 minutes" },
            { name: "_grecaptcha", duration: "30 minutes" },
          ],
        },
        {
          id: "cloudflare",
          title: "Cloudflare",
          description:
            "We use Cloudflare to optimize site performance, enhance security, and protect against DDoS attacks. Cloudflare may set cookies on your device to improve user experience.",
          cookies: [
            { name: "_cf_bm", duration: "30 minutes" },
            { name: "_cf_test", duration: "1 day" },
            { name: "_cflb", duration: "24 hours" },
          ],
        },
        {
          id: "wordpress",
          title: "WordPress",
          description:
            "We use WordPress to manage  our website conetent. Various cookies are used to manage to user session, preferences and authentication.",
          cookies: [
            { name: "Wordpress-*", duration: "session" },
            { name: "comment_author_*", duration: "1 year" },
            { name: "wordpress_logged_in_*", duration: "session" },
            { name: "Wordpress_sec_*", duration: "session" },
            { name: "Wordpress_test_cookies", duration: "session" },
          ],
        },
      ],
    },
    {
      id: "marketing",
      title: "Marketing",
      description: "Marketing cookies help us understand your preferences.",
      subCategories: [
        {
          id: "facebook",
          title: "Facebook",
          description:
            "We use Facebook to personalize your experience on our site.",
          cookies: [
            { name: "_fbp", duration: "3 months" },
            { name: "fr", duration: "3 months" },
          ],
        },
        {
          id: "google",
          title: "Google Ads/Adsense",
          description:
            "We use Google Ads/Adsense to display personalize ads based on browsing behavior and preferences.",
          cookies: [
            { name: "IDE", duration: "13 months" },
            { name: "NID", duration: "6 months" },
            { name: "SAPISID", duration: "2 years" },
            { name: "_gac", duration: "90 days" },
          ],
        },
      ],
    },
    {
      id: "Analytics",
      title: "Analytics",
      description:
        "Analytics cookies allow us to analyse your visits and actions so we can make our service better",
      subCategories: [
        {
          id: "googleanalytics",
          title: "Google Analytics",
          description:
            "We use Google Analytics to collect data on how visitors interact wih our site, which help uus to improve user experience and site performance",
          cookies: [
            { name: "_ga", duration: "2 years" },
            { name: "_gat", duration: "1 minute" },
            { name: "_ga_*", duration: "2 years" },
            { name: "_gid", duration: "24 hours" },
          ],
        },
        {
          id: "segment",
          title: "Segment",
          description:
            "We use Segment to collect, transorm,and send data to various analytics tools for unified analysis",
          cookies: [
            { name: "ajs_anonymouus_id", duration: "1 years" },
            { name: "ajs_group_id", duration: "1 years" },
            { name: "aks_user_id", duration: "1 years" },
          ],
        },
      ],
    },
  ];

  // Layout style based on preferences
  const getLayoutStyle = () => {
    if (preferenceSettings.layout === "Box") {
      return {
        borderRadius: 2,
        height: "80vh",
        maxHeight: "700px",
        width: "600px",
        margin: "20px",
        bgcolor: isDarkTheme
          ? "#000000"
          : themeSettings.background || "#FFFFFF",
        color: isDarkTheme ? "#FFFFFF" : themeSettings.text || "#000000",
      };
    } else if (preferenceSettings.layout === "Bar") {
      return {
        height: "100vh",
        width: "30%",
        position: "fixed",
        top: 0,
        bottom: 0,
        [preferenceSettings.modalAlignment.toLowerCase()]: 0,
        margin: 0,
        borderRadius: 0,
        bgcolor: isDarkTheme
          ? "#000000"
          : themeSettings.background || "#FFFFFF",
        color: isDarkTheme ? "#FFFFFF" : themeSettings.text || "#000000",
      };
    }
  };

  // Function to determine if nested content should be shown
  const shouldShowNestedContent = (category) => {
    if (preferenceSettings.controlLevel === "Category & Services") {
      return true;
    }
    return (
      preferenceSettings.controlLevel === "Category Only" &&
      preferenceSettings.displayCookies
    );
  };

  const isBarLayout = preferenceSettings.layout === "Bar";

  const renderButtons = () => {
    // Different sizes for primary and secondary buttons in box layout
    const buttonDimensions = isBarLayout
      ? {
          primary: { width: "250px", height: "48px" },
          secondary: { width: "250px", height: "48px" },
        }
      : {
          primary: { width: "120px", height: "40px" }, // Smaller size for Accept/Reject
          secondary: { width: "200px", height: "40px" }, // Larger width for Save Preferences
        };

    const getButtonOverrides = (type) => ({
      "& .MuiButton-root": {
        width: `${buttonDimensions[type].width} !important`,
        height: `${buttonDimensions[type].height} !important`,
        minWidth: `${buttonDimensions[type].width} !important`,
        minHeight: `${buttonDimensions[type].height} !important`,
        maxWidth: `${buttonDimensions[type].width} !important`,
        maxHeight: `${buttonDimensions[type].height} !important`,
        padding: "12px 20px !important",
      },
      "&.MuiButtonBase-root": {
        width: `${buttonDimensions[type].width} !important`,
        height: `${buttonDimensions[type].height} !important`,
        minWidth: `${buttonDimensions[type].width} !important`,
        minHeight: `${buttonDimensions[type].height} !important`,
        maxWidth: `${buttonDimensions[type].width} !important`,
        maxHeight: `${buttonDimensions[type].height} !important`,
        padding: "12px 20px !important",
      },
    });
    // Determine button order based on privacy trigger position
    const shouldReverse =
      preferenceSettings.privacyTriggerPosition === "Bottom Right";
    const primaryButtonStyle = {
      ...getButtonOverrides("primary"),
      "&.MuiButtonBase-root": {
        border: `2px solid ${
          isDarkTheme
            ? themeSettings.primaryButtonBg || "#FFFFFF"
            : themeSettings.primaryButtonBg || "#FFFFFF"
        } !important`,
        backgroundColor: isDarkTheme
          ? "transparent !important"
          : "transparent !important",
        color: `${
          isDarkTheme
            ? themeSettings.primaryButtonText || "#FFFFFF"
            : themeSettings.primaryButtonText || "#FFFFFF"
        } !important`,
        fontSize: "12px", // Increase font size slightly for better readability
        minWidth: "120px", // Prevents the button from becoming too small
        maxWidth: "100%", // Ensures it doesn't overflow the container
        padding: "8px 16px", // Adds proper spacing
        whiteSpace: "normal", // Allows text to wrap if necessary
        wordBreak: "break-word", // Ensures text breaks properly if needed
        textAlign: "center", // Keeps text centered
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "&:hover": {
          backgroundColor: isDarkTheme
            ? themeSettings.primaryButtonBg || "#34495E !important"
            : `${themeSettings.primaryButtonBg || "#717171"} !important`,
          opacity: 0.9,
        },
      },
    };
    const ButtonWrapper = styled(Box)({
      "& .MuiButton-root": {
        border: `2px solid ${
          isDarkTheme
            ? themeSettings.primaryButtonBg || "#FFFFFF"
            : themeSettings.primaryButtonBg || "#FFFFFF"
        } !important`,
        backgroundColor: `${
          isDarkTheme ? "transparent" : "transparent"
        } !important`,
        borderRadius: `${
          themeSettings.buttonBorderRadius || "0.4"
        }rem !important`,
        color: `${
          isDarkTheme
            ? themeSettings.primaryButtonText || "#ECF0F1"
            : themeSettings.primaryButtonText || "#FFFFFF"
        } !important`,
        width: "100% !important",
        height: "100% !important",
        "&:hover": {
          backgroundColor: `${
            isDarkTheme
              ? themeSettings.primaryButtonBg || "#34495E"
              : themeSettings.primaryButtonBg || "#717171"
          } !important`,
          color: `${
            isDarkTheme
              ? themeSettings.primaryButtonText || "#ECF0F1"
              : themeSettings.primaryButtonText || "#FFFFFF"
          } !important`,
          opacity: "0.9 !important",
        },
      },
    });

    return (
      <Box
        sx={{
          p: 1,
          borderTop: 1,
          borderColor: isDarkTheme ? "rgba(255, 255, 255, 0.12)" : "divider",
          display: "flex",
          flexDirection: isBarLayout ? "column" : "row",
          gap: isBarLayout ? 2 : 6, // Increased gap in box layout
          mt: "auto",
          alignItems: isBarLayout ? "center" : "center",
          // justifyContent: isBarLayout ? 'center' : 'space-between',
          justifyContent: isBarLayout
            ? "center"
            : shouldReverse
            ? "row-reverse"
            : "row",
          backgroundColor: isDarkTheme ? "#1a1a1a" : "inherit",
        }}
      >
        {/* Left side buttons - Accept All and Reject */}
        <Box
          sx={{
            display: "flex",
            flexDirection: isBarLayout ? "column" : "row",
            gap: 2,
            alignItems: "center",
            order: isBarLayout ? 0 : shouldReverse ? 1 : 2,
          }}
        >
          {preferenceSettings.acceptAll && (
            <ButtonWrapper
              sx={{
                width: buttonDimensions.primary.width,
                height: buttonDimensions.primary.height,
                "& > button": {
                  width: "100% !important",
                  height: "100% !important",
                },
              }}
            >
              <ButtonCommon onClick={() => onClose()} sx={primaryButtonStyle}>
                {preferenceSettings.acceptAllText || "Accept All"}
              </ButtonCommon>
            </ButtonWrapper>
          )}
          {preferenceSettings.acceptNecessary && (
            <ButtonWrapper
              sx={{
                width: buttonDimensions.primary.width,
                height: buttonDimensions.primary.height,

                "& > button": {
                  width: "100% !important",
                  height: "100% !important",
                },
              }}
            >
              <ButtonCommon onClick={() => onClose()} sx={primaryButtonStyle}>
                {preferenceSettings.acceptNecessaryText || "Reject"}
              </ButtonCommon>
            </ButtonWrapper>
          )}
        </Box>

        {/* Right side - Save Preferences button */}
        {preferenceSettings.savePreferences && (
          <ButtonWrapper
            sx={{
              width: buttonDimensions.secondary.width,
              height: buttonDimensions.secondary.height,
              order: isBarLayout ? 0 : shouldReverse ? 2 : 1,
              marginLeft: isBarLayout ? 0 : "auto", // Push to right in box layout
              "& > button": {
                width: "100% !important",
                height: "100% !important",
              },
            }}
          >
            <ButtonCommon onClick={() => onClose()} sx={primaryButtonStyle}>
              {preferenceSettings.savePreferencesText || "Save Preferences"}
            </ButtonCommon>
          </ButtonWrapper>
        )}
      </Box>
    );
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
    requestType: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    requestType: false,
    message: false,
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check required fields
    const newErrors = {
      name: !formData.name,
      email: !formData.email,
      requestType: !formData.requestType,
      message: formData.requestType === "other" && !formData.message,
    };
    setErrors(newErrors);

    if (Object.values(newErrors).includes(true)) {
      return; // Do not submit if there are errors
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={false}
      fullScreen={isBarLayout}
      PaperProps={{
        sx: {
          ...getLayoutStyle(),
          display: "flex",
          flexDirection: "column",
          overflow: "scroll", // Important to prevent outer scroll
          bgcolor: isDarkTheme
            ? "#121212 !important"
            : `${themeSettings.background} !important`,
          m: isBarLayout ? 0 : 2,
          boxShadow: isDarkTheme ? "0px 0px 20px rgba(0,0,0,0.5)" : undefined,
          border: isDarkTheme ? "1px solid rgba(255,255,255,0.1)" : "none",
          borderRadius: `${
            themeSettings.modalBorderRadius || "0.5"
          }rem !important`,
        },
      }}
    >
      {/* Close Button */}
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
          color: themeSettings.text,
          zIndex: 1,
          "&:hover": {
            backgroundColor: "rgba(255,255,255,0.1)", // Better hover effect for dark mode
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      <Grid container sx={{ height: "100%", overflow: "hidden" }}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <CommonTabs
            value={value}
            handleChange={handleChange}
            tabs={[
              {
                label: "Settings",
                value: "1",
                content: (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "calc(100vh - 64px)", // Take full height
                      maxHeight: isBarLayout ? "100vh" : "700px", // Set max height based on layout
                    }}
                  >
                    <Box sx={{ pb: 2, flexShrink: 0 }}>
                      <Box sx={{ textAlign: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <BannerPrefrenceLogo
                            style={{
                              height:
                                preferenceSettings.layout === "Bar"
                                  ? "100px"
                                  : "150px",
                              width: "auto",
                              filter: isDarkTheme ? "brightness(0.9)" : "none",
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: 600,
                            mb: 1,
                            color: themeSettings.heading,
                            textShadow: isDarkTheme
                              ? "0px 0px 1px rgba(255,255,255,0.1)"
                              : "none",
                          }}
                        >
                          {preferenceSettings.title || "User experience"}
                        </Typography>
                        <Typography
                          sx={{
                            color: themeSettings.text,
                            opacity: isDarkTheme ? 0.87 : 1, // Better readability in dark mode
                          }}
                        >
                          {preferenceSettings.description ||
                            "Our mission is to help you have the most delightful experience while browsing our pages."}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Scrollable Content */}
                    <Box
                      sx={{
                        flex: 1,
                        overflowY: "auto",
                        px: 3,
                        pb: isBarLayout ? 20 : 2,
                        "&::-webkit-scrollbar": {
                          width: "8px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? "rgba(255,255,255,0.2)"
                              : "rgba(0,0,0,0.2)",
                          borderRadius: "4px",
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? "rgba(255,255,255,0.05)"
                              : "rgba(0,0,0,0.05)",
                        },
                      }}
                    >
                      {cookieCategories.map((category) => (
                        <Box key={category.id} sx={{ mb: 3 }}>
                          <CustomAccordion
                            expanded={expandedCategory === category.id}
                            onChange={(event, isExpanded) =>
                              setExpandedCategory(
                                isExpanded ? category.id : null
                              )
                            }
                          >
                            <CustomAccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  sx={{ color: themeSettings.text }}
                                />
                              }
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontWeight: 600,
                                    color: themeSettings.heading,
                                  }}
                                >
                                  {category.title}
                                </Typography>
                                <CustomSwitch
                                  checked={preferences[category.id]}
                                  onChange={() => {
                                    setPreferences((prev) => ({
                                      ...prev,
                                      [category.id]: !prev[category.id],
                                    }));
                                  }}
                                  disabled={category.isRequired}
                                />
                              </Box>
                            </CustomAccordionSummary>

                            <AccordionDetails>
                              <Typography sx={{ color: "#333333", mb: 3 }}>
                                {category.description}
                              </Typography>

                              {shouldShowNestedContent(category) &&
                                category.subCategories?.map((subCategory) => (
                                  <CustomAccordion
                                    key={subCategory.id}
                                    expanded={
                                      expandedSubCategory === subCategory.id
                                    }
                                    onChange={(event, isExpanded) =>
                                      setExpandedSubCategory(
                                        isExpanded ? subCategory.id : null
                                      )
                                    }
                                  >
                                    <CustomAccordionSummary
                                      expandIcon={
                                        <ExpandMoreIcon
                                          sx={{ color: "#333333" }}
                                        />
                                      }
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        sx={{
                                          fontWeight: 600,
                                          color: themeSettings.heading,
                                        }}
                                      >
                                        {subCategory.title}
                                      </Typography>
                                    </CustomAccordionSummary>

                                    <AccordionDetails>
                                      {preferenceSettings.displayCookies && (
                                        <Paper
                                          sx={{
                                            bgcolor: "rgba(0,0,0,0.02)",
                                            color: "#333333",
                                          }}
                                          elevation={0}
                                        >
                                          <Table>
                                            <TableHead>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    color: "#6B7273",
                                                  }}
                                                >
                                                  Name
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    color: "#6B7273",
                                                  }}
                                                >
                                                  Duration
                                                </TableCell>
                                                <TableCell align="right"></TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {subCategory.cookies?.map(
                                                (cookie, index) => (
                                                  <TableRow key={index}>
                                                    <TableCell
                                                      sx={{
                                                        color: "#6B7280",
                                                      }}
                                                    >
                                                      {cookie.name}
                                                    </TableCell>
                                                    <TableCell
                                                      sx={{
                                                        color: "#6B7280",
                                                      }}
                                                    >
                                                      {cookie.duration}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                      <IconButton size="small">
                                                        <InfoOutlinedIcon
                                                          sx={{
                                                            color: "#6B7280",
                                                          }}
                                                        />
                                                      </IconButton>
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                              )}
                                            </TableBody>
                                          </Table>
                                        </Paper>
                                      )}
                                    </AccordionDetails>
                                  </CustomAccordion>
                                ))}
                            </AccordionDetails>
                          </CustomAccordion>
                        </Box>
                      ))}

                      {/* More Information Section */}
                      <Box
                        sx={{ mt: 4, mb: 6, overflowY: "auto", height: "100%" }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 600,
                            mb: 2,
                            color: themeSettings.heading,
                          }}
                        >
                          {preferenceSettings.moreInfoTitle ||
                            "More Information"}
                        </Typography>
                        <Typography
                          color="text.secondary"
                          sx={{ mb: 2, color: themeSettings.text }}
                        >
                          {preferenceSettings.moreInfoContent ||
                            "For any queries in relation to our policy on cookies and your choices, please contact us."}
                        </Typography>
                        <Typography
                          color="text.secondary"
                          sx={{ mb: 2, color: themeSettings.text }}
                        >
                          Your Consent ID: <br />
                          3e594f4e-b6d6-11ef-b535-b5cfaa07b6af
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color:
                              themeSettings.darkmode === "dark"
                                ? "#FFFFFF"
                                : themeSettings.text || "#555555",
                            fontSize: "1.0rem",
                          }}
                        >
                          Consent Management Powered By{" "}
                          <a
                            href="https://thedataprivacy.cloud"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontWeight: "bold",
                              color:
                                themeSettings.darkmode === "dark"
                                  ? "#FFFFFF"
                                  : "#00B894",
                              fontSize: "1.0rem",
                              textDecoration: "none",
                            }}
                          >
                            Data Privacy Cloud
                          </a>
                        </Typography>
                      </Box>
                    </Box>

                    {/* Footer with action buttons */}
                    <Box
                      sx={{
                        position: "absolute", // Change to absolute positioning
                        bottom: 0, // Stick to bottom
                        left: 0,
                        right: 0,
                        p: 2,
                        borderTop: (theme) =>
                          `1px solid ${
                            theme.palette.mode === "dark"
                              ? "rgba(255,255,255,0.1)"
                              : "rgba(0,0,0,0.1)"
                          }`,
                        display: "flex",
                        flexDirection: isBarLayout ? "column" : "row",
                        gap: 2,
                        bgcolor: isDarkTheme
                          ? "rgba(0,0,0,0.9)" // Make background more opaque
                          : "rgba(255,255,255,0.9)",
                        backdropFilter: "blur(8px)", // Add blur effect for better visibility
                        zIndex: 10, // Ensure footer stays on top
                      }}
                    >
                      {renderButtons()}
                    </Box>
                  </Box>
                ),
              },
              {
                label: "Data Request",
                value: "2",
                content: (
                  <Box
                    sx={{
                      maxHeight: "68vh", // Adjust height as needed
                      overflowY: "auto", // Enables scrolling
                    }}
                  >
                    <form onSubmit={handleSubmit}>
                      <Stack spacing={3}>
                        {/* Name and Email row */}
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: 2,
                          }}
                        >
                          <Box>
                            <InputLabel
                              shrink
                              htmlFor="name"
                              required
                              sx={{ mb: 1 }}
                            >
                              Name
                            </InputLabel>
                            <TextField
                              fullWidth
                              id="name"
                              name="name"
                              placeholder="Name"
                              variant="outlined"
                              size="small"
                              sx={{ mb: 2 }}
                              value={formData.name}
                              onChange={handleFormChange}
                              error={errors.name}
                              helperText={errors.name ? "Name is required" : ""}
                            />
                          </Box>
                          <Box>
                            <InputLabel
                              shrink
                              htmlFor="email"
                              required
                              sx={{ mb: 1 }}
                            >
                              Email
                            </InputLabel>
                            <TextField
                              required
                              fullWidth
                              id="email"
                              name="email"
                              type="email"
                              placeholder="Type your email"
                              variant="outlined"
                              size="small"
                              value={formData.email}
                              onChange={handleFormChange}
                              error={errors.email}
                              helperText={
                                errors.email ? "Email is required" : ""
                              }
                            />
                          </Box>
                        </Box>

                        {/* Address and Phone row */}
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: 2,
                          }}
                        >
                          <Box>
                            <InputLabel shrink htmlFor="address" sx={{ mb: 1 }}>
                              Address
                            </InputLabel>
                            <TextField
                              fullWidth
                              id="address"
                              name="address"
                              placeholder="Type your address"
                              variant="outlined"
                              size="small"
                              value={formData.address}
                              onChange={handleFormChange}
                            />
                          </Box>
                          <Box>
                            <InputLabel shrink htmlFor="phone" sx={{ mb: 1 }}>
                              Phone
                            </InputLabel>
                            <TextField
                              fullWidth
                              id="phone"
                              name="phone"
                              placeholder="Type your phone number"
                              variant="outlined"
                              size="small"
                              value={formData.phone}
                              onChange={handleFormChange}
                            />
                          </Box>
                        </Box>

                        {/* Request Type */}
                        <FormControl error={errors.requestType} fullWidth>
                          <FormLabel sx={{ fontSize: "12px" }}>
                            Request Type *
                          </FormLabel>
                          <RadioGroup
                            name="requestType"
                            value={formData.requestType}
                            onChange={handleFormChange}
                          >
                            <Box
                              sx={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr", // 2 columns
                                gap: 0.5, // spacing between items
                              }}
                            >
                              <FormControlLabel
                                value="export"
                                control={<Radio />}
                                label="Data export"
                              />
                              <FormControlLabel
                                value="deletion"
                                control={<Radio />}
                                label="Data deletion"
                              />
                              <FormControlLabel
                                value="updation"
                                control={<Radio />}
                                label="Data updation"
                              />
                              <FormControlLabel
                                value="other"
                                control={<Radio />}
                                label="Other request"
                              />
                            </Box>
                          </RadioGroup>
                          {errors.requestType && (
                            <Box sx={{ color: "red", fontSize: "12px" }}>
                              Request type is required
                            </Box>
                          )}
                        </FormControl>

                        {/* Message */}
                        <Box>
                          <InputLabel
                            shrink
                            htmlFor="message"
                            required={
                              formData.requestType === "other" ? true : false
                            }
                            sx={{ mb: 1 }}
                          >
                            Message
                          </InputLabel>
                          <TextField
                            required={
                              formData.requestType === "other" ? true : false
                            }
                            fullWidth
                            id="message"
                            name="message"
                            placeholder="Describe your request"
                            variant="outlined"
                            size="small"
                            multiline
                            rows={4}
                            value={formData.message}
                            onChange={handleFormChange}
                            error={errors.message}
                            helperText={
                              errors.message ? "Message is required" : ""
                            }
                          />
                        </Box>

                        {/* Buttons */}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 4,
                          }}
                        >
                          <Box sx={{ display: "flex", gap: 2 }}>
                            <ButtonCommon
                              sx={{ borderColor: "lightgray" }}
                              onClick={onClose}
                            >
                              Cancel
                            </ButtonCommon>
                            <Button
                              type="submit"
                              sx={{
                                textTransform: "capitalize",
                                border: `2px solid #00B894`,
                                color: "#525455",
                                fontWeight: "bold",
                                padding: "12px 20px",
                                borderRadius: "8px",
                                height: "48px",
                              }}
                            >
                              Submit
                            </Button>
                          </Box>
                        </Box>
                      </Stack>
                    </form>
                  </Box>
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ManagePreferencesModel;
