import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ButtonCommon from "../../../CommonComponent/ButtonCommon";

const PreviewModal = ({ isOpen, onClose, data, onManagePreferences }) => {
  const { bannerSettings, themeSettings } = data;

  const getBannerPosition = () => {
    const position = bannerSettings.position;

    // For Bar layout
    if (bannerSettings.layout === "Bar") {
      return {
        position: "absolute",
        [position.toLowerCase()]: 0,
        left: 0,
        right: 0,
        width: "100%",
      };
    }

    // For Box and Cloud layouts
    const positionStyles = {
      TopLeft: { top: 20, left: 20 },
      TopCenter: { top: 20, left: "50%", transform: "translateX(-50%)" },
      TopRight: { top: 20, right: 20 },
      MiddleLeft: { top: "50%", left: 20, transform: "translateY(-50%)" },
      MiddleCenter: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
      MiddleRight: { top: "50%", right: 20, transform: "translateY(-50%)" },
      BottomLeft: { bottom: 20, left: 20 },
      BottomCenter: { bottom: 20, left: "50%", transform: "translateX(-50%)" },
      BottomRight: { bottom: 20, right: 20 },
    };

    return {
      position: "absolute",
      ...positionStyles[position],
    };
  };

  const getLayoutStyles = () => {
    const layout = bannerSettings.layout;
    const isDarkTheme = themeSettings?.darkMode === "dark";
    const baseStyles = {
      backgroundColor: isDarkTheme
        ? "#000000"
        : themeSettings?.background || "#FFFFFF",
      color: isDarkTheme ? "#FFFFFF" : "#000000", // Text color based on theme
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    };

    switch (layout) {
      case "Bar":
        return {
          ...baseStyles,
          width: "100%",
          // minHeight: "200px",
          padding: "12px 24px",
          borderRadius: 0,
        };
      case "Cloud":
        return {
          ...baseStyles,
          width: "80%",
          // minHeight: "200px",
          padding: "12px 24px",
          borderRadius: `${themeSettings.modalBorderRadius}rem !important`,
        };
      case "Box":
      default:
        return {
          ...baseStyles,
          width: "500px",
          padding: "24px",
          borderRadius: `${themeSettings.modalBorderRadius}rem !important`,
        };
    }
  };
  const renderButtons = () => {
    let buttons = [
      { field: "acceptAll", defaultText: "Ok for me", variant: "outlined" },
      {
        field: "managePreferences",
        defaultText: "I want to choose",
        variant: "outlined",
      },
      {
        field: "acceptNecessary",
        defaultText: "No Thanks",
        variant: "outlined",
      },
    ];
    const isDarkTheme = themeSettings.darkMode === "dark";

    let activeButtons = buttons.filter(
      (button) => bannerSettings[button.field]
    );
    if (bannerSettings.swapButtons) {
      activeButtons = activeButtons.reverse();
    }

    const isStacked = bannerSettings.buttonPlacement === "Stacked";
    const isBarOrCloud = ["Bar", "Cloud"].includes(bannerSettings.layout);

    // Create styled wrappers for different button types
    const ButtonWrapper = styled(Box)({
      "& .MuiButton-root": {
        border: `2px solid ${isDarkTheme
            ? themeSettings.primaryButtonBg || "#FFFFFF"
            : themeSettings.primaryButtonBg || "#FFFFFF"
          } !important`,
        backgroundColor: `${isDarkTheme ? "transparent" : "transparent"
          } !important`,
        borderRadius: `${themeSettings.buttonBorderRadius || "0.4"
          }rem !important`,
        color: `${isDarkTheme
            ? themeSettings.primaryButtonText || "#FFFFFF"
            : themeSettings.primaryButtonText || "#FFFFFF"
          } !important`,
        width: "100% !important",
        minWidth: "120px !important",
        fontSize: "0.85rem !important",
        fontWeight: "bold !important",
        "&:hover": {
          backgroundColor: `${isDarkTheme
              ? themeSettings.primaryButtonBg || "rgba(255, 255, 255, 0.1)"
              : themeSettings.primaryButtonBg || "#00B894"
            } !important`,
          color: `${themeSettings.primaryButtonText || "#FFFFFF"} !important`,
          opacity: "0.9 !important",
        },
      },
    });

    return (
      <Box
        sx={{
          display: "flex",
          // background: themeSettings.darkModePrimaryButtonBg || "#FFFFFF",
          background: isDarkTheme ? "#000000" : "transparent",
          color: isDarkTheme ? "#FFFFFF" : "#000000", // Text color based on theme
          flexDirection: isStacked ? "column" : "row",
          gap: 1,
          ...(isBarOrCloud &&
          {
            // mx: 4,
            // my: 4,
          }),
        }}
      >
        {activeButtons.map((button, index) => (
          <ButtonWrapper
            key={index}
            sx={{
              flex: isStacked ? "1" : "initial",
            }}
          >
            <ButtonCommon
              onClick={() => {
                if (button.field === "managePreferences") {
                  onClose();
                  onManagePreferences();
                } else {
                  onClose();
                }
              }}
            >
              {bannerSettings[`${button.field}Text`] || button.defaultText}
            </ButtonCommon>
          </ButtonWrapper>
        ))}
      </Box>
    );
  };

  // const RichTextDisplay = ({ content }) => {
  //   return (
  //     <Box
  //       dangerouslySetInnerHTML={{ __html: content }}
  //       sx={{
  //         '& p': {
  //           margin: 0,
  //           padding: 0
  //         },
  //         '& strong': {
  //           fontWeight: 'bold'
  //         },
  //         '& em': {
  //           fontStyle: 'italic'
  //         },
  //         '& u': {
  //           textDecoration: 'underline'
  //         },
  //         '& a': {
  //           color: '#00B894',
  //           textDecoration: 'none',
  //           '&:hover': {
  //             textDecoration: 'underline'
  //           }
  //         }
  //       }}
  //     />
  //   );
  // };

  const RichTextDisplay = ({ content }) => {
    const handleLinkClick = (e) => {
      // Handle link clicks to open in new tab
      if (e.target.tagName.toLowerCase() === "a") {
        e.preventDefault();
        window.open(e.target.href, "_blank", "noopener,noreferrer");
      }
    };

    return (
      <Box
        onClick={handleLinkClick}
        dangerouslySetInnerHTML={{ __html: content }}
        sx={{
          "& p": {
            margin: 0,
            padding: 0,
          },
          "& strong": {
            fontWeight: "bold",
          },
          "& em": {
            fontStyle: "italic",
          },
          "& u": {
            textDecoration: "underline",
          },
          "& a": {
            color: "#00B894",
            textDecoration: "none",
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          },
        }}
      />
    );
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          ...getBannerPosition(),
          ...getLayoutStyles(),
        }}
      >
        {["Bar", "Cloud"].includes(bannerSettings.layout) ? (
          // Bar and Cloud Layout
          <Box
            sx={{
              display: "flex",
              // alignItems: "flex-start",
              gap: 3,
              height: "100%",
              width: bannerSettings.layout === "Bar" ? "100%" : "100%",
              margin: bannerSettings.layout === "Bar" ? 0 : 0,
            }}
          >
            {/* Logo */}
            {/* <BannerPrefrenceLogo
              style={{
                height: "150px",
                width: "auto",
              }}
            /> */}

            {/* Content and Buttons Container */}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection:
                  bannerSettings.buttonPlacement === "Stacked" ||
                    bannerSettings.buttonPlacement === "Row"
                    ? "row"
                    : "column",
                gap: 2,
                height: "100%",
                alignItems: "center",
              }}
            >
              {/* Content */}
              <Box
                sx={{
                  flex:
                    bannerSettings.buttonPlacement === "Stacked"
                      ? "0 0 70%"
                      : 1,
                  width:
                    bannerSettings.buttonPlacement === "Stacked"
                      ? "70%"
                      : "100%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.1rem",
                    color: themeSettings.heading || "#000000",
                    mb: 1,
                  }}
                >
                  {bannerSettings.title || "We use cookies "}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.95rem",
                    lineHeight: "1.5",
                    color: themeSettings.text || "#555555",
                    mb: 2,
                  }}
                >
                  <RichTextDisplay content={bannerSettings.description} />
                </Typography>

                {/* Footer */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // gap: 1,
                    mt: 2,
                  }}
                >
                  {/* <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      color: themeSettings.darkmode === 'dark' 
                      ? '#FFFFFF' 
                      : (themeSettings.text || "#555555"),
                      fontSize: "0.8rem",
                    }}
                  >
                    Consents Powered by 
                  </Typography>
                <PowerbyLogoInBanner
                    style={{
                      height: "20px",
                      width: "auto",
                    }}
                  /> */}
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color:
                        themeSettings.darkmode === "dark"
                          ? "#FFFFFF"
                          : themeSettings.text || "#555555",
                      fontSize: "0.9rem",
                    }}
                  >
                    Consent Management Powered By{" "}
                    <a
                      href="https://thedataprivacy.cloud"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontWeight: "bold",
                        color:
                          themeSettings.darkmode === "dark"
                            ? "#FFFFFF"
                            : "#00B894",
                        fontSize: "0.9rem",
                        textDecoration: "none",
                      }}
                    >
                      Data Privacy Cloud
                    </a>
                  </Typography>
                </Box>
              </Box>

              {/* Buttons Container */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection:
                    bannerSettings.buttonPlacement === "Stacked"
                      ? "column"
                      : "row",
                  gap: 1,
                  justifyContent:
                    bannerSettings.buttonPlacement === "Stacked"
                      ? "flex-start"
                      : "center",
                  alignItems: "center",
                  flex:
                    bannerSettings.buttonPlacement === "Stacked"
                      ? "0 0 30%"
                      : "none",
                  alignSelf:
                    bannerSettings.buttonPlacement === "Stacked" ||
                      bannerSettings.buttonPlacement === "Inline"
                      ? "flex-start"
                      : "center",
                }}
              >
                {renderButtons()}
              </Box>
            </Box>
          </Box>
        ) : (
          // Box Layout
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {/* Header Section */}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}
            >
              <Box sx={{ flex: 1, mr: 2 }}>
                {/* <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                    color: "#00B894",
                    mb: 1,
                  }}
                >
                  Hi there!
                </Typography> */}
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    color: themeSettings.heading || "#000000",
                    mb: 1,
                  }}
                >
                  {bannerSettings.title || "We're the cookies!"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.95rem",
                    lineHeight: "1.5",
                    color: themeSettings.text || "#555555",
                  }}
                >
                  <RichTextDisplay content={bannerSettings.description} />
                </Typography>
              </Box>
              {/* <BannerPrefrenceLogo
                style={{
                  width: "150px",
                  height: "150px",
                }}
              /> */}
            </Box>
            {/* Powered By Logo */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  color:
                    themeSettings.darkmode === "dark"
                      ? "#FFFFFF"
                      : themeSettings.text || "#555555",
                  fontSize: "1.0rem",
                }}
              >
                Consent Management Powered By{" "}
                <a
                  href="https://thedataprivacy.cloud"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: "bold",
                    color:
                      themeSettings.darkmode === "dark" ? "#FFFFFF" : "#00B894",
                    fontSize: "1.0rem",
                    textDecoration: "none",
                  }}
                >
                  Data Privacy Cloud
                </a>
              </Typography>
            </Box>

            {/* Buttons */}
            {renderButtons()}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default PreviewModal;
