import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  Tabs,
  Tab,
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  InputAdornment,
  CircularProgress,
} from "@mui/material";

const ThemeSettings = ({ data, onUpdate, loading }) => {
  const [formData, setFormData] = useState({
    darkMode: data?.darkMode || "light",
    // Basics Colors
    background: data?.background || "#FFFFFF",
    heading: data?.heading || "#000000",
    text: data?.text || "#333333",
    link: data?.link || "#FFFFFF",
    primaryButtonBg: data?.primaryButtonBg || "#717171",
    primaryButtonText: data?.primaryButtonText || "#333333",
    secondaryButtonBg: data?.secondaryButtonBg || "#717171",
    secondaryButtonText: data?.secondaryButtonText || "#FFFFFF",
    // Advanced: Dark Mode Colors
    darkModeBackground: data?.darkModeBackground || "#FFFFFF",
    darkModeHeading: data?.darkModeHeading || "#333333",
    darkModeText: data?.darkModeText || "#717171",
    darkModeLink: data?.darkModeLink || "#FFFFFF",
    darkModePrimaryButtonBg: data?.darkModePrimaryButtonBg || "#FFFFFF",
    darkModePrimaryButtonText: data?.darkModePrimaryButtonText || "#333333",
    darkModeSecondaryButtonBg: data?.darkModeSecondaryButtonBg || "#717171",
    darkModeSecondaryButtonText: data?.darkModeSecondaryButtonText || "#FFFFFF",
    // Border Radius
    modalBorderRadius: data?.modalBorderRadius || "0.5rem",
    buttonBorderRadius: data?.buttonBorderRadius || "0.4rem",
    // Logos
    logoUrl: data?.logoUrl || "",
    darkModeLogoUrl: data?.darkModeLogoUrl || "",
  });

  useEffect(() => {
    if (data) {
      setFormData({
        darkMode: data.darkMode || "light",
        // Basics Colors
        background: data.background || "#FFFFFF",
        heading: data.heading || "#000000",
        text: data.text || "#333333",
        link: data.link || "#FFFFFF",
        primaryButtonBg: data.primaryButtonBg || "#00B894",
        primaryButtonText: data.primaryButtonText || "#ffffff",
        secondaryButtonBg: data.secondaryButtonBg || "#717171",
        secondaryButtonText: data.secondaryButtonText || "#FFFFFF",
        // Advanced: Dark Mode Colors
        darkModeBackground: data.darkModeBackground || "#FFFFFF",
        darkModeHeading: data.darkModeHeading || "#333333",
        darkModeText: data.darkModeText || "#717171",
        darkModeLink: data.darkModeLink || "#FFFFFF",
        darkModePrimaryButtonBg: data.darkModePrimaryButtonBg || "#FFFFFF",
        darkModePrimaryButtonText: data.darkModePrimaryButtonText || "#333333",
        darkModeSecondaryButtonBg: data.darkModeSecondaryButtonBg || "#717171",
        darkModeSecondaryButtonText:
          data.darkModeSecondaryButtonText || "#FFFFFF",
        // Border Radius
        modalBorderRadius: data.modalBorderRadius || "0.5rem",
        buttonBorderRadius: data.buttonBorderRadius || "0.4rem",
        // Logos
        logoUrl: data.logoUrl || "",
        darkModeLogoUrl: data.darkModeLogoUrl || "",
      });
    }
  }, [data]);

  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);

  const handleColorPickerOpen = (colorKey) => {
    setSelectedColor(colorKey);
    setColorPickerOpen(true);
  };

  const handleColorPickerClose = () => {
    setColorPickerOpen(false);
    setSelectedColor(null);
  };
  const [activeTab, setActiveTab] = useState("basic");
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
 
  const handleInputChange = (field, value) => {
    const newFormData = {
      ...formData,
      [field]: value,
    };
    // If it's a radius field
    if (field.includes("BorderRadius")) {
      // Ensure the value is a valid number
      const numValue = parseFloat(value);
      if (!isNaN(numValue)) {
        // setFormData((prev) => ({
        //   ...prev,
        //   [field]: value, // Store just the number, 'rem' is added in display
        // }));
        // onUpdate({
        //   ...formData,
        //   [field]: value,
        // });
        setFormData(newFormData);
        onUpdate(newFormData);
      }
    } else {
      //   Handle other inputs normally
      // setFormData((prev) => ({
      //   ...prev,
      //   [field]: value,
      // }));
      // onUpdate({
      //   ...data,
      //   [field]: value,
      // });
      setFormData(newFormData);
      onUpdate(newFormData);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        // width: "800px",
        // minWidth: "800px",
        // maxWidth: "800px",
        flexShrink: 0,
      }}
    >
      {/* Dark Mode Section */}
      <Box>
        <Typography
          variant="h6"
          mb={1.5}
          sx={{
            color: "#525455",
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          Dark Mode
        </Typography>
        <Typography
          variant="body2"
          mb={2}
          sx={{
            color: "#6B7280",
            fontSize: "14px",
          }}
        >
          Customize the display mode for your consent banner.
        </Typography>
        <Card
          variant="outlined"
          sx={{
            bgcolor: "#F9FAFB",
            border: "1px solid #E5E7EB",
            borderRadius: "8px",
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 2,
                width: "100%",
              }}
            >
              {["auto", "light", "dark"].map((mode) => (
                <Button
                  key={mode}
                  onClick={() => handleInputChange("darkMode", mode)}
                  sx={{
                    height: "70px",
                    fontSize: "16px",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    borderRadius: "8px",
                    border: "1px solid",
                    borderColor:
                      formData.darkMode === mode ? "#00B894" : "#E5E7EB",
                    backgroundColor:
                      formData.darkMode === mode ? "#F0FDF9" : "#FFFFFF",
                    color: formData.darkMode === mode ? "#00B894" : "#525455",
                    "&:hover": {
                      backgroundColor:
                        formData.darkMode === mode ? "#F0FDF9" : "#F9FAFB",
                      borderColor:
                        formData.darkMode === mode ? "#00B894" : "#B0B9C5",
                    },
                  }}
                >
                  {mode.charAt(0).toUpperCase() + mode.slice(1)}
                </Button>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* Colours Section */}
      <Box mt={3}>
        <Typography
          variant="h6"
          mb={1.5}
          sx={{
            color: "#525455",
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          Colours
        </Typography>
        <Typography
          variant="body2"
          mb={2}
          sx={{
            color: "#6B7280",
            fontSize: "14px",
          }}
        >
          Customize the button styles of your cookie consent banner.
        </Typography>

        <Card
          variant="outlined"
          sx={{
            bgcolor: "#F9FAFB",
            border: "1px solid #E5E7EB",
            borderRadius: "8px",
          }}
        >
          <CardContent sx={{ p: 3 }}>
            {/* Tab Navigation */}
            <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB", mb: 3 }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="banner settings tabs"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#00B894",
                  },
                }}
              >
                <Tab
                  label="Basic"
                  value="basic"
                  sx={{
                    textTransform: "none",
                    fontWeight: 600,
                    color: "#525455",
                    "&.Mui-selected": {
                      color: "#00B894",
                    },
                  }}
                />
                <Tab
                  label="Advanced"
                  value="advanced"
                  sx={{
                    textTransform: "none",
                    fontWeight: 600,
                    color: "#525455",
                    "&.Mui-selected": {
                      color: "#00B894",
                    },
                  }}
                />
              </Tabs>
            </Box>

            {/* Color Fields */}
            {activeTab === "basic" && (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {
                  // Basic Colors
                  [
                    {
                      label: "Button Border & Selection Background",
                      key: "primaryButtonBg",
                      defaultColor: "#FFFFFF",
                    },
                    {
                      label: "Button Text",
                      key: "primaryButtonText",
                      defaultColor: "#333333",
                    },
                    // {
                    //   label: "Dark Mode: Button Background",
                    //   key: "darkModePrimaryButtonBg",
                    //   defaultColor: "#FFFFFF",
                    // },
                    // {
                    //   label: "Dark Mode: Button Text",
                    //   key: "darkModePrimaryButtonText",
                    //   defaultColor: "#333333",
                    // },
                  ].map((color) => (
                    <Box
                      key={color.key}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        py: 2,
                        borderBottom: "1px solid #E5E7EB",
                        "&:last-child": {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#525455",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {color.label}
                      </Typography>
                      <Box sx={{ position: "relative", width: "200px" }}>
                        <TextField
                          fullWidth
                          value={formData[color.key] || color.defaultColor}
                          onChange={(e) =>
                            handleInputChange(color.key, e.target.value)
                          }
                          onClick={() => handleColorPickerOpen(color.key)}
                          InputProps={{
                            startAdornment: (
                              <Box
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  backgroundColor:
                                    formData[color.key] || color.defaultColor,
                                  border: "1px solid #E5E7EB",
                                  mr: 1,
                                }}
                              />
                            ),
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "40px",
                              backgroundColor: "#FFFFFF",
                              "& fieldset": {
                                borderColor: "#E5E7EB",
                              },
                              "&:hover fieldset": {
                                borderColor: "#B0B9C5",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#00B894",
                              },
                            },
                            "& input": {
                              pl: 0,
                              fontSize: "14px",
                              color: "#525455",
                            },
                          }}
                        />
                        <input
                          type="color"
                          value={formData[color.key] || color.defaultColor}
                          onChange={(e) =>
                            handleInputChange(color.key, e.target.value)
                          }
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            )}
            {activeTab === "advanced" && (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {
                  // Advanced Colors
                  [
                    { label: "Background", key: "background" },
                    { label: "Heading", key: "heading" },
                    { label: "Text", key: "text" },
                    // { label: "Link", key: "link" },
                    {
                      label: "Button Background",
                      key: "primaryButtonBg",
                    },
                    { label: "Button Text", key: "primaryButtonText" },
                    // {
                    //   label: "Dark Mode: Background",
                    //   key: "darkModeBackground",
                    // },
                    // { label: "Dark Mode: Heading", key: "darkModeHeading" },
                    // { label: "Dark Mode: Text", key: "darkModeText" },
                    // { label: "Dark Mode: Link", key: "darkModeLink" },
                    // {
                    //   label: "Dark Mode: Button Background",
                    //   key: "darkModePrimaryButtonBg",
                    // },
                    // {
                    //   label: "Dark Mode: Button Text",
                    //   key: "darkModePrimaryButtonText",
                    // },
                  ].map((color) => (
                    <Box
                      key={color.key}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        py: 2,
                        borderBottom: "1px solid #E5E7EB",
                        "&:last-child": {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#525455",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {color.label}
                      </Typography>
                      <Box sx={{ position: "relative", width: "200px" }}>
                        <TextField
                          fullWidth
                          value={formData[color.key] || color.defaultColor}
                          onChange={(e) =>
                            handleInputChange(color.key, e.target.value)
                          }
                          onClick={() => handleColorPickerOpen(color.key)}
                          InputProps={{
                            startAdornment: (
                              <Box
                                sx={{
                                  width: "25px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  backgroundColor:
                                    formData[color.key] || color.defaultColor,
                                  border: "1px solid #E5E7EB",
                                  mr: 1,
                                }}
                              />
                            ),
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "40px",
                              backgroundColor: "#FFFFFF",
                              "& fieldset": {
                                borderColor: "#E5E7EB",
                              },
                              "&:hover fieldset": {
                                borderColor: "#B0B9C5",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#00B894",
                              },
                            },
                            "& input": {
                              pl: 0,
                              fontSize: "14px",
                              color: "#525455",
                            },
                          }}
                        />
                        <input
                          type="color"
                          value={formData[color.key] || color.defaultColor}
                          onChange={(e) =>
                            handleInputChange(color.key, e.target.value)
                          }
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            )}
          </CardContent>
        </Card>

        {/* Color Picker Dialog */}
        <Dialog
          open={colorPickerOpen}
          onClose={handleColorPickerClose}
          PaperProps={{
            sx: {
              borderRadius: "8px",
              width: "300px",
            },
          }}
        >
          <DialogTitle
            sx={{
              borderBottom: "1px solid #E5E7EB",
              p: 2,
            }}
          >
            Choose Color
          </DialogTitle>
          <DialogContent sx={{ p: 2 }}>
            <TextField
              type="color"
              fullWidth
              value={formData[selectedColor] || "#FFFFFF"}
              onChange={(e) => handleInputChange(selectedColor, e.target.value)}
              sx={{
                "& input": {
                  height: "150px",
                  padding: "0",
                  borderRadius: "4px",
                  border: "1px solid #E5E7EB",
                },
              }}
            />
          </DialogContent>
        </Dialog>
      </Box>

      {/* Border Radius Section */}
      <Box mt={3}>
        <Typography
          variant="h6"
          mb={1.5}
          sx={{
            color: "#525455",
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          Border Radius
        </Typography>
        <Typography
          variant="body2"
          mb={2}
          sx={{
            color: "#6B7280",
            fontSize: "14px",
          }}
        >
          Customize the border radius of your cookie consent banner.
        </Typography>
        <Card
          variant="outlined"
          sx={{
            bgcolor: "#F9FAFB",
            border: "1px solid #E5E7EB",
            borderRadius: "8px",
          }}
        >
          <CardContent sx={{ p: 3 }}>
            {[
              {
                label: "Modal Border Radius",
                key: "modalBorderRadius",
                defaultValue: "0.5",
              },
              {
                label: "Button Border Radius",
                key: "buttonBorderRadius",
                defaultValue: "0.4",
              },
            ].map((radius, index, array) => (
              <Box key={radius.key}>
                <Box mb={3}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      color: "#111827",
                      mb: 1,
                      fontSize: "14px",
                    }}
                  >
                    {radius.label}
                  </Typography>
                  <TextField
                    fullWidth
                    value={formData[radius.key] || radius.defaultValue}
                    onChange={(e) => {
                      // Allow numbers, single decimal point, and leading zeros
                      const value = e.target.value.replace(/[^0-9.]/g, "");
                      // Ensure only one decimal point
                      const parts = value.split(".");
                      const formattedValue =
                        parts.length > 2
                          ? `${parts[0]}.${parts.slice(1).join("")}`
                          : value;
                      handleInputChange(radius.key, formattedValue);
                    }}
                    placeholder="0.5"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">rem</InputAdornment>
                      ),
                      inputProps: {
                        step: "0.1",
                        min: "0",
                        max: "5",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "#FFFFFF",
                        "& fieldset": {
                          borderColor: "#E9ECEF",
                          borderRadius: "8px",
                        },
                        "&:hover fieldset": {
                          borderColor: "#DEE2E6",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#00B894",
                        },
                      },
                    }}
                  />
                </Box>
                {index < array.length - 1 && (
                  <Divider
                    sx={{
                      my: 2,
                      borderColor: "#E5E7EB",
                    }}
                  />
                )}
              </Box>
            ))}
            <Typography sx={{ color: '#5F5F5F80' }}>
              Add any valid CSS unit (e.g., 0.5rem, 1.5em, 10%) with up to 4 decimal places.
            </Typography>
          </CardContent>
        </Card>
      </Box>

      {/* Logo Section */}
      {/* <Box mt={3}>
        <Typography
          variant="h6"
          mb={1.5}
          sx={{
            color: "#525455",
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          Logos
        </Typography>
        <Card
          variant="outlined"
          sx={{
            bgcolor: "#F9FAFB",
            border: "1px solid #E5E7EB",
            borderRadius: "8px",
          }}
        >
          <CardContent sx={{ p: 3 }}>
            {[
              {
                label: "Logo URL",
                key: "logoUrl",
                placeholder: "Enter logo URL",
              },
              {
                label: "Dark Mode Logo URL",
                key: "darkModeLogoUrl",
                placeholder: "Enter dark mode logo URL",
              },
            ].map((logo) => (
              <Box key={logo.key} mb={3}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    color: "#111827",
                    mb: 1,
                    fontSize: "14px",
                  }}
                >
                  {logo.label}
                </Typography>
                <TextField
                  fullWidth
                  placeholder={logo.placeholder}
                  value={formData[logo.key] || ""}
                  onChange={(e) => handleInputChange(logo.key, e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#FFFFFF",
                      "& fieldset": {
                        borderColor: "#E9ECEF",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#DEE2E6",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00B894",
                      },
                    },
                  }}
                />
              </Box>
            ))}
          </CardContent>
        </Card>
      </Box> */}
    </Box>
  );
};

export default ThemeSettings;
