import apiClient from "./apiClient";

export const getAllServices = async () => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get("/services", {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getServiceById = async (serviceId) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get(`/services/${serviceId}`, {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createService = async (payload) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.post("/services", payload, {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateService = async (serviceId, serviceData) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.put(
      `/services/${serviceId}`,
      serviceData,
      {
        headers: {
          website_id: websiteId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteService = async (serviceId) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.delete(`/services/${serviceId}`, {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addStorageItem = async (payload, serviceId) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.post("/services/storage_items", payload, {
      headers: {
        service_id: serviceId,
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateStorageItem = async (payload, serviceId) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.put(`/services/${serviceId}`, payload, {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteStorageItem = async (name, serviceId) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const result = await apiClient.delete(`/services/storage_items/${name}`, {
      headers: {
        service_id: serviceId,
        website_id: websiteId,
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const addService = async (payload) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.post("/services/add_service", payload, {
      headers: {
        website_id: websiteId,
      },
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};
