import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import CustomBreadcrumb from "../CommonComponent/CommonBreadcrumb";
import CommonTabs from "../CommonComponent/CommonTabs";
import CommonHeader from "../CommonComponent/CommonHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDataRequest, getPresignedUrl } from "../api/dataRequest";
import { showErrorToast } from "../toastUtils";
import { getUserData } from "../api/user";
import Loader from "../CommonComponent/Loader";
import { getWebsiteById } from "../api/websiteService";
import { getFormattedTimestamp } from "../utils/dateUtils";
import { fetchUserAttributes } from "@aws-amplify/auth";
import { FileDownload } from "@mui/icons-material";

const RequestLog = ({
  status,
  createdOn,
  closedOn,
  closedBy,
  comment,
  attachment,
}) => {
  const handleDownload = async (fileName) => {
    try {
      const response = await getPresignedUrl(fileName);
      if (response.status_code === 200 && response.success) {
        const presignedUrl = response.data.url;
        const fileResponse = await fetch(presignedUrl);
        if (!fileResponse.ok) {
          throw new Error("Failed to download the file");
        }
        const blob = await fileResponse.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName; // Use the file name provided by the API
        a.click();
        window.URL.revokeObjectURL(url); // Clean up the URL object
      } else {
        throw new Error("Failed to get presigned URL");
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <Box sx={{ mb: 3 }}>
      {status?.new && (
        <Box sx={{ p: 2, border: "1px solid #ddd", borderRadius: 2, mb: 2 }}>
          <Typography variant="h6" fontWeight={700} color="#525455">
            New Request
          </Typography>
          <Typography variant="body" color="text.secondary" sx={{ mt: 1 }}>
            <strong>Created On:</strong> {createdOn}
          </Typography>
        </Box>
      )}

      {status?.closed && (
        <Box
          sx={{
            p: 2,
            border: "1px solid #ddd",
            borderRadius: 2,
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Typography variant="h6" fontWeight={700} color="#525455">
            Closed Request
          </Typography>
          <Typography variant="body" color="text.secondary" sx={{ mt: 1 }}>
            <strong>Closed On:</strong> {closedOn}
          </Typography>
          <Typography variant="body" color="text.secondary" sx={{ mt: 1 }}>
            <strong>Closed By:</strong> {closedBy}
          </Typography>
          <Typography variant="body" color="text.secondary" sx={{ mt: 1 }}>
            <strong>Attachment:</strong>{" "}
            <Box
              sx={{
                display: "inline-flex", // Aligns both the text and icon on the same line
                alignItems: "center", // Ensures that the text and icon are vertically aligned
                gap: "8px", // Space between file name and download icon
              }}
            >
              <Typography
                color="primary"
                fontWeight="bold"
                textTransform="lowercase"
              >
                {attachment}
              </Typography>
              <FileDownload
                fontSize="small"
                sx={{ cursor: "pointer" }} // Makes the icon clickable
                onClick={() => handleDownload(attachment)}
              />
            </Box>
          </Typography>
          <Typography variant="body" color="text.secondary" sx={{ mt: 1 }}>
            <strong>Comment:</strong> {comment !== "" ? comment : "--"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const DataRequestDetails = () => {
  const [consentInfo, setConsentInfo] = useState({});
  const [timezone, setTimezone] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const { id } = useParams();
  const [currentUser, setCurrentUser] = useState(null);

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const response = await getDataRequest(id);
      setConsentInfo(response.data || {});
      setLoading(false);
    } catch (err) {
      showErrorToast(err);
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const res = await getUserData();
      if (res?.status_code === 200) {
        setUserData(res?.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getWebsiteDetails = async () => {
    try {
      const websiteId = localStorage.getItem("website_id");
      const websiteData = await getWebsiteById(websiteId);
      const timezoneFromApi = websiteData?.data?.time_zone;
      setTimezone(timezoneFromApi); // Save the timezone from the website details
    } catch (err) {
      throw err;
    }
  };

  const fetchCurrentUser = async () => {
    const data = await fetchUserAttributes();
    setCurrentUser(data);
  };

  useEffect(() => {
    fetchData();
    fetchUserData();
    getWebsiteDetails();
    fetchCurrentUser();
    //eslint-disable-next-line
  }, []);

  const breadcrumbItems = [
    {
      text: "Data Requests",
      href: "/data-requests",
    },
    {
      text: "Data Request Details",
    },
  ];

  const tabs = [
    {
      label: "Data Request Logs",
      value: "1",
      content: (
        <Box>
          <RequestLog
            status={consentInfo?.status} // Dynamically pass the status
            createdOn={
              getFormattedTimestamp(
                consentInfo?.status?.new?.created_on,
                timezone
              ) || ""
            }
            closedOn={
              getFormattedTimestamp(
                consentInfo?.status?.closed?.closed_on,
                timezone
              ) || ""
            }
            closedBy={
              currentUser?.zoneinfo === "Billing_Admin"
                ? userData.find(
                    (user) =>
                      user.user_id === consentInfo?.status?.closed?.close_by
                  )?.full_name ||
                  "Unknown" ||
                  ""
                : consentInfo?.status?.closed?.close_user || ""
            }
            attachment={consentInfo?.status?.closed?.attachment || ""}
            comment={consentInfo?.status?.closed?.comments || ""}
          />
        </Box>
      ),
    },
  ];

  const assigneeName =
    currentUser?.zoneinfo === "Billing_Admin"
      ? userData.find((user) => user.user_id === consentInfo.assignee)
          ?.full_name || "Unknown User"
      : consentInfo.assignee_name;

  // Determine status message based on status object
  const statusMessage = consentInfo?.status?.closed ? "Closed" : "New";

  return (
    <Box>
      <Box sx={{ display: "flex", mb: 3 }}>
        <CommonHeader
          title="Data Request Details"
          subTitle="You can review the data request details and status update on this page."
        />
      </Box>

      <Box sx={{ mb: 4 }}>
        <CustomBreadcrumb
          items={breadcrumbItems}
          onBackClick={() => navigate("/data-requests")}
        />
      </Box>

      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3, backgroundColor: "#F9FAFB", height: "100%" }}>
              <Typography
                variant="h6"
                gutterBottom
                color="#525455"
                fontWeight="bold"
              >
                Data Request Information
              </Typography>
              <Divider />
              {consentInfo && (
                <>
                  {Object.entries(consentInfo)
                    .filter(([key]) => key !== "assignee_name") // Exclude assignee_name
                    .map(([key, value], index, array) => (
                      <Box key={key} sx={{ mb: 1, mt: 2 }}>
                        <Typography
                          variant="body"
                          color="text.secondary"
                          sx={{ textTransform: "capitalize", display: "block" }}
                        >
                          {key
                            .replace(/_/g, " ")
                            .replace(/([A-Z])/g, " $1")
                            .toLowerCase()}
                        </Typography>
                        <Typography
                          variant="body2"
                          flexWrap="wrap"
                          sx={{
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                            whiteSpace: "normal",
                          }}
                        >
                          {key === "status"
                            ? statusMessage
                            : key === "assignee"
                            ? assigneeName
                            : value !== ""
                            ? value
                            : "--"}
                        </Typography>
                        {!(index + 1 === array.length) && (
                          <Divider sx={{ mt: 2 }} />
                        )}
                      </Box>
                    ))}
                </>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} md={8}>
            <CommonTabs value={"1"} tabs={tabs} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DataRequestDetails;
