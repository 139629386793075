import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { Typography, Box } from "@mui/material";
import {
  Error,
  // ForwardToInbox,
  CheckCircle,
  FileDownload,
} from "@mui/icons-material";
import { timelineItemClasses } from "@mui/lab";
import { getFormattedTimestamp } from "../../utils/dateUtils";
import { getBreachPresignedUrl } from "../../api/dataBreach";

// Convert status object to array format for mapping
const transformStatusData = (statusData) => {
  return Object.entries(statusData).map(([key, value]) => ({
    type: key,
    timestamp: value.updated_at || value.created_on || value.closed_on, // Use available timestamp
    comment: value.comment || "",
    final_response_to_db: value.final_response_to_db || "",
    initial_response_to_db: value.initial_response_to_db || "",
    closed_by: value.closed_by || null,
    close_user: value.close_user || null,
  }));
};

// Status Labels
const getStatusLabel = (type) => {
  const labels = {
    new: "Breach Created",
    // dp_informed: "DP Informed",
    // dpb_informed: "DPB Informed",
    // details_sent_to_dpb: "Details sent to DPB",
    closed: "Breach Closed",
  };
  return labels[type] || "Unknown Status";
};

// Status Colors
const getDotColor = (type) => {
  const colors = {
    new: "#d32f2f",
    // dp_informed: "#ff9800",
    // dpb_informed: "#673ab7",
    // details_sent_to_dpb: "#2196f3",
    closed: "#4caf50",
  };
  return colors[type] || "#9e9e9e";
};

// Status Icons
const getIcon = (type) => {
  const icons = {
    new: <Error />,
    // dp_informed: <ForwardToInbox />,
    // dpb_informed: <ForwardToInbox />,
    // details_sent_to_dpb: <ForwardToInbox />,
    closed: <CheckCircle />,
  };
  return icons[type] || null;
};

const BreachStatusTimeline = ({ status, time_zone, attachment }) => {
  const breachStatuses = transformStatusData(status);

  const handleDownload = async (fileName) => {
    try {
      const response = await getBreachPresignedUrl(fileName);
      if (response.status_code === 200 && response.success) {
        const presignedUrl = response.data.url;

        // Fetch the file from the presigned URL
        const fileResponse = await fetch(presignedUrl);
        if (!fileResponse.ok) {
          throw new Error("Failed to download the evidence file");
        }

        // Convert response to a Blob and create a download link
        const blob = await fileResponse.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName; // Set file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up after download
        window.URL.revokeObjectURL(url); // Revoke the URL object
      } else {
        throw new Error("Failed to get presigned URL for evidence file");
      }
    } catch (error) { }
  };

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        padding: 0,
        margin: 0,
      }}
    >
      {breachStatuses.map((event, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot sx={{ bgcolor: getDotColor(event.type) }}>
              {getIcon(event.type)}
            </TimelineDot>
            {index !== breachStatuses.length - 1 && (
              <TimelineConnector sx={{ height: 40 }} />
            )}
          </TimelineSeparator>

          <TimelineContent sx={{ py: 2, px: 2 }}>
            {/* Status and Timestamp in One Line */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {getStatusLabel(event.type)}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {getFormattedTimestamp(event.timestamp, time_zone)}
              </Typography>
            </Box>

            {/* Comment Section */}
            {event.type === "new" && (
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontWeight="bold"
                  sx={{ mr: 2 }}
                >
                  {attachment || "No attachment available"}
                </Typography>
                {attachment && <FileDownload
                  fontSize="small"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleDownload(attachment)}
                />}
              </Box>
            )}
            {event.type !== "new" && (
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                Comment: {event.comment || "No comment available"}
              </Typography>
            )}

            {/* Extra Details for Closed Status */}
            {event.type === "closed" && (
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                Closed by: {event?.close_user || "Unknown"}
              </Typography>
            )}

            {/* Evidence Section */}
            {event.initial_response_to_db && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mr: 1 }}
                >
                  Initial response to DB:
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontWeight="bold"
                  sx={{ mr: 2 }}
                >
                  {event.initial_response_to_db}
                </Typography>
                <FileDownload
                  fontSize="small"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleDownload(event.initial_response_to_db)}
                />
              </Box>
            )}
            {event.final_response_to_db && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mr: 1 }}
                >
                  Final response to DB:
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontWeight="bold"
                  sx={{ mr: 2 }}
                >
                  {event.final_response_to_db}
                </Typography>
                <FileDownload
                  fontSize="small"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleDownload(event.final_response_to_db)}
                />
              </Box>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default BreachStatusTimeline;
