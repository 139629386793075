import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Link,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import myIcon from "../assets/svg/logo.svg";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutline } from "@mui/icons-material";
import { resetPassword, confirmResetPassword } from "@aws-amplify/auth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { showErrorToast } from "../toastUtils";

const ForgotPassword = () => {
  const [step, setStep] = React.useState("email");
  const [formData, setFormData] = React.useState({
    email: "",
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const navigate = useNavigate();

  const validateField = (name, value) => {
    switch (name) {
      case "email":
        if (!value) {
          return "Email is required";
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          return "Invalid email address";
        }
        return "";
      case "otp":
        if (!value) {
          return "OTP is required";
        }
        if (value.length !== 6) {
          return "OTP must be 6 digits";
        }
        return "";
      case "newPassword":
        if (!value) {
          return "New password is required";
        }
        if (value.length < 8) {
          return "Password must be at least 8 characters long";
        }
        if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/.test(value)) {
          return "Password must include lowercase, uppercase, number, and special character";
        }
        return "";
      case "confirmPassword":
        if (!value) {
          return "Confirm password is required";
        }
        if (value !== formData.newPassword) {
          return "Passwords do not match";
        }
        return "";
      default:
        return "";
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (touched[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: validateField(name, value),
      }));
    }
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: validateField(name, value),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;
    const newErrors = {};

    if (step === "email") {
      newErrors.email = validateField("email", formData.email);
      if (newErrors.email) valid = false;
      try {
        await resetPassword({ username: formData.email });
      } catch (err) {
        valid = false;
      }
    } else if (step === "otp") {
      newErrors.otp = validateField("otp", formData.otp);
      newErrors.newPassword = validateField(
        "newPassword",
        formData.newPassword
      );
      newErrors.confirmPassword = validateField(
        "confirmPassword",
        formData.confirmPassword
      );
      if (newErrors.newPassword || newErrors.confirmPassword) valid = false;
      const { email, otp, newPassword } = formData;
      try {
        await confirmResetPassword({
          username: email,
          confirmationCode: otp,
          newPassword,
        });
      } catch (err) {
        showErrorToast(err?.message);
        valid = false;
      }
    }

    setErrors(newErrors);
    if (valid) {
      if (step === "email") {
        setStep("otp");
      } else {
        setStep("done");
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <Container
      component="main"
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F8F8F8",
        padding: 3,
      }}
    >
      {/* Logo at the top */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 4,
        }}
      >
        <img src={myIcon} alt="logo" width="250" height="50" />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 450,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Text above the form */}
          {step === "email" && (
            <>
              <Typography
                variant="h5"
                sx={{ mb: 1, color: "#5F5F5F", fontWeight: 600 }}
              >
                Forgot Your Password?
              </Typography>
              <Typography variant="body2" color="#868686">
                No worries, we will send you reset instructions.
              </Typography>
            </>
          )}
          {step === "otp" && (
            <>
              <Typography
                variant="h5"
                sx={{ mb: 1, color: "#5F5F5F", fontWeight: 600 }}
              >
                Password Reset
              </Typography>
              <Typography variant="body2" color="#868686">
                We send a code to{" "}
                <span style={{ fontWeight: "bold" }}>{formData.email} </span>
              </Typography>
            </>
          )}
          {step === "done" && (
            <>
              <CheckCircleOutline
                fontSize="large"
                sx={{ mt: 3, color: "#00B894" }}
              />
              <Typography
                variant="h5"
                sx={{ mb: 1, color: "#5F5F5F", fontWeight: 600 }}
              >
                All Done
              </Typography>
              <Typography variant="body2" color="#868686">
                Your password has been reset. Would you like to login?
              </Typography>
            </>
          )}

          {step !== "done" && (
            <Paper
              elevation={2}
              sx={{
                width: "90%",
                p: 4,
                borderRadius: "12px",
                backgroundColor: "white",
                border: "none",
                mt: 2,
              }}
              variant="outlined"
            >
              <Box component="form" onSubmit={handleSubmit}>
                {step === "email" && (
                  <>
                    <InputLabel shrink htmlFor="email" required>
                      Email
                    </InputLabel>
                    <TextField
                      margin="none"
                      size="small"
                      required
                      fullWidth
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={formData.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      sx={{ mb: 2 }}
                    />
                  </>
                )}
                {step === "otp" && (
                  <>
                    <InputLabel shrink htmlFor="otp" required>
                      OTP
                    </InputLabel>
                    <TextField
                      margin="none"
                      fullWidth
                      size="small"
                      name="otp"
                      required
                      type="text"
                      value={formData.otp || ""}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                        setFormData((prev) => {
                          return {
                            ...prev,
                            otp: value,
                          };
                        });
                      }}
                      id={`otp`}
                      error={touched.otp && !!errors.otp}
                      sx={{ mb: 2 }}
                    />
                    <InputLabel shrink htmlFor="newPassword" required>
                      New Password
                    </InputLabel>
                    <TextField
                      margin="none"
                      size="small"
                      required
                      fullWidth
                      name="newPassword"
                      type={showPassword ? "text" : "password"} // Toggle type between text and password
                      value={formData.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.newPassword && !!errors.newPassword}
                      helperText={touched.newPassword && errors.newPassword}
                      sx={{ mb: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <InputLabel shrink htmlFor="confirmPassword" required>
                      Confirm Password
                    </InputLabel>
                    <TextField
                      margin="none"
                      size="small"
                      required
                      fullWidth
                      name="confirmPassword"
                      type={showPassword ? "text" : "password"}
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.confirmPassword && !!errors.confirmPassword
                      }
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      sx={{ mb: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}

                {step !== "done" && (
                  <>
                    <Button
                      type="submit"
                      size="small"
                      fullWidth
                      variant="outlined"
                      sx={{
                        mt: 2,
                        mb: 2,
                        "&:hover": {
                          backgroundColor: "#009B7D",
                          color: "white",
                        },
                        borderRadius: 1.5,
                        border: "2px solid #009B7D",
                        textTransform: "none",
                        fontSize: "1rem",
                        color: "#525455",
                        fontWeight: 700,
                      }}
                    >
                      {step === "email" ? "Reset Password" : "Reset Password"}
                    </Button>
                  </>
                )}
              </Box>
            </Paper>
          )}
          {step === "done" && (
            <Button
              type="submit"
              size="small"
              variant="outlined"
              sx={{
                mt: 2,
                "&:hover": {
                  backgroundColor: "#009B7D",
                  color: "white",
                },
                borderRadius: 1.5,
                border: "2px solid #009B7D",
                textTransform: "none",
                fontSize: "1rem",
                color: "#525455",
                fontWeight: 700,
              }}
              onClick={() => navigate("/login")}
            >
              Login Now
            </Button>
          )}
          {step !== "done" && (
            <Typography variant="body" color="text.secondary" mt={2}>
              Or{" "}
              <Link
                href="/login"
                sx={{ color: "#5F5F5F" }}
                fontWeight="600"
                underline="none"
              >
                Back to Login
              </Link>
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
