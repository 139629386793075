import axios from "axios";
import { fetchAuthSession } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../services/authService";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request Interceptor: Attach token to every request
apiClient.interceptors.request.use(
  async (config) => {
    try {
      const organizationId = localStorage.getItem("organization_id_str");

      // Fetch the latest access token from Cognito
      const session = await fetchAuthSession();
      const accessToken = session.tokens?.accessToken?.toString();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      if (organizationId) {
        config.headers["organization_id"] = organizationId;
      }
    } catch (error) {
    }

    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const navigate = useNavigate();
      handleLogout(navigate);
    }
    return Promise.reject(error);
  }
);

export default apiClient;