// utils/dateUtils.js

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with required plugins
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Function to format the timestamp based on the selected timezone
 * @param {string|Date} timestamp - The timestamp to format (in UTC).
 * @param {string} selectedTimezone - The selected timezone (e.g., "Asia/Kolkata").
 * @returns {string} - The formatted timestamp (YYYY-MM-DD HH:mm).
 */
export const getFormattedTimestamp = (timestamp, selectedTimezone = "UTC") => {
  if (!selectedTimezone) {
    // If no timezone is provided, return in UTC by default
    return dayjs.utc(timestamp).format("YYYY-MM-DD HH:mm");
  }
  
  // Return the timestamp converted to the selected timezone
  return dayjs.utc(timestamp).tz(selectedTimezone).format("YYYY-MM-DD HH:mm");
};

/**
 * Function to convert an hour (e.g., "06") in UTC to the selected timezone
 * @param {string} hourString - The hour in UTC (e.g., "06").
 * @param {string} selectedTimezone - The selected timezone (e.g., "Asia/Kolkata").
 * @returns {string} - The converted hour in HH:mm format.
 */
export const convertHourToTimezone = (hourString, selectedTimezone = "UTC") => {
  // Assume the hour is in UTC and construct a full timestamp (YYYY-MM-DD HH:mm:ss)
  const utcTime = dayjs.utc().startOf('day').hour(parseInt(hourString, 10));

  // Convert to the selected timezone
  const convertedTime = utcTime.tz(selectedTimezone);

  // Return only the hour in HH:mm format
  return convertedTime.format("HH:mm");
};