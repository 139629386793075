import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Button,
  IconButton,
  FormHelperText,
  Chip,
  Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import WarningIcon from '@mui/icons-material/Warning';
import { createBanner, getAllFrameworks, getFrameworkLanguages } from '../../../api/bannerService';
import { showErrorToast, showSuccessToast } from '../../../toastUtils';
import { getAllLanguages } from '../../../api/websiteService';
import { useNavigate } from 'react-router-dom';

const BannerSetupModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [frameworks, setFrameworks] = useState([]);
  const [allLanguage, setAllLanguage] = useState(null);
  const [filteredSupportedLanguages, setFilteredSupportedLanguages] = useState(null);
  const [formData, setFormData] = useState({
    // bannerName: '',
    frameworkId: '',
    defaultLanguage: 'en',
    supportedLanguages: []
  });
  const [errors, setErrors] = useState({
    // bannerName: false,
    frameworkId: false,
    defaultLanguage: false
  });
  const [isMandatoryToTrans, setIsMandatoryToTrans] = useState(null)


  // Fetch frameworks on component mount
  useEffect(() => {
    const fetchFrameworks = async () => {
      try {
        setLoading(true);
        const response = await getAllFrameworks();
        // console.log(response, response.success && response.data);

        if (response.success && response.data) {
          setFrameworks(response?.data?.consent_data);
        }
      } catch (error) {
        console.error("Error fetching frameworks:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFrameworks();
  }, []);

  // Fetch languages when framework changes
  useEffect(() => {
    const fetchLanguages = async () => {
      if (!formData.frameworkId) return;

      try {
        setLoading(true);
        const response = await getFrameworkLanguages(formData.frameworkId);
        if (response.success && response.data) {
          // Set supported languages from the framework
          const supportedLanguages = response.data.supported_languages;
          // console.log('langgg', response, supportedLanguages, formData);

          // if (data.supportedLanguages?.length && allLanguage?.length) {
          const filteredLanguages = allLanguage.filter(lang =>
            supportedLanguages.includes(lang.language_code) // ✅ Proper filtering
          );
          setFilteredSupportedLanguages(filteredLanguages);

          // Set default language to the first available language if current is not supported
          // const defaultLanguage = supportedLanguages.includes(formData.defaultLanguage)
          //   ? formData.defaultLanguage
          //   : supportedLanguages[0] || 'en';


          setIsMandatoryToTrans(response.data.is_mandatory_to_trans)

          setFormData(prev => ({
            ...prev,
            supportedLanguages: supportedLanguages,
            // defaultLanguage
          }));
        }
      } catch (error) {
        console.error("Error fetching framework languages:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLanguages();
    //eslint-disable-next-line
  }, [formData.frameworkId, formData.defaultLanguage]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Clear error for the field
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: false
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {
      // bannerName: !formData.bannerName.trim(),
      frameworkId: !formData.frameworkId,
      defaultLanguage: !formData.defaultLanguage
    };

    setErrors(newErrors);

    // if (newErrors.bannerName) {
    //   showErrorToast("Please enter a banner name");
    // } else 
    if (newErrors.frameworkId) {
      showErrorToast("Please select a framework");
    }

    return !Object.values(newErrors).some(error => error);
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {

        setLoading(true);

        const payload = {
          framework_id: formData.frameworkId,
          language_code: formData.defaultLanguage,
        }

        const response = await Promise.race([
          createBanner(payload),
          new Promise((_, reject) => setTimeout(() => reject(new Error("Request timed out after 30 seconds")), 30000)),
        ]);
        // console.log('response createBanner', response);

        if (!response.success) {
          showErrorToast("Failed to create banner");
          return;
        }

        // Close the modal
        onClose();

        // Navigate to the banner creation page
        // navigate("/banner/create");
        navigate(`/banner/${response.data.banner_id}`);
        showSuccessToast("Banner created successfully!")
      } catch (err) {
        console.log(err);
        showErrorToast(err.message);
      }
      finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchAllLanguage = async () => {
      try {
        const response = await getAllLanguages()

        if (response.success && response.data) {
          setAllLanguage(response.data)
        }
      } catch (err) {
        console.log(err);
      }
    }

    fetchAllLanguage()
  }, [])

  // Get the selected framework details
  const selectedFramework = frameworks && frameworks?.find(f => f.framework_id === formData.frameworkId);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Create New Banner</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        <Box sx={{ mb: 3 }}>
          <Typography variant="body2" color="text.secondary" mb={2}>
            Please provide the basic information to get started with your banner configuration.
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* <TextField
            fullWidth
            label="Banner Name"
            name="bannerName"
            value={formData.bannerName}
            onChange={handleChange}
            error={errors.bannerName}
            helperText={errors.bannerName ? "Banner name is required" : ""}
            placeholder="Enter a descriptive name for your banner"
            required
          /> */}

          <FormControl fullWidth error={errors.frameworkId} required>
            <InputLabel id="framework-select-label">Framework</InputLabel>
            <Select
              labelId="framework-select-label"
              id="framework-select"
              name="frameworkId"
              value={formData.frameworkId}
              label="Framework"
              onChange={handleChange}
              required
            >
              {/* <MenuItem value="">
                <em>Select a framework</em>
              </MenuItem> */}
              {frameworks.map((framework) => (
                <MenuItem key={framework.framework_id} value={framework.framework_id}>
                  {framework.framework_name}
                </MenuItem>
              ))}
            </Select>
            {errors.frameworkId && <FormHelperText>Framework is required</FormHelperText>}
          </FormControl>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '5px', mb: 1 }}>
            {selectedFramework && (
              <Box>
                <Typography variant="body2" color="text.secondary" mb={0}>
                  {selectedFramework.framework_description}
                </Typography>
              </Box>
            )}

            {isMandatoryToTrans && (
              <Alert severity="warning" sx={{ mt: 1, p: 0, pl: 1 }}>
                {`As per ${selectedFramework.framework_name}, banner must be available in all required languages. Some translations are missing. Please complete translations for all the required languages. The banner will be displayed in English for missing translations.`}
              </Alert>
            )}
          </Box>


          {/* {formData.supportedLanguages.length > 0 && ( */}
          <FormControl fullWidth error={errors.defaultLanguage} required>
            <InputLabel id="language-select-label">Default Language</InputLabel>
            <Select
              labelId="language-select-label"
              id="language-select"
              name="defaultLanguage"
              value={formData.defaultLanguage}
              label="Default Language"
              onChange={handleChange}
            >
              {allLanguage && allLanguage.length > 0 && allLanguage.map((lang) => (
                <MenuItem key={lang.language_id} value={lang.language_code}>
                  {lang.language_description}
                </MenuItem>
              ))}
            </Select>
            {errors.defaultLanguage && <FormHelperText>Default Language is required</FormHelperText>}
            {formData.defaultLanguage !== 'en' && (
              <Alert severity="warning" sx={{ mt: 1, p: 0, pl: 1 }}>
                Please translate the banner content into your default language in banner setting.
              </Alert>
            )}
          </FormControl>
          {/* )} */}

          {/* {formData.supportedLanguages && formData.supportedLanguages.length > 0 && ( */}
          {filteredSupportedLanguages && filteredSupportedLanguages.length > 0 && (
            <Box>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Supported Languages:
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {/* {formData.supportedLanguages.map(lang => ( */}
                {filteredSupportedLanguages.map(lang => (
                  <Chip
                    key={lang.language_id}
                    label={lang.language_description}
                    // variant={lang === formData.defaultLanguage ? "filled" : "outlined"}
                    // color={lang === formData.defaultLanguage ? "primary" : "default"}
                    variant="outlined"
                    color="default"
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Button onClick={onClose} color="inherit">Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BannerSetupModal;