import React, { useState, useEffect } from "react";
import CustomSwitch from "../../../CommonComponent/CustomSwitch";
import {
  Box,
  TextField,
  Typography,
  Card,
  CardContent,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from "@mui/icons-material/Link"; // Add this import
import {
  RichTextEditor,
  MenuControlsContainer,
  MenuButtonBold,
  MenuButtonItalic,
  MenuButtonUnderline,
  MenuDivider,
} from "mui-tiptap";
import Link from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline"; // Add this import

// import LockIcon from '@mui/icons-material/Lock';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const BannerSettings = ({ data, onUpdate, loading }) => {
  const [formData, setFormData] = useState({
    title: data?.title || "We use cookies",
    description: data?.description || "We use cookies to personalize content, ads & to analyze our traffic. By clicking Accept All, you consent to cookies. Please feel free to update your preferences anytime in future.",
    footerContent: data?.footerContent || "Concent Management Powered By Data Privacy Cloud",
    layout: data?.layout || "Cloud",
    buttonPlacement: data?.buttonPlacement || "Inline",
    position: data?.position || "BottomCenter",
    equalWeight: data?.equalWeight ?? true,
    swapButtons: data?.swapButtons ?? false,
    acceptAll: data?.acceptAll ?? true,
    acceptNecessary: data?.acceptNecessary ?? true,
    managePreferences: data?.managePreferences ?? true,
    acceptAllText: data?.acceptAllText || "",
    acceptNecessaryText: data?.acceptNecessaryText || "",
    managePreferencesText: data?.managePreferencesText || "",
  });
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [editorInstance, setEditorInstance] = useState(null);
  const [selectedText, setSelectedText] = useState(""); // Add this state

  const LinkDialog = ({ open, onClose, onSubmit, initialText }) => {
    const [url, setUrl] = useState("");
    const [text, setText] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
      if (open) {
        setText(initialText || "");
        setUrl("");
        setError("");
      }
    }, [open, initialText]);

    const handleSubmit = (e) => {
      e?.preventDefault();

      if (!url) {
        setError("Please enter a URL");
        return;
      }

      // Add https:// if no protocol is specified
      let finalUrl = url;
      if (!/^https?:\/\//i.test(finalUrl)) {
        finalUrl = `https://${finalUrl}`;
      }

      if (!/^https?:\/\/.+\..+/i.test(finalUrl)) {
        setError("Please enter a valid URL");
        return;
      }

      onSubmit({
        url: finalUrl,
        text: initialText || text // Use initialText if available, otherwise use text input
      });

      setUrl("");
      setText("");
      setError("");
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ pb: 1 }}>Add Link</DialogTitle>
        <DialogContent sx={{ pb: 2 }}>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="URL"
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
                setError("");
              }}
              margin="normal"
              placeholder="https://"
              required
              error={!!error}
              helperText={error}
              autoFocus
            />
            {!initialText && ( // Show text field only when no text is selected
              <TextField
                fullWidth
                label="Link Text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                margin="normal"
                required={!initialText}
                placeholder="Enter text to display"
              />
            )}
            {initialText && (
              <TextField
                fullWidth
                label="Selected Text"
                value={initialText}
                margin="normal"
                disabled
              />
            )}
          </form>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={onClose} sx={{ color: "#666" }}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={!url || (!initialText && !text)} // Disable if URL is empty or if no text is provided when needed
            sx={{
              bgcolor: "#00B894",
              "&:hover": {
                bgcolor: "#00a583",
              },
            }}
          >
            Add Link
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    if (data) {
      setFormData({
        title: data.title || "",
        description: data.description || "",
        footerContent:
          data.footerContent ||
          "Concent Management Powered By Data Privacy Cloud",
        layout: data.layout || "Cloud",
        buttonPlacement: data.buttonPlacement || "Inline",
        position: data.position || "BottomLeft",
        equalWeight: data.equalWeight ?? true,
        swapButtons: data.swapButtons ?? false,
        acceptAll: data.acceptAll ?? true,
        acceptAllText: data?.acceptAllText || "",
        acceptNecessary: data.acceptNecessary ?? true,
        acceptNecessaryText: data?.acceptNecessaryText || "",
        managePreferences: data.managePreferences ?? true,
        managePreferencesText: data?.managePreferencesText || "",
        animation: data.animation || "slideIn",
      });
    }
  }, [data]);

  const handleInputChange = (field, value) => {
    const newFormData = {
      ...formData,
      [field]: value,
    };

    // Handle layout changes
    if (field === "layout") {
      newFormData.position = value === "Bar" ? "Top" : "MiddleCenter";
      newFormData.buttonPlacement =
        value === "Box" ? "Inline" : newFormData.buttonPlacement;
    }

    // Handle toggle switches (fields without 'Text' suffix)
    if (!field.endsWith("Text")) {
      // When toggling on, preserve existing text or initialize empty
      if (value === true) {
        newFormData[`${field}Text`] = formData[`${field}Text`] || "";
      }
      // When toggling off, we don't clear the text, so it's preserved
    }

    setFormData(newFormData);
    onUpdate(newFormData);
  };
  const layoutOptions = [
    {
      key: "Box",
      description: "Banner appears as a compact box",
    },
    {
      key: "Cloud",
      description: "Banner appears as a wider banner",
    },
    {
      key: "Bar",
      description: "Banner appears as a full width bar",
    },
  ];

    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', p: 3 }}>
          <CircularProgress />
        </Box>
      );
    }

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        {/* Banner Content */}
        <Box>
          <Typography
            variant="h6"
            mb={1.5}
            sx={{
              color: "#525455",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Banner Content
          </Typography>
          <Typography variant="body2" sx={{ mb: 1.5, color: "#525455" }}>
            Change the content that will be displayed in your cookie
            consent banner.
          </Typography>
          <Card
            variant="outlined"
            sx={{
              bgcolor: "#F9FAFB",
              border: "1px solid #E5E7EB",
              borderRadius: "8px",
            }}
          >
            <CardContent sx={{ p: 3 }}>
              {/* Title */}
              <Box mb={3}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    color: "#111827",
                    mb: 1,
                    fontSize: "14px",
                  }}
                >
                  Title
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Enter banner title"
                  value={formData.title}
                  onChange={(e) => handleInputChange("title", e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#FFFFFF",
                      "& fieldset": {
                        borderColor: "#E9ECEF",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#DEE2E6",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00B894",
                      },
                    },
                  }}
                />
              </Box>

              {/* Description */}
              {/* <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    color: "#111827",
                    mb: 1,
                    fontSize: "14px",
                  }}
                >
                  Description
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  placeholder="Enter banner description"
                  value={formData.description}
                  onChange={(e) =>
                    handleInputChange("description", e.target.value)
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#FFFFFF",
                      "& fieldset": {
                        borderColor: "#E9ECEF",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#DEE2E6",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00B894",
                      },
                    },
                  }}
                />
              </Box> */}
              {/* little bit working  */}

              {/* <Box>
  <Typography
    variant="subtitle1"
    sx={{
      fontWeight: 600,
      color: "#111827",
      mb: 1,
      fontSize: "14px",
    }}
  >
    Description
  </Typography>
  <Box
    sx={{
      '& .MuiTiptap-root': {
        minHeight: '120px',
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        border: '1px solid #E9ECEF',
        '&:hover': {
          borderColor: '#DEE2E6',
        },
        '&:focus-within': {
          borderColor: '#00B894',
        },
      },
      '& .MuiTiptap-toolbar': {
        borderBottom: '1px solid #E9ECEF',
        padding: '8px',
      },
      '& .MuiTiptap-content': {
        padding: '16px',
        minHeight: '120px',
        fontSize: '16px',
      },
      '& .MuiDialog-paper': {
        width: '400px',
        padding: '16px',
      }
    }}
  >
    <RichTextEditor
      content={formData.description}
      onUpdate={({ editor }) => {
        handleInputChange("description", editor.getHTML())
      }}
      extensions={[
        StarterKit,
        Underline,
        Link.configure({
          openOnClick: false,
          HTMLAttributes: {
            rel: 'noopener noreferrer',
            target: '_blank',
          },
          validate: url => /^https?:\/\//.test(url),
        }),
      ]}
      placeholder="Enter banner description"
      renderControls={({ editor }) => (
        <MenuControlsContainer>
          <MenuButtonBold />
          <MenuButtonItalic />
          <MenuButtonUnderline />
          <MenuDivider />
          <MenuButtonEditLink 
            onClick={() => {
              const url = window.prompt('URL', '');
              
              if (url === null) {
                return;
              }

              if (url === '') {
                editor.commands.unsetLink();
                return;
              }

              // Validate URL
              if (!/^https?:\/\//.test(url)) {
                alert('Please enter a valid URL starting with http:// or https://');
                return;
              }

              editor.commands.setLink({ href: url });
            }}
          />
        </MenuControlsContainer>
      )}
    />
  </Box>
</Box> */}
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    color: "#111827",
                    mb: 1,
                    fontSize: "14px",
                  }}
                >
                  Description
                </Typography>
                <Box
                  sx={{
                    "& .MuiTiptap-root": {
                      minHeight: "120px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "8px",
                      border: "1px solid #E9ECEF",
                      "&:hover": {
                        borderColor: "#DEE2E6",
                      },
                      "&:focus-within": {
                        borderColor: "#00B894",
                      },
                    },
                    "& .MuiTiptap-toolbar": {
                      borderBottom: "1px solid #E9ECEF",
                      padding: "8px",
                    },
                    "& .MuiTiptap-content": {
                      padding: "16px",
                      minHeight: "120px",
                      fontSize: "16px",
                      whiteSpace: "pre-wrap !important", // Add this
                      wordWrap: "break-word !important", // Add this
                      "& p": {
                        margin: "0 0 1em 0",
                        whiteSpace: "pre-wrap !important", // Add this
                        wordWrap: "break-word !important", // Add this
                        "&:last-child": {
                          marginBottom: 0,
                        },
                      },
                      "& *": { // Add this block
                        whiteSpace: "pre-wrap !important",
                        wordWrap: "break-word !important",
                      },
                    },
                  }}
                >
                  <RichTextEditor
                    content={formData.description}
                    editable={true}
                    onUpdate={({ editor }) => {
                      setEditorInstance(editor);
                      handleInputChange("description", editor.getHTML());
                    }}
                    onCreate={({ editor }) => {
                      setEditorInstance(editor);
                      // Set initial content when editor is created
                      if (formData.description) {
                        editor.commands.setContent(formData.description);
                      }
                    }}
                    // extensions={[
                    //   StarterKit,
                    //   Underline,
                    //   Link.configure({
                    //     openOnClick: true,
                    //     HTMLAttributes: {
                    //       rel: "noopener noreferrer",
                    //       target: "_blank",
                    //     },
                    //     validate: (url) => /^https?:\/\//.test(url),
                    //   }),
                    // ]}
                    extensions={[
                      StarterKit.configure({
                        paragraph: {
                          HTMLAttributes: {
                            class: 'paragraph',
                          },
                          keepMarks: true,
                          preserveWhitespace: true,
                        },
                        text: {
                          preserveWhitespace: true, // Add this
                        },
                      }),
                      Underline,
                      Link.configure({
                        openOnClick: false,
                        HTMLAttributes: {
                          rel: "noopener noreferrer",
                          target: "_blank",
                        },
                        validate: (url) => /^https?:\/\//.test(url),
                        // Add keyboard shortcuts
                        shortcuts: {
                          'Mod-k': () => {
                            const isLink = editorInstance.isActive('link');
                            if (isLink) {
                              editorInstance.chain().focus().unsetLink().run();
                            } else {
                              setLinkDialogOpen(true);
                            }
                          },
                        },
                      }),
                    ]}
                    editorProps={{
                      attributes: {
                        class: 'prose prose-sm focus:outline-none',
                      },
                      handleKeyDown: (view, event) => {
                        // Special handling for space key
                        if (event.key === ' ') {
                          const { state } = view;
                          const { selection } = state;
                          const { empty } = selection;

                          // If at the end of the document, insert a non-breaking space
                          if (empty && selection.$head.pos === state.doc.content.size) {
                            view.dispatch(state.tr.insertText(' '));
                            return true;
                          }
                        }
                        return false;
                      },
                    }}
                    placeholder="Enter banner description"
                    renderControls={() => (
                      <MenuControlsContainer>
                        <MenuButtonBold />
                        <MenuButtonItalic />
                        <MenuButtonUnderline />
                        <MenuDivider />
                        <Tooltip title={editorInstance?.isActive('link') ? "Remove Link" : "Add Link"}>
                          <IconButton
                            onClick={() => {
                              // Get selected text when link button is clicked
                              if (editorInstance) {
                                if (editorInstance.isActive('link')) {
                                  // Remove link if already a link
                                  editorInstance.chain().focus().unsetLink().run();
                                } else {
                                  // Add new link
                                  const { from, to } = editorInstance.state.selection;
                                  const selectedContent = editorInstance.view.state.doc.textBetween(
                                    from,
                                    to,
                                    ' '
                                  );
                                  setSelectedText(selectedContent);
                                  setLinkDialogOpen(true);
                                }
                              }
                            }}
                            size="small"
                            sx={{
                              "textDecoration": null,
                              "&:hover": {
                                color: "#00B894",
                              },
                            }}
                          >
                            {editorInstance?.isActive('link') ? (
                              <LinkOffIcon fontSize="small" />
                            ) : (
                              <LinkIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Tooltip>
                      </MenuControlsContainer>
                    )}
                  />
                  <LinkDialog
                    open={linkDialogOpen}
                    initialText={selectedText}
                    onClose={() => {
                      setLinkDialogOpen(false);
                      setSelectedText(""); // Clear selected text when dialog closes
                    }}
                    onSubmit={({ url, text }) => {
                      if (editorInstance) {
                        const selection = editorInstance.state.selection;
                        const hasSelection = !selection.empty;

                        if (hasSelection) {
                          // If text is selected, convert it to a link
                          editorInstance
                            .chain()
                            .focus()
                            .setLink({ href: url })
                            .run();
                        } else if (text) {
                          // If no text is selected but text is provided in dialog
                          editorInstance
                            .chain()
                            .focus()
                            .insertContent({
                              type: "text",
                              marks: [
                                {
                                  type: "link",
                                  attrs: {
                                    href: url,
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                  },
                                },
                              ],
                              text: text,
                            })
                            .run();
                        }
                        setLinkDialogOpen(false);
                        setSelectedText("");
                      }
                    }}
                  />
                </Box>
              </Box>

              {/* Footer Content */}
              {/* <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    color: "#111827",
                    mb: 1,
                    fontSize: "14px",
                  }}
                >
                  Footer Content
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Enter footer content"
                  value={formData.footerContent}
                  onChange={(e) =>
                    handleInputChange("footerContent", e.target.value)
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#FFFFFF",
                      "& fieldset": {
                        borderColor: "#E9ECEF",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#DEE2E6",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00B894",
                      },
                    },
                  }}
                />
              </Box> */}
              {/* Working for enterprise */}
              {/* <Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      color: "#111827",
                      fontSize: "14px",
                    }}
                  >
                    Footer Content
                  </Typography>
                 
                  {data?.planType !== "enterprise" && data?.planType !== 'business' &&  (
                    <Box
                      component="span"
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        color: "#6B7280",
                        fontSize: "12px",
                      }}
                    >
                      <LockIcon sx={{ fontSize: "16px", mr: 0.5 }} />
                      Enterprise/Business
                    </Box>
                  )}
                </Box>

                <TextField
                  fullWidth
                  placeholder="Enter footer content"
                  value={formData.footerContent}
                  onChange={(e) =>
                    handleInputChange("footerContent", e.target.value)
                  }
                  disabled={data?.planType !== "enterprise" && data?.planType !== 'business'}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor:
                        data?.planType !== "enterprise" ? "#F3F4F6" : "#FFFFFF",
                      "& fieldset": {
                        borderColor: "#E9ECEF",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor:
                          data?.planType === "enterprise"
                            ? "#DEE2E6"
                            : "#E9ECEF",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor:
                          data?.planType === "enterprise"
                            ? "#00B894"
                            : "#E9ECEF",
                      },
                      // Add styles for disabled state
                      "&.Mui-disabled": {
                        backgroundColor: "#F3F4F6",
                        "& fieldset": {
                          borderColor: "#E9ECEF",
                        },
                      },
                    },
                  }}
                />
                {data?.planType !== "enterprise" && data?.planType !== 'business' && (
                  <Typography
                    variant="caption"
                    sx={{
                      // display: "block",
                      mt: 1,
                      color: "#6B7280",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    <InfoOutlinedIcon sx={{ fontSize: "16px" }} />
                    Upgrade to our enterprise or buisness plan to edit the footer content
                  </Typography>
                )}
              </Box> */}
            </CardContent>
          </Card>
        </Box>

        <Box mt={2}>
          {/* <Typography
            variant="h6"
            mb={1.5}
            sx={{
              color: "#525455",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Banner Settings
          </Typography> */}
          <Card
            variant="outlined"
            sx={{
              bgcolor: "#F9FAFB",
              border: "1px solid #E5E7EB",
              borderRadius: "8px",
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                {/* Layout Section */}
                <Box>
                  <Typography
                    variant="subtitle1"
                    mb={2}
                    sx={{
                      color: "#525455",
                      fontWeight: 600,
                    }}
                  >
                    Layout
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: 2,
                      width: "100%",
                    }}
                  >
                    {layoutOptions.map((option) => (
                      <Button
                        key={option}
                        variant="outlined"
                        onClick={() => handleInputChange("layout", option.key)}
                        sx={{
                          borderColor:
                            formData.layout === option ? "#00B894" : "#E5E7EB",
                          color:
                            formData.layout === option ? "#00B894" : "#525455",
                          backgroundColor:
                            formData.layout === option ? "#F0FDF9" : "#FFFFFF",
                          "&:hover": {
                            backgroundColor:
                              formData.layout === option
                                ? "#F0FDF9"
                                : "#F9FAFB",
                            borderColor:
                              formData.layout === option
                                ? "#00B894"
                                : "#B0B9C5",
                          },
                          textTransform: "none",
                          height: "90px", // Increased height to accommodate description
                          fontSize: "16px",
                          borderRadius: "8px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "4px",
                          padding: "12px",
                          width: "200px",
                          border:
                            formData.layout === option.key
                              ? "1px solid #00B894"
                              : "1px solid #E5E7EB",
                          "&.Mui-selected": {
                            backgroundColor: "#F0FDF9",
                            borderColor: "#00B894",
                          },
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          component="div"
                          sx={{
                            color:
                              formData.layout === option.key
                                ? "#00B894"
                                : "#525455",
                            fontWeight:
                              formData.layout === option.key ? 600 : 400,
                          }}
                        >
                          {option.key}
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          sx={{
                            textAlign: "center",
                            color:
                              formData.layout === option.key
                                ? "#00B894"
                                : "#717171",
                            fontSize: "12px",
                            lineHeight: 1.2,
                          }}
                        >
                          {option.description}
                        </Typography>
                      </Button>
                    ))}
                  </Box>
                </Box>

                {/* Position Section */}
                <Box sx={{ mt: 3 }}>
                  <Typography
                    variant="subtitle1"
                    mb={2}
                    sx={{
                      color: "#525455",
                      fontWeight: 600,
                    }}
                  >
                    Banner Position
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns:
                        formData.layout === "Bar"
                          ? "repeat(2, 1fr)"
                          : "repeat(3, 1fr)",
                      gap: 2,
                      width: "100%",
                    }}
                  >
                    {formData.layout === "Bar"
                      ? // Position options for Bar layout
                      ["Top", "Bottom"].map((position) => (
                        <Button
                          key={position}
                          variant="outlined"
                          onClick={() =>
                            handleInputChange("position", position)
                          }
                          sx={{
                            borderColor:
                              formData.position === position
                                ? "#00B894"
                                : "#E5E7EB",
                            color:
                              formData.position === position
                                ? "#00B894"
                                : "#525455",
                            backgroundColor:
                              formData.position === position
                                ? "#F0FDF9"
                                : "#FFFFFF",
                            "&:hover": {
                              backgroundColor:
                                formData.position === position
                                  ? "#F0FDF9"
                                  : "#F9FAFB",
                              borderColor:
                                formData.position === position
                                  ? "#00B894"
                                  : "#B0B9C5",
                            },
                            textTransform: "none",
                            height: "60px",
                            fontSize: "16px",
                            borderRadius: "8px",
                          }}
                        >
                          {position}
                        </Button>
                      ))
                      : // Position options for Box and Cloud layouts
                      [
                        "TopLeft",
                        "TopCenter",
                        "TopRight",
                        "MiddleLeft",
                        "MiddleCenter",
                        "MiddleRight",
                        "BottomLeft",
                        "BottomCenter",
                        "BottomRight",
                      ].map((position) => (
                        <Button
                          key={position}
                          variant="outlined"
                          onClick={() =>
                            handleInputChange("position", position)
                          }
                          sx={{
                            borderColor:
                              formData.position === position
                                ? "#00B894"
                                : "#E5E7EB",
                            color:
                              formData.position === position
                                ? "#00B894"
                                : "#525455",
                            backgroundColor:
                              formData.position === position
                                ? "#F0FDF9"
                                : "#FFFFFF",
                            "&:hover": {
                              backgroundColor:
                                formData.position === position
                                  ? "#F0FDF9"
                                  : "#F9FAFB",
                              borderColor:
                                formData.position === position
                                  ? "#00B894"
                                  : "#B0B9C5",
                            },
                            textTransform: "none",
                            height: "60px",
                            fontSize: "16px",
                            borderRadius: "8px",
                          }}
                        >
                          {position.replace(/([A-Z])/g, " $1").trim()}
                        </Button>
                      ))}
                  </Box>
                </Box>

                {/* Consent Button Placement Section - Only show for Bar and Cloud layouts */}
                {formData.layout !== "Box" && (
                  <Box sx={{ mt: 3 }}>
                    <Typography
                      variant="subtitle1"
                      mb={2}
                      sx={{
                        color: "#525455",
                        fontWeight: 600,
                      }}
                    >
                      Consent Button Placement
                    </Typography>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: 2,
                        width: "100%",
                      }}
                    >
                      {["Stacked", "Inline", "Row"].map((placement) => (
                        <Button
                          key={placement}
                          variant="outlined"
                          onClick={() =>
                            handleInputChange("buttonPlacement", placement)
                          }
                          sx={{
                            borderColor:
                              formData.buttonPlacement === placement
                                ? "#00B894"
                                : "#E5E7EB",
                            color:
                              formData.buttonPlacement === placement
                                ? "#00B894"
                                : "#525455",
                            backgroundColor:
                              formData.buttonPlacement === placement
                                ? "#F0FDF9"
                                : "#FFFFFF",
                            "&:hover": {
                              backgroundColor:
                                formData.buttonPlacement === placement
                                  ? "#F0FDF9"
                                  : "#F9FAFB",
                              borderColor:
                                formData.buttonPlacement === placement
                                  ? "#00B894"
                                  : "#B0B9C5",
                            },
                            textTransform: "none",
                            height: "60px",
                            fontSize: "16px",
                            borderRadius: "8px",
                          }}
                        >
                          {placement}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>

        {/* Banner Buttons */}
        <Box>
          <Typography
            variant="h6"
            mb={1.5}
            sx={{
              color: "#525455",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Banner Buttons
          </Typography>
          <Typography variant="body2" sx={{ mb: 1.5, color: "#525455" }}>
            Change the presence and content of buttons in your cookie
            consent banner.
          </Typography>
          <Card
            variant="outlined"
            sx={{
              bgcolor: "#F9FAFB",
              border: "1px solid #E5E7EB",
              borderRadius: "12px",
            }}
          >
            <CardContent sx={{ p: 3 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                {/* Equal Weight Buttons Section */}
                {/* <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{ color: "#525455", fontWeight: 600, mb: 0.5 }}
                      >
                        Equal Weight Buttons
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#6B7280" }}>
                        The accept and reject button both share the same
                        colours.
                      </Typography>
                    </Box>
                    <CustomSwitch
                      checked={formData.equalWeight}
                      onChange={(e) =>
                        handleInputChange("equalWeight", e.target.checked)
                      }
                    />
                  </Box>
                </Box> */}

                {/* <Divider /> */}

                {/* Swap Buttons Section */}
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{ color: "#525455", fontWeight: 600, mb: 0.5 }}
                      >
                        Swap Buttons
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#6B7280" }}>
                        Flip the position of the buttons.
                      </Typography>
                    </Box>
                    <CustomSwitch
                      checked={formData.swapButtons}
                      onChange={(e) =>
                        handleInputChange("swapButtons", e.target.checked)
                      }
                    />
                  </Box>
                </Box>

                <Divider />

                {/* Customizable Button Sections */}
                {[
                  {
                    title: "Accept All",
                    subtitle: "Consent to all categories",
                    field: "acceptAll",
                    defaultText: "Accept All",
                    required: true,
                  },
                  {
                    title: "Accept necessary",
                    subtitle: "Consent to only necessary cookie",
                    field: "acceptNecessary",
                    defaultText: "Reject All",
                    required: false,
                  },
                  {
                    title: "Manage Preferences",
                    subtitle:
                      "Open model to customise & learn more about cookie usage",
                    field: "managePreferences",
                    defaultText: "Manage Preferences",
                    required: true,
                  },
                ].map((item, index) => (
                  <React.Fragment key={item.field}>
                    <Box>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr auto 1fr",
                          gap: 3,
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{ color: "#525455", fontWeight: 600, mb: 0.5 }}
                          >
                            {item.title}
                          </Typography>
                          <Typography variant="body2" sx={{ color: "#6B7280" }}>
                            {item.subtitle}
                          </Typography>
                        </Box>
                        <CustomSwitch
                          checked={item.required ? true : formData[item.field]}
                          onChange={(e) =>
                            handleInputChange(item.field, e.target.checked)
                          }
                          disabled={item.required}
                        />
                        <TextField
                          size="small"
                          //   value={formData[`${item.field}Text`] ? (formData[`${item.field}Text`] || item.defaultText) : item.defaultText}
                          value={
                            !formData[item.field]
                              ? item.defaultText
                              : formData[`${item.field}Text`] ?? ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              `${item.field}Text`,
                              e.target.value
                            )
                          }
                          disabled={!formData[item.field]}
                          placeholder={item.defaultText}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: formData[item.field]
                                ? "#FFFFFF"
                                : "#F3F4F6",
                              "& fieldset": {
                                borderColor: "#E5E7EB",
                              },
                              "&:hover fieldset": {
                                borderColor: formData[item.field]
                                  ? "#B0B9C5"
                                  : "#E5E7EB",
                              },
                              "& input": {
                                color: formData[item.field]
                                  ? "#525455"
                                  : "#9CA3AF",
                              },
                            },
                          }}
                        />
                      </Box>
                    </Box>
                    {index < 2 && <Divider sx={{ my: 2 }} />}
                  </React.Fragment>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default BannerSettings;
