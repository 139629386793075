import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Stack,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  styled,
  tableCellClasses,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import CommonHeader from "../CommonComponent/CommonHeader";
import ButtonCommon from "../CommonComponent/ButtonCommon";
import { useNavigate } from "react-router-dom";
import { Eye, FilterIcon, InstallationIcon } from "../assets/svg";
import { getConsentDetails } from "../api/consentLog";
import { getWebsiteById } from "../api/websiteService";
import { getFormattedTimestamp } from "../utils/dateUtils";

// Extend dayjs with the necessary plugins
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// Styled components for table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",
    color: "#000000",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ConsentLog = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [consentData, setConsentData] = useState([]);
  const [page, setPage] = useState(0); // Start with 0 for MUI pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0); // Store total count from API
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [timezone, setTimezone] = useState(null);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  // Debounce search query
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  // Fetch consent logs when pagination, search or date filters change
  const getConsentLogs = useCallback(async () => {
    setLoading(true);
    try {
      // Convert to 1-based for the API call (assuming your API is 1-based)
      const apiPage = page + 1;

      // Add query parameters for filters if needed
      const params = {
        search: debouncedSearchQuery || undefined,
        startDate: startDate ? startDate.format("YYYY-MM-DD") : undefined,
        endDate: endDate ? endDate.format("YYYY-MM-DD") : undefined,
      };

      // Use the updated function that incorporates the specific API details
      const res = await getConsentDetails(apiPage, rowsPerPage, params);

      if (res?.data) {
        setConsentData(res?.data?.consent_data || []);
        setTotalRecords(res?.data?.total_consents || 0);
      }
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, debouncedSearchQuery, startDate, endDate]);

  const getWebsiteDetails = async () => {
    try {
      const websiteId = localStorage.getItem("website_id");
      const websiteData = await getWebsiteById(websiteId);
      const timezoneFromApi = websiteData?.data?.time_zone;
      setTimezone(timezoneFromApi);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getConsentLogs();
  }, [getConsentLogs]);

  useEffect(() => {
    getWebsiteDetails();
  }, []);

  // Reset to first page when search or filters change
  useEffect(() => {
    setPage(0);
  }, [debouncedSearchQuery, startDate, endDate]);

  const handleRowClick = (id) => {
    navigate(`/consent-details/${id}`);
  };

  const downloadCSV = () => {
    // You might want to fetch all data for download or use current filtered data
    // This is a simplified version
    if (!consentData.length) return;

    const header = Object.keys(consentData[0]).join(",");
    const rows = consentData.map((row) => Object.values(row).join(","));
    const csvContent = [header, ...rows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "consent_log_report.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const clearDateFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setShowDateFilter(false);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <CommonHeader
          title="Consent Log"
          subTitle="You can review and audit the consent log and consent details on this page."
        />
      </Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 3 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <TextField
          placeholder="Search by Consent ID"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          size="small"
          sx={{ width: "300px" }}
          InputProps={{
            startAdornment: <Search color="action" sx={{ mr: 1 }} />,
          }}
        />
        <Stack direction="row" spacing={2}>
          <ButtonCommon
            icon={<FilterIcon />}
            onClick={() => setShowDateFilter((prev) => !prev)}
          >
            Filter by date
          </ButtonCommon>
          {(startDate || endDate) && (
            <ButtonCommon onClick={clearDateFilters}>
              Clear Filters
            </ButtonCommon>
          )}
          <ButtonCommon
            icon={<InstallationIcon />}
            onClick={downloadCSV}
            disabled={consentData.length === 0 || loading}
          >
            Download report
          </ButtonCommon>
        </Stack>
      </Stack>

      {showDateFilter && (
        <Box sx={{ mb: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="row" spacing={2}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                slotProps={{ textField: { size: "small", fullWidth: true } }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                minDate={startDate}
                slotProps={{ textField: { size: "small", fullWidth: true } }}
              />
            </Stack>
          </LocalizationProvider>
        </Box>
      )}

      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderRadius: "12px" }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Consent ID</StyledTableCell>
              <StyledTableCell>IP Country</StyledTableCell>
              <StyledTableCell>Device</StyledTableCell>
              <StyledTableCell>Accept Type</StyledTableCell>
              <StyledTableCell>Accepted At</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : Array.isArray(consentData) && consentData?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No consent logs found.
                </TableCell>
              </TableRow>
            ) : (
              Array.isArray(consentData) &&
              consentData.map((row) => (
                <TableRow
                  key={row.consent_id}
                  hover
                  onClick={() => handleRowClick(row.consent_id)}
                  sx={{ cursor: "pointer" }}
                >
                  <StyledTableCell>{row.consent_hashid}</StyledTableCell>
                  <StyledTableCell>{row.ip_country}</StyledTableCell>
                  <StyledTableCell>{row.device_name}</StyledTableCell>
                  <StyledTableCell>
                    {row.user_preferences?.acceptType}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getFormattedTimestamp(row.accepted_at, timezone)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton size="small">
                      <Eye />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" mt={3}>
        <TablePagination
          component="div"
          count={totalRecords}
          page={page}
          onPageChange={(event, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </Box>
    </Box>
  );
};

export default ConsentLog;
