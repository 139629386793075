import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useParams } from "react-router-dom";
import { generateEmail } from "../../api/dataBreach";
import { getFormattedTimestamp } from "../../utils/dateUtils";

const GenerateEmail = ({ time_zone }) => {
  const { id } = useParams();
  const [emailData, setEmailData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [copied, setCopied] = useState({ dp: false, dpb: false });

  // Fetch Email Template on Component Mount
  useEffect(() => {
    const fetchEmailTemplate = async () => {
      try {
        const response = await generateEmail(id);
        if (response.status_code === 200 && response.data) {
          setEmailData(response.data); // ✅ Fix: Correctly accessing data
        } else {
          setEmailData(null);
        }
      } catch (error) {
        setEmailData(null);
      }
      setLoading(false);
    };

    fetchEmailTemplate();
  }, [id]);

  // Copy to Clipboard Function
  const handleCopyToClipboard = (type) => {
    if (emailData && emailData[type]) {
      const copyText = `${formatBodyText(emailData[type].body)}`;

      navigator.clipboard.writeText(copyText);

      setCopied((prevState) => ({ ...prevState, [type]: true }));

      setTimeout(() => {
        setCopied((prevState) => ({ ...prevState, [type]: false }));
      }, 2000);
    }
  };

  const formatBodyText = (body) => {
    if (typeof body === "object") {
      return Object.entries(body)
        .map(([key, value]) => `${key}:\n${value || "N/A"}`)
        .join("\n\n");
    }
    return body;
  };

  const formatBody = (body) => {
    if (typeof body === "object") {
      return Object.entries(body).map(([key, value]) => {
        let formattedValue = value || "N/A";
        if (key === "Date and Time of Breach" && value) {
          formattedValue = getFormattedTimestamp(value, time_zone);
        }
        return (
          <Box key={key} sx={{ mb: 2 }}>
            <Typography variant="body1" fontWeight="bold">
              {key}:
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
              {formattedValue}
            </Typography>
          </Box>
        );
      });
    }
    return <Typography variant="body2">{body}</Typography>;
  };

  return (
    <Box>
      {loading ? (
        <CircularProgress />
      ) : emailData ? (
        <>
          {/* DP Email Template */}
          <EmailTemplateCard
            title="Email to Data Principal (DP)"
            body={formatBody(emailData.dp.body)}
            copied={copied.dp}
            onCopy={() => handleCopyToClipboard("dp")}
          />

          {/* DPB Email Template */}
          <EmailTemplateCard
            title="Email to Data Protection Board (DPB)"
            body={formatBody(emailData.dpb.body)}
            copied={copied.dpb}
            onCopy={() => handleCopyToClipboard("dpb")}
          />
        </>
      ) : (
        <Typography color="error">No email template available</Typography>
      )}
    </Box>
  );
};

// 🔹 Reusable Component for Email Template Display
const EmailTemplateCard = ({ title, body, copied, onCopy }) => {
  return (
    <Paper sx={{ p: 3, mb: 3, backgroundColor: "#F9FAFB" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="subtitle1" color="primary" fontWeight="bold">
          {title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={onCopy}>
            {copied ? <CheckCircleIcon color="success" /> : <ContentCopyIcon />}
          </IconButton>
          {copied && (
            <Typography variant="body2" color="success.main">
              Copied!
            </Typography>
          )}
        </Box>
      </Box>
      <Typography
        variant="body2"
        sx={{
          whiteSpace: "pre-wrap",
          fontFamily: "monospace",
          fontSize: "14px",
        }}
      >
        {body}
      </Typography>
    </Paper>
  );
};

export default GenerateEmail;
