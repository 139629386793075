import apiClient from "./apiClient";

export const getUserData = async (page = 1, perPage = 50) => {
  try {
    const response = await apiClient.get("/user", {
      params: {
        page,
        per_page: perPage,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (data) => {
  try {
    const response = await apiClient.post("/user", data);
    return response; // Return the response here
  } catch (error) {
    throw error; // Optionally throw the error to handle it elsewhere
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await apiClient.delete(`/user/${userId}`);
    return response;
  } catch (error) {
    throw error;
  }
};
