import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Box, Typography, Link } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { CheckOutlined } from "@mui/icons-material";
import { getFormattedTimestamp } from "../../utils/dateUtils";

export default function VisitorAction({ consentDetails, timezone }) {
  // Sample data - replace with your actual data source
  const events = [
    {
      type: "visit",
      url: consentDetails?.user_preferences?.user_action_page ? consentDetails?.user_preferences?.user_action_page : "No URL available",
      timestamp: getFormattedTimestamp(consentDetails?.accepted_at, timezone),
    },
    {
      type: "consent",
      label: `Consent: ${consentDetails?.user_preferences?.acceptType}`,
      url: consentDetails?.user_preferences?.user_action_page
        ? new URL(consentDetails?.user_preferences?.user_action_page).origin
        : "No URL available",
      timestamp: getFormattedTimestamp(consentDetails?.accepted_at, timezone),
    },
    {
      type: "session",
      timestamp: getFormattedTimestamp(consentDetails?.accepted_at, timezone),
    },
  ];

  const getIcon = (type) => {
    switch (type) {
      case "visit":
      case "session":
        return <PersonOutlineIcon />;
      case "consent":
        return <CheckOutlined />;
      default:
        return <PersonOutlineIcon />;
    }
  };

  const getDotColor = (type) => {
    switch (type) {
      case "consent":
        return "#00B894";
      default:
        return "none";
    }
  };

  const getLabel = (type, label) => {
    switch (type) {
      case "visit":
        return "User visited";
      case "consent":
        return label || "Consent";
      case "session":
        return "Session Start";
      default:
        return label;
    }
  };

  return (
    <Box sx={{ maxWidth: "800px", margin: "0 auto" }}>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          padding: 0,
          margin: 0,
        }}
      >
        {events.map((event, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot sx={{ bgcolor: getDotColor(event.type) }}>
                {getIcon(event.type)}
              </TimelineDot>
              {index !== events.length - 1 && (
                <TimelineConnector sx={{ height: 40 }} />
              )}
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Box sx={{ position: "relative" }}>
                <Typography variant="body2" color="text.secondary">
                  {getLabel(event.type, event.label)}
                </Typography>
                {event.url && (
                  <Link
                    // href={event.url}
                    underline="hover"
                    sx={{ color: "text.primary", display: "block" }}
                  >
                    {event.url}
                  </Link>
                )}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                  }}
                >
                  {event.timestamp}
                </Typography>
              </Box>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
}
