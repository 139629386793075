import apiClient from "./apiClient";

export const getAllCategory = async () => {
  const websiteId = localStorage.getItem("website_id");
  const organizationId = localStorage.getItem("organization_id_str");

  try {
    const response = await apiClient.get("/categories", {
      headers: {
        website_id: websiteId, // Correctly passing in headers
        organization_id: organizationId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCategory = async (payload) => {
  const websiteId = localStorage.getItem("website_id");
  const organizationId = localStorage.getItem("organization_id_str");

  try {
    const response = await apiClient.post("/categories/", payload, {
      headers: {
        website_id: websiteId,
        organization_id: organizationId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCategory = async (categoryId, categoryData) => {
  const websiteId = localStorage.getItem("website_id");
  const organizationId = localStorage.getItem("organization_id_str");

  try {
    const response = await apiClient.put(
      `/categories/${categoryId}`,
      categoryData,
      {
        headers: {
          website_id: websiteId,
          organization_id: organizationId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCategory = async (categoryId) => {
  const websiteId = localStorage.getItem("website_id");
  const organizationId = localStorage.getItem("organization_id_str");

  try {
    const response = await apiClient.delete(`/categories/${categoryId}`, {
      headers: {
        website_id: websiteId,
        organization_id: organizationId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoryById = async (categoryId) => {
  const websiteId = localStorage.getItem("website_id");
  const organizationId = localStorage.getItem("organization_id_str");

  try {
    const response = await apiClient.get(`/categories/${categoryId}`, {
      headers: {
        website_id: websiteId,
        organization_id: organizationId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoryTranslations = async (parentCaregoryId) => {
  const websiteId = localStorage.getItem("website_id");
  const organizationId = localStorage.getItem("organization_id_str");

  try {
    const response = await apiClient.get(`/category/translations/${parentCaregoryId}`, {
      headers: {
        website_id: websiteId,
        organization_id: organizationId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveCategoryTranslations = async (payload) => {
  const websiteId = localStorage.getItem("website_id");
  const organizationId = localStorage.getItem("organization_id_str");

  try {
    const response = await apiClient.post(`/category/save_translation`, payload, {
      headers: {
        website_id: websiteId,
        organization_id: organizationId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCategoryTranslations = async (caregoryTranslateId, payload) => {
  const websiteId = localStorage.getItem("website_id");
  const organizationId = localStorage.getItem("organization_id_str");

  try {
    const response = await apiClient.put(`category/${caregoryTranslateId}`, payload, {
      headers: {
        website_id: websiteId,
        organization_id: organizationId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCategoryTranslations = async (caregoryTranslateId) => {
  const websiteId = localStorage.getItem("website_id");
  const organizationId = localStorage.getItem("organization_id_str");

  try {
    const response = await apiClient.delete(`/category/${caregoryTranslateId}`, {
      headers: {
        website_id: websiteId,
        organization_id: organizationId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
