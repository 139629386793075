import apiClient from "./apiClient";

export const getConsentDetails = async (
  page = 1,
  per_page = 50,
  filters = {}
) => {
  try {
    const websiteId = localStorage.getItem("website_id");
    const { search, startDate, endDate } = filters;

    // Construct query parameters
    let url = `/consent/get-consent-details?page=${page}&per_page=${per_page}`;

    if (search) url += `&consent_hash_id=${search}`;
    if (startDate) url += `&start_date=${startDate}`;
    if (endDate) url += `&end_date=${endDate}`;

    const response = await apiClient.get(url, {
      headers: {
        website_id: websiteId,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchConsentData = async (consentId) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get(`/consent/${consentId}`, {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPageVisitData = async (consent_hashid) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get(
      `/get_timeline_data/${consent_hashid}`,
      {
        headers: {
          website_id: websiteId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
