import React from 'react'
import { CloseOutlined } from "@mui/icons-material"
import { Box, Button, Divider, IconButton, Modal, Typography } from "@mui/material"
import { useState } from "react"

// export default function DeletePopup({ openModal, setOpenModal, handleRemoveLanguage, langCode, translations, isExistingTranslation }) {
export default function DeletePopup({ openModal, setOpenModal, handleRemoveLanguage, langCode }) {
    const [isDeleting, setIsDeleting] = useState(false)

    const handleClose = () => setOpenModal((prev) => {
        return {
            ...prev,
            [langCode]: false
        }
    })

    return (
        <Modal
            open={openModal[langCode]}
            onClose={handleClose}
        >
            <Box sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 600,
                bgcolor: "#FFFFFF",
                borderRadius: "16px",
                boxShadow: 24,
                p: 3,
            }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6" color="#525455" fontWeight="bold">
                        Delete Translation
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseOutlined />
                    </IconButton>
                </Box>
                <Typography color="#989898">
                    Are you sure you want to delete this translation?
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Box gap={2} display="flex">
                    <Button
                        variant="outlined"
                        sx={{
                            border: "2px solid #00B894",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                        }}
                        onClick={() => {
                            setIsDeleting(true)
                            handleRemoveLanguage(langCode);
                            // handleRemoveLanguage(langCode, translations[langCode]?.banner_trans_id, isExistingTranslation, setIsDeleting);
                            handleClose();
                            setIsDeleting(false)
                        }}
                    >
                        {isDeleting ? 'Deleting' : 'Delete'}
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            border: "2px solid #CECECE",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
