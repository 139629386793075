// src/api/websiteService.js
import apiClient from "./apiClient";

export const createWebsite = async (url, userId, timeZone) => {
  try {
    const payload = {
      domain_name: url,
      created_by: userId,
      time_zone: timeZone,
    };
    const response = await apiClient.post("/website/create_website", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateWebsite = async (websiteId, data) => {
  try {
    const response = await apiClient.put(`/website/update/${websiteId}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllWebsites = async () => {
  try {
    const response = await apiClient.get("/website/get_all_website");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getWebsiteById = async (websiteId) => {
  try {
    const response = await apiClient.get(`/website/${websiteId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteWebsite = async (websiteId) => {
  try {
    const response = await apiClient.put(`/website/delete/${websiteId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const startScan = async (websiteId, url) => {
  const payload = {
    website_id: websiteId,
    url_name: url,
  };
  try {
    const response = await apiClient.post("/scan/start", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const scanWebsite = async (scanId) => {
  try {
    const response = await apiClient.get(`/scan/${scanId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const lastScan = async () => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get("/last_scan", {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// export const getAllLanguages = async () => {
//   try {
//     const response = await apiClient.get("/languages");
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

// Get all languages
export const getAllLanguages = async () => {
  try {
    const response = await apiClient.get(`/languages/get-all-language`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

