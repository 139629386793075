import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Link,
  Paper,
  InputLabel,
  CircularProgress,
  styled,
  Grid,
} from "@mui/material";
import myIcon from "../assets/svg/logo.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import { signIn, getCurrentUser, fetchUserAttributes } from "@aws-amplify/auth";
import { getAllWebsites } from "../api/websiteService";
import axios from "axios";
import { showErrorToast, showSuccessToast } from "../toastUtils";
import illustrationSvg from "../assets/svg/signup.svg";
// import { validateLicense } from "../api/licensing";
// import { useDispatch } from "react-redux";
// import { setLicenseData } from "../store/licenseSlice";

const LoginPage = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isForgotPasswordDisabled, setIsForgotPasswordDisabled] =
    useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  // New styled component for the scrollable illustration side
  const IllustrationContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100vh",
    overflow: "hidden", // Hide overflowing content
    padding: theme.spacing(2),
    position: "sticky",
    top: 0,
    [theme.breakpoints.down("md")]: {
      display: "none", // Hide on smaller screens
    },
  }));

  // Render the scrollable illustration side
  const renderIllustration = () => {
    return (
      <>
        <IllustrationContainer>
          <div className="scroll-container">
            <img
              src={illustrationSvg}
              alt="Privacy Protection Illustration"
              className="scrolling-image"
            />
            <img
              src={illustrationSvg}
              alt="Privacy Protection Illustration Clone"
              className="scrolling-image"
              style={{ paddingTop: "10px" }}
            />
          </div>
        </IllustrationContainer>

        <style>
          {`
          .scroll-container {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
          }
          .scrolling-image {
            max-width: 100%;
            height: auto;
            animation: scrollVertical 120s linear infinite;
          }
          @keyframes scrollVertical {
            0% { transform: translateY(0%); }
            100% { transform: translateY(-100%); } /* Moves up infinitely */
          }
        `}
        </style>
      </>
    );
  };

  const illustration = useMemo(() => renderIllustration(), []);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        if (currentUser) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const validateField = (name, value) => {
    switch (name) {
      case "email":
        if (!value) {
          return "Email is required";
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          return "Invalid email address";
        }
        return "";

      case "password":
        if (!value) {
          return "Password is required";
        }
        return "";

      default:
        return "";
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setFormData((prev) => ({
      ...prev,
      [name]: fieldValue,
    }));

    if (touched[name]) {
      const fieldError = validateField(name, fieldValue);
      setErrors((prev) => ({
        ...prev,
        [name]: fieldError,
      }));
    }
  };

  const handleBlur = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));

    const fieldError = validateField(name, fieldValue);
    setErrors((prev) => ({
      ...prev,
      [name]: fieldError,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const { email, password } = formData;

      if (!email || !password) {
        setError("Please fill in all required fields.");
        return;
      }

      const signInResponse = await signIn({ username: email, password });

      if (
        signInResponse.nextStep.signInStep ===
        "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        setIsForgotPasswordDisabled(true);
        navigate("/change-password", { state: { email } });
        return;
      }

      const data = await getCurrentUser();
      const currentUser = await fetchUserAttributes();

      const organizationId = currentUser?.profile;

      if (organizationId !== undefined && organizationId !== null) {
        const numericId = Number(organizationId);

        if (!isNaN(numericId)) {
          localStorage.setItem("organization_id_str", String(numericId));
        }
      }

      if (data?.userId) {
        localStorage.setItem("user_id", data.userId);
      }

      // Wait for organization_id to be stored in local storage
      await waitForOrganizationId();
      // const licenseResponse = await validateLicense();
      // if (!licenseResponse || licenseResponse?.data?.license_plan_details?.status !== "active") {
      //   showErrorToast("Your license is not active. Please contact support.");
      //   setLoading(false);
      //   return;
      // }
      // dispatch(setLicenseData(licenseResponse?.data));
      const response = await new Promise((resolve) =>
        setTimeout(async () => {
          const apiResponse = await getAllWebsites();
          resolve(apiResponse);
        }, 1000)
      );

      // Navigate based on response
      if (response?.status_code === 404) {
        navigate("/add-website");
      } else {
        showSuccessToast("Logged in successfully!");
        navigate("/dashboard");
      }
    } catch (error) {
      handleSignInError(error);
      showErrorToast(error);
    } finally {
      setLoading(false);
    }
  };

  // Helper function to wait for organization_id
  const waitForOrganizationId = async () => {
    return new Promise((resolve) => {
      const checkStorage = () => {
        const storedOrgId = localStorage.getItem("organization_id_str");
        if (storedOrgId && !isNaN(Number(storedOrgId))) {
          resolve();
        } else {
          setTimeout(checkStorage, 100); // Retry after 100ms
        }
      };
      checkStorage();
    });
  };

  // Error handler function
  const handleSignInError = (error) => {
    if (error.name === "NotAuthorizedException") {
      setError("Incorrect username or password.");
    } else if (error.name === "UserNotConfirmedException") {
      setError("Please verify your email before logging in.");
    } else if (error.name === "UserNotFoundException") {
      setError("User does not exist.");
    } else if (axios.isCancel(error)) {
      setError("Request canceled. Please try again.");
    } else {
      setError("An error occurred during sign in. Please try again.");
    }
  };

  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
      }}
    >
      {/* Left side - Scrollable Illustration */}
      <Grid
        item
        md={6}
        lg={6}
        sx={{
          display: { xs: "none", md: "block" },
          background: "#F6F6F6",
          pl: 1,
        }}
      >
        {illustration}
      </Grid>

      {/* Right side - Form */}
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
          background: "#F6F6F6",
        }}
      >
        <Container
          component="main"
          maxWidth={false}
          sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#F8F8F8",
            padding: 3,
          }}
        >
          {/* Logo at the top */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 4,
            }}
          >
            <img src={myIcon} alt="logo" width="250" height="50" />
          </Box>

          {/* Form Section */}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: 450,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* Text above the form */}
              <Typography
                variant="h5"
                sx={{
                  mb: 4,
                  color: "#5F5F5F",
                  fontWeight: 600,
                  fontFamily: "DM Sans",
                }}
              >
                Sign in to your account
              </Typography>

              <Paper
                elevation={2}
                sx={{
                  width: "90%",
                  p: 4,
                  borderRadius: "12px",
                  backgroundColor: "white",
                  border: "none",
                }}
                variant="outlined"
              >
                <Box component="form" onSubmit={handleSubmit}>
                  <InputLabel shrink htmlFor="email" required>
                    User name or Email
                  </InputLabel>
                  <TextField
                    margin="none"
                    size="small"
                    required
                    fullWidth
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{ mb: 2 }}
                  />
                  <InputLabel shrink htmlFor="password" required>
                    Password
                  </InputLabel>
                  <TextField
                    margin="none"
                    size="small"
                    required
                    fullWidth
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    value={formData.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ mb: 2 }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end", // Align to the right
                    }}
                  >
                    <Link
                      href="/forgot-password"
                      sx={{
                        color: "#00B894",
                        textDecoration: "none",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                      disabled={isForgotPasswordDisabled}
                    >
                      Forgot password?
                    </Link>
                  </Box>

                  <Button
                    type="submit"
                    size="small"
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 2,
                      mb: 2,
                      "&:hover": {
                        backgroundColor: "#009B7D",
                        color: "white",
                      },
                      borderRadius: 1.5,
                      border: "2px solid #009B7D",
                      textTransform: "none",
                      fontSize: "1rem",
                      color: "#525455",
                      fontWeight: 700,
                    }}
                    disabled={loading}
                  >
                    {!loading ? "Sign In" : <CircularProgress size={20} />}
                  </Button>
                  <Typography color="error">{error}</Typography>
                </Box>
                <Link
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/sign-up")}
                >
                  Sign Up
                </Link>
              </Paper>
            </Box>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
