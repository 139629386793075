import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
  Switch,
  Button,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineItemClasses,
} from "@mui/lab";
import CommonHeader from "../CommonComponent/CommonHeader";
import {
  getIntegrationCategory,
  getUserIntegrations,
  getIntegrationById,
  updateIntegration,
} from "../api/integration";
import CustomBreadcrumb from "../CommonComponent/CommonBreadcrumb";
import { showErrorToast, showSuccessToast } from "../toastUtils";
import Loader from "../CommonComponent/Loader";
import { generateHTML } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import Image from "@tiptap/extension-image";
import Underline from "@tiptap/extension-underline";
import CommonTabs from "../CommonComponent/CommonTabs";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { getWebsiteById } from "../api/websiteService";
import { getFormattedTimestamp } from "../utils/dateUtils";
// import { useSelector } from "react-redux";

const Integrations = () => {
  const [activeIntegrations, setActiveIntegrations] = useState([]);
  const [allIntegrations, setAllIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [integrationEnabled, setIntegrationEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [integrationCategory, setIntegrationCateroy] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [htmlContent, setHtmlContent] = useState("");
  const [value, setValue] = useState("1"); // Default to "1"

  // const licenseData = useSelector(
  //   (state) => state?.license?.data?.license_plan_details
  // );

  const fetchIntegrations = async () => {
    try {
      setLoading(true);
      const response = await getUserIntegrations();
      if (response?.status_code === 200) {
        const data = response?.data;
        const active = data.filter((integration) => integration.is_enabled);
        const all = data.filter((integration) => !integration.is_enabled);

        setActiveIntegrations(active);
        setAllIntegrations(all);
        setLoading(false);
      } else {
        showErrorToast("Failed to fetch integrations: " + response?.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchIntegrationCategory = async () => {
    try {
      setLoading(true);
      const response = await getIntegrationCategory();
      if (response?.status_code === 200) {
        setIntegrationCateroy([{ name: "All" }, ...response?.data]);
        setLoading(false);
      } else {
        setLoading(false);
        showErrorToast(
          "Failed to fetch integration category: " + response?.message
        );
      }
    } catch (error) {
      showErrorToast(error);
      setLoading(false);
    }
  };

  const handleCardClick = async (integrationId) => {
    try {
      setLoading(true);
      setSelectedIntegration(null);
      const response = await getIntegrationById(integrationId);
      if (response?.status_code === 200) {
        setSelectedIntegration(response?.data);
        let contentJSON = response?.data?.installation_steps;

        if (typeof contentJSON === "string") {
          contentJSON = JSON.parse(contentJSON);
        }

        if (
          contentJSON?.type === "doc" &&
          Array.isArray(contentJSON?.content)
        ) {
          const html = generateHTML(contentJSON, [
            StarterKit,
            TextStyle,
            Image,
            Underline,
          ]);
          setHtmlContent(html);
        } 
        setLoading(false);
      } else {
        showErrorToast(
          "Failed to fetch integration details: " + response?.message
        );
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIntegrations();
    fetchIntegrationCategory();
  }, []);

  useEffect(() => {
    if (selectedIntegration) {
      setIntegrationEnabled(selectedIntegration?.is_enabled); // Sync with selected integration
    }
  }, [selectedIntegration]);

  const [timezone, setTimezone] = useState(null);

  const getWebsiteDetails = async () => {
    try {
      const websiteId = localStorage.getItem("website_id");
      const websiteData = await getWebsiteById(websiteId);
      const timezoneFromApi = websiteData?.data?.time_zone;
      setTimezone(timezoneFromApi);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    const fetchWebsiteDetails = async () => {
      await getWebsiteDetails();
    };
    fetchWebsiteDetails();
  }, []); // Run only once when component mounts

  const breadcrumbItems = [
    {
      text: "Integrations",
    },
    {
      text: selectedIntegration?.name,
    },
  ];

  // const isIntegrationSupported = (integrationName) => {
  //   try {
  //     if (!licenseData?.license_plan?.integration_support) return true;
  //       const supportedIntegrations = Array.isArray(licenseData.license_plan.integration_support)
  //       ? licenseData.license_plan.integration_support
  //       : [licenseData.license_plan.integration_support];
  
  //     return supportedIntegrations.includes(integrationName);
  //   } catch (error) {
  //     return true; // Default to allowing all integrations if there's an error
  //   }
  // };  

  const IntegrationCard = ({ integration, onClick, isDisabled }) => (
    <Card
      variant="outlined"
      sx={{
        p: 1,
        height: "100%",
        cursor: isDisabled ? "not-allowed" : "pointer",
        opacity: isDisabled ? 0.5 : 1,
        pointerEvents: isDisabled ? "none" : "auto",
        "&:hover": {
          boxShadow: isDisabled ? "none" : 2,
          transition: "box-shadow 0.3s ease-in-out",
        },
      }}
      onClick={() => !isDisabled && onClick(integration?.integration_id)}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ml: 1,
            }}
          >
            <img
              src={`/images/${integration.icon}`}
              alt={`${integration.name} Icon`}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
          <Box display="flex" alignItems="center" gap="10px" mt={2}>
            <Typography variant="subtitle1">{integration?.name}</Typography>
          </Box>
        </Box>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            mb: 2,
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
            textOverflow: "ellipsis",
          }}
        >
          {integration?.description}
        </Typography>
        <Box sx={{ display: "flex", gap: 1, mt: "auto" }}>
          <Chip
            label={integration?.integration_category}
            size="small"
            sx={{
              bgcolor: "#E6E7EB",
              height: 20,
              borderRadius: "4px",
            }}
          />
          {integration?.license_type !== null && (
            <Chip
              label={integration?.license_type}
              size="small"
              sx={{
                bgcolor: "#E6E7EB",
                height: 20,
                borderRadius: "4px",
              }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  const handleIntegrationUpdate = (e) => {
    setIntegrationEnabled(e.target.checked);
  };

  const handleUpdateClick = async () => {
    const integrationData = {
      is_enabled: integrationEnabled,
    };
    try {
      setLoading(true);
      const result = await updateIntegration(
        selectedIntegration?.integration_id,
        integrationData
      );
      if (result?.status_code === 200) {
        await fetchIntegrations();
        setSelectedIntegration(null);
        setLoading(false);
        showSuccessToast("Integration updated successfully");
      } else {
        showErrorToast("Failed to update integration: " + result?.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const filteredIntegrations = (
    Array.isArray(allIntegrations) ? allIntegrations : []
  ).filter((integration) =>
    selectedCategory === "All"
      ? true
      : integration?.integration_category === selectedCategory
  );

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const getDotColor = (type) =>
    type === "enabled" ? "success.main" : "error.main";

  // Function to get icon based on type
  const getIcon = (type) => (type === "enabled" ? <CheckCircle /> : <Cancel />);

  // Transform API data into usable format
  const formatEvents = (timeline) => {
    let events = [];
    timeline.forEach((entry) => {
      if (entry.enabled_on) {
        events.push({
          type: "enabled",
          timestamp: entry.enabled_on,
        });
      }
      if (entry.disabled_on) {
        events.push({
          type: "disabled",
          timestamp: entry.disabled_on,
        });
      }
    });
    return events.reverse();
  };

  const IntegrationTimeline = ({ timeline }) => {
    if (!timezone) {
      return <Loader />;
    }

    const events = formatEvents(timeline).map((event) => ({
      ...event,
      timestamp: event.timestamp,
    }));

    if (events.length === 0) {
      return (
        <Box mt={2} p={2}>
          <Typography variant="h6" color="textSecondary">
            No integration history available.
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        mt={2}
        p={2}
        sx={{
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Timeline
          sx={{
            padding: 0,
            margin: 0,
            [`& .${timelineItemClasses.root}:before`]: {
              display: "none",
            },
          }}
        >
          {events.map((event, index) => (
            <TimelineItem key={index} position="right">
              <TimelineSeparator>
                <TimelineDot sx={{ bgcolor: getDotColor(event.type) }}>
                  {getIcon(event.type)}
                </TimelineDot>
                {index !== events.length - 1 && (
                  <TimelineConnector sx={{ height: 40 }} />
                )}
              </TimelineSeparator>
              <TimelineContent sx={{ py: 1, px: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {event.type === "enabled"
                    ? "Integration Enabled"
                    : "Integration Disabled"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {getFormattedTimestamp(event.timestamp, timezone)}{" "}
                  {/* Already formatted */}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CommonHeader
          title="Integrations"
          subTitle="You can enable and disable the integrations on this page. Please follow the steps on the respective integration page for more details."
          sx={{ flex: 1 }}
        />
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          {!selectedIntegration && (
            <Box sx={{ p: 1 }}>
              {/* Active Integrations Section */}
              <Box sx={{ mb: 3 }}>
                <Typography
                  variant="h6"
                  color="#525455"
                  fontWeight="bold"
                  mb={2}
                >
                  Active Integrations
                </Typography>
                <Grid container spacing={2}>
                  {Array.isArray(activeIntegrations) &&
                  activeIntegrations.length > 0 ? (
                    activeIntegrations.map((integration) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={integration.integration_id}
                      >
                        <IntegrationCard
                          integration={integration}
                          onClick={handleCardClick}
                          // isDisabled={!isIntegrationSupported(integration.name)}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        sx={{ textAlign: "center", color: "gray", mt: 2 }}
                      >
                        No active integrations available
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>

              {/* All Integrations Section */}
              <Box
                sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="h6" fontWeight="bold" color="#525455">
                  All Integrations
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  {(Array.isArray(integrationCategory)
                    ? integrationCategory
                    : []
                  ).map((filter) => (
                    <Chip
                      key={filter?.name}
                      label={filter?.name}
                      onClick={() => handleCategoryClick(filter?.name)}
                      color={
                        filter?.name === selectedCategory
                          ? "primary"
                          : "default"
                      }
                      variant="outlined"
                      sx={{
                        "&.MuiChip-root": {
                          borderRadius: 1,
                          height: 32,
                        },
                      }}
                    />
                  ))}
                </Box>
              </Box>

              <Grid container spacing={2}>
                {Array.isArray(filteredIntegrations) &&
                filteredIntegrations.length > 0 ? (
                  (Array.isArray(filteredIntegrations)
                    ? filteredIntegrations
                    : []
                  ).map((integration) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={integration.integration_id}
                    >
                      <IntegrationCard
                        integration={integration}
                        onClick={handleCardClick}
                        // isDisabled={!isIntegrationSupported(integration.name)}
                      />
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{ textAlign: "center", color: "gray", mt: 2 }}
                    >
                      No data available
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}

          {selectedIntegration && (
            <>
              <Box sx={{ mb: 2 }}>
                <CustomBreadcrumb
                  items={breadcrumbItems}
                  onBackClick={() => setSelectedIntegration(null)}
                />
              </Box>

              <Box>
                {/* Header Section */}
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        borderRadius: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mr: 1,
                      }}
                    >
                      <img
                        src={`/images/${selectedIntegration?.icon}`}
                        alt={`${selectedIntegration?.name} Icon`}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Typography variant="h6" fontWeight="bold" color="#525455">
                      {selectedIntegration?.name}
                    </Typography>
                    <Chip
                      variant="filled"
                      label={selectedIntegration?.integration_category}
                      sx={{
                        fontSize: "15px",
                        fontWeight: 400,
                        background: "#E6E7EB",
                        borderRadius: "4px",
                        mr: 2,
                        ml: 2,
                      }}
                    />
                    {selectedIntegration?.license_type !== null && (
                      <Chip
                        variant="filled"
                        label={selectedIntegration?.license_type}
                        sx={{
                          fontSize: "15px",
                          fontWeight: 400,
                          background: "#E6E7EB",
                          borderRadius: "4px",
                          mr: 2,
                          ml: 2,
                        }}
                      />
                    )}
                  </Box>
                  <Switch
                    color="primary"
                    checked={integrationEnabled}
                    onChange={handleIntegrationUpdate}
                  />
                  <Button
                    sx={{
                      border: `2px solid #00B894`,
                      borderRadius: "8px",
                      padding: "8px 12px",
                      textTransform: "capitalize",
                      fontFamily: "DM Sans",
                      fontSize: "15px",
                      "&:hover": {
                        backgroundColor: "#00B894",
                        color: "#fff",
                      },
                      color: "#525455",
                      fontWeight: "bold",
                      "& > *:first-child": {
                        marginRight: "8px",
                      },
                      height: "48px",
                    }}
                    onClick={handleUpdateClick}
                    disabled={
                      selectedIntegration?.is_enabled === integrationEnabled
                    }
                  >
                    Update
                  </Button>
                </Box>
                {/* Description */}
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#5f6368",
                    mb: 2,
                    maxWidth: "800px",
                  }}
                >
                  {selectedIntegration.description}
                </Typography>
                <Divider />
                <CommonTabs
                  value={value}
                  handleChange={handleChange}
                  tabs={[
                    {
                      label: "Installation Steps",
                      value: "1",
                      content: (
                        <Box mt={2}>
                          <Box
                            sx={{
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              p: 2,
                              // mt: 3,
                              backgroundColor: "#f9f9f9",
                              overflowY: "auto",
                              "& img": {
                                maxWidth: "100%",
                                height: "auto",
                              },
                            }}
                            dangerouslySetInnerHTML={{ __html: htmlContent }} // Render the HTML
                          />
                        </Box>
                      ),
                    },
                    {
                      label: "Integration History",
                      value: "2",
                      content: (
                        <IntegrationTimeline
                          timeline={selectedIntegration?.time_line}
                        />
                      ),
                    },
                  ]}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Integrations;
