import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Typography,
  Grid,
  Select,
  LinearProgress,
  MenuItem,
  Divider,
  Paper,
} from "@mui/material";
import {
  RemoveRedEyeOutlined,
  PersonOutlineOutlined,
  CheckCircleOutline,
  TrendingUpOutlined,
  TrendingDownOutlined,
  AutorenewRounded,
} from "@mui/icons-material";
import {
  LineChart,
  Line,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  Legend,
} from "recharts";
import CommonHeader from "../../CommonComponent/CommonHeader";
import {
  getDashboardConsents,
  getDashboardCountires,
  getDashboardDevices,
  getDashboardStats,
  getDashboardVisitors,
} from "../../api/dashboard";
import Loader from "../../CommonComponent/Loader";
import OnboardingProgress from "./OnboardingProgress";
import { fetchUserAttributes } from "@aws-amplify/auth";
import Flag from "react-world-flags";
import { getWebsiteById } from "../../api/websiteService";
import { convertHourToTimezone } from "../../utils/dateUtils";
import { getConsentDetails } from "../../api/consentLog";
import { ClosedDataRequests, OpenDataRequests, TotalDataRequests } from "../../assets/svg";

const Dashboard = () => {
  const [countries, setCountries] = useState([]);
  const [stats, setStats] = useState({});
  const [consents, setConsents] = useState([]);
  const [visitors, setVisitors] = useState([]);
  const [device, setDevice] = useState([]);
  const [selectedDays, setSelectedDays] = useState(1); // Default to last 24 hours

  const [loading, setLoading] = useState(true);
  const [isOnboardingRequired, setIsOnboardingRequired] = useState(false);
  const [websiteId, setWebsiteId] = useState(null);
  const [timezone, setTimezone] = useState(null);

  // Fetch website ID and store it
  const fetchWebsiteById = async () => {
    try {
      setLoading(true);
      const user = await fetchUserAttributes();
      const id = user?.website;

      if (id) {
        localStorage.setItem("website_id", id);
        setWebsiteId(id); // Set websiteId state
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // Fetch website details like timezone
  const getWebsiteDetails = async (id) => {
    setLoading(true);
    try {
      const websiteData = await getWebsiteById(id);
      const timezoneFromApi = websiteData?.data?.time_zone;
      setTimezone(timezoneFromApi);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  // Fetch all dashboard data
  const fetchAllData = async (id) => {
    setLoading(true);
    try {
      const [
        countriesResponse,
        statsResponse,
        devicesResponse,
        consentsResponse,
        visitorsResponse,
      ] = await Promise.all([
        getDashboardCountires(selectedDays),
        getDashboardStats(selectedDays),
        getDashboardDevices(selectedDays),
        getDashboardConsents(selectedDays),
        getDashboardVisitors(selectedDays),
      ]);

      // Process data
      const countriesData = countriesResponse?.data || [];
      const statsData = statsResponse?.data || {};
      const devicesData = Array.isArray(devicesResponse?.data)
        ? devicesResponse.data
        : [];
      const consentsData = Array.isArray(consentsResponse?.data)
        ? consentsResponse.data.map((item) => ({
            date:
              selectedDays === 1
                ? convertHourToTimezone(item.date, timezone)
                : item.date,
            fullConsents: parseInt(item.full_consent),
            customConsents: parseInt(item.custom_consent),
            onlyNecessary: parseInt(item.only_necessary),
            noDecision: parseInt(item.no_decision),
          }))
        : [];
      const visitorsData = Array.isArray(visitorsResponse?.data)
        ? visitorsResponse.data.map((item) => ({
            date:
              selectedDays === 1
                ? convertHourToTimezone(item.date, timezone)
                : item.date,
            pageViews: parseInt(item.pageviews),
            uniqueVisitors: parseInt(item.unique_visitors),
          }))
        : [];

      // Set state with fetched data
      setCountries(countriesData);
      setStats(statsData);
      setDevice(devicesData);
      setConsents(consentsData);
      setVisitors(visitorsData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getConsentLogs = async () => {
    setLoading(true);
    try {
      const res = await getConsentDetails();
      // Check if API returned 404 or empty data
      if (
        res?.status_code === 404 ||
        !res?.data ||
        Object.keys(res.data).length === 0
      ) {
        setIsOnboardingRequired(true);
        return;
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeWebsite = async () => {
      try {
        await fetchWebsiteById();
        getConsentLogs();
      } catch (error) {}
    };
    initializeWebsite();
  }, []);

  // After websiteId is set, fetch website details
  useEffect(() => {
    if (websiteId) {
      getWebsiteDetails(websiteId);
    }
  }, [websiteId]);

  useEffect(() => {
    if (websiteId && timezone !== null) {
      fetchAllData(websiteId);
    }
    // eslint-disable-next-line
  }, [websiteId, timezone, selectedDays]);

  const refreshData = () => {
    fetchAllData();
  };

  const formatNumber = (num) => {
    if (num === null || num === undefined) return "0";
    const number = Number(num);
    if (number >= 1_000_000_000) {
      return (number / 1_000_000_000).toFixed(1) + "B";
    } else if (number >= 1_000_000) {
      return (number / 1_000_000).toFixed(1) + "M";
    } else if (number >= 1_000) {
      return (number / 1_000).toFixed(1) + "K";
    } else {
      return number.toLocaleString();
    }
  };

  const StatCard = ({ icon, title, value, growth }) => (
    <Card
      variant="outlined"
      sx={{
        p: 3,
        height: "100%",
        borderRadius: 2,
        bgcolor: "white",
        pb: 0,
        pl: 0,
        pr: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          pl: 3,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0, 200, 150, 0.1)",
              mr: 2,
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: "400", color: "#989898" }}
            >
              {title}
            </Typography>
            <Typography
              variant="h6"
              sx={{ mb: 1, fontWeight: "bold", color: "#525455" }}
            >
              {formatNumber(value)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background:
              growth > 0 ? "#F0FFFC" : growth < 0 ? "#FFEFF2" : "#E0E0E0", // Grey background for growth = 0
            padding: "4px 8px",
            borderRadius: 2,
            mr: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color:
                growth > 0 ? "#00B894" : growth < 0 ? "#FF2E57" : "#9E9E9E", // Grey color for growth = 0
              fontWeight: "bold",
            }}
          >
            {growth > 0 ? (
              <TrendingUpOutlined fontSize="8px" />
            ) : growth < 0 ? (
              <TrendingDownOutlined fontSize="8px" />
            ) : (
              <TrendingUpOutlined fontSize="8px" /> // You can use any icon here for growth = 0 or leave it empty
            )}
            {growth !== 0 && (growth > 0 ? "+" : "")}
            {Math.round(growth)}%
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background: "#F9FAFB",
          pl: 3,
        }}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ fontSize: "0.875rem" }}
        >
          {selectedDays === 1 ? "last 24 hours" : `last ${selectedDays} days`}
        </Typography>
      </Box>
    </Card>
  );

  if (loading) {
    <Loader />;
  }

  const getLast30DaysRange = () => {
    const currentDate = new Date();
    const pastDate = new Date(currentDate);
    pastDate.setDate(currentDate.getDate() - selectedDays);

    const startDate = pastDate?.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    });
    const endDate = currentDate?.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    });

    return `${startDate} - ${endDate}`;
  };

  const handleTimePeriodChange = (event) => {
    const newPeriod = event.target.value;
    setSelectedDays(newPeriod); // Update the selected days
  };

  const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload || !payload.length) {
      return null;
    }

    const data = payload[0]?.payload;

    return (
      <Paper
        elevation={3}
        sx={{
          p: 2,
          minWidth: 200,
          backgroundColor: "white",
          borderRadius: 1,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="h6" sx={{ fontSize: "1rem", fontWeight: 600 }}>
            {data?.name}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Total
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              <Typography
                variant="body"
                color="#525455"
                sx={{ fontWeight: 400 }}
              >
                {formatNumber(data?.value)}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background:
                    data?.percentage > 0
                      ? "#F0FFFC"
                      : data?.percentage < 0
                      ? "#FFEFF2"
                      : "#E0E0E0", // Grey background for growth = 0
                  padding: "4px 8px",
                  borderRadius: 2,
                  mr: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      data?.percentage > 0
                        ? "#00B894"
                        : data?.percentage < 0
                        ? "#FF2E57"
                        : "#9E9E9E", // Grey color for growth = 0
                    fontWeight: "bold",
                  }}
                >
                  {data?.percentage > 0 ? (
                    <TrendingUpOutlined fontSize="8px" />
                  ) : data?.percentage < 0 ? (
                    <TrendingDownOutlined fontSize="8px" />
                  ) : (
                    <TrendingUpOutlined fontSize="8px" /> // You can use any icon here for growth = 0 or leave it empty
                  )}
                  {data?.percentage !== 0 && (data?.percentage > 0 ? "+" : "")}
                  {Math.round(data?.percentage)}%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {isOnboardingRequired ? (
          <OnboardingProgress />
        ) : (
          <>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}
            >
              <CommonHeader
                title="Dashboard"
                subTitle={
                  <>
                    {getLast30DaysRange()}{" "}
                    <AutorenewRounded
                      onClick={refreshData}
                      sx={{ cursor: "pointer" }}
                    />{" "}
                  </>
                }
              />
              <Box sx={{ display: "flex" }}>
                <Select
                  value={selectedDays}
                  onChange={handleTimePeriodChange} // Add this handler
                  displayEmpty
                  inputProps={{ "aria-label": "Select time period" }}
                  sx={{
                    textTransform: "capitalize",
                    color: "black",
                    minWidth: 120,
                    height: 40,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "green",
                      },
                      "&:focus fieldset": {
                        borderColor: "green",
                      },
                    },
                  }}
                >
                  <MenuItem value={1}>Last 24 Hours</MenuItem>
                  <Divider />
                  <MenuItem value={7}>Last 7 Days</MenuItem>
                  <Divider />
                  <MenuItem value={30}>Last 30 Days</MenuItem>
                  <Divider />
                  <MenuItem value={60}>Last 60 Days</MenuItem>
                  <Divider />
                  <MenuItem value={90}>Last 90 Days</MenuItem>
                </Select>
              </Box>
            </Box>

            {loading ? (
              <Loader />
            ) : (
              <>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12} md={4}>
                    <StatCard
                      icon={<RemoveRedEyeOutlined sx={{ color: "#00B894" }} />}
                      title="Page Views"
                      value={stats?.pageviews}
                      growth={stats?.pageviews_difference}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <StatCard
                      icon={<PersonOutlineOutlined sx={{ color: "#00B894" }} />}
                      title="Unique Visitors"
                      value={stats?.unique_visitors}
                      growth={stats?.unique_visitors_difference}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <StatCard
                      icon={<CheckCircleOutline sx={{ color: "#00B894" }} />}
                      title="Consents"
                      value={stats?.consents}
                      growth={stats?.consents_difference}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12} md={4}>
                    <StatCard
                      icon={<TotalDataRequests />}
                      title="Total Data Requests"
                      value={stats?.data_requests}
                      growth={stats?.data_requests_difference}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <StatCard
                      icon={<OpenDataRequests />}
                      title="Open Data Requests"
                      value={stats?.open_requests}
                      growth={stats?.open_requests_difference}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <StatCard
                      icon={<ClosedDataRequests />}
                      title="Closed Data Requests"
                      value={stats?.closed_requests}
                      growth={stats?.closed_requests_difference}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <Card
                      variant="outlined"
                      sx={{
                        p: 3,
                        height: "100%",
                        borderRadius: 2,
                        border: "1px solid #D7D7D7",
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        fontWeight="bold"
                        sx={{ color: "#525455", mb: 2 }}
                      >
                        Visitors
                      </Typography>
                      <LineChart
                        width={700}
                        height={250}
                        data={visitors}
                        margin={{
                          top: 8,
                          right: 30,
                          left: 20,
                          bottom: 5,
                          color: "#525455",
                        }}
                      >
                        <XAxis
                          dataKey="date"
                          stroke="#999"
                          tick={{
                            fill: "#525455",
                            fontSize: 12,
                            fontWeight: 400,
                          }}
                        />
                        <YAxis
                          stroke="#999"
                          tick={{
                            fill: "#525455",
                            fontSize: 12,
                            fontWeight: 400,
                          }}
                        />
                        <Tooltip />
                        <Line
                          type="monotone"
                          dataKey="pageViews"
                          stroke="#00B894"
                          strokeWidth={1}
                          dot={true}
                        />
                        <Line
                          type="monotone"
                          dataKey="uniqueVisitors"
                          stroke="#3CC3DF"
                          strokeWidth={1}
                          dot={false}
                          strokeDasharray="5 5"
                        />
                        <Legend
                          layout="horizontal"
                          verticalAlign="top"
                          align="right"
                        />
                      </LineChart>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Card
                      variant="outlined"
                      sx={{
                        p: 3,
                        height: "100%",
                        borderRadius: 2,
                        border: "1px solid #D7D7D7",
                        overflowY: "auto",
                        maxHeight: "350px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        fontWeight="bold"
                        sx={{ color: "#525455", mb: 2 }}
                      >
                        Consent By Countries
                      </Typography>
                      {(Array.isArray(countries)
                        ? [...countries].sort((a, b) => b.count - a.count)
                        : []
                      ).map((country) => {
                        const countryName = country?.name ?? "Other"; // Set default name
                        const isoCode = country?.iso_code;
                        const percentage =
                          typeof country.percentage === "string"
                            ? parseFloat(country.percentage.replace("%", ""))
                            : country.percentage;
                        return (
                          <Box
                            key={countryName}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: 2,
                              gap: 2,
                            }}
                          >
                            {/* Left Section */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flex: 1,
                                gap: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  width: 35,
                                  height: 35,
                                  borderRadius: "50%",
                                  border: "1px solid #DADADA",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Flag
                                  code={isoCode}
                                  fallback={
                                    <img
                                      src={"/images/default.svg"}
                                      alt="default"
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                        objectFit: "contain",
                                      }}
                                    />
                                  }
                                  alt={`${isoCode} flag`}
                                  style={{ width: 16, height: 16 }}
                                />
                              </Box>
                              <Box sx={{ flex: 1 }}>
                                <Typography
                                  variant="body2"
                                  color="#525455"
                                  sx={{ fontWeight: 400, marginBottom: 0.5 }}
                                >
                                  {countryName}
                                </Typography>
                                <LinearProgress
                                  variant="determinate"
                                  value={percentage}
                                  sx={{
                                    height: 4,
                                    borderRadius: 3,
                                    backgroundColor: "#F0F0F0",
                                    "& .MuiLinearProgress-bar": {
                                      backgroundColor: "#00B894",
                                    },
                                  }}
                                />
                              </Box>
                            </Box>

                            {/* Right Section */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  background:
                                    country?.percentage > 0
                                      ? "#F0FFFC"
                                      : country?.percentage < 0
                                      ? "#FFEFF2"
                                      : "#E0E0E0",
                                  padding: "4px 8px",
                                  borderRadius: 2,
                                  mr: 2,
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color:
                                      country?.percentage > 0
                                        ? "#00B894"
                                        : country?.percentage < 0
                                        ? "#FF2E57"
                                        : "#9E9E9E",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {country?.percentage > 0 ? (
                                    <TrendingUpOutlined fontSize="8px" />
                                  ) : country?.percentage < 0 ? (
                                    <TrendingDownOutlined fontSize="8px" />
                                  ) : (
                                    <TrendingUpOutlined fontSize="8px" />
                                  )}
                                  {country?.percentage !== 0 &&
                                    (country?.percentage > 0 ? "+" : "")}
                                  {Math.round(country?.percentage)}%
                                </Typography>
                              </Box>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontWeight: 500,
                                  color: "#525455",
                                }}
                              >
                                {formatNumber(country?.count)}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Card>
                  </Grid>

                  {/* Consents Chart */}
                  <Grid item xs={12} md={8}>
                    <Card
                      variant="outlined"
                      sx={{
                        p: 3,
                        height: "100%",
                        borderRadius: 2,
                        border: "1px solid #D7D7D7",
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        fontWeight="bold"
                        sx={{ color: "#525455", mb: 2 }}
                      >
                        Consents
                      </Typography>
                      <LineChart
                        width={700}
                        height={300}
                        data={consents}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <XAxis
                          dataKey="date"
                          stroke="#999"
                          tick={{
                            fill: "#525455",
                            fontSize: 12,
                            fontWeight: 400,
                          }}
                        />
                        <YAxis
                          stroke="#999"
                          tick={{
                            fill: "#525455",
                            fontSize: 12,
                            fontWeight: 400,
                          }}
                        />
                        <Tooltip />
                        <Line
                          type="monotone"
                          dataKey="fullConsents"
                          stroke="#6366f1"
                          strokeWidth={1}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey="customConsents"
                          stroke="#f59e0b"
                          strokeWidth={1}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey="onlyNecessary"
                          stroke="#10b981"
                          strokeWidth={1}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey="noDecision"
                          stroke="#ef4444"
                          strokeWidth={1}
                          dot={false}
                        />
                        <Legend
                          layout="horizontal"
                          verticalAlign="top"
                          align="right"
                        />
                      </LineChart>
                    </Card>
                  </Grid>

                  {/* Devices Chart */}
                  <Grid item xs={12} md={4}>
                    <Card
                      variant="outlined"
                      sx={{
                        p: 3,
                        height: "100%",
                        borderRadius: 2,
                        border: "1px solid #D7D7D7",
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        fontWeight="bold"
                        sx={{ color: "#525455", mb: 2 }}
                      >
                        Consent By Devices
                      </Typography>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <PieChart width={400} height={300}>
                          {/* Main Pie Chart */}
                          <Pie
                            data={device}
                            cx="50%"
                            cy="50%"
                            innerRadius={80}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            nameKey="name"
                            label={({ name, value }) =>
                              `${formatNumber(value)}`
                            }
                          >
                            {(Array.isArray(device) ? device : []).map(
                              (_, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={
                                    [
                                      "#10b981",
                                      "#6366f1",
                                      "#1E90FF",
                                      "#FFA502",
                                      "#D7D7D7",
                                    ][index]
                                  }
                                />
                              )
                            )}
                          </Pie>
                          <Tooltip
                            content={<CustomTooltip />}
                            wrapperStyle={{ outline: "none" }}
                          />
                          <Legend
                            verticalAlign="top"
                            align="left"
                            iconType="circle"
                            formatter={(value) => (
                              <span
                                style={{ color: "#525455", fontSize: "14px" }}
                              >
                                {value}
                              </span>
                            )}
                            wrapperStyle={{
                              display: "flex",
                              flexWrap: "wrap",
                              paddingLeft: "10px",
                              justifyContent: "flex-start",
                              gap: "10px",
                            }}
                          />
                        </PieChart>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
