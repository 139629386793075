import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, Divider, IconButton, Modal, Typography } from "@mui/material";
import Sidebar from "../../CommonComponent/Sidebar";
import ButtonCommon from "../../CommonComponent/ButtonCommon";
import SearchIcon from "@mui/icons-material/Search";
import GeneralSettings from "./Sections/GeneralSettings";
import BannerSettings from "./Sections/BannerSettings";
import PreferenceModal from "./Sections/PreferenceModal";
import ThemeSettings from "./Sections/ThemeSettings";
import LanguageSettings from "./Sections/LanguageSettings";
import FrameworkSettings from "./Sections/FrameworkSettings";
import PreviewModal from "./Components/PreviewModal";
import {
  showSuccessToast,
  showErrorToast,
  showInfoToast,
} from "../../toastUtils";
import ManagePreferencesModel from "./Components/PreviewPreferenceModal";
import {
  frontendToBackendMapper,
  getBannerById,
  getBannerTranslations,
  updateBanner,
} from "../../api/bannerService";
import { getAllLanguages } from "../../api/websiteService";
import { CloseOutlined } from "@mui/icons-material";
import WarningIcon from '@mui/icons-material/Warning';

const ConfirmPopup = ({ openModal, setOpenModal, handleConfirmOk }) => {
  const handleClose = () => setOpenModal(false)

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
    >
      <Box sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "#FFFFFF",
        borderRadius: "16px",
        boxShadow: 24,
        p: 3,
      }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" color="warning" fontWeight="bold" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <WarningIcon /> <span>Warning</span>
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Typography color="#989898">
          You have modified the content of the banner. Please ensure translations are updated for all required languages before saving.
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box gap={2} display="flex">
          <Button
            variant="outlined"
            sx={{
              border: "2px solid #00B894",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
            onClick={handleConfirmOk}
          >
            Ok
          </Button>
          <Button
            variant="outlined"
            sx={{
              border: "2px solid #CECECE",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

const BannerPage = () => {
  const { bannerId } = useParams();
  const navigate = useNavigate();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isManagePreferencesOpen, setIsManagePreferencesOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("General Settings");
  const [isLastActiveBanner, setIsLastActiveBanner] = useState(false);
  const [originalBannerData, setOriginalBannerData] = useState(null); //creat this for checking name is changed in updatebanner
  const [initialSetupComplete, setInitialSetupComplete] = useState(false);
  const [errors, setErrors] = useState({
    bannerName: false,
  });
  const [formData, setFormData] = useState(null)
  // const [initiaFormData, setInitialFormData] = useState(null);
  const [allLanguage, setAllLanguage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [savedTranslations, setSavedTranslations] = useState({});
  const [isMandatoryToTrans, setIsMandatoryToTrans] = useState(null);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (bannerId) {
  //     const initialData = JSON.parse(localStorage.getItem("bannerFormData"));
  //     console.log("Initial data:", initialData);
  //     setInitialFormData(initialData);
  //   }
  //   //eslint-disable-next-line
  // }, []);

  // Check for data from the setup modal when component mounts
  useEffect(() => {
    if (!bannerId) {
      // console.log('if banner id is not there');

      try {
        const storedData = JSON.parse(localStorage.getItem("bannerFormData"));
        if (storedData && storedData.frameworkSettings && storedData.generalSettings) {
          // Update the form data with the initial setup data
          // console.log("Stored data found:", storedData);

          setFormData(prevData => ({
            ...prevData,
            frameworkSettings: {
              ...prevData?.frameworkSettings,
              ...storedData?.frameworkSettings
            },
            generalSettings: {
              ...prevData?.generalSettings,
              ...storedData?.generalSettings
            }
          }));

          // Mark initial setup as complete and skip the framework tab
          setInitialSetupComplete(true);
        }
      } catch (error) {
        console.error("Error parsing stored banner form data:", error);
      }
    }
  }, [bannerId]);

  // Save form data to localStorage whenever it changes
  useEffect(() => {
    // console.log('1', formData)
    localStorage.setItem("bannerFormData", JSON.stringify(formData));
  }, [formData]);

  const validateForm = () => {
    const newErrors = {
      bannerName:
        !formData?.generalSettings?.bannerName ||
        formData?.generalSettings?.bannerName.trim() === "",
    };

    setErrors(newErrors);
    // Show toast message if banner name is empty
    if (newErrors.bannerName) {
      showErrorToast("Please enter a banner name");
    }
    return !Object.values(newErrors).some((error) => error);
  };

  const handleInputChange = (section, newData) => {
    if (section === "generalSettings" && "bannerName" in newData) {
      setErrors((prev) => ({ ...prev, bannerName: false }));
    }
    // If changing active status and this is the last active banner
    if (
      section === "generalSettings" &&
      "isActive" in newData &&
      isLastActiveBanner &&
      !newData.isActive
    ) {
      showInfoToast(
        "Cannot deactivate the last active banner. Please activate another banner first."
      );
      return;
    }

    setFormData((prev) => {
      const updated = {
        ...prev,
        [section]: {
          ...prev[section],
          ...newData,
        },
      };

      return updated;
    });
  };

  const handlePreview = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  const handleOpenManagePreferences = () => {
    // console.log("handleOpenManagePreferences called"); // Debug log
    setIsPreviewOpen(false);
    setIsManagePreferencesOpen(true);
  };

  const handleCloseManagePreferences = () => {
    setIsManagePreferencesOpen(false);
  };

  // Add loading state
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }
    // Check if trying to deactivate the last active banner
    if (isLastActiveBanner && !formData.generalSettings.isActive) {
      showInfoToast(
        "Cannot deactivate the last active banner. Please activate another banner first."
      );
      return;
    }
    setIsSaving(true);
    try {
      const mappedData = frontendToBackendMapper(formData);
      // const supportedLanguages = formData.frameworkSettings.supportedLanguages;

      let savedBannerData;
      if (bannerId) {
        // Update existing banner
        const updateData = { ...mappedData };

        // Check if name is unchanged
        if (updateData?.name === originalBannerData?.banner_html_data?.name) {
          // Remove name from banner_html_data
          delete updateData.name;
        }

        if (!updateData?.framework_id || updateData?.framework_id === '') {
          delete updateData.framework_id;
        }

        if (updateData?.language_settings) {
          delete updateData.language_settings;
        }

        // Checking the banner field data is changed
        const isEdited = updateData?.consent_title !== originalBannerData?.banner_html_data?.consent_title ||
          updateData?.consent_description !== originalBannerData?.banner_html_data?.consent_description ||
          updateData?.banner_accept_btn_text !== originalBannerData?.banner_html_data?.banner_accept_btn_text ||
          updateData?.banner_accept_necessary_btn_text !== originalBannerData?.banner_html_data?.banner_accept_necessary_btn_text ||
          updateData?.banner_preferences_btn_text !== originalBannerData?.banner_html_data?.banner_preferences_btn_text ||
          updateData?.settings_title !== originalBannerData?.banner_html_data?.settings_title ||
          updateData?.settings_description !== originalBannerData?.banner_html_data?.settings_description ||
          updateData?.settings_more_info_title !== originalBannerData?.banner_html_data?.settings_more_info_title ||
          updateData?.settings_more_info !== originalBannerData?.banner_html_data?.settings_more_info ||
          updateData?.preferences_accept_btn_text !== originalBannerData?.banner_html_data?.preferences_accept_btn_text ||
          updateData?.preferences_accept_necessary_btn_text !== originalBannerData?.banner_html_data?.preferences_accept_necessary_btn_text ||
          updateData?.save_preferences_btn_text !== originalBannerData?.banner_html_data?.save_preferences_btn_text;

        // console.log('updateData', updateData, isEdited, originalBannerData);

        if (isEdited) {
          setOpenModal(true)
          return
        }

        const finalPayload = {
          banner_html_data: updateData,
          // supported_languages: supportedLanguages
        };

        // console.log("Update payload (before API call):", finalPayload);
        // console.log("Final payload after name removal:", finalPayload);
        savedBannerData = await Promise.race([
          updateBanner(bannerId, finalPayload), // You'll need to create this function
          new Promise((_, reject) =>
            setTimeout(
              () => reject(new Error("Request timed out after 30 seconds")),
              30000
            )
          ),
        ]);
      }


      // Update local state and storage
      setFormData(savedBannerData.data);
      localStorage.setItem("bannerFormData", JSON.stringify(savedBannerData));
      showSuccessToast(
        bannerId
          ? "Banner updated successfully!"
          : "Banner settings saved successfully!"
      );
      navigate("/banner");
    } catch (error) {

      // Show the exact error message without additional prefix if it's a 403
      if (error.status_code === 403 || error.success === false) {
        showErrorToast(error.message);
      } else {
        // For other errors, show with prefix
        const errorMessage = error.response?.data?.message || error.message;
        showErrorToast(`Failed to save banner settings: ${errorMessage}`);
      }
      return false;
    } finally {
      setIsSaving(false);
    }
  };


  const handleConfirmOk = async () => {
    setOpenModal(false);
    setIsSaving(true);
    try {
      const mappedData = frontendToBackendMapper(formData);
      // const supportedLanguages = formData.frameworkSettings.supportedLanguages;

      // Check if name is unchanged
      if (mappedData.name === originalBannerData?.banner_html_data?.name) {
        // Remove name from banner_html_data
        delete mappedData.name;
      }

      if (!mappedData.framework_id || mappedData.framework_id === '') {
        delete mappedData.framework_id;
      }

      if (mappedData.language_settings) {
        delete mappedData.language_settings;
      }

      const payload = {
        banner_html_data: mappedData,
        // supported_languages: supportedLanguages
      };

      const savedBannerData = await Promise.race([
        updateBanner(bannerId, payload),
        new Promise((_, reject) => setTimeout(() => reject(new Error("Request timed out after 30 seconds")), 30000)),
      ]);
      // console.log('savedBannerData', savedBannerData);


      setFormData(savedBannerData.data);
      localStorage.setItem("bannerFormData", JSON.stringify(savedBannerData));
      showSuccessToast("Banner updated successfully!");
      navigate("/banner");
    } catch (error) {
      console.log(error);
      showErrorToast(error.message);
    } finally {
      setIsSaving(false);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let mappedData;
    const fetchBannerData = async () => {
      setLoading(true);

      if (bannerId) {
        // console.log('first')
        try {
          const storedData = JSON.parse(localStorage.getItem("editBannerData"));
          setIsLastActiveBanner(storedData?.isLastActiveBanner || false);

          // First get banner data
          const response = await getBannerById(bannerId);
          // console.log('response getBannerById', response, storedData, formData);

          // console.log("API Response in banner page:", response); // Check raw API response
          // console.log("API Response frameworkID", response.data.banner_html_data.framework_id); // Check raw API response

          if (response?.data?.banner_html_data) {
            setOriginalBannerData(response.data); // Store original data
            const banner_html_data = response.data.banner_html_data;
            const banner_language_code = response.data.language_code || 'en';

            // Then get translations
            const translationsResponse = await getBannerTranslations(bannerId);
            // console.log('translations response:', translationsResponse);

            // Convert translations array to object
            const translationsObject = translationsResponse?.data?.reduce((acc, item) => {
              acc[item.language_code] = item;
              return acc;
            }, {}) || {};

            // Mark saved translations
            const savedTranslationsState = Object.keys(translationsObject).reduce((acc, key) => {
              acc[key] = true;
              return acc;
            }, {});
            setSavedTranslations(savedTranslationsState);

            setIsMandatoryToTrans(response.data.is_mandatory_to_trans)

            // Map all data including translations
            mappedData = {
              frameworkSettings: {
                frameworkId: response.data.banner_html_data.framework_id || null,
                languageName: response.data.language_code || 'en',
                supportedLanguages: Array.isArray(response.data.supported_languages)
                  ? response.data.supported_languages
                  : [response.data.language_code || 'en'],
                frameworkName: response.data.framework_name
              },
              generalSettings: {
                bannerName: banner_html_data.name || "",
                isActive: banner_html_data.enabled || false,
                forcedConsent: banner_html_data.forced_consent || false,
                consentDuration: banner_html_data.consent_duration_days?.toString() || "",
                targeting: banner_html_data.target_type || "WorldWide",
              },
              bannerSettings: {
                title: banner_html_data.consent_title || "",
                description: banner_html_data.consent_description || "",
                footerContent: banner_html_data.consent_additional_description || "",
                layout: banner_html_data.consent_layout,
                buttonPlacement: banner_html_data.consent_button_placement,
                position: banner_html_data.consent_position,
                equalWeight: banner_html_data.equal_weight_buttons,
                swapButtons: banner_html_data.swap_buttons,
                acceptAll: banner_html_data.show_banner_accept_btn,
                acceptNecessary: banner_html_data.show_banner_accept_necessary_btn,
                managePreferences: banner_html_data.show_banner_preferences_btn,
                managePreferencesText: banner_html_data.banner_preferences_btn_text,
                acceptAllText: banner_html_data.banner_accept_btn_text,
                acceptNecessaryText: banner_html_data.banner_accept_necessary_btn_text,
              },
              preferenceSettings: {
                enablePrivacyTrigger: banner_html_data.show_floating_preferences_icon || false,
                privacyTriggerPosition: banner_html_data.floating_preferences_icon_position,
                displayCookies: banner_html_data.show_services || true,
                controlLevel: banner_html_data.preferences_control_level,
                title: banner_html_data.settings_title,
                description: banner_html_data.settings_description,
                moreInfoTitle: banner_html_data.settings_more_info_title,
                moreInfoContent: banner_html_data.settings_more_info,
                layout: banner_html_data.settings_layout,
                modalAlignment: banner_html_data.settings_position_x,
                acceptAll: banner_html_data.show_preferences_accept_btn,
                acceptNecessary: banner_html_data.show_preferences_accept_necessary_btn,
                savePreferences: banner_html_data.show_save_preferences_btn,
                acceptAllText: banner_html_data.preferences_accept_btn_text,
                acceptNecessaryText: banner_html_data.preferences_accept_necessary_btn_text,
                savePreferencesText: banner_html_data.save_preferences_btn_text,
              },
              themeSettings: {
                darkMode: banner_html_data.dark_mode,
                background: banner_html_data.button_colors?.bg || "#333333",
                heading:
                  banner_html_data.button_colors?.heading_color || "#000000",
                text: banner_html_data.button_colors?.text_color || "#333333",
                link: banner_html_data.button_colors?.link_color || "#FFFFFF",
                primaryButtonBg:
                  banner_html_data.button_colors?.btn_primary_bg || "#717171",
                primaryButtonText:
                  banner_html_data.button_colors?.btn_primary_color ||
                  "#ffffff",
                secondaryButtonBg:
                  banner_html_data.button_colors?.btn_secondary_bg || "#717171",
                secondaryButtonText:
                  banner_html_data.button_colors?.btn_secondary_color ||
                  "#FFFFFF",
                darkModeBackground:
                  banner_html_data.button_colors?.dark_bg || "#FFFFFF",
                darkModeHeading:
                  banner_html_data.button_colors?.dark_heading_color ||
                  "#333333",
                darkModeText:
                  banner_html_data.button_colors?.dark_text_color || "#717171",
                darkModeLink:
                  banner_html_data.button_colors?.dark_link_color || "#FFFFFF",
                darkModePrimaryButtonBg:
                  banner_html_data.button_colors?.dark_btn_primary_bg ||
                  "#FFFFFF",
                darkModePrimaryButtonText:
                  banner_html_data.button_colors?.dark_btn_primary_color ||
                  "#333333",
                darkModeSecondaryButtonBg:
                  banner_html_data.button_colors?.dark_btn_secondary_bg ||
                  "#717171",
                darkModeSecondaryButtonText:
                  banner_html_data.button_colors?.dark_btn_secondary_color ||
                  "#FFFFFF",
                modalBorderRadius:
                  banner_html_data.modal_border_radius?.toString() || "0.5",
                buttonBorderRadius: banner_html_data.btn_border_radius || "0.4",
                logoUrl: banner_html_data.logo_url || "",
                darkModeLogoUrl: banner_html_data.dark_mode_logo_url || "",
              },
              languageSettings: {
                defaultLanguage: banner_html_data.default_language || banner_language_code || 'en',
                supportedLanguages: response.data.available_translations || [banner_language_code || 'en'],
                translations: translationsObject || {}, // Store translations here
              }
            };

            // console.log('Setting formData with:', mappedData);
            setFormData(mappedData);
          }
        } catch (error) {
          console.error('Error:', error);
          showErrorToast(error.message || 'Failed to fetch data');
        }
      }

      setLoading(false);
    };

    fetchBannerData();
    //eslint-disbale-next-line
  }, [bannerId]);

  const sidebarTabs = [
    "General Settings",
    "Banner",
    "Manage Preference",
    "Theme",
    "Framework",
    "Translations",
  ];

  const renderContent = () => {
    switch (activeTab) {
      case "Framework":
        return (
          <FrameworkSettings
            data={formData?.frameworkSettings}
            onUpdate={(data) => handleInputChange("frameworkSettings", data)}
            disableFrameworkSelection={!!bannerId} // Disable framework selection if bannerId exists
            allLanguage={allLanguage && allLanguage}
            loading={loading}
            bannerId={bannerId}
            translations={formData?.languageSettings.translations}
            onUpdateTranslation={(data) => handleInputChange("languageSettings", data)}
          />
        );
      case "General Settings":
        return (
          <GeneralSettings
            data={formData?.generalSettings}
            onUpdate={(data) => handleInputChange("generalSettings", data)}
            errors={errors}
            isLastActiveBanner={isLastActiveBanner} // Add this line
            loading={loading}
          />
        );
      case "Banner":
        return (
          <BannerSettings
            data={formData?.bannerSettings}
            onUpdate={(data) => handleInputChange("bannerSettings", data)}
            loading={loading}
          />
        );
      case "Manage Preference":
        return (
          <PreferenceModal
            data={formData?.preferenceSettings}
            onUpdate={(data) => handleInputChange("preferenceSettings", data)}
            loading={loading}
          />
        );
      case "Theme":
        return (
          <ThemeSettings
            data={formData?.themeSettings}
            onUpdate={(data) => handleInputChange("themeSettings", data)}
            loading={loading}
          />
        );
      case "Translations":
        return (
          <LanguageSettings
            data={{
              ...formData?.languageSettings,
              defaultLanguage: formData?.frameworkSettings?.languageName,
              supportedLanguages: formData?.frameworkSettings?.supportedLanguages,
              // translations: translations || {}
            }}
            bannerSettings={formData?.bannerSettings}
            preferenceSettings={formData?.preferenceSettings}
            onUpdate={(data) => handleInputChange('languageSettings', data)}
            bannerId={bannerId} // Pass bannerId to allow direct API calls for translations
            allLanguage={allLanguage && allLanguage}
            savedTranslations={savedTranslations}
            setSavedTranslations={setSavedTranslations}
            isMandatoryToTrans={isMandatoryToTrans}
            frameworkName={formData?.frameworkSettings?.frameworkName}
            loading={loading}
          />
        );
      default:
        return null;
    }
  };

  // Get all languages
  useEffect(() => {
    const fetchAllLanguage = async () => {
      try {
        const response = await getAllLanguages()

        if (response.success && response.data) {
          setAllLanguage(response.data)
        }
      } catch (err) {
        console.log(err);
      }
    }

    fetchAllLanguage()
  }, [])

  return (
    <>
      <Box
        sx={{
          height: "calc(100vh - 80px)",
          position: "relative",
          overflow: "hidden",
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            // borderBottom: "1px solid #e0e0e0",
            height: "64px",
            position: "sticky",
            top: 0,
            zIndex: 1000,
            flexShrink: 0,
          }}
        >
          <Typography>
            {/* {formData.generalSettings.bannerName || "New Banner"} */}
            <Typography variant="h5" component="h4">
              Setup Banner
            </Typography>
            <Typography variant="body2" sx={{ color: "#717171" }}>
              Customize your banner to match your website's look and feel or make
              it multilingual as per your requirement.
            </Typography>
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <ButtonCommon onClick={handlePreview} icon={<SearchIcon />} disabled={loading}>
              Preview
            </ButtonCommon>
            <ButtonCommon onClick={handleSave} disabled={loading || isSaving}>
              {/* {isSaving
                ? bannerId
                  ? "Updating..."
                  : "Saving..."
                : bannerId
                  ? "Update"
                  : "Save"} */}
              {isSaving ? 'Updating...' : 'Update'}
            </ButtonCommon>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            height: "calc(100% - 64px)",
            position: "relative",
            "@media (max-width: 768px)": {
              // For mobile devices
              flexDirection: "column",
            },
            mt: 3,
          }}
        >
          <Box
            sx={{
              width: "250px",
              borderRight: "1px solid #e0e0e0",
              bgcolor: "white",
              flexShrink: 0,
              // overflow: "hidden",
              "@media (max-width: 768px)": {
                width: "100%",
                borderRight: "none",
                borderBottom: "1px solid #e0e0e0",
              },
            }}
          >
            <Sidebar
              tabs={sidebarTabs}
              activeTab={initialSetupComplete && !bannerId ? "General Settings" : activeTab}
              onTabChange={setActiveTab}
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              p: 3,
              overflowY: "auto",
              maxWidth: "800px", // Maximum width for content
              margin: "0 auto", // Center content on larger screens
              width: "100%",
              "@media (max-width: 768px)": {
                maxWidth: "100%",
                padding: 2,
              },
            }}
          >
            {renderContent()}
          </Box>
        </Box>

        {isPreviewOpen && (
          <PreviewModal
            onClose={handleClosePreview}
            isOpen={isPreviewOpen}
            data={formData}
            onManagePreferences={handleOpenManagePreferences}
          />
        )}

        {isManagePreferencesOpen && (
          <ManagePreferencesModel
            isOpen={isManagePreferencesOpen}
            onClose={handleCloseManagePreferences}
            data={formData}
          />
        )}
      </Box>
      <ConfirmPopup openModal={openModal} setOpenModal={setOpenModal} handleConfirmOk={handleConfirmOk} />
    </>
  );
};

export default BannerPage;
