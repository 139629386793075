import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Paper,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CommonHeader from "../../CommonComponent/CommonHeader";
import CustomBreadcrumb from "../../CommonComponent/CommonBreadcrumb";
import CommonTabs from "../../CommonComponent/CommonTabs";
import {
  generateBreachPresignedUrl,
  getBreachData,
  updateDataBreach,
} from "../../api/dataBreach";
import { showErrorToast, showSuccessToast } from "../../toastUtils";
import Loader from "../../CommonComponent/Loader";
import BreachStatusTimeline from "./BreachStatusTimeline";
import { getWebsiteById } from "../../api/websiteService";
import { getFormattedTimestamp } from "../../utils/dateUtils";
import GenerateEmail from "./GenerateEmail";
import ButtonCommon from "../../CommonComponent/ButtonCommon";
import CommonDrawer from "../../CommonComponent/CommonDrawer";
import { UploadFile } from "@mui/icons-material";

const DataBreachDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [breachDetails, setBreachDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [timezone, setTimezone] = useState(null);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchBreachDetails = async () => {
      setLoading(true);
      try {
        const response = await getBreachData(id);
        setBreachDetails(response?.data || {});
      } catch (err) {
        showErrorToast("Failed to fetch breach details");
      } finally {
        setLoading(false);
      }
    };

    if (id) fetchBreachDetails();
  }, [id]);

  const getWebsiteDetails = async () => {
    setLoading(true);
    try {
      const websiteId = localStorage.getItem("website_id");
      const websiteData = await getWebsiteById(websiteId);
      const timezoneFromApi = websiteData?.data?.time_zone;
      setTimezone(timezoneFromApi);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWebsiteDetails();
  }, []);

  const breadcrumbItems = [
    { text: "Data Breach", href: "/data-breach" },
    { text: "Data Breach Details" },
  ];

  const breachInfoFields = [
    { key: "breach_id", label: "Breach ID" },
    { key: "days_lapsed", label: "Days Lapsed" },
    { key: "assignee_name", label: "Assignee" },
    { key: "summary", label: "Summary" },
    { key: "detailed_description", label: "Detailed Description" },
    {
      key: "date_and_time_of_breach",
      label: "Date and Time of Breach",
      format: (val) =>
        val ? getFormattedTimestamp(val, timezone) : "Not Available",
    },
    { key: "location_of_breach", label: "Location of Breach" },
    { key: "impact", label: "Impact" },
    { key: "mitigations", label: "Mitigations" },
    { key: "safety_measures_for_dp", label: "Safety Measures" },
    { key: "root_cause", label: "Root Cause" },
    { key: "responsible_person", label: "Responsible Person" },
    { key: "remedial_measures", label: "Remedial Measures" },
    { key: "intimations_to_dps", label: "Intimations to DPs" },
  ];

  const tabs = [
    {
      label: "Data Breach Timeline",
      value: "1",
      content: breachDetails?.status ? (
        <BreachStatusTimeline
          status={breachDetails?.status}
          time_zone={timezone}
          attachment={breachDetails?.attachment}
        />
      ) : (
        <Typography>No timeline available</Typography>
      ),
    },
    {
      label: "Email for DPs / DPB",
      value: "2",
      content: <GenerateEmail time_zone={timezone} />,
    },
  ];

  const [formData, setFormData] = useState({
    summary: "",
    detailed_description: "",
    location_of_breach: "",
    impact: "",
    mitigations: "",
    safety_measures_for_dp: "",
    root_cause: "",
    responsible_person: "",
    remedial_measures: "",
    intimations_to_dps: "",
  });

  // Populate form with existing breach details
  useEffect(() => {
    if (breachDetails) {
      setFormData({
        summary: breachDetails.summary || "",
        detailed_description: breachDetails.detailed_description || "",
        location_of_breach: breachDetails.location_of_breach || "",
        attachment: breachDetails.attachment || "",
        impact: breachDetails.impact || "",
        mitigations: breachDetails.mitigations || "",
        safety_measures_for_dp: breachDetails.safety_measures_for_dp || "",
        root_cause: breachDetails.root_cause || "",
        responsible_person: breachDetails.responsible_person || "",
        remedial_measures: breachDetails.remedial_measures || "",
        intimations_to_dps: breachDetails.intimations_to_dps || "",
      });
    }
  }, [breachDetails]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    // Remove error message when user starts typing
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const [fileError, setFileError] = useState("");

  const handleCreateFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    const fileSizeLimit = 10 * 1024 * 1024; // 10MB in bytes
    if (selectedFile.name.includes(" ")) {
      setFileError("File name should not contain spaces.");
      return;
    }
    if (selectedFile.size > fileSizeLimit) {
      setFileError("File size should not exceed 10MB.");
      return;
    }
    setFileError(""); // Clear error if valid
    setFormData({ ...formData, attachment: selectedFile });
  };

  // Handle Input Changes
  const validateFields = () => {
    let newErrors = {};

    Object.keys(formData).forEach((key) => {
      if (key === "attachment") return; // Skip validation for attachment field
      const value = formData[key];
      if (typeof value === "string" && !value.trim()) {
        newErrors[key] = `${key.replaceAll("_", " ")} is required`;
      } else if (value == null) {
        newErrors[key] = `${key.replaceAll("_", " ")} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle Update API Call
  const handleUpdate = async () => {
    if (!validateFields()) {
      return;
    }
    try {
      setLoading(true);
      let updatedFormData = { ...formData }; // Clone formData
      if (formData.attachment instanceof File) {
        const { presigned_url, file_name } = await generateBreachPresignedUrl(
          formData.attachment.name
        );

        if (!presigned_url) {
          throw new Error("Failed to get presigned URL");
        }
        const uploadResponse = await fetch(presigned_url, {
          method: "PUT",
          body: formData.attachment,
          headers: {
            "Content-Type": formData.attachment.type,
          },
        });

        if (!uploadResponse.ok) {
          throw new Error("File upload failed!");
        }
        updatedFormData.attachment = file_name;
      }
      const res = await updateDataBreach(id, updatedFormData);
      if (res?.status_code === 200) {
        const response = await getBreachData(id);
        setBreachDetails(response?.data || {});
        showSuccessToast(res?.message || "Breach details updated successfully");
        setOpen(false); // Close modal
      }
    } catch (error) {
      showErrorToast(
        error?.response?.data?.message || "Error updating breach details"
      );
    } finally {
      setLoading(false);
    }
  };

  const [initialFormData, setInitialFormData] = useState({});

  useEffect(() => {
    if (open) {
      setInitialFormData(formData); // Store initial state when opening
    }
    //eslint-disable-next-line
  }, [open]);

  const isFormChanged =
    JSON.stringify(formData) !== JSON.stringify(initialFormData);

  const latestStatusKey = breachDetails?.status
    ? Object.keys(breachDetails.status).pop() // Get the last status key
    : "Unknown";

  return (
    <Box>
      <Box sx={{ display: "flex", mb: 3 }}>
        <CommonHeader
          title="Data Breach Details"
          subTitle="Review breach details on this page."
        />
        <ButtonCommon
          onClick={() => setOpen(true)}
          disabled={latestStatusKey === "closed"}
        >
          Edit
        </ButtonCommon>
      </Box>

      <Box sx={{ mb: 4 }}>
        <CustomBreadcrumb
          items={breadcrumbItems}
          onBackClick={() => navigate("/data-breach")}
        />
      </Box>

      {loading ? (
        <Loader />
      ) : (
        Object.keys(breachDetails).length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 3, backgroundColor: "#F9FAFB", height: "100%" }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  color="#525455"
                  fontWeight="bold"
                >
                  Data Breach Information
                </Typography>
                <Divider />
                {breachInfoFields.map(({ key, label, format }, index) => {
                  const isLastItem = index === breachInfoFields.length - 1; // Check if it's the last item
                  return (
                    <Box key={key} sx={{ mb: 1, mt: 2 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ textTransform: "capitalize", display: "block" }}
                      >
                        {label}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ wordBreak: "break-word", whiteSpace: "normal" }}
                      >
                        {format
                          ? format(breachDetails[key])
                          : breachDetails[key] !== undefined &&
                            breachDetails[key] !== null
                          ? breachDetails[key]
                          : "Not Available"}
                      </Typography>
                      {!isLastItem && <Divider sx={{ mt: 2 }} />}{" "}
                      {/* Hide divider for the last item */}
                    </Box>
                  );
                })}
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <CommonTabs
                value={tabValue}
                handleChange={(_, newValue) => setTabValue(newValue)}
                tabs={tabs}
              />
            </Grid>
          </Grid>
        )
      )}
      <CommonDrawer
        open={open}
        onClose={() => setOpen(false)}
        title="Edit Breach Details"
        subTitle="Update the breach information as necessary."
        primaryButton={{
          label: "Update",
          onClick: handleUpdate,
          sx: { px: 4 },
          disabled: !isFormChanged || loading, // Disable button if no changes
        }}
        drawerWidth={500}
      >
        {/* Summary */}
        <Typography variant="body" gutterBottom fontWeight={500}>
          Summary
        </Typography>
        <TextField
          fullWidth
          size="small"
          name="summary"
          value={formData.summary}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          error={!!errors.summary}
          helperText={errors.summary}
        />

        {/* Detailed Description */}
        <Typography variant="body" gutterBottom fontWeight={500}>
          Detailed Description
        </Typography>
        <FormHelperText
          style={{ fontSize: "14px", color: "#666", marginBottom: "5px" }}
        >
          A description of the breach, including its nature, extent, and the
          timing and location of its occurrence.
        </FormHelperText>
        <TextareaAutosize
          minRows={3}
          name="detailed_description"
          value={formData.detailed_description}
          onChange={handleInputChange}
          style={{ width: "100%", padding: 8, fontSize: 14, marginBottom: 16 }}
        />
        {errors.detailed_description && (
          <p style={{ color: "red", fontSize: "12px", margin: 0 }}>
            {errors.detailed_description}
          </p>
        )}

        {/* Location of Breach */}
        <Typography variant="body" gutterBottom fontWeight={500}>
          Location of Breach
        </Typography>
        <TextField
          fullWidth
          size="small"
          name="location_of_breach"
          value={formData.location_of_breach}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          error={!!errors.location_of_breach}
          helperText={errors.location_of_breach}
        />

        {/* File Upload */}
        <Typography variant="body" gutterBottom fontWeight={500}>
          Attachment
        </Typography>
        <Button
          variant="contained"
          component="label"
          startIcon={<UploadFile />}
          fullWidth
          sx={{ mt: 1 }}
        >
          {formData.attachment instanceof File
            ? formData.attachment.name // If a new file is selected, show file name
            : formData.attachment || "Choose File"}
          <input
            type="file"
            accept=".pdf,.jpeg,.jpg"
            onChange={handleCreateFileChange}
            hidden
          />
        </Button>
        <Typography
          variant="caption"
          sx={{ mt: 1, color: "gray", display: "block" }}
        >
          * Accepted file formats: <strong>.jpg, .jpeg, .pdf</strong>
        </Typography>
        {errors.file && (
          <Typography color="error" variant="caption">
            {errors.file}
          </Typography>
        )}
        {fileError && (
          <Typography color="error" variant="caption">
            {fileError}
          </Typography>
        )}

        {/* Impact */}
        <Typography variant="body" gutterBottom fontWeight={500}>
          Impact
        </Typography>
        <FormHelperText
          style={{ fontSize: "14px", color: "#666", marginBottom: "5px" }}
        >
          The consequences relevant to his/her, that are likely to arise from
          the breach.
        </FormHelperText>
        <TextField
          fullWidth
          size="small"
          name="impact"
          value={formData.impact}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          error={!!errors.impact}
          helperText={errors.impact}
        />

        {/* Mitigations */}
        <Typography variant="body" gutterBottom fontWeight={500}>
          Mitigations
        </Typography>
        <FormHelperText
          style={{ fontSize: "14px", color: "#666", marginBottom: "5px" }}
        >
          The measures implemented and being implemented by the Data Fiduciary,
          if any, to mitigate risk.
        </FormHelperText>
        <TextField
          fullWidth
          size="small"
          name="mitigations"
          value={formData.mitigations}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          error={!!errors.mitigations}
          helperText={errors.mitigations}
        />

        {/* Safety Measures */}
        <Typography variant="body" gutterBottom fontWeight={500}>
          Safety Measures for Data Principal
        </Typography>
        <FormHelperText
          style={{ fontSize: "14px", color: "#666", marginBottom: "5px" }}
        >
          The safety measures that he/she may take to protect his/her interests.
        </FormHelperText>
        <TextField
          fullWidth
          size="small"
          name="safety_measures_for_dp"
          value={formData.safety_measures_for_dp}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          error={!!errors.safety_measures_for_dp}
          helperText={errors.safety_measures_for_dp}
        />

        {/* Root Cause */}
        <Typography variant="body" gutterBottom fontWeight={500}>
          Root Cause
        </Typography>
        <FormHelperText
          style={{ fontSize: "14px", color: "#666", marginBottom: "5px" }}
        >
          The broad facts related to the events, circumstances and reasons
          leading to the breach.
        </FormHelperText>
        <TextField
          fullWidth
          size="small"
          name="root_cause"
          value={formData.root_cause}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          error={!!errors.root_cause}
          helperText={errors.root_cause}
        />

        {/* Responsible Person */}
        <Typography variant="body" gutterBottom fontWeight={500}>
          Responsible Person for Breach
        </Typography>
        <FormHelperText
          style={{ fontSize: "14px", color: "#666", marginBottom: "5px" }}
        >
          Any findings regarding the person who caused the breach.
        </FormHelperText>
        <TextField
          fullWidth
          size="small"
          name="responsible_person"
          value={formData.responsible_person}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          error={!!errors.responsible_person}
          helperText={errors.responsible_person}
        />

        {/* Remedial Measures */}
        <Typography variant="body" gutterBottom fontWeight={500}>
          Remedial Measures
        </Typography>
        <FormHelperText
          style={{ fontSize: "14px", color: "#666", marginBottom: "5px" }}
        >
          Remedial measures taken to prevent recurrence of such breach.
        </FormHelperText>
        <TextField
          fullWidth
          size="small"
          name="remedial_measures"
          value={formData.remedial_measures}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          error={!!errors.remedial_measures}
          helperText={errors.remedial_measures}
        />

        {/* Intimations to DPs */}
        <Typography variant="body" gutterBottom fontWeight={500}>
          Intimations to DPs
        </Typography>
        <FormHelperText
          style={{ fontSize: "14px", color: "#666", marginBottom: "5px" }}
        >
          A report regarding the intimations given to affected Data Principals.
        </FormHelperText>
        <TextareaAutosize
          minRows={3}
          name="intimations_to_dps"
          value={formData.intimations_to_dps}
          onChange={handleInputChange}
          style={{ width: "100%", padding: 8, fontSize: 14, marginBottom: 16 }}
        />
        {errors.intimations_to_dps && (
          <p style={{ color: "red", fontSize: "12px", margin: 0 }}>
            {errors.intimations_to_dps}
          </p>
        )}
      </CommonDrawer>
    </Box>
  );
};

export default DataBreachDetails;
