import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Paper } from "@mui/material";
import CommonSubHeader from "../../CommonComponent/CommonSubHeader";
import {
  getWebsiteById,
  updateWebsite,
  // getAllLanguages,
} from "../../api/websiteService";
import Loader from "../../CommonComponent/Loader";
import TimezoneSelect from "react-timezone-select";
import ButtonCommon from "../../CommonComponent/ButtonCommon";
import { showErrorToast, showSuccessToast } from "../../toastUtils";

const GeneralSettings = () => {
  const [websiteDetails, setWebsiteDetails] = useState({});
  const [loading, setLoading] = useState(false);
  // const [loadingLanguages, setLoadingLanguages] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [isTimezoneEditable, setIsTimezoneEditable] = useState(false);
  // const [isLanguageEditable, setIsLanguageEditable] = useState(false);
  // const [languages, setLanguages] = useState([]);
  // const [defaultLanguage, setDefaultLanguage] = useState("");
  // const [supportedLanguages, setSupportedLanguages] = useState([]);

  const fetchWebsiteById = async () => {
    try {
      setLoading(true);
      const websiteId = localStorage.getItem("website_id");
      const data = await getWebsiteById(websiteId);
      if (data?.status_code === 200) {
        setLoading(false);
        setWebsiteDetails(data?.data);
        setSelectedTimezone(data?.data?.time_zone);

        // Initialize language settings
        // if (data?.data?.language) {
        // setDefaultLanguage(data.data.language.default_language);
        // setSupportedLanguages(data.data.language.supported_languages || []);
        // } else {
        // Default values if not set
        // setDefaultLanguage("en");
        // setSupportedLanguages(["en"]);
        // }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // const fetchLanguages = async () => {
  //   try {
  //     setLoadingLanguages(true);
  //     const response = await getAllLanguages();
  //     if (response?.status_code === 200 && response?.data) {
  //       setLanguages(response.data);
  //     }
  //     setLoadingLanguages(false);
  //   } catch (error) {
  //     console.error("Error fetching languages:", error);
  //     setLoadingLanguages(false);
  //   }
  // };

  useEffect(() => {
    fetchWebsiteById();
    // fetchLanguages();
  }, []);

  const handleTimezoneChange = async (timezone) => {
    setSelectedTimezone(timezone);
    setWebsiteDetails((prevDetails) => ({
      ...prevDetails,
      time_zone: timezone?.value,
    }));

    try {
      const websiteId = localStorage.getItem("website_id");
      await updateWebsite(websiteId, {
        time_zone: timezone?.value,
      });
      showSuccessToast("Timezone updated successfully!");
      setIsTimezoneEditable(false);
    } catch (error) {
      showErrorToast("Failed to update timezone.");
    }
  };

  const handleEditTimezone = () => {
    setIsTimezoneEditable(true);
  };

  // const handleEditLanguage = () => {
  //   setIsLanguageEditable(true);
  // };

  // const handleDefaultLanguageChange = (event) => {
  //   const newDefaultLanguage = event.target.value;
  //   setDefaultLanguage(newDefaultLanguage);

  //   // Ensure default language is also in supported languages
  //   if (!supportedLanguages.includes(newDefaultLanguage)) {
  //     setSupportedLanguages([...supportedLanguages, newDefaultLanguage]);
  //   }
  // };

  // const handleSupportedLanguagesChange = (event) => {
  //   const newSupportedLanguages = event.target.value;

  //   // Ensure default language is always included in supported languages
  //   if (!newSupportedLanguages.includes(defaultLanguage)) {
  //     newSupportedLanguages.push(defaultLanguage);
  //   }

  //   setSupportedLanguages(newSupportedLanguages);
  // };

  // const saveLanguageSettings = async () => {
  //   try {
  //     const websiteId = localStorage.getItem("website_id");
  //     const languageData = {
  //       language: {
  //         default_language: defaultLanguage,
  //         supported_languages: supportedLanguages,
  //       },
  //     };

  //     await updateWebsite(websiteId, languageData);
  //     showSuccessToast("Language settings updated successfully!");
  //     setIsLanguageEditable(false);
  //   } catch (error) {
  //     console.error("Error updating language settings:", error);
  //     showErrorToast("Failed to update language settings.");
  //   }
  // };

  const [isEditing, setIsEditing] = useState(false);
  const [dpoEmail, setDpoEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [initialDpoEmail, setInitialDpoEmail] = useState(""); // Store original email

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
    return emailRegex.test(email);
  };

  const saveDpoEmail = async () => {
    if (!validateEmail(dpoEmail)) {
      setEmailError("Please enter a valid email address."); // Show error
      return;
    }
    try {
      const websiteId = localStorage.getItem("website_id");
      const dpoData = {
        dpo_email: dpoEmail, // Send updated DPO email
      };

      await updateWebsite(websiteId, dpoData);
      showSuccessToast("DPO email updated successfully!");
      setIsEditing(false); // Disable editing after success
      setEmailError("");
    } catch (error) {
      showErrorToast("Failed to update DPO email.");
    }
  };

  useEffect(() => {
    if (websiteDetails && websiteDetails?.dpo_email) {
      setDpoEmail(websiteDetails.dpo_email);
    }
    // eslint-disable-next-line
  }, [websiteDetails.dpo_email]);

  return (
    <Box>
      <CommonSubHeader
        mediumTitle="Domain Settings"
        subTitle="Configure your website settings and specify where Data Privacy Cloud should operate."
      />

      {loading ? (
        <Loader />
      ) : (
        <Paper
          elevation={1}
          sx={{ p: 3, mb: 4, background: "#F9FAFB", borderRadius: "8px" }}
          variant="outlined"
        >
          {/* Account ID */}
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="body2"
              color="#525455"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Website HashID
            </Typography>
            <TextField
              fullWidth
              margin="none"
              size="small"
              value={websiteDetails?.account_hashid}
              variant="outlined"
              sx={{ mb: 1 }}
              disabled
            />
            <Typography variant="caption" color="text.secondary">
              Your account's unique identifier which is used in your widget
              script and across various features to recognise and manage your
              account.
            </Typography>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography
              variant="body2"
              color="#525455"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Website Domain
            </Typography>
            <TextField
              fullWidth
              size="small"
              margin="none"
              value={websiteDetails?.domain_name}
              variant="outlined"
              sx={{ mb: 1 }}
              disabled
            />
            <Typography variant="caption" color="text.secondary">
              This will be the name of your account and will be displayed in the
              Data Privacy Cloud dashboard.
            </Typography>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography
              variant="body2"
              color="#525455"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Created By
            </Typography>
            <TextField
              fullWidth
              size="small"
              margin="none"
              value={websiteDetails?.created_by}
              variant="outlined"
              sx={{ mb: 1 }}
              disabled
            />
            <Typography variant="caption" color="text.secondary">
              This will be the name of user who added above website.
            </Typography>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Box>
              <Typography
                variant="body2"
                color="#525455"
                sx={{ fontWeight: "bold", mb: 1 }}
              >
                Time Zone
              </Typography>
              <TimezoneSelect
                value={selectedTimezone}
                onChange={handleTimezoneChange}
                placeholder="Select Timezone"
                sx={{ width: "100%", mb: 1 }}
                isDisabled={!isTimezoneEditable}
              />
              <Typography variant="caption" color="text.secondary">
                This will be the timezone of this website.
              </Typography>
            </Box>
            {/* Edit Button */}
            {!isTimezoneEditable && (
              <ButtonCommon sx={{ mt: 1 }} onClick={handleEditTimezone}>
                Edit Timezone
              </ButtonCommon>
            )}
          </Box>

          <Box sx={{ mb: 3, display: "flex", flexDirection: "column" }}>
            <Typography
              variant="body2"
              color="#525455"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              DPO Email
            </Typography>
            <TextField
              fullWidth
              size="small"
              margin="none"
              value={dpoEmail || ""}
              onChange={(e) => setDpoEmail(e.target.value)}
              variant="outlined"
              sx={{ mb: 1 }}
              disabled={!isEditing} // Disable unless in edit mode
              error={!!emailError}
              helperText={emailError}
            />
            <Typography variant="caption" color="text.secondary">
              This is the registered email of the Data Protection Officer (DPO)
              responsible for handling data privacy concerns for this website.
            </Typography>

            <ButtonCommon
              sx={{ mt: 2, width: "max-content" }}
              onClick={() => {
                if (!isEditing) {
                  setIsEditing(true); // Enable input field
                  setInitialDpoEmail(dpoEmail); // Save current value before editing
                } else {
                  saveDpoEmail(); // Save when already editing
                }
              }}
              disabled={loading || (isEditing && dpoEmail === initialDpoEmail)}
            >
              {loading
                ? "Saving..."
                : !dpoEmail
                ? "Add DPO Email"
                : isEditing
                ? "Save DPO Email"
                : "Edit DPO Email"}
            </ButtonCommon>
          </Box>

          {/* Language Settings Section */}
          {/* <Box sx={{ mb: 3 }}>
            <Typography
              variant="body2"
              color="#525455"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Language Settings
            </Typography>


            <Box sx={{ mb: 2 }}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mb: 1 }}
              >
                <InputLabel id="default-language-label">
                  Default Language
                </InputLabel>
                <Select
                  labelId="default-language-label"
                  id="default-language-select"
                  value={defaultLanguage}
                  onChange={handleDefaultLanguageChange}
                  label="Default Language"
                  disabled={!isLanguageEditable || loadingLanguages}
                >
                  {loadingLanguages ? (
                    <MenuItem value="">Loading...</MenuItem>
                  ) : (
                    languages.map((lang) => (
                      <MenuItem
                        key={lang.language_code}
                        value={lang.language_code}
                      >
                        {lang.language_description} ({lang.language_code})
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <Typography variant="caption" color="text.secondary">
                The default language for your website content and user
                interface.
              </Typography>
            </Box>


            <Box sx={{ mb: 2 }}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mb: 1 }}
              >
                <InputLabel id="supported-languages-label">
                  Supported Languages
                </InputLabel>
                <Select
                  labelId="supported-languages-label"
                  id="supported-languages-select"
                  multiple
                  value={supportedLanguages}
                  onChange={handleSupportedLanguagesChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        const langObj = languages.find(
                          (lang) => lang.language_code === value
                        );
                        return (
                          <Chip
                            key={value}
                            label={
                              langObj
                                ? `${langObj.language_description} (${value})`
                                : value
                            }
                            size="small"
                          />
                        );
                      })}
                    </Box>
                  )}
                  label="Supported Languages"
                  disabled={!isLanguageEditable || loadingLanguages}
                >
                  {loadingLanguages ? (
                    <MenuItem value="">Loading...</MenuItem>
                  ) : (
                    languages.map((lang) => (
                      <MenuItem
                        key={lang.language_code}
                        value={lang.language_code}
                      >
                        {lang.language_description} ({lang.language_code})
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <Typography variant="caption" color="text.secondary">
                All languages your website supports. Your default language is
                automatically included.
              </Typography>
            </Box>


            {isLanguageEditable ? (
              <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                <ButtonCommon onClick={saveLanguageSettings}>
                  Save Language Settings
                </ButtonCommon>
                <ButtonCommon
                  variant="outlined"
                  onClick={() => setIsLanguageEditable(false)}
                >
                  Cancel
                </ButtonCommon>
              </Box>
            ) : (
              <ButtonCommon sx={{ mt: 1 }} onClick={handleEditLanguage}>
                Edit Language Settings
              </ButtonCommon>
            )}
          </Box> */}
        </Paper>
      )}
    </Box>
  );
};

export default GeneralSettings;
